// =============================
// Imports
// =============================

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import _find from 'lodash/find';
import _get from 'lodash/get';

import { setMenuOpen, resetModals as resetModalsBase } from '../../../store/actions/ModalsActions';

import MenuButton from '../../presentationals/menu/desktop/menubutton';

// Helpers
import * as pth from '../../../helpers/proptypes';
import { hasAccessToPrivate } from '../../../helpers/user';
import { rootRoute } from '../../../helpers/config';

// =============================
// Component
// =============================

class MenuButtonContainer extends Component {
  static propTypes = {
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      id: PropTypes.string,
      colors: pth.colorsModule,
      isVisible: PropTypes.bool,
      headerLogo: PropTypes.oneOf(['logo', 'logo_variant', 'logo_modal']),
    }),
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** If True, Menu is opened. */
    opened: PropTypes.bool.isRequired,
    /** Styled Components theme. */
    theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Callback function use to toggle Menu. */
    toggleMenu: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
  };

  render() {
    const { hasAccess, opened, toggleMenu, theme, ...props } = this.props;

    return (
      <MenuButton
        colors={theme.colors.currentMenuTheme}
        hasAccess={hasAccess}
        opened={opened}
        toggleMenu={toggleMenu}
        usedLogo={theme.colors.logo}
        {...props}
      />
    );
  }
}

function mapStateToProps({ config, user, modals, search }) {
  // Retrieve 1st visible FullSizeCoverModule
  const currentFullSizeCoverModule = _find(
    search.fullSizeCoverModule,
    item => item.isVisible,
    null,
  );

  return {
    opened: modals.isMenuOpen,
    isPreSearchOpened: modals.isPreSearchOpen,
    isAccountOpened: modals.isAccountOpen,
    fullSizeCoverModule: currentFullSizeCoverModule,
    leftHeaderLinks: _get(config, 'menus.data.header.customLinks.left', []),
    rootRoute: rootRoute(config.data),
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
  };
}

export default connect(mapStateToProps, {
  toggleMenu: v => setMenuOpen(v),
  resetModals: resetModalsBase,
})(withTheme(MenuButtonContainer));
