// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Div } from '@mewo/components';
import { motion } from 'framer-motion';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.scMenu};

  ${({ opened }) => (opened ? css`
    opacity: 1;
    pointer-events: auto;
  ` : css`
    opacity: 0;
    pointer-events: none;
  `)};
`;

export const Relative = styled.div`
  position: relative;
  pointer-events: auto;
`;

export const MenuBackground = styled(motion.div).attrs(({ opened, theme }) => ({
  initial: opened ? 'open' : 'close',
  animate: opened ? 'open' : 'close',
  transition: {
    ease: theme.transitionTiming.pose.easeInOut,
    duration: 0.7,
  },
  variants: {
    close: {
      scale: 0,
    },
    open: {
      scale: 1,
    },
  },
}))`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  height: 400vw;
  width: 400vw;
  position: fixed;
  left: calc(-200vw + 5rem);
  top: calc(-200vw + 5rem);
  z-index: ${({ theme }) => theme.zIndex.scMenuBackground};
`;
