// =============================
// Imports
// =============================

import { createElement, Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

// =============================
// Component
// =============================

function getErrorProps(context, meta, helperText) {
  const noError = {
    error: undefined,
    helperText,
  };

  if (!meta.error) return noError;
  if (context === 'autosave' && !meta.dirty) return noError;
  if (context !== 'autosave' && !meta.touched) return noError;

  return { error: true, helperText: meta.error };
}

/**
 * This component is used as a passerelle between React final form and Mewo component
 */

class MewoInput extends Component {
  static propTypes = {
    /** In which saving context this input is used */
    context: PropTypes.string,
    /** Helper text for input */
    helperText: PropTypes.string,
    /** Prop passed down by React final form */
    input: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Props passed down by React final form */
    meta: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Additional onChange, so it could be used outside of final-form */
    onChangePassthrough: PropTypes.func,
    /** i18n func. */
    t: PropTypes.func.isRequired,
    /** Input Component that will be used */
    use: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    context: '',
    helperText: '',
    onChangePassthrough: () => {},
  };

  render() {
    const {
      context,
      helperText,
      input,
      meta,
      onChangePassthrough,
      t,
      use,
      ...restProps
    } = this.props;

    return createElement(use, {
      ...input,
      onChange: (...args) => {
        onChangePassthrough(...args);
        return input.onChange(...args);
      },
      ...getErrorProps(context, meta, helperText),
      ...restProps,
      isTranslatableMessage: t('mewo_inputs.is_translatable_message'),
    });
  }
}

export default withTranslation('components')(MewoInput);
