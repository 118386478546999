// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { withTranslation } from '../../../../config/i18n';

import { Content, Item, ItemArrow, LinkItem } from './styles';

// =============================
// Component
// =============================

class UserMenu extends PureComponent {
  static displayName = 'UserMenuMobile';

  static propTypes = {
    /** Function used to close UserMenu. */
    closeUserMenu: PropTypes.func.isRequired,
    /** Function used to log user out. */
    logout: PropTypes.func.isRequired,
    /** Function used to open account modal. */
    openAccountModal: PropTypes.func.isRequired,
    /** Translate function. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  render() {
    const { closeUserMenu, logout, openAccountModal, t } = this.props;

    return (
      <ClickAwayListener onClickAway={closeUserMenu}>
        <Content>
          <Item
            onClick={() => {
              closeUserMenu();
              return openAccountModal();
            }}
          >
            {t('components:header.my_account')}
            <ItemArrow />
          </Item>
          <LinkItem route="/userplaylists">
            {t('components:header.my_playlists')}
            <ItemArrow />
          </LinkItem>
          <LinkItem route="/favorites">
            {t('components:header.my_favorites')}
            <ItemArrow />
          </LinkItem>
          <LinkItem route="/recentplays">
            {t('components:header.my_recent_plays')}
            <ItemArrow />
          </LinkItem>
          <Item
            onClick={() => {
              closeUserMenu();
              return logout();
            }}
          >
            {t('components:header.logout')}
            <ItemArrow />
          </Item>
        </Content>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('components')(UserMenu);
