// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';

// =============================
// Global
// =============================

export const descriptions = PropTypes.arrayOf(
  PropTypes.shape({
    locale: PropTypes.string,
    value: PropTypes.string,
  }),
);

export const image = PropTypes.shape({
  original: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  xsmall: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  small: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  large: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  isConverting: PropTypes.bool,
  isConverted: PropTypes.bool,
});

export const waveform = PropTypes.shape({
  small: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  large: PropTypes.shape({
    key: PropTypes.string,
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
});

// =============================
// Options
// =============================

export const rightsType = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  names: descriptions,
  type: PropTypes.string,
});

export const trackVersion = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  names: descriptions,
  searchNames: descriptions,
});

export const trackVersionBase = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  names: descriptions,
});

export const userIndustry = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  names: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
});

export const tag = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  names: descriptions,
  synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
});

export const tagWithCat = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  search_names: descriptions.isRequired,
  search_synonyms: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
  category_id: PropTypes.string.isRequired,
  category_key: PropTypes.string.isRequired,
  category_names: descriptions.isRequired,
  subCategory_id: PropTypes.string,
  subCategory_key: PropTypes.string,
  subCategory_names: descriptions,
});

export const tagSubCategory = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  search_names: descriptions.isRequired,
  category_id: PropTypes.string.isRequired,
  category_key: PropTypes.string.isRequired,
  category_names: descriptions.isRequired,
  tags: PropTypes.arrayOf(tagWithCat).isRequired,
});

export const tagCategory = PropTypes.shape({
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  names: descriptions.isRequired,
  search_names: descriptions.isRequired,
  color: PropTypes.string.isRequired,
  sub_categories: PropTypes.arrayOf(tagSubCategory).isRequired,
  tags: PropTypes.arrayOf(tagWithCat).isRequired,
});

export const tagWithColorAndCatNames = PropTypes.shape({
  id: PropTypes.string,
  color: PropTypes.string,
  names: descriptions,
  category_names: descriptions,
});

// =============================
// User
// =============================

export const user = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  companyName: PropTypes.string,
});

export const fulluser = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  avatarColor: PropTypes.string,
  phoneNumber: PropTypes.string,
  companyName: PropTypes.string,
  industry: userIndustry,
  country: PropTypes.string,
  canDownload: PropTypes.bool,
  canDownloadStems: PropTypes.bool,
  canAccess: PropTypes.bool,
  withNewsletter: PropTypes.bool,
});

export const userNotification = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  message: PropTypes.node,
  key: PropTypes.string,
  seen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  created_at: PropTypes.string,
});

// =============================
// Modules
// =============================

export const moduleBase = {
  id: PropTypes.string,
  type: PropTypes.string,
  visibility: PropTypes.string,
  industries: PropTypes.arrayOf(userIndustry),
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  padding: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  colors: PropTypes.shape({
    background: PropTypes.string,
    text: PropTypes.string,
    primary: PropTypes.string,
    primaryText: PropTypes.string,
    useCustomColor: PropTypes.bool,
  }),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export const spacings = PropTypes.shape({
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  padding: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
});

// =============================
// Config
// =============================

export const config = PropTypes.shape({
  id: PropTypes.string,
  websiteTitle: PropTypes.string,
  blogUrl: PropTypes.string,
  defaultLanguage: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  globalContact: PropTypes.shape({
    address: PropTypes.string,
    addressComplement: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  urlConfig: PropTypes.shape({
    herokuCname: PropTypes.string,
    herokuId: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
  integrations: PropTypes.shape({
    google: PropTypes.shape({
      analyticsCode: PropTypes.string,
    }),
    hotjar: PropTypes.shape({
      trackingId: PropTypes.string,
    }),
    mailchimp: PropTypes.bool,
    intercom: PropTypes.shape({
      appId: PropTypes.string,
    }),
  }),
  defaultUserRights: PropTypes.shape({
    canDownload: PropTypes.bool,
    canDownloadStems: PropTypes.bool,
    canAccess: PropTypes.bool,
  }),
  userRightsWhitelists: PropTypes.arrayOf(
    PropTypes.shape({
      canDownload: PropTypes.bool,
      canDownloadStems: PropTypes.bool,
      canAccess: PropTypes.bool,
      domain: PropTypes.string,
    }),
  ),
  permissions: PropTypes.shape({
    pagesCreation: PropTypes.shape({
      active: PropTypes.bool,
      max: PropTypes.number,
    }),
    userStats: PropTypes.shape({
      active: PropTypes.bool,
    }),
  }),
  privateAccess: PropTypes.bool,
  active: PropTypes.bool,
  tenant: PropTypes.string,
  customisations: PropTypes.shape({
    logo: image,
    logoVariant: image,
    favicon: image,
    fontFamily: PropTypes.string,
    colors: PropTypes.shape({
      variantSettings: PropTypes.shape({
        default: PropTypes.oneOf(['dark', 'light']),
        other: PropTypes.oneOf(['dark', 'light']),
        player: PropTypes.oneOf(['dark', 'light']),
      }),
      light: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
        boxShadow: PropTypes.oneOf(['dark', 'light']),
      }),
      dark: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
        boxShadow: PropTypes.oneOf(['dark', 'light']),
      }),
      header: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
        boxShadow: PropTypes.oneOf(['dark', 'light']),
      }),
      searchBar: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
      }),
      chip: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        outlineBackground: PropTypes.string,
        outlineText: PropTypes.string,
      }),
      filterBar: PropTypes.shape({
        headerFollowsFilterBar: PropTypes.bool,
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
      }),
      playlistWide: PropTypes.shape({
        background: PropTypes.string,
        text: PropTypes.string,
        primary: PropTypes.string,
        primaryText: PropTypes.string,
      }),
      modal: PropTypes.shape({
        primary: PropTypes.string,
        primaryText: PropTypes.string,
      }),
      type: PropTypes.shape({
        cancel: PropTypes.string,
        error: PropTypes.string,
        success: PropTypes.string,
        warning: PropTypes.string,
      }),
    }),
    homepage: PropTypes.shape({
      disableAccess: PropTypes.bool,
      url: PropTypes.string,
    }),
    preSearch: PropTypes.shape({
      fullpage: PropTypes.bool,
    }),
    guidedSearch: PropTypes.shape({
      active: PropTypes.bool,
    }),
    player: PropTypes.shape({
      size: PropTypes.string,
    }),
    playlists: PropTypes.shape({
      useHeaderVariant: PropTypes.bool,
    }),
    filterBar: PropTypes.shape({
      showUnavailableTags: PropTypes.bool,
    }),
    global: PropTypes.shape({
      edges: PropTypes.string,
    }),
    covers: PropTypes.shape({
      filter: PropTypes.shape({
        enable: PropTypes.bool,
      }),
    }),
  }),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const baseConfigMenu = PropTypes.arrayOf(
  PropTypes.shape({
    slug: PropTypes.string,
    names: descriptions,
    page: PropTypes.string,
  }),
);

export const configMenus = PropTypes.shape({
  mainMenu: PropTypes.shape({
    customLinks: PropTypes.shape({
      main: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
          names: descriptions,
          page: PropTypes.string,
          children: baseConfigMenu,
        }),
      ),
      footer: baseConfigMenu,
    }),
    socialLinks: PropTypes.shape({
      twitter: PropTypes.string,
      linkedin: PropTypes.string,
      facebook: PropTypes.string,
      instagram: PropTypes.string,
      spotify: PropTypes.string,
      youtube: PropTypes.string,
      deezer: PropTypes.string,
      applemusic: PropTypes.string,
    }),
  }),
  header: PropTypes.shape({
    customLinks: PropTypes.shape({
      left: baseConfigMenu,
      right: baseConfigMenu,
    }),
    backgroundColor: PropTypes.string,
  }),
  parentPages: PropTypes.shape({
    album: baseConfigMenu,
    artist: baseConfigMenu,
    catalog: baseConfigMenu,
    playlist: baseConfigMenu,
  }),
});

export const configPage = PropTypes.shape({
  id: PropTypes.string,
  defaultTitle: PropTypes.string,
  metaTitles: descriptions,
  metaDescriptions: descriptions,
  active: PropTypes.bool,
  isHome: PropTypes.bool,
  enableSeo: PropTypes.bool,
  slug: PropTypes.string,
  seoPreview: image,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      ...moduleBase,
      data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    }),
  ),
});

// =============================
// Entites
// =============================

export const displayartists = PropTypes.arrayOf(
  PropTypes.shape({
    artist: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
      aliases: PropTypes.arrayOf(PropTypes.string),
    }),
    alias: PropTypes.string,
  }),
);

export const catalog = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  descriptions,
  image,
});

export const album = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  albumRef: PropTypes.string,
  releaseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  descriptions,
  duration: PropTypes.number,
  nbTracks: PropTypes.number,
  displayArtists: displayartists,
  image,
});

export const artist = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
  aliases: PropTypes.arrayOf(PropTypes.string),
  descriptions,
  image,
});

export const label = PropTypes.shape({
  id: PropTypes.string,
  labelName: PropTypes.string,
  image,
});

export const publisher = PropTypes.shape({
  id: PropTypes.string,
  publisherName: PropTypes.string,
  image,
});

export const track = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  displayTitle: PropTypes.string,
  descriptions,
  lyrics: PropTypes.shape({
    locale: PropTypes.string,
    value: PropTypes.string,
  }),
  trackRef: PropTypes.string,
  trackNumber: PropTypes.number,
  releaseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.number,
  isFavorite: PropTypes.bool,
  prsTunecode: PropTypes.string,
  refId: PropTypes.string,
  album: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image,
  }),
  catalog: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  displayArtists: displayartists,
  publishingOwnerships: PropTypes.arrayOf(
    PropTypes.shape({
      publisher,
      rightsType,
    }),
  ),
  masterOwnerships: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        id: PropTypes.string,
        labelName: PropTypes.string,
      }),
      rightsType,
    }),
  ),
  artistsPublishingOwnerships: PropTypes.arrayOf(
    PropTypes.shape({
      artist: PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string,
        aliases: PropTypes.arrayOf(PropTypes.string),
      }),
      alias: PropTypes.string,
      rightsType,
    }),
  ),
  artistsMasterOwnerships: PropTypes.arrayOf(
    PropTypes.shape({
      artist: PropTypes.shape({
        id: PropTypes.string,
        fullName: PropTypes.string,
        aliases: PropTypes.arrayOf(PropTypes.string),
      }),
      alias: PropTypes.string,
      rightsType,
    }),
  ),
  version: trackVersionBase,
  versions: PropTypes.number,
  tags: PropTypes.arrayOf(tag),
  waveform,
});

export const smalltrack = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  displayTitle: PropTypes.string,
  descriptions,
  lyrics: PropTypes.shape({
    locale: PropTypes.string,
    value: PropTypes.string,
  }),
  duration: PropTypes.number,
  album: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    image,
  }),
  catalog: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  displayArtists: displayartists,
  version: trackVersionBase,
});

export const playertrack = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  displayTitle: PropTypes.string,
  duration: PropTypes.number,
  isFavorite: PropTypes.bool,
  displayArtists: displayartists,
  image,
  audiofile: PropTypes.string,
  waveform,
});

export const playlist = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  descriptions,
  createdAt: PropTypes.string,
  duration: PropTypes.number,
  nbTracks: PropTypes.number,
  tenant: user,
  image,
  covers: PropTypes.arrayOf(image),
});

export const pitch = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  descriptions,
  createdAt: PropTypes.string,
  duration: PropTypes.number,
  nbTracks: PropTypes.number,
  tenant: user,
  image,
  covers: PropTypes.arrayOf(image),
});

export const suggestedplaylist = PropTypes.shape({
  id: PropTypes.string,
  tenant: user,
  title: PropTypes.string,
  descriptions,
  image,
  covers: PropTypes.arrayOf(image),
});

export const suggestedalbum = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  albumRef: PropTypes.string,
  releaseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  descriptions,
  catalog: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  displayArtists: displayartists,
  image,
});

export const listuserplaylist = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const userplaylist = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  covers: PropTypes.arrayOf(image),
  user,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

// =============================
// Search
// =============================

export const preSearchDatas = PropTypes.shape({
  albums: PropTypes.shape({
    data: PropTypes.arrayOf(album),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  artists: PropTypes.shape({
    data: PropTypes.arrayOf(artist),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  catalogs: PropTypes.shape({
    data: PropTypes.arrayOf(catalog),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  labels: PropTypes.shape({
    data: PropTypes.arrayOf(label),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  playlists: PropTypes.shape({
    data: PropTypes.arrayOf(playlist),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  publishers: PropTypes.shape({
    data: PropTypes.arrayOf(playlist),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  tracks: PropTypes.shape({
    data: PropTypes.arrayOf(track),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
  tags: PropTypes.shape({
    data: PropTypes.arrayOf(tag),
    total: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }),
  }),
});

export const entitysearchdoc = PropTypes.shape({
  id: PropTypes.string,
  image,
  // All the rest
  title: PropTypes.string,
  // Album
  albumRef: PropTypes.string,
  // Playlist
  covers: PropTypes.arrayOf(image),
  // Catalog
  name: PropTypes.string,
  // Artist
  fullName: PropTypes.string,
  aliases: PropTypes.arrayOf(PropTypes.string),
  // Publisher
  publisherName: PropTypes.string,
  // Label
  labelName: PropTypes.string,
});

export const searchquery = PropTypes.shape({
  query: PropTypes.string,
  options: PropTypes.shape({
    filters: PropTypes.shape({
      catalogs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      playlists: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      artists: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      albums: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      labels: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      publishers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
      bpm: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      year: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      duration: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      stems: PropTypes.bool,
      tags: PropTypes.arrayOf(PropTypes.string),
      tags_or: PropTypes.arrayOf(PropTypes.string),
      tags_not: PropTypes.arrayOf(PropTypes.string),
      versions: PropTypes.arrayOf(PropTypes.string),
      versions_not: PropTypes.arrayOf(PropTypes.string),
    }),
    searchId: PropTypes.arrayOf(PropTypes.string),
    trackId: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.number,
  }),
});

const numberFacet = PropTypes.shape({
  key: PropTypes.string,
  from: PropTypes.number,
  to: PropTypes.number,
  docCount: PropTypes.number,
});

export const facets = PropTypes.shape({
  bpm: PropTypes.arrayOf(numberFacet),
  duration: PropTypes.arrayOf(numberFacet),
  year: PropTypes.arrayOf(numberFacet),
  tags: PropTypes.object.any, // eslint-disable-line react/forbid-prop-types
  versions: PropTypes.object.any, // eslint-disable-line react/forbid-prop-types
});

// =============================
// Color system
// =============================

export const colors = PropTypes.shape({
  background: PropTypes.string,
  text: PropTypes.string,
  primary: PropTypes.string,
  primaryText: PropTypes.string,
});

export const colorsModule = PropTypes.shape({
  background: PropTypes.string,
  text: PropTypes.string,
  primary: PropTypes.string,
  primaryText: PropTypes.string,
  useCustomColor: PropTypes.bool,
});

// =============================
// Dropdown
// =============================

export const dropdownPlacement = PropTypes.oneOf([
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'right',
  'right-start',
  'right-end',
  'left',
  'left-start',
  'left-end',
]);
