// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslation } from '../../../config/i18n';

import Filters from '../../presentationals/filters';

// =============================
// Component
// =============================

function mapStateToProps({ search, options }) {
  return {
    searchQuery: search.tracksSearch.query,
    tagsSc: options.data.tags_sc,
    tagsWithColorsAndCatNames: options.data.tags_colors_cat_names,
    versions: options.data.trackversions,
  };
}

export default compose(withTranslation('components'), connect(mapStateToProps))(Filters);
