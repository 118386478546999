// =============================
// Imports
// =============================

import styled from 'styled-components';
import { alpha } from '@material-ui/core';

import ScrollBar from '../presentationals/scrollbar';

import { headerHeight } from '../presentationals/header/mobile/variables';
import Button from '../presentationals/button';
import { textEditorContentStyles } from '../presentationals/modules/styles';
import { wrapperWidth, wrapperWidthSxga, wrapperWidthWuxga, wrapperWidthWxgaPlus } from './common.variables';
import { paddingX } from '../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const ModalContainer = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: ${headerHeight} ${paddingX} 0;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100vh;
    margin: 0 auto;
    max-width: ${wrapperWidth};
    padding-top: 11.5rem;
    padding-bottom: 5rem;
    width: 100%;

    ${theme.mediaAbove.sxga`
      max-width: ${wrapperWidthSxga};
    `};

    ${theme.mediaAbove.wxgaplus`
      max-width: ${wrapperWidthWxgaPlus};
    `};

    /* We set it on wuxga because if we set it before, content can go behind Menu SVG gradient. */
    ${theme.mediaAbove.wuxga`
      margin: 0 auto;
      max-width: ${wrapperWidthWuxga};
      width: 100%;
    `};
  `};
`;

export const Content = styled.div`
  ${textEditorContentStyles};
`;

export const TitleButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.text};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 3rem;
`;

export const TitleButton = styled(Button).attrs({
  size: 'small',
})``;

export const Separation = styled.div`
  background-color: ${({ theme }) => alpha(theme.colors.text, 0.2)};
  height: 0.1rem;
  margin: 4rem auto;
  user-select: none;
`;

export const StyledScrollBar = styled(ScrollBar).attrs({
  horizontal: false,
  noScrollBar: true,
  speed: 1,
  vertical: true,
})`
  height: 100%;
  mask-image: linear-gradient(transparent 0%, #fff 4rem, #fff);
  mask-mode: luminance;
  mask-repeat: repeat-x;

  && {
    .scrollarea-content {
      padding-bottom: 4rem;
      padding-top: 3rem;
    }
  }
`;
