// =============================
// Imports
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import FileSaver from 'file-saver';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';
import * as miscConfig from '../../config/misc';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import { getApiUrl, getXPreferredLanguage, camelCaseKeysDeep, sleep } from '../../helpers/misc';
import determineError, { MewoError } from '../../helpers/errors';
import { cancelableRequest, cancelRequest } from '../helpers/axios';
import download from '../../helpers/download';

// =============================
// Albums Actions
// =============================

export function getAlbum(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ALBUM_LOADING,
    });

    try {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'x-host': getState().core.serverContext.xHost,
        'x-auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      };

      const { data: albumData } = await cancelableRequest(rqs.GET_ALBUM, {
        method: 'get',
        url: getApiUrl(`public/albums/${id}`),
        headers,
      });

      const { data: albumTagsData } = await cancelableRequest(rqs.GET_ALBUM_TAGS, {
        method: 'get',
        url: getApiUrl(`public/albums/${id}/tracks/tags`),
        headers,
      });

      dispatch({
        type: acts.SET_ALBUM,
        payload: camelCaseKeysDeep({
          ...albumData,
          tags: albumTagsData,
        }),
      });

      dispatch({
        type: acts.GET_ALBUM_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ALBUM_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getAlbumTracks(id, _page = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ALBUM_TRACKS_LOADING,
    });

    try {
      const prevParentId = getState().entities.albumTracks.parentId;
      const page = prevParentId && prevParentId !== id ? 0 : _page;

      const response = await cancelableRequest(rqs.GET_ALBUM_TRACKS, {
        method: 'get',
        url: getApiUrl(
          `public/albums/${id}/tracks?page=${page}&max=${miscConfig.MUSIC_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);
      camelizedResponse.parentId = id;

      dispatch({
        type: acts.SET_ALBUM_TRACKS,
        payload: camelizedResponse,
      });

      dispatch({
        type: acts.GET_ALBUM_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ALBUM_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function resetAlbumAndItsTracks() {
  cancelRequest(rqs.GET_ALBUM);
  cancelRequest(rqs.GET_ALBUM_TRACKS);

  return {
    type: acts.RESET_ALBUM_AND_ITS_TRACKS,
  };
}

export function updateAlbumFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_ALBUM_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateAlbumRecents(id) {
  return {
    type: acts.UPDATE_ALBUM_RECENTS,
    payload: { id },
  };
}

// =============================
// Playlist Actions
// =============================

export function getPlaylist(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_PLAYLIST_LOADING,
    });

    try {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'x-host': getState().core.serverContext.xHost,
        'x-auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
      };

      const { data: playlistData } = await cancelableRequest(rqs.GET_PLAYLIST, {
        method: 'get',
        url: getApiUrl(`public/playlists/${id}`),
        headers,
      });

      const { data: playlistTagsData } = await cancelableRequest(rqs.GET_PLAYLIST_TAGS, {
        method: 'get',
        url: getApiUrl(`public/playlists/${id}/tracks/tags`),
        headers,
      });

      dispatch({
        type: acts.SET_PLAYLIST,
        payload: camelCaseKeysDeep({
          ...playlistData,
          tags: playlistTagsData,
        }),
      });

      dispatch({
        type: acts.GET_PLAYLIST_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_PLAYLIST_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function duplicatePlaylist(originalId, nextName) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.DUPLICATE_PLAYLIST_LOADING,
    });

    try {
      const { data } = await axios.post(
        getApiUrl(`public/playlists/${originalId}/duplicate`),
        { name: nextName },
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
          },
        },
      );

      dispatch({
        type: acts.DUPLICATE_PLAYLIST_SUCCESS,
        payload: {
          id: data.id,
          message: i18n.t('common:user_playlist.duplicate_success'),
        },
      });
    } catch (err) {
      dispatch({
        type: acts.DUPLICATE_PLAYLIST_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function getPlaylistTracks(id, _page = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_PLAYLIST_TRACKS_LOADING,
    });

    try {
      const prevParentId = getState().entities.playlistTracks.parentId;
      const page = prevParentId && prevParentId !== id ? 0 : _page;

      const response = await cancelableRequest(rqs.GET_PLAYLIST_TRACKS, {
        method: 'get',
        url: getApiUrl(
          `public/playlists/${id}/tracks?page=${page}&max=${miscConfig.MUSIC_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);
      camelizedResponse.parentId = id;

      dispatch({
        type: acts.SET_PLAYLIST_TRACKS,
        payload: camelizedResponse,
      });

      dispatch({
        type: acts.GET_PLAYLIST_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;

          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_PLAYLIST_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function resetPlaylistAndItsTracks() {
  cancelRequest(rqs.GET_PLAYLIST);
  cancelRequest(rqs.GET_PLAYLIST_TRACKS);

  return {
    type: acts.RESET_PLAYLIST_AND_ITS_TRACKS,
  };
}

export function updatePlaylistFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_PLAYLIST_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updatePlaylistRecents(id) {
  return {
    type: acts.UPDATE_PLAYLIST_RECENTS,
    payload: { id },
  };
}

// =============================
// Pitch Actions
// =============================

export function getPitch(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_PITCH_LOADING,
    });

    try {
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'x-host': getState().core.serverContext.xHost,
        'x-auth': getState().user.token,
        'x-preferred-language': getXPreferredLanguage(),
        'x-pitch-token': getState().user.pitchToken,
      };

      const { data: pitchData } = await cancelableRequest(rqs.GET_PITCH, {
        method: 'get',
        url: getApiUrl(`public/pitch/${id}`),
        headers,
      });

      const { data: pitchTagsData } = await cancelableRequest(rqs.GET_PITCH_TAGS, {
        method: 'get',
        url: getApiUrl(`public/pitch/${id}/tracks/tags`),
        headers,
      });

      dispatch({
        type: acts.SET_PITCH,
        payload: camelCaseKeysDeep({
          ...pitchData,
          tags: pitchTagsData,
        }),
      });

      dispatch({
        type: acts.GET_PITCH_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_not_found':
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_token_expired':
            message = i18n.t('errors:pitch.invalid_token');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;

          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_PITCH_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getPitchTracks(id, _page = 0) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_PITCH_TRACKS_LOADING,
    });

    try {
      const prevParentId = getState().entities.pitchTracks.parentId;
      const page = prevParentId && prevParentId !== id ? 0 : _page;

      const response = await cancelableRequest(rqs.GET_PITCH_TRACKS, {
        method: 'get',
        url: getApiUrl(`public/pitch/${id}/tracks?page=${page}&max=${miscConfig.MUSIC_ITEMS_PER_PAGE}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
        },
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);
      camelizedResponse.parentId = id;

      dispatch({
        type: acts.SET_PITCH_TRACKS,
        payload: camelizedResponse,
      });

      dispatch({
        type: acts.GET_PITCH_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_not_found':
          case err.response
            && err.response.status === 404
            && err.response.data.key === 'pitch_token_expired':
            message = i18n.t('errors:pitch.invalid_token');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;

          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;

          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_PITCH_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function resetPitchAndItsTracks() {
  cancelRequest(rqs.GET_PITCH);
  cancelRequest(rqs.GET_PITCH_TRACKS);

  return {
    type: acts.RESET_PITCH_AND_ITS_TRACKS,
  };
}

export function updatePitchFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_PITCH_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updatePitchRecents(id) {
  return {
    type: acts.UPDATE_PITCH_RECENTS,
    payload: { id },
  };
}

// =============================
// Artists Actions
// =============================

export function getArtist(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ARTIST_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_ARTIST, {
        method: 'get',
        url: getApiUrl(`public/artists/${id}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_ARTIST,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_ARTIST_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            statusCode = 404;
            type = 'NOT_FOUND';
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ARTIST_FAILURE,
          payload: {
            message,
            statusCode,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          error: err,
          type,
        });
      }
    }
  };
}

export function getArtistTracks(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ARTIST_TRACKS_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_ARTIST_TRACKS, {
        method: 'get',
        url: getApiUrl(`public/artists/${id}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_ARTIST_TRACKS,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_ARTIST_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ARTIST_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getArtistAlbums(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ARTIST_ALBUMS_LOADING,
    });

    const { parentId: prevParentId, isFetchedOnce, page } = getState().entities.artistAlbums;
    const nextPage = (prevParentId && prevParentId !== id) || !isFetchedOnce ? 0 : page + 1;

    try {
      const response = await cancelableRequest(rqs.GET_ARTIST_ALBUMS, {
        method: 'get',
        url: getApiUrl(
          `public/artists/${id}/albums?page=${nextPage}&max=${miscConfig.COVER_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);
      camelizedResponse.parentId = id;

      dispatch({
        type: acts.SET_ARTIST_ALBUMS,
        payload: camelizedResponse,
      });

      dispatch({
        type: acts.GET_ARTIST_ALBUMS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ARTIST_ALBUMS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getArtistAlbumsAppearsOn(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ARTIST_ALBUMS_APPEARS_ON_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_ARTIST_ALBUMS_APPEARS_ON, {
        method: 'get',
        url: getApiUrl(
          `public/artists/${id}/albums/appears?max=${miscConfig.COVER_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_ARTIST_ALBUMS_APPEARS_ON,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_ARTIST_ALBUMS_APPEARS_ON_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400:
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ARTIST_ALBUMS_APPEARS_ON_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getArtistPlaylistsAppearsOn(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_ARTIST_PLAYLISTS_APPEARS_ON, {
        method: 'get',
        url: getApiUrl(
          `public/artists/${id}/playlists/appears?&max=${miscConfig.COVER_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_ARTIST_PLAYLISTS_APPEARS_ON,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400:
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function resetArtistAndRelatedData() {
  cancelRequest(rqs.GET_ARTIST);
  cancelRequest(rqs.GET_ARTIST_TRACKS);
  cancelRequest(rqs.GET_ARTIST_ALBUMS);
  cancelRequest(rqs.GET_ARTIST_ALBUMS_APPEARS_ON);
  cancelRequest(rqs.GET_ARTIST_PLAYLISTS_APPEARS_ON);

  return {
    type: acts.RESET_ARTIST_AND_RELATED_DATA,
  };
}

export function updateArtistFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_ARTIST_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateArtistRecents(id) {
  return {
    type: acts.UPDATE_ARTIST_RECENTS,
    payload: { id },
  };
}

// =============================
// Catalogs Actions
// =============================

export function getCatalog(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CATALOG_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_CATALOG, {
        method: 'get',
        url: getApiUrl(`public/catalogs/${id}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_CATALOG,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CATALOG_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_CATALOG_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getCatalogTracks(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CATALOG_TRACKS_LOADING,
    });

    try {
      const response = await cancelableRequest(rqs.GET_CATALOG_TRACKS, {
        method: 'get',
        url: getApiUrl(`public/catalogs/${id}/tracks`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_CATALOG_TRACKS,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_CATALOG_TRACKS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_CATALOG_TRACKS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function getCatalogAlbums(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_CATALOG_ALBUMS_LOADING,
    });

    const { parentId: prevParentId, isFetchedOnce, page } = getState().entities.catalogAlbums;
    const nextPage = (prevParentId && prevParentId !== id) || !isFetchedOnce ? 0 : page + 1;

    try {
      const response = await cancelableRequest(rqs.GET_CATALOG_ALBUMS, {
        method: 'get',
        url: getApiUrl(
          `public/catalogs/${id}/albums?page=${nextPage}&max=${miscConfig.COVER_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      const camelizedResponse = camelCaseKeysDeep(response.data);
      camelizedResponse.parentId = id;

      dispatch({
        type: acts.SET_CATALOG_ALBUMS,
        payload: camelizedResponse,
      });

      dispatch({
        type: acts.GET_CATALOG_ALBUMS_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let message;
        let type;
        let statusCode;

        switch (true) {
          case err.response && err.response.status === 400: // ID is not ObjectID
          case err.response
            && err.response.status === 404
            && err.response.data.key !== 'config_not_found':
            message = i18n.t('pages:not_found.desc');
            type = 'NOT_FOUND';
            statusCode = 404;
            break;
          default:
            message = determineError(err);
        }

        dispatch({
          type: acts.GET_CATALOG_ALBUMS_FAILURE,
          payload: {
            message,
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({
          message,
          statusCode,
          error: err,
          type,
        });
      }
    }
  };
}

export function resetCatalogAndRelatedData() {
  cancelRequest(rqs.GET_CATALOG);
  cancelRequest(rqs.GET_CATALOG_TRACKS);
  cancelRequest(rqs.GET_CATALOG_ALBUMS);

  return {
    type: acts.RESET_CATALOG_AND_RELATED_DATA,
  };
}

export function updateCatalogFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_CATALOG_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateCatalogRecents(id) {
  return {
    type: acts.UPDATE_CATALOG_RECENTS,
    payload: { id },
  };
}

export function pollArchiveDownload(docId, jobId) {
  return async (dispatch, getState) => {
    try {
      const res = await axios({
        method: 'get',
        url: getApiUrl(`public/archive/pitch/${docId}/${jobId}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
        },
      });

      if (res.data.status === 'FAILED') {
        return dispatch({
          type: acts.POLLING_DOWNLOAD_ARCHIVE_FAILURE,
          payload: {
            message: i18n.t('errors:pitch.job_failed'),
            reqId: res.data.reqId,
          },
        });
      }

      if (res.data.status === 'COMPLETED') {
        return download(res.data.result.url);
      }

      await sleep(2000);

      return dispatch(pollArchiveDownload(docId, jobId));
    } catch (err) {
      let message;

      switch (true) {
        case err.response && err.response.status === 404:
          message = i18n.t('errors:pitch.job_not_found');
          break;

        default:
          message = determineError(err);
      }

      return dispatch({
        type: acts.POLLING_DOWNLOAD_ARCHIVE_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function downloadArchives(
  context,
  documentIds,
  quality = 'original',
  withVersions = false,
  email = '',
) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.DOWNLOAD_ARCHIVES_LOADING,
    });

    try {
      const res = await axios({
        method: 'post',
        url: getApiUrl('public/archive'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
        },
        data: {
          context,
          documentIds,
          quality,
          withVersions,
          ...(email ? { email } : {}),
        },
      });

      dispatch({
        type: acts.DOWNLOAD_ARCHIVES_SUCCESS,
        payload: {
          message: i18n.t('common:download_archive.success'),
        },
      });

      if (getState().user.pitchToken) {
        dispatch(pollArchiveDownload(documentIds[0], res.data[0]));
      }
    } catch (err) {
      let message;

      switch (true) {
        case err.response
          && err.response.status === 406
          && err.response.data.key === 'pitch_archive_limit':
          message = i18n.t('errors:pitch.archive_limit');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_not_found':
        case err.response
          && err.response.status === 404
          && err.response.data.key === 'pitch_token_expired':
          message = i18n.t('errors:pitch.invalid_token');
          break;

        case err.response
          && err.response.status === 406
          && err.response.data.message === 'too_many_jobs'
          && err.response.data.key === 'job_creation_failed':
          message = i18n.t('errors:download_archive.too_many_jobs');
          break;

        case err.response
          && err.response.status === 406
          && err.response.data.key !== 'job_creation_failed':
          message = i18n.t('errors:download_archive.no_permission');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          message = i18n.t('errors:download_archive.not_found', { count: documentIds.length });
          break;

        case err.response
          && err.response.status === 400
          && err.response.data.key === 'no_tracks':
          message = i18n.t('errors:download_archive.no_tracks', { count: documentIds.length });
          break;

        case err.response
          && err.response.status === 400
          && err.response.data.key === 'too_many_tracks':
          message = i18n.t('errors:download_archive.too_many_tracks', { count: documentIds.length });
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.DOWNLOAD_ARCHIVES_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function downloadCopyright(context, docId, docName) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.DOWNLOAD_COPYRIGHT_LOADING,
    });

    try {
      const response = await axios({
        method: 'get',
        url: getApiUrl(`public/copyright/${context}/${docId}`),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
          'x-pitch-token': getState().user.pitchToken,
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${docName} - Copyright.txt`);

      dispatch({
        type: acts.DOWNLOAD_COPYRIGHT_SUCCESS,
        payload: {
          message: i18n.t('common:download_copyright.success'),
        },
      });
    } catch (err) {
      let message;

      switch (true) {
        case err.response && err.response.status === 406:
          message = i18n.t('errors:download_copyright.no_permission');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          message = i18n.t('errors:download_copyright.not_found');
          break;

        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.DOWNLOAD_COPYRIGHT_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}
