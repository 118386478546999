// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxInput as Unwrapped } from '@mewo/components';

// Components
import { withTranslation } from '../../../../config/i18n';

// =============================
// Component
// =============================

class CheckboxInput extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Unwrapped
        lang={{
          Label: {
            optional: t('components:mewo_inputs.Label.optional'),
          },
        }}
        {...rest}
      />
    );
  }
}

export default withTranslation('components')(CheckboxInput);
