// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// Actions
import { updateVersionsFavorites } from '../actions/TrackActions';
import {
  updateAlbumFavorites,
  updatePlaylistFavorites,
  updateArtistFavorites,
  updateCatalogFavorites,
} from '../actions/EntitiesActions';
import { updateUserPlaylistFavorites } from '../actions/UserPlaylistsActions';
import { updateTracksSearchFavorites } from '../actions/SearchActions';
import { updatePlayerFavorites } from '../actions/PlayerActions';
import { updateRecentPlaysFavorites } from '../actions/RecentPlaysActions';
import { updateModalsFavorites } from '../actions/ModalsActions';

// =============================
// Middleware
// =============================

function updateTracksFavoriteStatus(store, id, isFavorite) {
  store.dispatch(updateModalsFavorites(id, isFavorite));
  store.dispatch(updateVersionsFavorites(id, isFavorite));
  store.dispatch(updateAlbumFavorites(id, isFavorite));
  store.dispatch(updatePlaylistFavorites(id, isFavorite));
  store.dispatch(updateArtistFavorites(id, isFavorite));
  store.dispatch(updateCatalogFavorites(id, isFavorite));
  store.dispatch(updateUserPlaylistFavorites(id, isFavorite));
  store.dispatch(updateTracksSearchFavorites(id, isFavorite));
  store.dispatch(updatePlayerFavorites(id, isFavorite));
  store.dispatch(updateRecentPlaysFavorites(id, isFavorite));
}

/**
 * This middleware is used to update tracks everywhere in the application
 * without doing any api requests
 */
const favoritesMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.ADD_TO_FAVORITES_SUCCESS:
      updateTracksFavoriteStatus(store, action.payload, true);
      return next(action);

    case action.type === acts.REMOVE_FROM_FAVORITES_SUCCESS:
      updateTracksFavoriteStatus(store, action.payload, false);
      return next(action);

    default:
      return next(action);
  }
};

export default favoritesMiddleware;
