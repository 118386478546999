/* eslint-disable react/no-multi-comp, react/no-array-index-key */
// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import _get from 'lodash/get';

import { ConditionalWrapper } from '@mewo/components';
import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import WaveformWrapper from '../waveformwrapper';
import Link from '../../../../other/link';

import {
  getTrackAlbum,
  getTrackArtistsMasterOwnerships,
  getTrackArtistsPublishingOwnerships,
  getNextSeekValue,
  getTrackTitle,
} from '../../../../../helpers/entity/track';
import { getSearchUrl, getBaseRoute, getUrl } from '../../../../../helpers/entity';
import { presentDuration } from '../../../../../helpers/entity/common';
import { getValueByLocale } from '../../../../../helpers/i18n';
import * as pth from '../../../../../helpers/proptypes';

import {
  VersionName,
  DetailsAnimation,
  AlbumColumn,
  YearColumn,
  LabelColumn,
  PublisherColumn,
  PRSColumn,
  ArtistRole,
  ArtistColumn,
  DetailsTitle,
  DetailsValue,
  VersionColumn,
  VersionDuration,
  VersionActions,
  MagicWandIcon,
  AddToPlaylistIcon,
  ThreeDotIcon,
  ShareIcon,
  PlayIcon,
  PauseIcon,
  StyledTooltip,
  StyledLink,
  AddToFavoriteIcon,
  VersionTitle,
  Details,
  VersionMusicItem,
  StyledAddToPlaylist,
  StyledShareButton,
  StyledDropdownButton,
  VersionRecentlyPlayedIcon,
  VersionVersions,
  BPMColumn,
} from '../styles';

// =============================
// Component
// =============================

class DetailsComponent extends PureComponent {
  static propTypes = {
    /** Name of the player context */
    contextName: PropTypes.string,
    pitchToken: PropTypes.string,
    /** rendered display artists */
    displayArtists: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Player current time */
    getCurrentTime: PropTypes.func,
    /** Player getMedia function */
    getMedia: PropTypes.func.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Function to trigger download copyright */
    handleDownloadCopyright: PropTypes.func.isRequired,
    /** Asserts if the track is current */
    isCurrent: PropTypes.func.isRequired,
    /** Asserts versions are fetching */
    isFetchingVersions: PropTypes.bool.isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Asserts if the track is being played */
    isPlaying: PropTypes.func.isRequired,
    /** Locale used by user */
    locale: PropTypes.string,
    /** Music object */
    music: pth.track.isRequired,
    /** Player play function */
    play: PropTypes.func.isRequired,
    /** Callback function called when user click on the Heart button */
    onAddToFavorites: PropTypes.func.isRequired,
    /** Callback function called when user click on the Delete button */
    onDelete: PropTypes.func,
    /** Handle click on MagicWand icon */
    onMagicWand: PropTypes.func.isRequired,
    /** Handle click on pause icon */
    onPause: PropTypes.func.isRequired,
    /** details opened */
    opened: PropTypes.bool.isRequired,
    /** Render alternative title */
    renderAlternativeTitle: PropTypes.func.isRequired,
    /** Render alternative title */
    renderFilesDropdown: PropTypes.func.isRequired,
    /** Player seek function */
    seek: PropTypes.func.isRequired,
    /** Open track lyrics modal */
    setTrackLyricsOpen: PropTypes.func.isRequired,
    clickableLinks: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Versions */
    versions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    contextName: null,
    getCurrentTime: null,
    locale: null,
    onDelete: null,
    clickableLinks: true,
    pitchToken: null,
  };

  renderAlbumName = () => {
    const { music, clickableLinks } = this.props;

    const album = getTrackAlbum(music);

    if (album.id) {
      return (
        <ConditionalWrapper
          condition={clickableLinks}
          Wrapper={<StyledLink route={getBaseRoute('albums')} nextAs={getUrl('albums')(album)} />}
        >
          {album.title}
        </ConditionalWrapper>
      );
    }

    return '';
  };

  renderReleaseDate = () => {
    const { music } = this.props;

    if (music.releaseDate) return new Date(music.releaseDate).getFullYear();
    return '';
  };

  getOwnershipsByKey = (collection = [], key) => collection.filter(o => _get(o, 'rightsType.key') === key);

  renderLabels = (uniqColumn = false) => {
    const { music, t } = this.props;
    const labels = this.getOwnershipsByKey(_get(music, 'masterOwnerships', []), 'label');

    const len = labels.length;

    if (!len) return null;

    // NOTE: Do not render as column in description if it's too long
    if (len > 1 && !uniqColumn) return null;
    // NOTE: Do not render as line in description if it's too short
    if (len <= 1 && uniqColumn) return null;

    return (
      <LabelColumn fullWidth={len > 1}>
        <DetailsTitle>{t('components:music_item.label')}</DetailsTitle>
        <DetailsValue>
          {labels.map((doc, i) => (
            <Fragment key={`labels-${doc.id}-${i}`}>
              {_get(doc, 'label.labelName')}
              {i < len - 1 && ', '}
            </Fragment>
          ))}
        </DetailsValue>
      </LabelColumn>
    );
  };

  renderPublishers = (uniqColumn = false) => {
    const { music, t } = this.props;
    const publishers = this.getOwnershipsByKey(_get(music, 'publishingOwnerships'), 'publisher');

    const len = publishers.length;

    if (!len) return null;

    // NOTE: Do not render as column in description if it's too long
    if (len > 1 && !uniqColumn) return null;
    // NOTE: Do not render as line in description if it's too short
    if (len <= 1 && uniqColumn) return null;

    return (
      <PublisherColumn fullWidth={len > 1}>
        <DetailsTitle>{t('components:music_item.publisher')}</DetailsTitle>
        <DetailsValue>
          {publishers.map((doc, i) => (
            <Fragment key={`publishers-${doc.id}-${i}`}>
              {_get(doc, 'publisher.publisherName')}
              {i < len - 1 && ', '}
            </Fragment>
          ))}
        </DetailsValue>
      </PublisherColumn>
    );
  };

  renderBPM = () => {
    const { music, t } = this.props;

    const bpm = _get(music, 'bpm', '');

    if (!bpm) return null;

    return (
      <BPMColumn>
        <DetailsTitle>{t('components:music_item.bpm')}</DetailsTitle>
        <DetailsValue>{parseInt(bpm, 10)}</DetailsValue>
      </BPMColumn>
    );
  };

  renderPRSCode = () => {
    const { music, t } = this.props;

    const prsCode = _get(music, 'prsTunecode', '');

    if (!prsCode) return null;

    return (
      <PRSColumn>
        <DetailsTitle>{t('components:music_item.prs_code')}</DetailsTitle>
        <DetailsValue>{prsCode}</DetailsValue>
      </PRSColumn>
    );
  };

  renderArtistsOwnerships = () => {
    const { displayArtists, locale, music, clickableLinks } = this.props;

    const amos = getTrackArtistsMasterOwnerships(music, locale);
    const apos = getTrackArtistsPublishingOwnerships(music, locale);

    const aos = [...amos, ...apos];
    const len = aos.length;

    if (!len) return null;

    return aos.map((ao, i) => (
      <Fragment key={`ao-${ao.id}-${i}`}>
        {i === 0 && displayArtists.length > 0 && ', '}
        <ConditionalWrapper
          condition={clickableLinks}
          Wrapper={<StyledLink inline route={getSearchUrl('artistsOwnerships')(ao)} />}
        >
          {ao.name}
          <ArtistRole>
            &nbsp;(
            {ao.rightsType}
            )
          </ArtistRole>
        </ConditionalWrapper>
        {i < len - 1 && ', '}
      </Fragment>
    ));
  };

  renderEmptyVersionMusicItems = (music) => {
    const { versions } = music;
    const retVal = [];

    for (let i = 0, len = versions - 1; i < len; i += 1) {
      retVal.push(<VersionMusicItem key={i} />);
    }

    return retVal;
  };

  renderDropdownButton = (music) => {
    const { handleDownloadCopyright, isLogged, setTrackLyricsOpen, t, pitchToken } = this.props;

    if (!isLogged && !pitchToken) {
      return (
        <StyledTooltip content={t('components:music_item.other_actions')}>
          <Link route="/login">
            <ThreeDotIcon />
          </Link>
        </StyledTooltip>
      );
    }

    const options = [];

    // Show lyrics
    if (_get(music, 'lyrics')) {
      options.push({
        action: () => setTrackLyricsOpen(true, music),
        label: t('components:music_item.see_lyrics'),
        name: 'see_lyrics',
      });
    }

    // Download Copyright
    options.push({
      action: (isLogged || pitchToken) ? () => handleDownloadCopyright(music) : null,
      label: t('components:music_item.download_copyright'),
      name: 'download_copyright',
      authRequired: !pitchToken,
    });

    return (
      <StyledDropdownButton
        noButton
        options={options}
        togglerElement={(
          <StyledTooltip content={t('components:music_item.other_actions')}>
            <ThreeDotIcon />
          </StyledTooltip>
        )}
      />
    );
  };

  renderVersionMusicItem = (music) => {
    const {
      contextName,
      isLogged,
      getCurrentTime,
      getMedia,
      hasAccess,
      isCurrent: isCurrentFnc,
      isPlaying: isPlayingFnc,
      locale,
      music: sourceMusic,
      onAddToFavorites,
      onDelete,
      onMagicWand,
      onPause,
      play,
      seek,
      renderAlternativeTitle,
      renderFilesDropdown,
      clickableLinks,
      t,
    } = this.props;

    // If version is the same as the base version do not render
    if (sourceMusic.id === music.id) return null;

    // Check if version is playing
    const isCurrent = isCurrentFnc(music.id, playerCtx.MUSIC_ITEM_VERSION);
    const isPlaying = isPlayingFnc(music.id, playerCtx.MUSIC_ITEM_VERSION);

    // Play function
    const playFnc = isCurrent ? play : () => getMedia(music.id, playerCtx.MUSIC_ITEM_VERSION);
    const seekFnc = isCurrent
      ? seekValue => seek(getNextSeekValue(_get(music, 'duration', 0), seekValue))
      : () => getMedia(music.id, playerCtx.MUSIC_ITEM_VERSION);

    // Only show magic search if duration is above 10 seconds and below 20 minutes
    const hasMagicSearch = _get(music, 'duration', 0) >= 10 && _get(music, 'duration', 0) <= 1200;

    const isRecentlyPlayed = contextName !== playerCtx.USER_RECENTS && _get(music, 'recentlyPlayed', false);

    return (
      <VersionMusicItem isCurrent={isCurrent} key={`${sourceMusic.id}-${music.id}`}>
        {isRecentlyPlayed && (
          <VersionRecentlyPlayedIcon title={t('components:music_item.recently_played')} />
        )}
        <VersionColumn>
          {!isPlaying && <PlayIcon onClick={playFnc} />}
          {isPlaying && <PauseIcon onClick={onPause} />}
        </VersionColumn>
        <VersionName>{renderAlternativeTitle(music)}</VersionName>
        <VersionDuration>{music.duration && presentDuration(music.duration)}</VersionDuration>
        <VersionVersions>
          {getValueByLocale(_get(music, 'version.names', []), locale)}
        </VersionVersions>
        <WaveformWrapper
          music={music}
          isCurrent={isCurrent}
          isPlaying={isPlaying}
          getMedia={getMedia}
          seek={seek}
          seekFnc={seekFnc}
          getCurrentTime={getCurrentTime}
          hasAccess={hasAccess}
          type="version"
        />
        {hasAccess && (
          <VersionActions hasAccess={hasAccess} hasDeleteIcon={!!onDelete}>
            {hasMagicSearch && clickableLinks && (
              <StyledTooltip content={t('components:music_item.magical_search')}>
                <MagicWandIcon onClick={() => onMagicWand(music)} />
              </StyledTooltip>
            )}

            {renderFilesDropdown(music)}

            {isLogged && clickableLinks && (
              <Fragment>
                <StyledAddToPlaylist
                  type="track"
                  itemId={music.id}
                  togglerElement={(
                    <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                      <AddToPlaylistIcon />
                    </StyledTooltip>
                  )}
                />
                <StyledTooltip content={t('components:music_item.add_to_favorite')}>
                  <AddToFavoriteIcon
                    checked={isLogged && music.isFavorite}
                    onClick={() => {
                      onAddToFavorites(music);
                    }}
                  />
                </StyledTooltip>
              </Fragment>
            )}

            {!isLogged && clickableLinks && (
              <Fragment>
                <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                  <Link route="/login">
                    <AddToPlaylistIcon />
                  </Link>
                </StyledTooltip>
                <StyledTooltip content={t('components:music_item.add_to_favorite')}>
                  <Link route="/login">
                    <AddToFavoriteIcon />
                  </Link>
                </StyledTooltip>
              </Fragment>
            )}

            {clickableLinks && (
              <StyledShareButton
                url={getUrl('tracks')(music)}
                text={getTrackTitle(music)}
                togglerElement={(
                  <StyledTooltip content={t('components:music_item.share')}>
                    <ShareIcon />
                  </StyledTooltip>
                )}
              />
            )}

            {this.renderDropdownButton(music)}
          </VersionActions>
        )}

        {!hasAccess && (
          <VersionActions hasAccess={hasAccess}>
            {renderFilesDropdown(music)}

            {this.renderDropdownButton(music)}
          </VersionActions>
        )}
      </VersionMusicItem>
    );
  };

  render() {
    const { displayArtists, isFetchingVersions, music, opened, versions, t } = this.props;

    return (
      <AnimatePresence>
        {opened && (
          <DetailsAnimation>
            <Details>
              {this.renderAlbumName() && (
                <AlbumColumn>
                  <DetailsTitle>{t('components:music_item.album')}</DetailsTitle>
                  <DetailsValue>{this.renderAlbumName()}</DetailsValue>
                </AlbumColumn>
              )}
              {this.renderReleaseDate() && (
                <YearColumn>
                  <DetailsTitle>{t('components:music_item.year')}</DetailsTitle>
                  <DetailsValue>{this.renderReleaseDate()}</DetailsValue>
                </YearColumn>
              )}
              {this.renderLabels()}
              {this.renderPublishers()}
              {this.renderPRSCode()}
              {this.renderBPM()}
            </Details>
            <Details fullWidth>{this.renderLabels(true)}</Details>
            <Details fullWidth>{this.renderPublishers(true)}</Details>
            {this.renderArtistsOwnerships() && (
              <Details fullWidth>
                <ArtistColumn>
                  <DetailsTitle>{t('components:music_item.artists')}</DetailsTitle>
                  <DetailsValue>
                    {displayArtists}
                    {this.renderArtistsOwnerships()}
                  </DetailsValue>
                </ArtistColumn>
              </Details>
            )}
            {music.versions > 1 && isFetchingVersions && (
              <Fragment>
                <VersionTitle>{t('components:music_item.loading_versions')}</VersionTitle>
                {this.renderEmptyVersionMusicItems(music)}
              </Fragment>
            )}
            {music.versions > 1 && versions.length > 0 && !isFetchingVersions && (
              <Fragment>
                <VersionTitle>{t('components:music_item.versions_and_edit')}</VersionTitle>
                {versions.map(this.renderVersionMusicItem)}
              </Fragment>
            )}
          </DetailsAnimation>
        )}
      </AnimatePresence>
    );
  }
}

export default DetailsComponent;
