// =============================
// Imports
// =============================

import { FilterBar } from './styles';

// =============================
// Exports
// =============================

FilterBar.displayName = 'FilterBarDesktop';

export default FilterBar;
