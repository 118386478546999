/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const NoLogo = styled.div`
  color: ${({ colors }) => colors.text};
  transition: ${({ theme }) => theme.transition.medium};
`;
