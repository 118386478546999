// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _get from 'lodash/get';
import _some from 'lodash/some';

import { ThemedLoader } from './styles';

// =============================
// Component
// =============================

function mapStateToProps({
  auth,
  user,
  pages,
  config,
  suggested,
  favorites,
  entities,
  track,
  userplaylists,
  search,
  player,
  recentplays,
}) {
  return {
    active: _some(
      [
        _get(auth, 'isLoading', false),
        _get(auth, 'recover.isLoading', false),
        _get(auth, 'register.isLoading', false),
        _get(auth, 'resetPassword.isLoading', false),
        _get(auth, 'setPassword.isLoading', false),
        _get(auth, 'activateAccount.isLoading', false),

        _get(user, 'isModifying', false),
        _get(user, 'isModifyingPassword', false),
        _get(user, 'isSubscribingToNewsletter', false),

        _get(pages, 'notifications.isFetching', false),
        _get(pages, 'notifications.isChecking', false),

        _get(config, 'isFetching', false),
        _get(config, 'legal.isFetching', false),
        _get(config, 'menus.isFetching', false),
        _get(config, 'home.isFetching', false),
        _get(config, 'page.isFetching', false),
        _get(config, 'contactEmail.isLoading', false),

        _get(suggested, 'albums.isFetching', false),
        _get(suggested, 'playlists.isFetching', false),

        _get(favorites, 'isFetching', false),
        _get(favorites, 'isAdding', false),
        _get(favorites, 'isRemoving', false),

        _get(entities, 'album.isFetching', false),
        _get(entities, 'albumTracks.isFetching', false),

        _get(entities, 'playlist.isFetching', false),
        _get(entities, 'playlistTracks.isFetching', false),

        _get(entities, 'artist.isFetching', false),
        _get(entities, 'artistTracks.isFetching', false),
        _get(entities, 'artistAlbums.isFetching', false),
        _get(entities, 'artistAlbumsAppearsOn.isFetching', false),
        _get(entities, 'artistPlaylistsAppearsOn.isFetching', false),

        _get(entities, 'catalog.isFetching', false),
        _get(entities, 'catalogTracks.isFetching', false),
        _get(entities, 'catalogAlbums.isFetching', false),

        _get(entities, 'isDownloadingArchives', false),
        _get(entities, 'isPollingDownloadArchive', false),
        _get(entities, 'isDownloadingCopyright', false),

        _get(track, 'isDownloadingAudiofile', false),

        _get(userplaylists, 'isCreating', false),

        _get(userplaylists, 'list.isFetching', false),

        _get(userplaylists, 'playlists.isFetching', false),

        _get(userplaylists, 'playlist.isFetching', false),
        _get(userplaylists, 'playlist.isDuplicating', false),
        _get(userplaylists, 'playlist.isModifying', false),
        _get(userplaylists, 'playlist.isDeleting', false),

        _get(userplaylists, 'playlistTracks.isFetching', false),
        _get(userplaylists, 'playlistTracks.isHandlingTracks', []).length > 0,
        _get(userplaylists, 'playlistTracks.isReordering', false),

        _get(search, 'entitySearch.isFetching', false),
        _get(search, 'preSearch.isFetching', false),
        _get(search, 'tracksSearch.isFetching', false)
          && !_get(search, 'tracksSearch.isDoingMaiaSearch', false),
        _get(search, 'tracksSearch.isSendingMaiaTextSignal', false),

        _get(player, 'isFetching', false),

        _get(recentplays, 'isAdding', false),
        _get(recentplays, 'isFetching', false),
      ],
      e => e,
    ),
  };
}

export default connect(mapStateToProps)(ThemedLoader);
