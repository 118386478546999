// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Div, Chip } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const StyledChip = styled(Chip)`
  margin-bottom: 0.8rem;
  margin-right: 0.8rem;
  min-height: 2.5rem;
`;
