// =============================
// Imports
// =============================

import { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Wrapper } from './styles';

// =============================
// Component
// =============================

const Button = memo(
  forwardRef(
    (
      {
        centered,
        children,
        className,
        color,
        disabled,
        fillVariant,
        fullWidth,
        onClick,
        size,
        theme,
        type,
        ...rest
      },
      ref,
    ) => (
      <Wrapper
        centered={centered}
        className={className}
        color={color}
        disabled={disabled}
        fillVariant={fillVariant}
        fullWidth={fullWidth}
        onClick={onClick}
        ref={ref}
        size={size}
        type={type}
        {...theme.helpers.getViewProps(rest)}
      >
        {children}
      </Wrapper>
    ),
  ),
);

Button.propTypes = {
  /** If set to true, add margin left/right auto */
  centered: PropTypes.bool,
  /** The content of the button. */
  children: PropTypes.node.isRequired,
  /** Props used by styled component to override styles */
  className: PropTypes.string,
  /** The color to use. */
  color: PropTypes.oneOf(['primary', 'cancel', 'error', 'success', 'warning']),
  /** If true, the button will be disabled. */
  disabled: PropTypes.bool,
  /** Button can be eitheir plain or outline. */
  fillVariant: PropTypes.oneOf(['ghost', 'plain', 'outline']),
  /** If true, the button will take up the full width of its container. */
  fullWidth: PropTypes.bool,
  /** Pass onClick handler */
  onClick: PropTypes.func,
  /** Button size. */
  size: PropTypes.oneOf(['small', 'medium']),
  /** Styled component theme. */
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Button type(same as html) */
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

Button.defaultProps = {
  centered: false,
  className: '',
  color: 'primary',
  disabled: false,
  fillVariant: 'plain',
  fullWidth: false,
  onClick: null,
  size: 'medium',
  theme: null,
  type: 'button',
};

export default withTheme(Button);
