// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import Link from '../../../../other/link';

import { svgHoverCirclePath } from '../../../../../helpers/front/svg';

import { headerHeight, headerPaddingX } from '../../../header/desktop/variables';
import { filterBarWidth } from '../../../filterbar/desktop/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${headerHeight};
  justify-content: flex-start;
  left: 0;
  padding-right: ${headerPaddingX};
  position: fixed;
  top: 0;
  width: ${({ fullSizeCoverIsVisible }) => (fullSizeCoverIsVisible ? '55%' : 'auto')};
  z-index: ${({ theme }) => theme.zIndex.scMenuButton};
`;

export const MenuButtonLogo = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding-left: ${headerPaddingX};
  position: relative;
  width: ${filterBarWidth};
`;

export const MenuButtonLogoBackground = styled.div`
  background-color: ${({ theme }) => (theme.colors.filterBar.headerFollowsFilterBar
    ? theme.colors.filterBar.background
    : theme.colors.header.background)};
  height: 100%;
  left: 0;
  opacity: ${({ theme }) => (theme.colors.onDefaultContext ? 0.9 : 0)};
  position: absolute;
  transform: translate3d(-${filterBarWidth}, 0, 0);
  transition: transform ${({ theme }) => theme.transition.medium},
    background-color ${({ theme }) => theme.transition.medium},
    box-shadow ${({ theme }) => theme.transition.medium};
  width: 100%;

  ${({ filterBarOpened }) => filterBarOpened
    && css`
      transform: translate3d(0, 0, 0);
    `};

  ${({ isPreSearchOpened, theme }) => isPreSearchOpened
    && css`
      background-color: ${theme.colors.header.background};
      box-shadow: 0 1.5rem 1rem -1.5rem ${theme.colors.header.boxShadow};
    `};
`;

export const MenuButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-right: 2rem;
  position: relative;
  text-transform: uppercase;
  user-select: none;

  ${({ colors }) => css`
    ${svgHoverCirclePath(colors.text, colors.background, false)};
  `};
`;

export const StyledLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
  textEllipsis: false,
}))`
  ${({ placedOnHeader }) => (placedOnHeader
    ? css`
          &:not(:first-child) {
            margin-left: 1.6rem;

            ${({ theme }) => theme.mediaAbove.uxga`
              margin-left: 4rem;
            `};
          }
        `
    : css`
          &:not(:last-child) {
            margin-right: 1.6rem;

            ${({ theme }) => theme.mediaAbove.uxga`
              margin-right: 4rem;
            `};
          }
        `)};
`;

export const Links = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
`;

export const LeftLinks = styled(Links)`
  margin-left: 2rem;
  transition: opacity ${({ theme }) => theme.transition.fast};
  z-index: -1;

  ${({ opened }) => (!opened
    ? css`
          opacity: 1;
          pointer-events: auto;
        `
    : css`
          opacity: 0;
          pointer-events: none;
        `)};
`;
