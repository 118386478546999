// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';
import { motion } from 'framer-motion';

import LinkBase from '../../../../other/link';
import UserLanguages from '../../../../containers/userLanguages';

import { isHoverPrimary } from '../../../../../helpers/front/getHoverType';

// =============================
// Styles
// =============================

export const MenuContainer = styled(motion.div).attrs(({ opened }) => ({
  animate: opened ? 'open' : 'close',
}))`
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  overflow: hidden;
  padding-left: 12rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.scMenu};

  ${({ opened }) => (opened
    ? css`
      pointer-events: auto;
    `
    : css`
      pointer-events: none;
    `)};

  ${({ theme }) => theme.mediaAbove.sxga`
    padding-left: 18rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    padding-left: 15vw;
  `};
`;

export const SideMenuWrapper = styled(motion.div).attrs(() => ({
  variants: {
    close: {},
    open: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  },
}))`
  align-items: flex-start;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  max-width: 50rem;
  margin-right: 3rem;
  z-index: 1;
`;

export const menuCenterMarginRight = '8rem';
export const menuCenterMarginRightSxga = '18rem';
export const menuCenterMarginRightUxga = '26rem';

export const MenuCenter = styled(motion.div).attrs(() => ({
  variants: {
    close: {
      transition: {
        staggerChildren: 0,
      },
    },
    open: {
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  },
}))`
  flex-shrink: 0;
  margin-right: ${menuCenterMarginRight};
  overflow-wrap: anywhere;
  width: 45rem;
  z-index: 1;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: ${menuCenterMarginRightSxga};
    width: 55rem;
  `};

  ${({ theme }) => theme.mediaAbove.uxga`
    margin-right: ${menuCenterMarginRightUxga};
  `};

 /** Need to be below MenuCenter so sublinks can hide sideMenuWrapper. */
  &:hover + ${SideMenuWrapper} {
    z-index: 0;
  }
`;

export const MenuItemCounter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-size: 2rem;
  height: 4rem;
  margin-left: 2rem;
  min-width: 4rem;
  padding: 0 1.2rem;
  transition: color ${({ theme }) => theme.transition.medium},
    background-color ${({ theme }) => theme.transition.medium};

  ${({ inactive, theme }) => inactive
    && css`
      background-color: ${alpha(theme.colors.text, 0.6)};
      color: ${theme.colors.background};
    `};
`;

export const SideMenu = styled(motion.div).attrs(({ theme }) => ({
  variants: {
    close: {
      x: -20,
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 0.9, // Wait for 3 menu items to be shown
        duration: 0.4,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
}))`
  & > * {
    /** We use translateZ(0) so browsers will use translate3d when we animate it.
    Fix a bug when text go a little bit up after the animation has ended. */
    transform: translateZ(0);
  }
  &:not(:last-child) {
    margin-bottom: 6rem;
  }
`;

export const SideMenuTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2rem;
`;

export const SideMenuItem = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const FooterLinks = styled(motion.div).attrs(({ theme }) => ({
  variants: {
    close: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        delay: 1.15, // Wait for 3 menu items to be shown
        duration: 0.5,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
}))`
  align-items: center;
  bottom: 3rem;
  display: flex;
  margin-left: auto;
  position: fixed;
  right: 3rem;
  z-index: 1;
`;

export const Link = styled(LinkBase)`
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  position: relative;

  svg {
    transition: transform ${({ theme }) => theme.transition.fast}
      ${({ theme }) => theme.transitionTiming.easeInOut};
  }

  &:after {
    background-color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    transition: transform ${({ theme }) => theme.transition.fast}
      ${({ theme }) => theme.transitionTiming.easeInOut};
    transform: scaleX(0);
    transform-origin: left;
    top: 100%;
    width: 100%;
  }

  &:hover {
    color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};

    svg {
      fill: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
    }

    &:after {
      transform: scaleX(1);
    }
  }

  ${({ inactive }) => inactive
    && css`
      color: ${({ theme }) => alpha(theme.colors.text, 0.6)};
    `};
`;

export const DotIcon = styled(Icons.Dot).attrs(({ theme }) => ({
  fill: theme.colors.text,
}))`
  margin: 0 0.8rem;
`;

export const StyledUserLanguages = styled(UserLanguages)``;

export const SocialLinks = styled(motion.div).attrs(({ theme }) => ({
  variants: {
    close: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        delay: 1.15, // Wait for 3 menu items to be shown
        duration: 0.5,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
}))`
  align-items: center;
  display: flex;
  position: fixed;
  right: 3rem;
  top: 3rem;
  z-index: 1;

  ${Link} {
    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`;

export const SocialLink = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const AppleIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmApple,
}))``;

export const DeezerIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmDeezer,
}))``;

export const FacebookIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmFacebook,
}))``;

export const InstagramIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmInstagram,
}))``;

export const LinkedinIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmLinkedin,
}))``;

export const SpotifyIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmSpotify,
}))``;

export const TwitterIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmTwitter,
}))``;

export const YoutubeIcon = styled(SocialLink).attrs(() => ({
  as: Icons.SmYoutube,
}))``;
