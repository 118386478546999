// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import { WithCustomTheme } from '@mewo/components';
import Router from 'next/router';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import SwitchInput from '../inputs/switchinput';

import {
  VeilWrapper,
  TextButton,
  Container,
  PolicyTitle,
  Policy,
  CookieCategoryWrapper,
  CookieChoiceWrapper,
  CookieTitle,
  CookieDesc,
  ButtonsWrapper,
  StyledButton,
  RefuseAllButton,
} from './styles';

// =============================
// Component
// =============================

class CookieConsent extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** Cookies settings for privacy */
    privacyCookies: PropTypes.shape({
      mandatory: PropTypes.bool.isRequired,
      analytics: PropTypes.bool.isRequired,
      media: PropTypes.bool.isRequired,
    }).isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Function used to set privacy cookie. */
    setPrivacyCookie: PropTypes.func.isRequired,
    /** Function used to trigger Cookies' modal closing/opening. */
    setCookiesModalOpen: PropTypes.func.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  state = {
    mounted: false,
    showSettings: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({ mounted: true }));
    }, 1000);
  }

  setCookiesAccepted = (acceptAll = false, refuseAll = false) => {
    const { setPrivacyCookie } = this.props;

    setPrivacyCookie('mandatory', true);

    if (acceptAll && !refuseAll) {
      setPrivacyCookie('analytics', true);
      setPrivacyCookie('media', true);
    } else if (refuseAll) {
      setPrivacyCookie('analytics', false);
      setPrivacyCookie('media', false);
    }
  };

  goToCookies = () => {
    const { hasAccess, setCookiesModalOpen } = this.props;

    this.setCookiesAccepted();

    if (!hasAccess) {
      setCookiesModalOpen(true);
    } else {
      Router.push('/cookies');
    }
  };

  toggleSettings = () => {
    const { showSettings } = this.state;

    this.setState({ showSettings: !showSettings });
  };

  render() {
    const {
      className,
      privacyCookies,
      setPrivacyCookie,
      t,
    } = this.props;
    const { mounted, showSettings } = this.state;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.cookieConsent,
        })}
      >
        <VeilWrapper show={!privacyCookies.mandatory && mounted}>
          <Container className={className}>
            <RefuseAllButton onClick={() => this.setCookiesAccepted(false, true)}>
              {t('components:cookie_consent.refuse_all')}
            </RefuseAllButton>
            <CookieCategoryWrapper>
              <PolicyTitle>{t('components:cookie_consent.cookie_policy_title')}</PolicyTitle>
              <Policy>
                {t('components:cookie_consent.cookie_policy')}
                &nbsp;
              </Policy>
              <TextButton onClick={this.goToCookies}>
                {t('components:cookie_consent.learn_more')}
              </TextButton>
              <CookieDesc>{t('components:cookie_consent.other_cookie_policy')}</CookieDesc>
            </CookieCategoryWrapper>
            {showSettings && (
              <Fragment>
                <CookieCategoryWrapper>
                  <CookieChoiceWrapper>
                    <SwitchInput
                      onChange={value => setPrivacyCookie('analytics', value)}
                      value={privacyCookies.analytics}
                    />
                    <CookieTitle>{t('components:cookie_consent.accept_analytics')}</CookieTitle>
                  </CookieChoiceWrapper>
                  <CookieDesc>{t('components:cookie_consent.accept_analytics_desc')}</CookieDesc>
                </CookieCategoryWrapper>
                <CookieCategoryWrapper>
                  <CookieChoiceWrapper>
                    <SwitchInput
                      onChange={value => setPrivacyCookie('media', value)}
                      value={privacyCookies.media}
                    />
                    <CookieTitle>{t('components:cookie_consent.accept_media')}</CookieTitle>
                  </CookieChoiceWrapper>
                  <CookieDesc>{t('components:cookie_consent.accept_media_desc')}</CookieDesc>
                </CookieCategoryWrapper>
              </Fragment>
            )}
            <ButtonsWrapper>
              {!showSettings && (
                <StyledButton
                  onClick={this.toggleSettings}
                  fillVariant="outline"
                >
                  {t('components:cookie_consent.show_settings')}
                </StyledButton>
              )}
              <StyledButton onClick={() => this.setCookiesAccepted(!showSettings)}>
                {!showSettings && t('components:cookie_consent.accept_all')}
                {showSettings && t('components:cookie_consent.accept_and_close')}
              </StyledButton>
            </ButtonsWrapper>
          </Container>
        </VeilWrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('components')(CookieConsent);
