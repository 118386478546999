// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Router from 'next/router';

import { withTranslation } from '../../../../config/i18n';

import CurrentTimeFetcher from '../../../other/currentTime';
import ModalCloser from '../../../other/modalCloser';

import { encodeAndRemovePercent } from '../../../../helpers/misc';
import { getBaseRoute, getUrl } from '../../../../helpers/entity';
import { presentDuration } from '../../../../helpers/entity/common';
import {
  getTrackTitle,
  getTrackCoverUrl,
  getTrackAlbum,
  getNextSeekValue,
  getTrackDisplayArtists as getTrackDisplayArtistsHelper,
} from '../../../../helpers/entity/track';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  TitleWrapper,
  Title,
  SubTitle,
  MobileActionList,
  MobileAction,
  SimilarIcon,
  LyricsIcon,
  AddToPlaylistIcon,
  AddToFavoritesIcon,
  AlbumIcon,
  ArtistsIcon,
  CopyrightIcon,
  TagsIcon,
  VersionIcon,
} from '../styles';
import {
  Controls,
  CurrentTime,
  NextIcon,
  PlayerBox,
  PlayIcon,
  PrevIcon,
  PauseIcon,
  StyledWaveform,
} from './styles';

// =============================
// Stories
// =============================

class ModalMusicPlayer extends PureComponent {
  static propTypes = {
    /** Function used to add track to favorites. */
    addToFavorites: PropTypes.func.isRequired,
    /** Function used to open modal */
    closeModal: PropTypes.func.isRequired,
    /** Player actions. */
    getCurrentTime: PropTypes.func,
    goToNextTrack: PropTypes.func.isRequired,
    goToPrevTrack: PropTypes.func.isRequired,
    /** If True, track has a new track to play when ending. */
    hasNext: PropTypes.bool.isRequired,
    /** If True, user is logged in. */
    isLogged: PropTypes.bool,
    /** If True, a track is paused. */
    isPaused: PropTypes.bool.isRequired,
    /** If True, is playing a track. */
    isPlaying: PropTypes.bool.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Function used to open add to playlist modal. */
    openAddToPlaylistModal: PropTypes.func.isRequired,
    /** Function used to open track artists modal. */
    openTrackArtistsModal: PropTypes.func.isRequired,
    /** Function used to open lyrics modal. */
    openTrackLyricsModal: PropTypes.func.isRequired,
    /** Function used to open track ownerships modal. */
    openTrackOwnershipsModal: PropTypes.func.isRequired,
    /** Function used to open track tags modal. */
    openTrackTagsModal: PropTypes.func.isRequired,
    /** Function used to open track versions modal. */
    openTrackVersionsModal: PropTypes.func.isRequired,
    /** Function used to pause a track. */
    pause: PropTypes.func.isRequired,
    /** Function used to play a track. */
    play: PropTypes.func.isRequired,
    /** Function used to remove track to favorites. */
    removeFromFavorites: PropTypes.func.isRequired,
    /** Function use to seek track. */
    seek: PropTypes.func.isRequired,
    /** Function used to translate. */
    t: PropTypes.func.isRequired,
    /** Track */
    track: pth.track,
  };

  static defaultProps = {
    getCurrentTime: null,
    isLogged: false,
    opened: false,
    track: null,
  };

  getSubTitle = () => {
    const { track } = this.props;

    const album = getTrackAlbum(track);

    if (album.id) {
      return <SubTitle centered>{album.title}</SubTitle>;
    }

    return null;
  };

  getTrackDisplayArtists = () => {
    const { track } = this.props;

    const displayArtists = getTrackDisplayArtistsHelper(track);

    if (!displayArtists) return null;

    return displayArtists.reduce((acc, da, i) => {
      if (i === 0) acc.push(' - ');

      acc.push(da.name);

      if (i < displayArtists.length - 1) acc.push(', ');

      return acc;
    }, []);
  };

  handleMagicWand = () => {
    const { track } = this.props;

    const query = encodeAndRemovePercent(JSON.stringify([track.id, getTrackTitle(track)]));

    Router.push(`/search?track_id=${query}`);
  };

  handleFavorite = () => {
    const { addToFavorites, isLogged, removeFromFavorites, track } = this.props;

    if (isLogged) {
      if (track.isFavorite) {
        removeFromFavorites(track.id);
      } else {
        addToFavorites(track.id);
      }
    }
  };

  handleSeek = (seekValue) => {
    const { seek, track } = this.props;

    seek(getNextSeekValue(_get(track, 'duration', 0), seekValue));
  };

  render() {
    const {
      closeModal,
      getCurrentTime,
      goToNextTrack,
      goToPrevTrack,
      hasNext,
      isLogged,
      isPaused,
      isPlaying,
      opened,
      openAddToPlaylistModal,
      openTrackArtistsModal,
      openTrackLyricsModal,
      openTrackOwnershipsModal,
      openTrackTagsModal,
      openTrackVersionsModal,
      pause,
      play,
      t,
      track,
    } = this.props;

    // If we don't return null here, component won't lock body when opened.
    if (!opened || !track) return null;

    const waveformSvg = _get(track, 'waveform.small.url', null);

    // Status for get current time
    let status = 0;
    if (isPaused) status = 1;
    if (isPlaying) status = 2;

    const trackOwnerships = [
      ..._get(track, 'artistsMasterOwnerships', []),
      ..._get(track, 'artistsPublishingOwnerships', []),
      ..._get(track, 'masterOwnerships', []),
      ..._get(track, 'publishingOwnerships', []),
    ];

    return (
      <DefaultModal bottomCloseBtn name="musicPlayer" onClose={closeModal} opened={opened}>
        <Wrapper>
          <Informations small>
            <ModalCover
              lazyload={false}
              placeholderType="track"
              src={getTrackCoverUrl(track)}
              type="track"
              small
              alt={getTrackAlbum(track).title}
            />
          </Informations>
          <Content>
            <TitleWrapper>
              <Title centered>
                {getTrackTitle(track)}
                {this.getTrackDisplayArtists()}
              </Title>
              {this.getSubTitle()}
            </TitleWrapper>

            <PlayerBox>
              <CurrentTimeFetcher status={status} getCurrentTime={getCurrentTime}>
                {currentTime => (
                  <Fragment>
                    <StyledWaveform
                      progress={(currentTime / _get(track, 'duration', 0)) * 100}
                      svg={waveformSvg}
                      onSeek={this.handleSeek}
                    />
                    <CurrentTime>
                      <span>{presentDuration(currentTime)}</span>
                      <span>{presentDuration(_get(track, 'duration', 0))}</span>
                    </CurrentTime>
                  </Fragment>
                )}
              </CurrentTimeFetcher>
              <Controls>
                <PrevIcon onClick={goToPrevTrack} />
                {!isPlaying && <PlayIcon onClick={play} />}
                {isPlaying && <PauseIcon onClick={pause} />}
                <NextIcon onClick={goToNextTrack} disabled={!hasNext} />
              </Controls>
            </PlayerBox>

            <MobileActionList>
              <MobileAction onClick={this.handleMagicWand}>
                <SimilarIcon />
                {t('components:music_modal.search_similar_tracks')}
              </MobileAction>
              <MobileAction isLink={!isLogged} route="/login" onClick={this.handleFavorite}>
                <AddToFavoritesIcon checked={track.isFavorite} />
                {track.isFavorite
                  ? t('components:music_modal.remove_from_favorites')
                  : t('components:music_modal.add_to_favorites')}
              </MobileAction>
              <MobileAction
                isLink={!isLogged}
                route="/login"
                onClick={!isLogged ? null : () => openAddToPlaylistModal(true, track, 'track')}
              >
                <AddToPlaylistIcon />
                {t('components:entity_actions.add_to_playlist')}
              </MobileAction>
              {!_isEmpty(track.lyrics) && (
                <MobileAction onClick={() => openTrackLyricsModal(true, track)}>
                  <LyricsIcon />
                  {t('components:music_modal.lyrics')}
                </MobileAction>
              )}
              {getUrl('tracks')(track) && (
                <ModalCloser onClose={closeModal} path={getUrl('tracks')(track)}>
                  {onClose => (
                    <MobileAction
                      isLink
                      route={getBaseRoute('tracks')}
                      nextAs={getUrl('tracks')(track)}
                      onClickPassthrough={onClose}
                    >
                      <AlbumIcon />
                      {t('components:music_modal.go_to_album')}
                    </MobileAction>
                  )}
                </ModalCloser>
              )}
              {!!track.displayArtists.length && (
                <MobileAction onClick={() => openTrackArtistsModal(true, track)}>
                  <ArtistsIcon />
                  {t('components:music_modal.see_artists')}
                </MobileAction>
              )}
              {track.versions > 1 && (
                <MobileAction onClick={() => openTrackVersionsModal(true, track)}>
                  <VersionIcon>
                    +
                    {track.versions - 1}
                  </VersionIcon>
                  {t('components:music_modal.versions')}
                </MobileAction>
              )}
              {!!track.tags.length && (
                <MobileAction onClick={() => openTrackTagsModal(true, track)}>
                  <TagsIcon />
                  {t('components:music_modal.tags')}
                </MobileAction>
              )}
              {!!trackOwnerships.length && (
                <MobileAction onClick={() => openTrackOwnershipsModal(true, track)}>
                  <CopyrightIcon />
                  {t('components:music_modal.ownerships')}
                </MobileAction>
              )}
            </MobileActionList>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalMusicPlayer);
