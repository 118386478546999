// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { motion } from 'framer-motion';

import { isHoverPrimary } from '../../../../helpers/front/getHoverType';

// =============================
// Styles
// =============================

export const Languages = styled(motion.div).attrs(({ theme }) => ({
  variants: {
    close: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        delay: 1.15, // Wait for 3 menu items to be shown
        duration: 0.5,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
}))`
  align-items: center;
  bottom: 3rem;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  left: 3rem;
  margin-right: auto;
  position: fixed;
`;

export const Lang = styled.div`
  cursor: pointer;
  margin: 0.4rem;
  opacity: 0.5;
  position: relative;
  text-transform: capitalize;
  transition: opacity ${({ theme }) => theme.transition.medium};

  &:after {
    background-color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.2))};
    content: '';
    height: 0.2rem;
    left: 0;
    position: absolute;
    transition: transform ${({ theme }) => theme.transition.fast}
      ${({ theme }) => theme.transitionTiming.easeInOut};
    transform: scaleX(0);
    transform-origin: left;
    top: 100%;
    width: 100%;
  }

  &:hover {
    color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
    opacity: 1;

    &:after {
      transform: scaleX(1);
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ selected }) => selected
    && css`
      opacity: 1;
    `};
`;
