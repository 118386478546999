// =============================
// Imports
// =============================

import { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../config/i18n';

import getViewProps from '../../../helpers/front/getviewprops';

import { Desktop } from '../../other/responsive';

import { Wrapper, Option, StyledCheckboxInput, DescIcon, TagIcon, OptionName } from './styles';

// =============================
// Component
// =============================

class MusicItemOptionsToggler extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** If True, musicitem show description. */
    descriptionStatus: PropTypes.bool,
    /** Forwarded ref */
    innerRef: PropTypes.func,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** If True, musicitem show tags. */
    tagStatus: PropTypes.bool,
    /** Callback function used to show/hide musicitem description. */
    toggleDescription: PropTypes.func,
    /** Callback function used to show/hide musicitem tags. */
    toggleTag: PropTypes.func,
  };

  static displayName = 'MusicItemOptionsToggler';

  static defaultProps = {
    className: '',
    descriptionStatus: false,
    innerRef: null,
    tagStatus: false,
    toggleDescription: null,
    toggleTag: null,
  };

  render() {
    const {
      className,
      descriptionStatus,
      innerRef,
      t,
      tagStatus,
      toggleDescription,
      toggleTag,
    } = this.props;

    return (
      <Wrapper {...getViewProps(this.props)} className={className} ref={innerRef}>
        <Desktop>
          <Option onClick={() => toggleTag(!tagStatus)}>
            <StyledCheckboxInput checked={tagStatus} />
            <TagIcon checked={tagStatus} />
            <OptionName checked={tagStatus}>
              {t('components:music_item_options_toggler.tags')}
            </OptionName>
          </Option>
        </Desktop>
        <Option onClick={() => toggleDescription(!descriptionStatus)}>
          <StyledCheckboxInput checked={descriptionStatus} />
          <DescIcon checked={descriptionStatus} />
          <OptionName checked={descriptionStatus}>
            {t('components:music_item_options_toggler.description')}
          </OptionName>
        </Option>
      </Wrapper>
    );
  }
}

function forwardedRef(props, ref) {
  return <MusicItemOptionsToggler innerRef={ref} {...props} />;
}

forwardedRef.displayName = MusicItemOptionsToggler.displayName;

export default withTranslation('components')(forwardRef(forwardedRef));
