// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import { WrapIfDesktop } from '../../../other/responsive';

import { getArtistName, getArtistCoverUrl } from '../../../../helpers/entity/artist';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  StyledScrollBar,
  Title,
  TitleWrapper,
  TextWithLineBreaks,
} from '../styles';

// =============================
// Stories
// =============================

class ModalArtist extends PureComponent {
  static propTypes = {
    /** Artist */
    artist: pth.artist.isRequired,
    /** Function used to close modal */
    closeArtistDescModal: PropTypes.func.isRequired,
    /** Locale */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      artist,
      closeArtistDescModal,
      i18n: { language },
      opened,
    } = this.props;

    if (!artist) return null;

    const description = getValueByLocale(artist.descriptions, language);

    return (
      <DefaultModal bottomCloseBtn name="artist" onClose={closeArtistDescModal} opened={opened} wideContent>
        <Wrapper>
          <Informations>
            <ModalCover
              placeholderType="artist"
              type="artist"
              src={getArtistCoverUrl(artist)}
              lazyload={false}
              rounded
              alt={getArtistName(artist)}
            />
          </Informations>
          <Content>
            <WrapIfDesktop Wrapper={<StyledScrollBar />}>
              <TitleWrapper>
                <Title>{getArtistName(artist)}</Title>
              </TitleWrapper>
              <TextWithLineBreaks>{description}</TextWithLineBreaks>
            </WrapIfDesktop>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalArtist);
