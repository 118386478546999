// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  album: {
    isFetching: false,
    isFetchedOnce: false,
    data: {},
  },
  albumTracks: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
    parentId: null,
  },
  playlist: {
    isFetching: false,
    isFetchedOnce: false,
    isDuplicating: false,
    data: {},
  },
  playlistTracks: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
    parentId: null,
  },
  pitch: {
    isFetching: false,
    isFetchedOnce: false,
    isDuplicating: false,
    data: {},
  },
  pitchTracks: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
    parentId: null,
  },
  artist: {
    isFetching: false,
    isFetchedOnce: false,
    data: {},
  },
  artistTracks: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  artistAlbums: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
    parentId: null,
  },
  artistAlbumsAppearsOn: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  artistPlaylistsAppearsOn: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  catalog: {
    isFetching: false,
    isFetchedOnce: false,
    data: {},
  },
  catalogTracks: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  catalogAlbums: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
    parentId: null,
  },
  isDownloadingArchives: false,
  isPollingDownloadArchive: false,
  isDownloadingCopyright: false,
};

// =============================
// Reducer
// =============================

export default function entities(state = initialState, action) {
  switch (action.type) {
    // Albums
    case acts.SET_ALBUM:
      return {
        ...state,
        album: {
          ...state.album,
          data: action.payload,
        },
      };

    case acts.GET_ALBUM_LOADING:
      return {
        ...state,
        album: {
          ...state.album,
          isFetching: true,
        },
      };

    case acts.GET_ALBUM_SUCCESS:
      return {
        ...state,
        album: {
          ...state.album,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ALBUM_FAILURE:
      return {
        ...state,
        album: {
          ...state.album,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_ALBUM_TRACKS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          albumTracks: {
            ...state.albumTracks,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          ...action.payload,
          data: [...state.albumTracks.data, ...action.payload.data],
        },
      };
    }

    case acts.GET_ALBUM_TRACKS_LOADING:
      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          isFetching: true,
        },
      };

    case acts.GET_ALBUM_TRACKS_SUCCESS:
      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ALBUM_TRACKS_FAILURE:
      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.RESET_ALBUM_AND_ITS_TRACKS:
      return {
        ...state,
        album: initialState.album,
        albumTracks: initialState.albumTracks,
      };

    case acts.UPDATE_ALBUM_FAVORITES:
      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          data: state.albumTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_ALBUM_RECENTS:
      return {
        ...state,
        albumTracks: {
          ...state.albumTracks,
          data: state.albumTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    // Playlist
    case acts.SET_PLAYLIST:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          data: action.payload,
        },
      };

    case acts.GET_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isFetching: true,
        },
      };

    case acts.GET_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.DUPLICATE_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDuplicating: true,
        },
      };

    case acts.DUPLICATE_PLAYLIST_SUCCESS:
    case acts.DUPLICATE_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDuplicating: false,
        },
      };

    case acts.SET_PLAYLIST_TRACKS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          playlistTracks: {
            ...state.playlistTracks,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          ...action.payload,
          data: [...state.playlistTracks.data, ...action.payload.data],
        },
      };
    }

    case acts.GET_PLAYLIST_TRACKS_LOADING:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetching: true,
        },
      };

    case acts.GET_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_PLAYLIST_TRACKS_FAILURE:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.RESET_PLAYLIST_AND_ITS_TRACKS:
      return {
        ...state,
        playlist: initialState.playlist,
        playlistTracks: initialState.playlistTracks,
      };

    case acts.UPDATE_PLAYLIST_FAVORITES:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          data: state.playlistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_PLAYLIST_RECENTS:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          data: state.playlistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    // Pitch
    case acts.SET_PITCH:
      return {
        ...state,
        pitch: {
          ...state.pitch,
          data: action.payload,
        },
      };

    case acts.GET_PITCH_LOADING:
      return {
        ...state,
        pitch: {
          ...state.pitch,
          isFetching: true,
        },
      };

    case acts.GET_PITCH_SUCCESS:
      return {
        ...state,
        pitch: {
          ...state.pitch,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_PITCH_FAILURE:
      return {
        ...state,
        pitch: {
          ...state.pitch,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_PITCH_TRACKS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          pitchTracks: {
            ...state.pitchTracks,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          ...action.payload,
          data: [...state.pitchTracks.data, ...action.payload.data],
        },
      };
    }

    case acts.GET_PITCH_TRACKS_LOADING:
      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          isFetching: true,
        },
      };

    case acts.GET_PITCH_TRACKS_SUCCESS:
      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_PITCH_TRACKS_FAILURE:
      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.UPDATE_PITCH_FAVORITES:
      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          data: state.pitchTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_PITCH_RECENTS:
      return {
        ...state,
        pitchTracks: {
          ...state.pitchTracks,
          data: state.pitchTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    case acts.RESET_PITCH_AND_ITS_TRACKS:
      return {
        ...state,
        pitch: initialState.pitch,
        pitchTracks: initialState.pitchTracks,
      };

    // Artist
    case acts.SET_ARTIST:
      return {
        ...state,
        artist: {
          ...state.artist,
          data: action.payload,
        },
      };

    case acts.GET_ARTIST_LOADING:
      return {
        ...state,
        artist: {
          ...state.artist,
          isFetching: true,
        },
      };

    case acts.GET_ARTIST_SUCCESS:
      return {
        ...state,
        artist: {
          ...state.artist,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ARTIST_FAILURE:
      return {
        ...state,
        artist: {
          ...state.artist,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_ARTIST_TRACKS:
      return {
        ...state,
        artistTracks: {
          ...state.artistTracks,
          data: action.payload,
        },
      };

    case acts.GET_ARTIST_TRACKS_LOADING:
      return {
        ...state,
        artistTracks: {
          ...state.artistTracks,
          isFetching: true,
        },
      };

    case acts.GET_ARTIST_TRACKS_SUCCESS:
      return {
        ...state,
        artistTracks: {
          ...state.artistTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ARTIST_TRACKS_FAILURE:
      return {
        ...state,
        artistTracks: {
          ...state.albumTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_ARTIST_ALBUMS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          artistAlbums: {
            ...state.artistAlbums,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        artistAlbums: {
          ...state.artistAlbums,
          ...action.payload,
          data: [...state.artistAlbums.data, ...action.payload.data],
        },
      };
    }

    case acts.GET_ARTIST_ALBUMS_LOADING:
      return {
        ...state,
        artistAlbums: {
          ...state.artistAlbums,
          isFetching: true,
        },
      };

    case acts.GET_ARTIST_ALBUMS_SUCCESS:
      return {
        ...state,
        artistAlbums: {
          ...state.artistAlbums,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ARTIST_ALBUMS_FAILURE:
      return {
        ...state,
        artistAlbums: {
          ...state.artistAlbums,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_ARTIST_ALBUMS_APPEARS_ON:
      return {
        ...state,
        artistAlbumsAppearsOn: {
          ...state.artistAlbumsAppearsOn,
          data: action.payload,
        },
      };

    case acts.GET_ARTIST_ALBUMS_APPEARS_ON_LOADING:
      return {
        ...state,
        artistAlbumsAppearsOn: {
          ...state.artistAlbumsAppearsOn,
          isFetching: true,
        },
      };

    case acts.GET_ARTIST_ALBUMS_APPEARS_ON_SUCCESS:
      return {
        ...state,
        artistAlbumsAppearsOn: {
          ...state.artistAlbumsAppearsOn,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ARTIST_ALBUMS_APPEARS_ON_FAILURE:
      return {
        ...state,
        artistAlbumsAppearsOn: {
          ...state.artistAlbumsAppearsOn,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_ARTIST_PLAYLISTS_APPEARS_ON:
      return {
        ...state,
        artistPlaylistsAppearsOn: {
          ...state.artistPlaylistsAppearsOn,
          data: action.payload,
        },
      };

    case acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_LOADING:
      return {
        ...state,
        artistPlaylistsAppearsOn: {
          ...state.artistPlaylistsAppearsOn,
          isFetching: true,
        },
      };

    case acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_SUCCESS:
      return {
        ...state,
        artistPlaylistsAppearsOn: {
          ...state.artistPlaylistsAppearsOn,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_ARTIST_PLAYLISTS_APPEARS_ON_FAILURE:
      return {
        ...state,
        artistPlaylistsAppearsOn: {
          ...state.artistPlaylistsAppearsOn,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.RESET_ARTIST_AND_RELATED_DATA:
      return {
        ...state,
        artist: initialState.artist,
        artistTracks: initialState.artistTracks,
        artistAlbums: initialState.artistAlbums,
        artistAlbumsAppearsOn: initialState.artistAlbumsAppearsOn,
        artistPlaylistsAppearsOn: initialState.artistPlaylistsAppearsOn,
      };

    case acts.UPDATE_ARTIST_FAVORITES:
      return {
        ...state,
        artistTracks: {
          ...state.artistTracks,
          data: state.artistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_ARTIST_RECENTS:
      return {
        ...state,
        artistTracks: {
          ...state.artistTracks,
          data: state.artistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    // Catalog
    case acts.SET_CATALOG:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          data: action.payload,
        },
      };

    case acts.GET_CATALOG_LOADING:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          isFetching: true,
        },
      };

    case acts.GET_CATALOG_SUCCESS:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_CATALOG_FAILURE:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_CATALOG_TRACKS:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          data: action.payload,
        },
      };

    case acts.GET_CATALOG_TRACKS_LOADING:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          isFetching: true,
        },
      };

    case acts.GET_CATALOG_TRACKS_SUCCESS:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_CATALOG_TRACKS_FAILURE:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_CATALOG_ALBUMS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          catalogAlbums: {
            ...state.catalogAlbums,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        catalogAlbums: {
          ...state.catalogAlbums,
          ...action.payload,
          data: [...state.catalogAlbums.data, ...action.payload.data],
        },
      };
    }

    case acts.GET_CATALOG_ALBUMS_LOADING:
      return {
        ...state,
        catalogAlbums: {
          ...state.catalogAlbums,
          isFetching: true,
        },
      };

    case acts.GET_CATALOG_ALBUMS_SUCCESS:
      return {
        ...state,
        catalogAlbums: {
          ...state.catalogAlbums,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_CATALOG_ALBUMS_FAILURE:
      return {
        ...state,
        catalogAlbums: {
          ...state.catalogAlbums,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.RESET_CATALOG_AND_RELATED_DATA:
      return {
        ...state,
        catalog: initialState.catalog,
        catalogTracks: initialState.catalogTracks,
        catalogAlbums: initialState.catalogAlbums,
      };

    case acts.UPDATE_CATALOG_FAVORITES:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          data: state.catalogTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_CATALOG_RECENTS:
      return {
        ...state,
        catalogTracks: {
          ...state.catalogTracks,
          data: state.catalogTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    case acts.DOWNLOAD_ARCHIVES_LOADING:
      return {
        ...state,
        isDownloadingArchives: true,
      };

    case acts.DOWNLOAD_ARCHIVES_SUCCESS:
    case acts.DOWNLOAD_ARCHIVES_FAILURE:
      return {
        ...state,
        isDownloadingArchives: false,
      };

    case acts.POLLING_DOWNLOAD_ARCHIVE_LOADING:
      return {
        ...state,
        isPollingDownloadArchive: true,
      };

    case acts.POLLING_DOWNLOAD_ARCHIVE_SUCCESS:
    case acts.POLLING_DOWNLOAD_ARCHIVE_FAILURE:
      return {
        ...state,
        isPollingDownloadArchive: false,
      };

    case acts.DOWNLOAD_COPYRIGHT_LOADING:
      return {
        ...state,
        isDownloadingCopyright: true,
      };

    case acts.DOWNLOAD_COPYRIGHT_SUCCESS:
    case acts.DOWNLOAD_COPYRIGHT_FAILURE:
      return {
        ...state,
        isDownloadingCopyright: false,
      };

    default:
      return state;
  }
}
