// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import {
  getMedia as getMediaBase,
  play as playBase,
  pause as pauseBase,
} from '../../../store/actions/PlayerActions';
import * as playerCtx from '../../../store/constants/PlayerContexts';
import * as playerStates from '../../../store/constants/PlayerStates';

import Cover from '../../presentationals/cover';

// =============================
// Component
// =============================

class CoverContainer extends PureComponent {
  static propTypes = {
    /** Id of the player context */
    contextId: PropTypes.string,
    /** Name of the player context */
    contextName: PropTypes.string,
    getMedia: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    playerStore: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    contextId: null,
    contextName: null,
  };

  isPlayable = () => {
    const { contextId, contextName } = this.props;

    return !!(contextName && contextId);
  };

  isCurrent = () => {
    const { contextId, contextName, playerStore } = this.props;

    if (!this.isPlayable()) return false;

    return contextName === playerCtx.SEARCH
      ? _get(playerStore, 'current.id', null) === contextId
          && _get(playerStore, 'contextName', null) === contextName
      : _get(playerStore, 'contextName', null) === contextName
          && _get(playerStore, 'contextId', null) === contextId;
  };

  isPlaying = () => {
    const { playerStore } = this.props;

    return !!(this.isCurrent() && _get(playerStore, 'playerState') === playerStates.PLAYING);
  };

  render() {
    const { contextId, contextName, getMedia, pause, play, playerStore, ...rest } = this.props;

    let onPlayFnc = null;
    let onPauseFnc = null;

    if (this.isPlayable()) {
      // Get on play function
      if (this.isCurrent() && !this.isPlaying()) {
        onPlayFnc = play;
      }

      if (!this.isCurrent() && contextName === playerCtx.SEARCH) {
        onPlayFnc = () => getMedia(contextId, contextName);
      }

      if (!this.isCurrent() && contextName !== playerCtx.SEARCH) {
        onPlayFnc = () => getMedia(null, contextName, contextId);
      }

      // Get on pause function
      if (this.isPlaying()) {
        onPauseFnc = pause;
      }
    }

    return (
      <Cover
        isCurrent={this.isCurrent()}
        onPause={onPauseFnc}
        onPlay={onPlayFnc}
        isPlaying={this.isPlaying()}
        {...rest}
      />
    );
  }
}

function mapStateToProps({ player }) {
  return {
    playerStore: player,
  };
}

export default connect(mapStateToProps, {
  getMedia: getMediaBase,
  pause: pauseBase,
  play: playBase,
})(CoverContainer);
