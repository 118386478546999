// =============================
// Imports
// =============================

// External Dependencies
import axios, { isCancel } from 'axios';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';
import * as miscConfig from '../../config/misc';

// Constants
import * as acts from '../constants/ActionTypes';
import * as rqs from '../constants/RequestTypes';

// Helpers
import { getApiUrl, getXPreferredLanguage, camelCaseKeysDeep } from '../../helpers/misc';
import determineError, { MewoError } from '../../helpers/errors';
import { cancelableRequest, cancelRequest } from '../helpers/axios';

// =============================
// Actions
// =============================

export function getFavorites() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_FAVORITES_LOADING,
    });

    try {
      const offset = getState().favorites.data.length;

      const response = await cancelableRequest(rqs.GET_FAVORITES, {
        method: 'get',
        url: getApiUrl(
          `public/favorites?offset=${offset}&limit=${miscConfig.MUSIC_ITEMS_PER_PAGE}`,
        ),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_FAVORITES,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_FAVORITES_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        dispatch({
          type: acts.GET_FAVORITES_FAILURE,
          payload: {
            message: determineError(err),
            reqId: _get(err, 'response.data.reqId'),
          },
        });

        throw new MewoError({ error: err });
      }
    }
  };
}

export function addToFavorites(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.ADD_TO_FAVORITES_LOADING,
    });

    try {
      const response = await axios.post(
        getApiUrl(`public/favorites/tracks/${id}`),
        {},
        {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': getState().core.serverContext.xHost,
            'x-auth': getState().user.token,
            'x-preferred-language': getXPreferredLanguage(),
          },
        },
      );

      if (getState().favorites.isFetchedOnce) {
        const prevData = getState().favorites.data;

        dispatch({
          type: acts.REPLACE_FAVORITES_DATA,
          payload: [camelCaseKeysDeep(response.data), ...prevData],
        });
      }

      dispatch({
        type: acts.ADD_TO_FAVORITES_SUCCESS,
        payload: id,
      });
    } catch (err) {
      let message;
      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          message = i18n.t('errors:favorites.track_not_found');
          break;

        case err.response
          && err.response.status === 400
          && err.response.data.key === 'validation_error':
          // NOTE: We can add the line below if necessary
          // && err.response.data.message === '"x-auth" is not allowed to be empty'
          message = i18n.t('errors:favorites.not_logged_in');
          break;

        case err.response
          && err.response.status === 400
          && err.response.data.key !== 'invalid_token':
          message = i18n.t('errors:favorites.limit_reached');
          break;
        default:
          message = determineError(err);
      }

      dispatch({
        type: acts.ADD_TO_FAVORITES_FAILURE,
        payload: {
          message,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function removeFromFavorites(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REMOVE_FROM_FAVORITES_LOADING,
    });

    try {
      await axios.delete(getApiUrl(`public/favorites/tracks/${id}`), {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      if (getState().favorites.isFetchedOnce) {
        const prevData = getState().favorites.data;

        dispatch({
          type: acts.REPLACE_FAVORITES_DATA,
          payload: prevData.filter(d => d.id !== id),
        });
      }

      dispatch({
        type: acts.REMOVE_FROM_FAVORITES_SUCCESS,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: acts.REMOVE_FROM_FAVORITES_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function resetFavorites() {
  cancelRequest(rqs.GET_FAVORITES);

  return {
    type: acts.RESET_FAVORITES,
  };
}
