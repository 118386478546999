// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { WithCustomTheme } from '@mewo/components';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import { alpha } from '@material-ui/core';
import _get from 'lodash/get';
import _xor from 'lodash/xor';

import { withTranslation } from '../../../../config/i18n';

import { getValueByLocale } from '../../../../helpers/i18n';
import getViewProps from '../../../../helpers/front/getviewprops';
import * as pth from '../../../../helpers/proptypes';
import { getLinkRouteProps, isExternalLink, isLinkActive } from '../../../../helpers/link';

import {
  AppleIcon,
  DeezerIcon,
  DotIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SpotifyIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../desktop/menucontent/styles';
import {
  CloseMenuButton,
  MenuButton,
  MenuContent,
  MenuHeader,
  Wrapper,
  StyledLink,
  SocialLinks,
  BlogLink,
  MenuLink,
  MenuLinkCounter,
  MenuLinks,
  FooterLinks,
  MenuSubLink,
  MenuLinkContainer,
  MenuSubLinkCounter,
  StyledUserLanguages,
  MenuSubLinkContainer,
  StyledArrowDropdown,
} from './styles';

// =============================
// Component
// =============================

class Menu extends PureComponent {
  static displayName = 'MenuMobile';

  static propTypes = {
    /* Available languages */
    availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Blog URL. */
    blogUrl: PropTypes.string,
    /** Close menu modal. */
    closeMenu: PropTypes.func.isRequired,
    /** Menu data */
    data: pth.configMenus.isRequired,
    /** User locale. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, user is logged. */
    isLogged: PropTypes.bool.isRequired,
    /** Number of user playlists */
    nbPlaylists: PropTypes.number.isRequired,
    /** If True, Menu is opened. */
    opened: PropTypes.bool,
    /** Router props. */
    router: PropTypes.shape({
      asPath: PropTypes.string,
    }).isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Styled component theme object. */
    // eslint-disable-next-line react/forbid-prop-types
    theme: PropTypes.object.isRequired,
  };

  static defaultProps = {
    blogUrl: '',
    opened: false,
  };

  state = {
    sublinksOpened: [],
  };

  componentDidUpdate(prevProps) {
    const { opened } = this.props;

    // Menu is always mounted and awaiting to be opened, that's why we do not handle
    // "is-locked" on didMount or willUnmount, which we do in modal/mobile
    if (prevProps.opened === false && opened === true) {
      document.body.classList.toggle('is-locked-menu', true);
    } else if (prevProps.opened === true && opened === false) {
      document.body.classList.toggle('is-locked-menu', false);
    }
  }

  handleSublinksChange = linkId => this.setState(prevState => ({
    sublinksOpened: _xor([linkId], prevState.sublinksOpened),
  }));

  handleCloseMenu = (link) => {
    const {
      closeMenu,
      router: { asPath },
    } = this.props;

    // Reset opened sublinks
    this.setState({
      sublinksOpened: [],
    });

    if (!link) return closeMenu();

    // External link always close menu when clicked on.
    if (isExternalLink(link)) {
      return closeMenu();
    }

    /**
     * Closing menu for internal link is handled by nextRouting on the AppShell,
     * so we don't need to close the meny by ourselves, except when already
     * on destination page (because the route won't change), in that case, we need
     * to pass the closeMenu function to the Link.
     * We also need to prepend a "/" to link.slug to test equality
     * because router asPath has a trailing "/" that link.slug don't.
     * */
    let linkSlug = '/'; // Homepage
    if (link.page && link.slug) linkSlug = `/page/${link.slug}`;
    if (!link.page && link.slug) linkSlug = `/${link.slug}`;

    if (linkSlug === asPath) {
      return closeMenu();
    }

    return null;
  };

  renderFooterLinks = () => {
    const {
      data,
      i18n: { language },
      isLogged,
      t,
    } = this.props;

    const footerLinks = [
      ..._get(data, 'mainMenu.customLinks.footer', []),
      {
        names: [
          { locale: 'en', value: t('components:menu.legal', { lng: 'en' }) },
          { locale: 'fr', value: t('components:menu.legal', { lng: 'fr' }) },
          { locale: 'es', value: t('components:menu.legal', { lng: 'es' }) },
          { locale: 'de', value: t('components:menu.legal', { lng: 'de' }) },
        ],
        slug: '/legal',
        page: null,
      },
      {
        names: [
          { locale: 'en', value: t('components:menu.cookies', { lng: 'en' }) },
          { locale: 'fr', value: t('components:menu.cookies', { lng: 'fr' }) },
          { locale: 'es', value: t('components:menu.cookies', { lng: 'es' }) },
          { locale: 'de', value: t('components:menu.cookies', { lng: 'de' }) },
        ],
        slug: 'cookies',
        page: null,
      },
    ];

    const nbFooterLinks = footerLinks.length;

    return (
      <FooterLinks>
        {footerLinks.map((link, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={`${link.slug}-${i}`}>
            <StyledLink
              onClickPassthrough={() => this.handleCloseMenu(link)}
              inactive={!isLinkActive(link, isLogged)}
              {...getLinkRouteProps(link, isLogged)}
            >
              {getValueByLocale(link.names, language)}
            </StyledLink>
            {i !== nbFooterLinks - 1 && <DotIcon />}
          </Fragment>
        ))}
      </FooterLinks>
    );
  };

  getLinkCounter = (link) => {
    const { nbPlaylists } = this.props;

    if (link.slug === 'userplaylists') {
      if (nbPlaylists) return <MenuLinkCounter>{nbPlaylists}</MenuLinkCounter>;
    }

    return null;
  };

  getSubLinkCounter = (link) => {
    const { nbPlaylists } = this.props;

    if (link.slug === 'userplaylists') {
      if (nbPlaylists) return <MenuSubLinkCounter>{nbPlaylists}</MenuSubLinkCounter>;
    }

    return null;
  };

  getSocialLinkIcon = (key) => {
    switch (key) {
      case 'applemusic':
        return <AppleIcon />;
      case 'deezer':
        return <DeezerIcon />;
      case 'facebook':
        return <FacebookIcon />;
      case 'instagram':
        return <InstagramIcon />;
      case 'linkedin':
        return <LinkedinIcon />;
      case 'spotify':
        return <SpotifyIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'youtube':
        return <YoutubeIcon />;
      default:
        return key;
    }
  };

  renderMainLinks = () => {
    const {
      data,
      i18n: { language },
      isLogged,
    } = this.props;
    const { sublinksOpened } = this.state;

    const mainLinks = _get(data, 'mainMenu.customLinks.main', []);
    const nbMainLinks = mainLinks.length;

    if (!nbMainLinks) return null;

    return (
      <MenuLinks>
        {mainLinks.map(link => (
          <MenuLinkContainer key={link.id}>
            <MenuLink
              {...getLinkRouteProps(link, isLogged)}
              inactive={!isLinkActive(link, isLogged)}
            >
              {getValueByLocale(link.names, language)}
              {this.getLinkCounter(link)}
              {!!link.children.length && (
                <StyledArrowDropdown
                  opened={sublinksOpened.includes(link.id)}
                  onClick={(e) => {
                    e.preventDefault();
                    return this.handleSublinksChange(link.id);
                  }}
                />
              )}
            </MenuLink>
            {!!link.children.length && (
              <MenuSubLinkContainer animate={sublinksOpened.includes(link.id) ? 'open' : 'close'}>
                {_get(link, 'children', []).map(subLink => (
                  <MenuSubLink
                    inactive={!isLinkActive(subLink, isLogged)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={subLink.id}
                    {...getLinkRouteProps(subLink, isLogged)}
                  >
                    {getValueByLocale(subLink.names, language)}
                    {this.getSubLinkCounter(subLink, true)}
                  </MenuSubLink>
                ))}
              </MenuSubLinkContainer>
            )}
          </MenuLinkContainer>
        ))}
      </MenuLinks>
    );
  };

  renderSocialLinks = () => {
    const { data, isLogged } = this.props;

    const socialLinks = _get(data, 'mainMenu.socialLinks', []);
    const nbSocialLinks = Object.keys(socialLinks).length;

    // No Social Links & no blog url.
    if (!nbSocialLinks) return null;

    const LinksToRender = [];

    // Social links and blog url can't be inactive
    Object.keys(socialLinks).forEach((key, i) => {
      const link = { slug: socialLinks[key] };

      LinksToRender.push(
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`social-${link.slug}-${i}`}>
          <StyledLink
            onClickPassthrough={() => this.handleCloseMenu(link)}
            {...getLinkRouteProps(link, isLogged)}
          >
            {this.getSocialLinkIcon(key)}
          </StyledLink>
        </Fragment>,
      );
    });

    return <SocialLinks>{LinksToRender}</SocialLinks>;
  };

  render() {
    const {
      availableLanguages,
      blogUrl,
      closeMenu,
      opened,
      router: { asPath },
      t,
      theme,
      ...rest
    } = this.props;

    return (
      <WithCustomTheme
        customTheme={currentTheme => ({
          colors: {
            ...currentTheme.colors.menu,
            // We reconstruct input for the language input
            input: {
              background: alpha(currentTheme.colors.menu.text, 0.1),
              text: currentTheme.colors.menu.text,
              helpers: {
                background: currentTheme.colors.menu.text,
                text: currentTheme.colors.menu.background,
              },
            },
          },
        })}
      >
        {/** key is used to reset scroll on page changes. */}
        <Wrapper key={asPath} opened={opened} {...getViewProps(rest)}>
          <MenuHeader>
            <MenuButton
              colors={theme.colors.currentMenuTheme}
              opened={opened}
              onClick={() => this.handleCloseMenu()}
            >
              <CloseMenuButton />
            </MenuButton>
            {blogUrl && (
              <BlogLink onClickPassthrough={this.handleCloseMenu} route={blogUrl}>
                {t('components:menu.blog')}
              </BlogLink>
            )}
          </MenuHeader>
          <MenuContent>
            {this.renderMainLinks()}
            {this.renderSocialLinks()}

            <StyledUserLanguages />

            {this.renderFooterLinks()}
          </MenuContent>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default compose(withTheme, withRouter, withTranslation('components'))(Menu);
