// =============================
// Imports
// =============================

import { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { usePopper } from 'react-popper';

import { getLinkRouteProps, isLinkActive } from '../../../../../helpers/link';

import { SubLinksContainer, MenuLink, SubLink, SubLinkArrow, Wrapper } from './styles';

// =============================
// Component
// =============================

const MenuItem = ({
  animationComplete,
  getLinkCounter,
  getValueByLocale,
  handleCloseMenu,
  index,
  isLogged,
  language,
  link,
  menuCenterRef,
  sideMenuRef,
}) => {
  const [arrowElement, setArrowElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  let arrowWidth = '';

  if (
    menuCenterRef.getBoundingClientRect
    && referenceElement.getBoundingClientRect
    && sideMenuRef.getBoundingClientRect
  ) {
    const menuCenterPosX = menuCenterRef.getBoundingClientRect().x;
    const referenceElementWidth = referenceElement.getBoundingClientRect().width;
    const sideMenuPosX = sideMenuRef.getBoundingClientRect().x;

    arrowWidth = sideMenuPosX - menuCenterPosX - referenceElementWidth;
  }

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: -30 } },
      {
        name: 'offset',
        options: {
          offset: [0, arrowWidth],
        },
      },
    ],
    placement: 'right-start',
    strategy: 'fixed',
  });

  return (
    <Wrapper key={`menuitem-${link.slug}-${index}`}>
      <MenuLink
        inactive={!isLinkActive(link, isLogged)}
        onClickPassthrough={() => handleCloseMenu(link)}
        ref={setReferenceElement}
        style={styles.reference}
        {...getLinkRouteProps(link, isLogged)}
      >
        {getValueByLocale(link.names, language)}
        {getLinkCounter(link)}
      </MenuLink>

      {(!!_get(link, 'children', []).length && animationComplete) && (
        <SubLinksContainer ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <SubLinkArrow style={styles.arrow} ref={setArrowElement} arrowWidth={arrowWidth} />

          {_get(link, 'children', []).map((subLink, subIndex) => (
            <SubLink
              inactive={!isLinkActive(link, isLogged)}
              onClickPassthrough={() => handleCloseMenu(subLink)}
              // eslint-disable-next-line react/no-array-index-key
              key={`${link.slug}-sub-${subLink.slug}-${subIndex}`}
              {...getLinkRouteProps(subLink, isLogged)}
            >
              {getValueByLocale(subLink.names, language)}
              {getLinkCounter(subLink)}
            </SubLink>
          ))}
        </SubLinksContainer>
      )}
    </Wrapper>
  );
};

MenuItem.propTypes = {
  /** If  True, Animation is complete */
  animationComplete: PropTypes.bool.isRequired,
  /** Get link counter number. */
  getLinkCounter: PropTypes.func.isRequired,
  /** Get value by locale. */
  getValueByLocale: PropTypes.func.isRequired,
  /** Callback function to close Menu. */
  handleCloseMenu: PropTypes.func.isRequired,
  /** MenuItem list index. */
  index: PropTypes.number.isRequired,
  /** If True, user is logged. */
  isLogged: PropTypes.bool.isRequired,
  /** Locale */
  language: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  link: PropTypes.object.isRequired,
  /** MenuCenter ref. */
  menuCenterRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /** SideMenu ref. */
  sideMenuRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

MenuItem.defaultProps = {
  menuCenterRef: null,
  sideMenuRef: null,
};

export default MenuItem;
