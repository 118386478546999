// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setPrivacyCookie } from '../../../store/actions/CoreActions';
import { setCookiesOpen } from '../../../store/actions/ModalsActions';

import CookiesConsent from '../../presentationals/cookieconsent';

import { hasAccessToPrivate } from '../../../helpers/user';

// =============================
// Component
// =============================

function mapStateToProps({ core, config, user }) {
  return {
    /** Cookies settings for privacy */
    privacyCookies: core.privacyCookies,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
  };
}

export default connect(mapStateToProps, {
  setPrivacyCookie,
  setCookiesModalOpen: setCookiesOpen,
})(CookiesConsent);
