// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  isCreating: false,
  list: {
    data: [],
    isFetching: false,
    isFetchedOnce: false,
  },
  playlists: {
    isFetching: false,
    isFetchedOnce: false,
    query: '',
    data: [],
    total: 0,
    nbPages: 0,
    page: 0,
  },
  playlist: {
    isFetching: false,
    isFetchedOnce: false,
    isDuplicating: false,
    isModifying: false,
    isDeleting: false,
    data: {},
  },
  playlistTracks: {
    isFetching: false,
    isFetchedOnce: false,
    isHandlingTracks: [],
    isReordering: false,
    data: [],
    total: 0,
    offset: null,
    limit: null,
  },
};

// =============================
// Reducer
// =============================

export default function userplaylists(state = initialState, action) {
  switch (action.type) {
    // Creation
    case acts.CREATE_USER_PLAYLIST_LOADING:
      return {
        ...state,
        isCreating: true,
      };

    case acts.CREATE_USER_PLAYLIST_SUCCESS:
    case acts.CREATE_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        isCreating: false,
      };

    // User playlists list
    case acts.SET_USER_PLAYLISTS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
        },
      };

    case acts.GET_USER_PLAYLISTS_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      };

    case acts.GET_USER_PLAYLISTS_LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          isFetchedOnce: true,
        },
      };

    case acts.GET_USER_PLAYLISTS_LIST_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          isFetchedOnce: false,
        },
      };

    // User playlists
    case acts.SET_USER_PLAYLISTS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          ...action.payload,
          data:
            action.payload.page === 0
              ? action.payload.data
              : [...state.playlists.data, ...action.payload.data],
        },
      };

    case acts.GET_USER_PLAYLISTS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetching: true,
        },
      };

    case acts.GET_USER_PLAYLISTS_SUCCESS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_USER_PLAYLISTS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.RESET_USER_PLAYLISTS:
      return {
        ...state,
        playlists: initialState.playlists,
      };

    // User playlist
    case acts.SET_USER_PLAYLIST:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          data: action.payload,
        },
      };

    case acts.GET_USER_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isFetching: true,
        },
      };

    case acts.GET_USER_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.data,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.DUPLICATE_USER_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDuplicating: true,
        },
      };

    case acts.DUPLICATE_USER_PLAYLIST_SUCCESS:
    case acts.DUPLICATE_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDuplicating: false,
        },
      };

    case acts.MODIFY_USER_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isModifying: true,
        },
      };

    case acts.MODIFY_USER_PLAYLIST_SUCCESS:
    case acts.MODIFY_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isModifying: false,
        },
      };

    case acts.REMOVE_USER_PLAYLIST_LOADING:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDeleting: true,
        },
      };

    case acts.REMOVE_USER_PLAYLIST_SUCCESS:
    case acts.REMOVE_USER_PLAYLIST_FAILURE:
      return {
        ...state,
        playlist: {
          ...state.playlist,
          isDeleting: false,
        },
      };

    // User playlist tracks
    case acts.SET_USER_PLAYLIST_TRACKS:
      if (action.payload.offset === 0) {
        return {
          ...state,
          playlistTracks: {
            ...state.playlistTracks,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          ...action.payload,
          data: [...state.playlistTracks.data, ...action.payload.data],
        },
      };

    case acts.GET_USER_PLAYLIST_TRACKS_LOADING:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetching: true,
        },
      };

    case acts.GET_USER_PLAYLIST_TRACKS_SUCCESS:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_USER_PLAYLIST_TRACKS_FAILURE:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.ADD_TRACK_TO_PLAYLIST_LOADING:
    case acts.REMOVE_TRACK_FROM_PLAYLIST_LOADING:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isHandlingTracks: [
            ...state.playlistTracks.isHandlingTracks,
            action.payload.id,
          ],
        },
      };

    case acts.ADD_TRACK_TO_PLAYLIST_SUCCESS:
    case acts.ADD_TRACK_TO_PLAYLIST_FAILURE:
    case acts.REMOVE_TRACK_FROM_PLAYLIST_SUCCESS:
    case acts.REMOVE_TRACK_FROM_PLAYLIST_FAILURE:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isHandlingTracks: [
            ...state.playlistTracks.isHandlingTracks
              .filter(playlistId => playlistId !== action.payload.id),
          ],
        },
      };

    case acts.REPLACE_USER_PLAYLIST_TRACKS_DATA:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          data: action.payload,
        },
      };

    // Global
    case acts.RESET_USER_PLAYLIST_AND_RELATED_DATA:
      return {
        ...state,
        playlist: initialState.playlist,
        playlistTracks: initialState.playlistTracks,
      };

    case acts.UPDATE_USER_PLAYLIST_FAVORITES:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          data: state.playlistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, isFavorite: action.payload.isFavorite };
          }),
        },
      };

    case acts.UPDATE_USER_PLAYLIST_RECENTS:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          data: state.playlistTracks.data.map((track) => {
            if (track.id !== action.payload.id) return track;
            return { ...track, recentlyPlayed: true };
          }),
        },
      };

    case acts.REORDER_TRACK_IN_PLAYLIST_LOADING:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isReordering: true,
        },
      };

    case acts.REORDER_TRACK_IN_PLAYLIST_SUCCESS:
    case acts.REORDER_TRACK_IN_PLAYLIST_FAILURE:
      return {
        ...state,
        playlistTracks: {
          ...state.playlistTracks,
          isReordering: false,
        },
      };

    case acts.REORDER_USER_PLAYLIST_TRACK: {
      if (state.playlist.data.id === action.payload.playlistId) {
        if (state.playlistTracks.data.find(t => t.id === action.payload.trackId)) {
          const nextTracks = state.playlistTracks.data.slice(0);
          const [track] = nextTracks.splice(action.payload.index, 1);
          nextTracks.splice(action.payload.destination, 0, track);

          return {
            ...state,
            playlistTracks: {
              ...state.playlistTracks,
              data: nextTracks,
            },
          };
        }
      }

      return state;
    }

    default:
      return state;
  }
}
