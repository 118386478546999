// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { FilterBar as FilterBarBase } from '@mewo/components';

import { musicPlayerHeight } from '../../musicplayer/desktop/variables';
import { filterBarWidth } from './variables';

// =============================
// Styles
// =============================

const headerHeight = '24.5rem';
const contentPaddingBottom = '3rem';

// eslint-disable-next-line import/prefer-default-export
export const FilterBar = styled(FilterBarBase).attrs(() => ({
  classNames: {
    content: 'contentClassName',
    header: 'headerClassName',
    textIndication: 'textIndication',
  },
}))`
  bottom: 0;
  left: 0;
  position: fixed;
  top: 0;
  transition: all ${({ theme }) => theme.transition.medium};
  transform: translate3d(-${filterBarWidth}, 0, 0);
  width: ${filterBarWidth};

  ${({ opened }) => opened
    && css`
      transform: translate3d(0, 0, 0);
    `};

  & .headerClassName {
    height: ${headerHeight};
    padding: 10.5rem 3rem 0;

    ${({ noSearchBar }) => noSearchBar && css`
      height: 17.5rem;
    `};
  }

  & .contentClassName {
    padding: ${headerHeight} 3rem ${contentPaddingBottom};

    ${({ noSearchBar }) => noSearchBar && css`
      padding: 17.5rem 3rem ${contentPaddingBottom};
    `};
    }

  & .textIndication {
    height: calc(100vh - (${headerHeight} + ${contentPaddingBottom}));
  }

  ${({ isMusicPlayerOpened, isMusicPlayerMinimized }) => (!isMusicPlayerMinimized && isMusicPlayerOpened)
    && css`
      & .contentClassName {
        height: calc(100vh - ${musicPlayerHeight});
        max-height: calc(100vh - ${musicPlayerHeight});
      }
    `};
`;
