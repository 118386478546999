// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setPlaylistOpen } from '../../../../store/actions/ModalsActions';

import ModalPlaylist from '../../../layouts/modals/playlist';

// =============================
// Component
// =============================

function mapStateToProps({ modals, entities }) {
  return {
    playlist: entities.playlist.data,
    opened: modals.isPlaylistOpen,
  };
}

export default connect(mapStateToProps, {
  closePlaylistDescModal: () => setPlaylistOpen(false),
})(ModalPlaylist);
