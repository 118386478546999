/* eslint-disable react/no-array-index-key */
// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import {
  getTrackArtistsMasterOwnerships,
  getTrackArtistsPublishingOwnerships,
} from '../../../../helpers/entity/track';
import { getSearchUrl } from '../../../../helpers/entity';
import * as pth from '../../../../helpers/proptypes';

import ModalCloser from '../../../other/modalCloser';

import {
  CloseModalBtn,
  Content,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../mobile.styles';
import { DefaultModal } from '../styles';
import { Ownership, OwnershipCategory, OwnershipTitle, Role, StyledLink } from './styles';

// =============================
// Layout
// =============================

class ModalTrackOwnerships extends PureComponent {
  static propTypes = {
    /** Function used to close track artists modal. */
    closeMusicItemModal: PropTypes.func.isRequired,
    /** Function used to close track artists modal. */
    closeMusicPlayerModal: PropTypes.func.isRequired,
    /** Function used to close track ownerships modal. */
    closeTrackOwnershipsModal: PropTypes.func.isRequired,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Translations. */
    t: PropTypes.func.isRequired,
    /** Track object. */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  renderArtistsOwnerships = () => {
    const {
      closeMusicItemModal,
      closeMusicPlayerModal,
      closeTrackOwnershipsModal,
      i18n: { language },
      t,
      track,
    } = this.props;

    const amos = getTrackArtistsMasterOwnerships(track, language);
    const apos = getTrackArtistsPublishingOwnerships(track, language);

    const aos = [...amos, ...apos];
    const len = aos.length;

    if (!len) return null;

    return (
      <OwnershipCategory>
        <OwnershipTitle>{t('components:modal_track_ownerships.artists_ownerships')}</OwnershipTitle>
        {aos.map((ao, i) => (
          <ModalCloser
            key={`ao-${ao.id}-${i}`}
            onClose={() => {
              closeMusicItemModal();
              closeMusicPlayerModal();
              closeTrackOwnershipsModal();
            }}
            path={getSearchUrl('artistsOwnerships')(ao)}
          >
            {onClose => (
              <Ownership>
                <StyledLink
                  inline
                  onClickPassthrough={onClose}
                  route={getSearchUrl('artistsOwnerships')(ao)}
                >
                  {ao.name}
                  <Role>
                    &nbsp;(
                    {ao.rightsType}
                    )
                  </Role>
                </StyledLink>
              </Ownership>
            )}
          </ModalCloser>
        ))}
      </OwnershipCategory>
    );
  };

  getOwnershipsByKey = (collection = [], key) => collection.filter(o => _get(o, 'rightsType.key') === key);

  renderLabels = () => {
    const {
      closeMusicItemModal,
      closeMusicPlayerModal,
      closeTrackOwnershipsModal,
      t,
      track,
    } = this.props;

    const labels = this.getOwnershipsByKey(_get(track, 'masterOwnerships', []), 'label');

    if (!labels.length) return null;

    return (
      <OwnershipCategory>
        <OwnershipTitle>{t('components:modal_track_ownerships.labels_ownerships')}</OwnershipTitle>
        {labels.map((doc, i) => (
          <ModalCloser
            key={`labels-${doc.id}-${i}`}
            onClose={() => {
              closeMusicItemModal();
              closeMusicPlayerModal();
              closeTrackOwnershipsModal();
            }}
            path={getSearchUrl('labels')(doc.label)}
          >
            {onClose => (
              <Ownership>
                <StyledLink
                  inline
                  onClickPassthrough={onClose}
                  route={getSearchUrl('labels')(doc.label)}
                >
                  {_get(doc, 'label.labelName')}
                </StyledLink>
              </Ownership>
            )}
          </ModalCloser>
        ))}
      </OwnershipCategory>
    );
  };

  renderPublishers = () => {
    const {
      closeMusicItemModal,
      closeMusicPlayerModal,
      closeTrackOwnershipsModal,
      t,
      track,
    } = this.props;

    const publishers = this.getOwnershipsByKey(_get(track, 'publishingOwnerships'), 'publisher');

    const len = publishers.length;

    if (!len) return null;

    return (
      <OwnershipCategory>
        <OwnershipTitle>
          {t('components:modal_track_ownerships.publishers_ownerships')}
        </OwnershipTitle>
        {publishers.map((doc, i) => (
          <ModalCloser
            key={`publishers-${doc.id}-${i}`}
            onClose={() => {
              closeMusicItemModal();
              closeMusicPlayerModal();
              closeTrackOwnershipsModal();
            }}
            path={getSearchUrl('publishers')(doc.publisher)}
          >
            {onClose => (
              <Ownership>
                <StyledLink
                  inline
                  onClickPassthrough={onClose}
                  route={getSearchUrl('publishers')(doc.publisher)}
                >
                  {_get(doc, 'publisher.publisherName')}
                </StyledLink>
              </Ownership>
            )}
          </ModalCloser>
        ))}
      </OwnershipCategory>
    );
  };

  render() {
    const { closeTrackOwnershipsModal, opened, t, track } = this.props;

    if (!track) return null;

    return (
      <DefaultModal
        name="trackOwnerships"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeTrackOwnershipsModal}
        opened={opened}
      >
        <ClickAwayListener onClickAway={closeTrackOwnershipsModal}>
          <Wrapper>
            <Header>
              <CloseModalBtn onClick={closeTrackOwnershipsModal}>
                {t('components:modal.close')}
              </CloseModalBtn>
              <Title>{t('components:modal_track_ownerships.ownerships')}</Title>
              <EmptySpacePlaceholder />
            </Header>

            <Content>
              {this.renderLabels()}
              {this.renderPublishers()}
              {this.renderArtistsOwnerships()}
            </Content>
          </Wrapper>
        </ClickAwayListener>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalTrackOwnerships);
