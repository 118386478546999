// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

// =============================
// Styles
// =============================

export const notificationMinHeight = '7rem';

export const Wrapper = styled.div`
  display: flex;
  min-height: ${notificationMinHeight};
  padding: 1.6rem;
  width: 100%;

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};

  ${({ seen }) => !seen
    && css`
      background-color: ${({ theme }) => alpha(theme.colors.dropdown.text, 0.1)};
    `};
`;

export const Content = styled.div``;

export const IconWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => alpha(theme.colors.dropdown.text, 0.4)};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 4rem;
  justify-content: center;
  margin-right: 0.8rem;
  width: 4rem;
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.colors.dropdown.text};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.5rem;
  margin-bottom: 0.6rem;

  a {
    color: ${({ theme }) => theme.colors.dropdown.primary};

    &:hover {
      opacity: 0.7;
    }
  }

  b {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const Date = styled.div`
  font-size: 1rem;
`;
