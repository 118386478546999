// =============================
// Imports
// =============================

// External dependencies
import Router from 'next/router';

// Actions
import { getUserPlaylist, getUserPlaylistsList } from '../actions/UserPlaylistsActions';

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Middleware
// =============================

const userPlaylistsMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.GET_USER_SESSION_TOKEN_SUCCESS: // NOTE: Login through form
    case action.type === acts.GET_USER_DATA_SUCCESS: // NOTE: Login through cookie
    case action.type === acts.MODIFY_USER_PLAYLIST_SUCCESS:
      store.dispatch(getUserPlaylistsList());
      return next(action);

    case action.type === acts.ADD_TRACK_TO_PLAYLIST_SUCCESS:
    case action.type === acts.REMOVE_TRACK_FROM_PLAYLIST_SUCCESS: {
      const currentUserPlaylist = store.getState().userplaylists.playlist.data;

      // Refresh playlist counter & duration
      if (currentUserPlaylist.id) {
        store.dispatch(getUserPlaylist(currentUserPlaylist.id));
      }

      store.dispatch(getUserPlaylistsList());
      return next(action);
    }

    case action.type === acts.CREATE_USER_PLAYLIST_SUCCESS:
    case action.type === acts.DUPLICATE_PLAYLIST_SUCCESS:
    case action.type === acts.DUPLICATE_PITCH_SUCCESS:
    case action.type === acts.DUPLICATE_USER_PLAYLIST_SUCCESS:
      store.dispatch(getUserPlaylistsList());
      Router.push('/userplaylist/[playlistId]', `/userplaylist/${action.payload.id}`);
      return next(action);

    // NOTE: This is only possible from a user playlist page, thus the redirection
    case action.type === acts.REMOVE_USER_PLAYLIST_SUCCESS: {
      store.dispatch(getUserPlaylistsList());

      if (action.payload.redirect) {
        Router.push('/userplaylists');
      }

      return next(action);
    }

    default:
      return next(action);
  }
};

export default userPlaylistsMiddleware;
