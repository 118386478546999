// =============================
// Helpers
// =============================

/**
 * Pass view props to component
 * @param {Object} props - Set of key value pairs corresponding to the property and the value
 * @returns {Object} - Returns only the necessary props
 */
const getViewProps = (props) => {
  const viewProps = [
    // Div
    'bgColor', // background color
    'whiteSpace', // White space (wrap, nowrap)
    'position', // Position (absolute, relative, etc)
    'hide', // Hide/Show element
    'height', // Height
    'minHeight', // Min Height
    'maxHeight', // Max Height
    'width', // Width
    'minWidth', // Min Width
    'maxWidth', // Max Width
    'opacity', // Opacity
    'overflow', // Overflow
    'overflowWrap', // Overflow wrap
    'order', // Flex order
    'margin', // Margin
    'mx', // Horizontal margin
    'ml', // margin-left
    'mr', // margin-right
    'my', // Vertical margin
    'mt', // margin-top
    'mb', // margin-bottom
    'padding', // Padding
    'px', // Horizontal padding
    'pl', // padding-left
    'pr', // padding-right
    'py', // Vertical padding
    'pt', // padding-top
    'pb', // padding-bottom
    'shrink', // flex-shrink

    // Flex
    'flex', // flex
    'align', // align-items
    'justify', // justify-content
    'direction', // flex-direction
    'wrap', // flex-wrap
    'flexBasis', // flex-basis
  ];

  const retProps = {};

  Object.keys(props).forEach((key) => {
    if (viewProps.indexOf(key) >= 0) {
      retProps[key] = props[key];
    }
  });

  return retProps;
};

export default getViewProps;
