// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import * as pth from '../../../../helpers/proptypes';
import { presentTags } from '../../../../helpers/entity/common';

import {
  CloseModalBtn,
  Content,
  ContentTitle,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../mobile.styles';
import { DefaultModal } from '../styles';
import { TagsList, StyledTag } from './styles';

// =============================
// Layout
// =============================

class ModalTrackTags extends PureComponent {
  static propTypes = {
    /** Function used to close track artists modal. */
    closeMusicItemModal: PropTypes.func.isRequired,
    /** Function used to close track artists modal. */
    closeMusicPlayerModal: PropTypes.func.isRequired,
    /** Function used to close track tags modal. */
    closeTrackTagsModal: PropTypes.func.isRequired,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Translations. */
    t: PropTypes.func.isRequired,
    /** Tags full list */
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    /** Track. */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  renderTags = () => {
    const {
      closeMusicItemModal,
      closeMusicPlayerModal,
      closeTrackTagsModal,
      i18n: { language },
      tags,
      track,
    } = this.props;

    const displayTags = presentTags(
      track.tags
        .map(({ id }) => tags.find(tag => tag.id === id)),
      language,
    );

    return displayTags.map(tag => (
      <StyledTag
        data={tag}
        key={tag.id}
        onClickPassthrough={() => {
          closeMusicItemModal();
          closeMusicPlayerModal();
          closeTrackTagsModal();
        }}
      />
    ));
  };

  render() {
    const { closeTrackTagsModal, opened, t, track } = this.props;

    if (!track) return null;

    return (
      <DefaultModal
        name="trackTags"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeTrackTagsModal}
        opened={opened}
      >
        <ClickAwayListener onClickAway={closeTrackTagsModal}>
          <Wrapper>
            <Header>
              <CloseModalBtn onClick={closeTrackTagsModal}>
                {t('components:modal.close')}
              </CloseModalBtn>
              <Title>{t('components:modal_track_tags.track_tags')}</Title>
              <EmptySpacePlaceholder />
            </Header>
            <Content>
              <ContentTitle>
                {track.tags.length}
                &nbsp;
                {t('components:modal_track_tags.tags')}
              </ContentTitle>
              <TagsList>{this.renderTags()}</TagsList>
            </Content>
          </Wrapper>
        </ClickAwayListener>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalTrackTags);
