// =============================
// Imports
// =============================

import styled from 'styled-components';
import { CheckboxInput, Icons } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const Option = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  fill: ${({ theme }) => theme.colors.text};
  user-select: none;

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

export const OptionName = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: ${({ checked }) => (checked ? '1' : '0.9')};
`;

export const DescIcon = styled(Icons.Description).attrs(({ checked, theme }) => ({
  fill: theme.colors.text,
  opacity: checked ? '1' : '0.9',
}))`
  margin-right: 0.4rem;
`;

export const TagIcon = styled(Icons.Tag).attrs(({ checked, theme }) => ({
  fill: theme.colors.text,
  opacity: checked ? '1' : '0.9',
  width: '2.1rem',
}))`
  margin-right: 0.4rem;
`;

export const StyledCheckboxInput = styled(CheckboxInput).attrs(({ theme }) => ({
  fillColor: theme.colors.text,
  fillColorChecked: theme.colors.primary,
}))`
  margin-right: 0.4rem;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 0.8rem;
  `};
`;
