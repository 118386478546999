// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Dropdown } from '@mewo/components';

import { notificationMinHeight } from './notification/styles';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(Dropdown).attrs(({ theme }) => ({
  dropdownStyles: css`
    background-color: ${theme.colors.dropdown.background};
    border-radius: ${(theme.edgeVariant === 'round' ? '0.5rem' : '0.2rem')};
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.boxShadow};
    color: ${theme.colors.dropdown.text};
    width: 38rem;
  `,
  withoutStyles: true,
}))``;

export const Header = styled.div`
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  padding: 0 1.6rem;
  width: 100%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.dropdown.text};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const MarkAllRead = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.dropdown.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const Content = styled.div`
  height: calc(${notificationMinHeight} * 5);
  width: 38rem;
`;

export const NoNotification = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: ${notificationMinHeight};
  justify-content: center;
`;
