// =============================
// Imports
// =============================

// External dependencies
import { memo, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AppThemeProvider, WithCustomTheme } from '@mewo/components';
import Favicon from 'react-favicon';
import { withRouter } from 'next/router';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _some from 'lodash/some';

// App
import { getUiConfig, getColorConfig, getMuiConfig } from './ui-config';

// Config
import { withTranslation } from '../config/i18n';

// Components
import MewoDevTools from '../components/presentationals/devTools';
import Drag from './drag';
import PlayerCore from '../components/other/playercore';
import CookieConsent from '../components/containers/cookieconsent';
import Loader from '../components/containers/loader';
import NotificationsList from '../components/containers/notificationsList';
import withResponsive from '../components/hoc/withResponsive';

// Helpers
import * as pth from '../helpers/proptypes';

// =============================
// Styles
// =============================

const FullScreenWrapper = styled.div`
  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  `};

  ${({ theme }) => theme.mediaBelow.minWidth`
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
  `};
`;

const GlobalStyle = memo(createGlobalStyle`
  html {
    min-width: 0;
  }

  /** Intercom Position handler */
  body .intercom-lightweight-app-launcher,
  body .intercom-launcher-frame {
    transition: bottom ${({ theme }) => theme.transition.medium};
    z-index: 1 !important;
  }

  /** We use wildcard because is-locked- might not be the 1st class on body. */
  body[class*="is-locked-"] {
    /** We need to apply those style to FullScreenWrapper since its the element
    doing the scroll on Showcase instead of body. */
    ${FullScreenWrapper} {
      overflow: hidden;
      position: relative;
    }
  }
`);

// =============================
// Page
// =============================

class Layout extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    isSimpleLayout: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    otherModalsOpened: PropTypes.bool.isRequired,
    darkModalsOpened: PropTypes.bool.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      id: PropTypes.string,
      colors: pth.colorsModule,
      isVisible: PropTypes.bool,
      headerLogo: PropTypes.oneOf(['logo', 'logo_variant', 'logo_modal']),
    }),
    isPreSearchOpen: PropTypes.bool.isRequired,
    router: PropTypes.shape({
      route: PropTypes.string,
      asPath: PropTypes.string,
    }).isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
  };

  render() {
    const {
      config,
      isSimpleLayout,
      isMobile,
      darkModalsOpened,
      otherModalsOpened,
      isPreSearchOpen,
      fullSizeCoverModule,
      router,
      i18n: { language },
      children,
    } = this.props;

    const isSearchPage = router.route === '/search';

    const themeOverride = {
      ...getUiConfig(config),
      ...getColorConfig(
        config,
        isSimpleLayout,
        darkModalsOpened,
        otherModalsOpened,
        isSearchPage,
        isPreSearchOpen,
        fullSizeCoverModule,
      ),
    };

    const faviconUrl = _get(config, 'customisations.favicon.original.url');

    return (
      <AppThemeProvider
        themeOverride={themeOverride}
        muiThemeOverride={getMuiConfig(themeOverride)}
        locale={language}
      >
        <WithCustomTheme
          customTheme={() => ({
            colors: {
              currentTheme: themeOverride.colors.currentTheme,
              currentMenuTheme: themeOverride.colors.currentMenuTheme,
              notification: themeOverride.colors.notification,
              tooltip: themeOverride.colors.tooltip,
              cookieConsent: themeOverride.colors.cookieConsent,
              logo: themeOverride.colors.logo,
              showHeaderShadow: themeOverride.colors.showHeaderShadow,
              onDefaultContext: themeOverride.colors.onDefaultContext,
            },
            isMobile: () => isMobile,
          })}
        >
          <Fragment>
            <MewoDevTools />
            <GlobalStyle />
            {!!faviconUrl && <Favicon url={faviconUrl} />}
            <Loader />
            <Drag
              isSimpleLayout={isSimpleLayout}
              locationKey={router.asPath}
              routeKey={router.route}
              isSearchPage={isSearchPage}
            >
              <FullScreenWrapper id="fullscreen-wrapper">
                {children}
              </FullScreenWrapper>
            </Drag>
            <NotificationsList />
            <CookieConsent />
            <PlayerCore />
          </Fragment>
        </WithCustomTheme>
      </AppThemeProvider>
    );
  }
}

function mapStateToProps({ modals, search }) {
  // Retrieve 1st visible FullSizeCoverModule
  const currentFullSizeCoverModule = _find(
    search.fullSizeCoverModule,
    item => item.isVisible,
    null,
  );

  return {
    otherModalsOpened: _some([
      _get(modals, 'isAccountOpen', false),
      _get(modals, 'isMenuOpen', false),
      _get(modals, 'isCookiesOpen', false),
      _get(modals, 'isLegalOpen', false),
      _get(modals, 'downloadArchive.isOpen', false),
    ]),
    darkModalsOpened: _some([
      _get(modals, 'trackLyrics.isOpen', false),
      _get(modals, 'isAlbumOpen', false),
      _get(modals, 'isArtistOpen', false),
      _get(modals, 'isCatalogOpen', false),
      _get(modals, 'isPlaylistOpen', false),
      _get(modals, 'video.isOpen', false),
    ]),
    isPreSearchOpen: modals.isPreSearchOpen,
    fullSizeCoverModule: currentFullSizeCoverModule,
  };
}

export default compose(
  withRouter,
  withTranslation(['common', 'components']),
  withResponsive,
  connect(mapStateToProps),
)(Layout);
