// =============================
// Imports
// =============================

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Logo from '../../logobase';

import * as pth from '../../../../helpers/proptypes';

import { NoLogo, StyledLink } from './styles';

// =============================
// Component
// =============================

const LogoWithConfig = ({
  config,
  innerRef,
  logoRoute,
  logo,
  logoVariant,
  logoModal,
  logoFilterBar,
  onClickPassthrough,
  usedLogo,
  ...rest
}) => {
  const src = _get(logo, 'original.url', undefined);
  const variantSrc = _get(logoVariant, 'original.url', undefined);
  const modalSrc = _get(logoModal, 'original.url', undefined);
  const filterBarSrc = _get(logoFilterBar, 'original.url', undefined);

  if (!src || !variantSrc || !modalSrc || !filterBarSrc) {
    return (
      <StyledLink onClickPassthrough={onClickPassthrough} route={logoRoute}>
        <NoLogo ref={innerRef} {...rest}>
          {_get(config, 'websiteTitle')}
        </NoLogo>
      </StyledLink>
    );
  }

  return (
    <StyledLink noHover onClickPassthrough={onClickPassthrough} route={logoRoute}>
      <Logo
        filterBarSrc={filterBarSrc}
        name={_get(config, 'websiteTitle')}
        modalSrc={modalSrc}
        ref={innerRef}
        src={src}
        usedLogo={usedLogo}
        variantSrc={variantSrc}
        {...rest}
      />
    </StyledLink>
  );
};

LogoWithConfig.displayName = 'LogoMobile';

LogoWithConfig.propTypes = {
  config: pth.config,
  innerRef: PropTypes.func,
  logo: pth.image.isRequired,
  logoFilterBar: pth.image.isRequired,
  logoModal: pth.image.isRequired,
  logoRoute: PropTypes.string.isRequired,
  logoVariant: pth.image.isRequired,
  onClickPassthrough: PropTypes.func,
  usedLogo: PropTypes.string.isRequired,
};

LogoWithConfig.defaultProps = {
  config: null,
  innerRef: null,
  onClickPassthrough: null,
};

function forwardedRef(props, ref) {
  return <LogoWithConfig innerRef={ref} {...props} />;
}

forwardedRef.displayName = LogoWithConfig.displayName;

export default forwardRef(forwardedRef);
