// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setArtistOpen } from '../../../../store/actions/ModalsActions';

import ModalArtist from '../../../layouts/modals/artist';

// =============================
// Component
// =============================

function mapStateToProps({ modals, entities }) {
  return {
    artist: entities.artist.data,
    opened: modals.isArtistOpen,
  };
}

export default connect(mapStateToProps, {
  closeArtistDescModal: () => setArtistOpen(false),
})(ModalArtist);
