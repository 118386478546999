// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _get from 'lodash/get';

import Logo from '../../presentationals/logo';

import { hasAccessToPrivate } from '../../../helpers/user';
import { rootRoute as getRootRoute } from '../../../helpers/config';

// =============================
// Component
// =============================

function mapStateToProps({ config, user }) {
  return {
    config: config.data,
    logo: _get(config.data, 'customisations.logo', {}),
    logoVariant: _get(config.data, 'customisations.logoVariant', {}),
    logoModal: _get(config.data, 'customisations.logoModal', {}),
    logoFilterBar: _get(config.data, 'customisations.logoFilterBar', {}),
    logoRoute: hasAccessToPrivate(user, config) ? getRootRoute(config.data) : '/login',
  };
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(Logo);
