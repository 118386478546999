// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { wrapperWidth, wrapperWidthSxga, wrapperWidthWuxga, wrapperWidthWxgaPlus } from '../../../layouts/common.variables';
import { headerHeight } from '../../../presentationals/header/desktop/variables';
import { musicPlayerPosBottom, musicPlayerPosBottomMinimized } from '../../../presentationals/musicplayer/desktop/variables';
import { marginAfterHeader, marginAfterLastItem, paddingX } from './variables';

// =============================
// Styles
// =============================

const Wrapper = styled.div`
  ${({ playerIsMinimized, playerIsOpened }) => css`
    &::after {
      content: '';
      display: block;
      width: 100%;

      ${playerIsOpened ? css`
        height: calc(${playerIsMinimized ? musicPlayerPosBottomMinimized : musicPlayerPosBottom} + ${marginAfterLastItem});
      ` : css`
        height: ${marginAfterLastItem};
      `};
    }
  `};
`;

export const CustomPageWrapper = styled.div`
  margin: 0 auto;
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  width: 100%;

  ${({ playerIsMinimized, playerIsOpened }) => css`
    &::after {
      content: '';
      display: block;
      width: 100%;

      ${playerIsOpened && css`
        height: calc(${playerIsMinimized ? musicPlayerPosBottomMinimized : musicPlayerPosBottom});
      `};
    }
  `};

  ${({ noPaddingTop }) => !noPaddingTop && css`
    padding-top: calc(${headerHeight});
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    max-width: ${wrapperWidth};
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    max-width: ${wrapperWidthSxga};
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    max-width: ${wrapperWidthWxgaPlus};
  `};

  ${/* We set it on wuxga because if we set it before, content can go behind Menu SVG gradient. */
  ({ theme }) => theme.mediaAbove.wuxga`
    max-width: ${wrapperWidthWuxga};
    width: 100%;
  `};
`;

export const EntityWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  ${/* TODO: Why don't we use a variable for the width here ? */
  ({ theme }) => theme.mediaAbove.wxgaplus`
    margin: 0 auto;
    width: 120rem;
  `};
`;

export const PlaylistWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  ${/* TODO: Why don't we use a variable for the width here ? */
  ({ theme }) => theme.mediaAbove.wxgaplus`
    margin: 0 auto;
    width: 120rem;
  `};

  /** Playlist relative styles */
  ${({ theme }) => {
    if (theme.displayConf.playlistImg === 'wide') {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
`;

export const PitchWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  ${/* TODO: Why don't we use a variable for the width here ? */
  ({ theme }) => theme.mediaAbove.wxgaplus`
    margin: 0 auto;
    width: 120rem;
  `};

  /** Pitch relative styles */
  ${({ theme }) => {
    if (theme.displayConf.playlistImg === 'wide') {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
`;

export const SearchWrapper = styled(Wrapper)`
  padding-left: calc(30rem + 8rem);
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  ${({ theme }) => theme.mediaAbove.wuxga`
    max-width: 170rem;
    margin: 0 auto;
  `};
`;

export const TextWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  ${/* TODO: Why don't we use a variable for the width here ? */
  ({ theme }) => theme.mediaAbove.wxgaplus`
    margin: 0 auto;
    width: 120rem;
  `};
`;

export default Wrapper;
