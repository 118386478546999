/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { Div } from '@mewo/components';

// =============================
// Styles
// =============================

const resetStyles = css`
  background-color: transparent;
  /** We use a 0.2rem transparent border as a placeholder,
  for Outline fillVariant, so every fillVariant have the
  same width. */
  border: 0.2rem solid transparent;
  font-family: inherit;
  outline: none;
  user-select: none;
`;

export const Wrapper = styled(Div).attrs({
  as: 'button',
})`
  ${resetStyles};
  align-items: center;
  border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '1rem' : '0.2rem')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  position: relative;
  transition-duration: ${({ theme }) => theme.transition.medium};
  white-space: nowrap;
  z-index: 0;

  ${({ centered }) => centered
    && css`
      margin-left: auto;
      margin-right: auto;
    `};

  ${({ fullWidth }) => fullWidth
    && css`
      justify-content: center;
      text-align: center;
      width: 100%;
    `};

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '2rem' : '0.2rem')};
          height: 4rem;
          font-size: 1.4rem;
          padding: 0 3.2rem;
        `;

      case 'medium':
      default:
        return css`
          border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '3.2rem' : '0.2rem')};
          height: 5.5rem;
          font-size: 1.4rem;
          padding: 0 3.2rem;
        `;
    }
  }}

  ${({ color, disabled, fillVariant, theme }) => {
    let clr = '';
    if (color === 'primary') clr = theme.colors.primary;
    else clr = theme.colors.type[color];

    if (disabled) clr = theme.colors.type.cancel;

    switch (fillVariant) {
      case 'outline':
        return css`
          color: ${clr};

          &:before {
            background-color: transparent;
            border: 0.2rem solid ${clr};
            border-radius: inherit;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: transform ${theme.transition.medium};
            width: 100%;
            z-index: -1;
          }

          ${!disabled
            && css`
              &:hover {
                color: ${clr};

                ${color === 'primary'
                && css`
                  color: ${theme.colors.primaryText};
                `};

                &:before {
                  ${theme.mediaAbove.minWidth`
                    transform: scale(1.1);
                  `};

                  ${color === 'primary'
                  && css`
                    background-color: ${theme.colors.primary};
                    border: 0.2rem solid ${theme.colors.primary};
                  `};
                }
              }
            `};
        `;

      case 'plain':
      default:
        return css`
          color: ${theme.colors.white};

          ${color === 'primary'
          && css`
            color: ${theme.colors.primaryText};
          `};

          &:before {
            background-color: ${clr};
            content: '';
            border-radius: inherit;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            transition: transform ${theme.transition.medium};
            width: 100%;
            z-index: -1;
          }

          ${!disabled
          && css`
            &:hover {
              &:before {
                ${theme.mediaAbove.minWidth`
                  transform: scale(1.1);
                `};
              }
            }
          `};
        `;
    }
  }};
`;
