// =============================
// Imports
// =============================

import { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';

import * as pth from '../../../../helpers/proptypes';
import * as entity from '../../../../helpers/entity';

import {
  ArtistCover,
  ArtistName,
  Wrapper,
  AddArtistAsFilterBtn,
  GoToIcon,
  OverlayLinkContainer,
  OverlayLink,
} from './styles';

// =============================
// Component
// =============================

class ArtistItem extends Component {
  static displayName = 'ArtistItemMobile';

  static propTypes = {
    /** Artist object */
    artist: pth.artist.isRequired,
    /** Lazy load */
    lazyload: PropTypes.bool,
    /** Function to execute onClick on the main link. */
    onClickPassthrough: PropTypes.func,
    /** Function to execute onClick on the search filter link. */
    onAddFilterClickPassthrough: PropTypes.func,
    /** If True, will render a Cover. */
    withCover: PropTypes.bool,
    /** If True, will display search button to add artist as a search filter. */
    withSearchFilterBtn: PropTypes.bool,
  };

  static defaultProps = {
    lazyload: true,
    onAddFilterClickPassthrough: null,
    onClickPassthrough: null,
    withCover: true,
    withSearchFilterBtn: false,
  };

  render() {
    const {
      artist,
      lazyload,
      onAddFilterClickPassthrough,
      onClickPassthrough,
      withCover,
      withSearchFilterBtn,
    } = this.props;

    return (
      <Wrapper withCover={withCover} withSearchFilterBtn={withSearchFilterBtn}>
        <OverlayLinkContainer>
          <OverlayLink
            onClickPassthrough={onAddFilterClickPassthrough}
            route={entity.getSearchUrl('artists')(artist)}
          />
        </OverlayLinkContainer>
        {withCover && (
          <ArtistCover
            alt={entity.getTitle('artists')(artist)}
            lazyload={lazyload}
          />
        )}
        <ArtistName>{entity.getTitle('artists')(artist)}</ArtistName>
        {withSearchFilterBtn && (
          <AddArtistAsFilterBtn
            onClickPassthrough={onClickPassthrough}
            route={entity.getBaseRoute('artists')}
            nextAs={entity.getUrl('artists')(artist)}
          >
            <GoToIcon />
          </AddArtistAsFilterBtn>
        )}
      </Wrapper>
    );
  }
}

function forwardedRef(props, ref) {
  return <ArtistItem innerRef={ref} {...props} />;
}

forwardedRef.displayName = ArtistItem.displayName;

export default forwardRef(forwardedRef);
