// =============================
// Imports
// =============================

import { Component } from 'react';
import _debounce from 'lodash/debounce';

// =============================
// Page
// =============================

export default function withGoogle(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return class extends Component {
    state = {
      ready: false,
    };

    componentDidMount() {
      this.debounceLoadApi();
    }

    loadGoogleApi = () => {
      const googleApiKey = process.env.GOOGLE_API_KEY;
      if (typeof window === 'undefined') return null;
      const existingScript = document.getElementById('googlemapsapi');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
        script.id = 'googlemapsapi';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => this.setApiMethods();
        return true;
      }

      if (window.google && window.google.maps) {
        return this.setApiMethods();
      }

      return this.debounceLoadApi();
    };

    // eslint-disable-next-line react/sort-comp
    debounceLoadApi = _debounce(this.loadGoogleApi, 1000, {
      leading: true,
      maxWait: 1000,
    });

    setApiMethods = () => {
      this.autocompleteApi = new window.google.maps.places.AutocompleteService(
        document.createElement('div'),
      );

      this.geocodingApi = new window.google.maps.Geocoder();
      this.placesApi = new window.google.maps.places.PlacesService(document.createElement('div'));

      this.setState({ ready: true });
    };

    render() {
      const { ready } = this.state;

      return (
        <WrappedComponent
          googleAutocomplete={this.autocompleteApi || null}
          googleGeocoding={this.geocodingApi || null}
          googlePlaces={this.placesApi || null}
          googleApisLoaded={ready}
          {...this.props}
        />
      );
    }
  };
}
