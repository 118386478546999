// =============================
// Imports
// =============================

import _startsWith from 'lodash/startsWith';
import _some from 'lodash/some';

// =============================
// Helpers
// =============================

export const isExternalLink = link => (
  _startsWith(link.slug, 'http') || _startsWith(link.slug, 'https')
);

export const isLinkActive = (link, isLogged = false) => !(
  _some(['favorites', 'recentplays', 'userplaylists'], s => s === link.slug) && !isLogged
);

export const getLinkRouteProps = (link, isLogged = false) => {
  // Handle inactive links.
  if (!isLinkActive(link, isLogged)) {
    return { route: 'register' };
  }

  // Handle external links
  if (isExternalLink(link)) {
    return { route: link.slug };
  }

  // Handle page links
  if (link.page) {
    // Home page
    if (!link.slug) {
      return { route: '/' };
    }

    return { route: '/page/[...slug]', nextAs: `/page/${link.slug}` };
  }

  // Handle internal links
  switch (true) {
    case link.slug.indexOf('albums/') === 0:
      return { route: '/albums/[albumId]', nextAs: `/${link.slug}` };

    case link.slug.indexOf('artists/') === 0:
      return { route: '/artists/[artistId]', nextAs: `/${link.slug}` };

    case link.slug.indexOf('catalogs/') === 0:
      return { route: '/catalogs/[catalogId]', nextAs: `/${link.slug}` };

    case link.slug.indexOf('drop/') === 0:
      return { route: '/drop/[pitchId]', nextAs: `/${link.slug}` };

    case link.slug.indexOf('userplaylist/') === 0:
      return { route: '/userplaylist/[playlistId]', nextAs: `/${link.slug}` };

    default:
      return { route: link.slug };
  }
};
