// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  isFetchedOnce: false,
  isAdding: false,
  isRemoving: false,
  data: [],
  total: 0,
  offset: null,
  limit: null,
};

// =============================
// Reducer
// =============================

export default function favorites(state = initialState, action) {
  switch (action.type) {
    case acts.SET_FAVORITES:
      if (action.payload.offset === 0) {
        return {
          ...state,
          ...action.payload,
        };
      }

      return {
        ...state,
        ...action.payload,
        data: [...state.data, ...action.payload.data],
      };

    case acts.REPLACE_FAVORITES_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case acts.GET_FAVORITES_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.GET_FAVORITES_SUCCESS:
      return {
        ...state,
        isFetchedOnce: true,
        isFetching: false,
      };

    case acts.GET_FAVORITES_FAILURE:
      return {
        ...state,
        isFetchedOnce: false,
        isFetching: false,
      };

    case acts.ADD_TO_FAVORITES_LOADING:
      return {
        ...state,
        isAdding: true,
      };

    case acts.ADD_TO_FAVORITES_SUCCESS:
    case acts.ADD_TO_FAVORITES_FAILURE:
      return {
        ...state,
        isAdding: false,
      };

    case acts.REMOVE_FROM_FAVORITES_LOADING:
      return {
        ...state,
        isRemoving: true,
      };

    case acts.REMOVE_FROM_FAVORITES_SUCCESS:
    case acts.REMOVE_FROM_FAVORITES_FAILURE:
      return {
        ...state,
        isRemoving: false,
      };

    case acts.RESET_FAVORITES:
      return initialState;

    default:
      return state;
  }
}
