// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { lighten } from '@material-ui/core';

import { Svg, SvgDefs } from './styles';

// =============================
// Component
// =============================

/*
  NOTE:
  Safari bug: Open presearch modal, close it, click on site Logo
  => Background component will render blank.

  To fix this we had to set defs out of Background component, not very sure why we have to do this
*/
export const BackgroundDefs = withTheme(({ theme }) => (
  <SvgDefs>
    <defs>
      <rect id="AppShellRect" opacity="1" x="0" y="0" width="1280" height="2141" />
      <linearGradient id="AppShellLinearGradient" x1="1" y1="1" x2="0.1" y2="0">
        <stop offset="0" stopColor={lighten(theme.colors.background, 0.20)} stopOpacity="1" />
        <stop offset="0.5" stopColor={theme.colors.background} stopOpacity="1" />
        <stop offset="1" stopColor={theme.colors.background} stopOpacity="1" />
      </linearGradient>
    </defs>
    <g opacity="1">
      <use xlinkHref="#AppShellRect" fill="url(#AppShellLinearGradient)" fillOpacity="1" />
    </g>
  </SvgDefs>
));

const Background = ({ theme, ...rest }) => (
  <Svg viewBox="0 0 1280 2141" width="100%" preserveAspectRatio="none" {...rest}>
    <g opacity="1">
      <use xlinkHref="#AppShellRect" fill="url(#AppShellLinearGradient)" fillOpacity="1" />
    </g>
  </Svg>
);

Background.propTypes = {
  /** Styled component theme. */
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      background: PropTypes.string,
    }),
  }).isRequired,
};

export default withTheme(Background);
