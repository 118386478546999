// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

// =============================
// Styles
// =============================

export const sharedStyles = css`
  cursor: pointer;
  display: block;
  text-decoration: none;

  &:active {
    outline: none;
  }

  ${({ inline }) => inline
    && css`
      display: inline-block;
    `}

  ${({ noHover }) => !noHover
    && css`
      transition: color ${({ theme }) => theme.transition.fast}, opacity ${({ theme }) => theme.transition.fast};

      &:hover {
        opacity: 0.75;
      }

      * {
        transition: color ${({ theme }) => theme.transition.fast}, opacity ${({ theme }) => theme.transition.fast};

        &:hover {
          opacity: 0.75;
        }
      }
    `};

  ${({ textEllipsis, theme }) => textEllipsis && theme.helpers.textEllipsis};
`;

export const LinkWrapper = styled.a`
  ${sharedStyles};
`;

export const Wrapper = styled.div`
  ${sharedStyles};
`;
