// =============================
// Imports
// =============================

import { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';

import getViewProps from '../../../../helpers/front/getviewprops';

import { Wrapper, VariantLogo, ModalLogo, Logo } from './styles';

// =============================
// Component
// =============================

class LogoBase extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** Filter bar logo */
    filterBarSrc: PropTypes.string.isRequired,
    /** Forwarded ref */
    innerRef: PropTypes.func,
    /** Modal logo */
    modalSrc: PropTypes.string.isRequired,
    /** Name to be used for alt text */
    name: PropTypes.string,
    /** Base logo */
    src: PropTypes.string.isRequired,
    /** Which logo are we using */
    usedLogo: PropTypes.oneOf(['default', 'variant', 'modal', 'filterBar']),
    /** Variant logo */
    variantSrc: PropTypes.string.isRequired,
  };

  static displayName = 'LogoBaseMobile';

  static defaultProps = {
    className: '',
    innerRef: null,
    name: 'logo',
    usedLogo: 'default',
  };

  render() {
    const {
      className,
      filterBarSrc,
      innerRef,
      modalSrc,
      name,
      src,
      usedLogo,
      variantSrc,
      ...rest
    } = this.props;

    return (
      <Wrapper ref={innerRef} className={className} {...getViewProps(rest)}>
        {/** NOTE: In mobile there is no filter bar logo, so we use the default logo */}
        <Logo alt={name} src={src} show={usedLogo === 'default' || usedLogo === 'filterBar'} />
        <VariantLogo alt={name} src={variantSrc} show={usedLogo === 'variant'} />
        <ModalLogo alt={name} src={modalSrc} show={usedLogo === 'modal'} />
      </Wrapper>
    );
  }
}

function forwardedRef(props, ref) {
  return <LogoBase innerRef={ref} {...props} />;
}

forwardedRef.displayName = LogoBase.displayName;

export default forwardRef(forwardedRef);
