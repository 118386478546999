// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { WrapIfDesktop } from '../../other/responsive';
import PageWrapper from '../../other/pagewrapper';

import { getValueByLocale } from '../../../helpers/i18n';
import * as pth from '../../../helpers/proptypes';

import {
  ModalContainer,
  Content,
  Title,
  Separation,
  StyledScrollBar,
} from '../common.text.styles';

// =============================
// Stories
// =============================

class Legal extends PureComponent {
  static propTypes = {
    legal: pth.descriptions.isRequired,
    /** If True, will only render content. To be injected in a modal. */
    modalInjection: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modalInjection: false,
  };

  render() {
    const { modalInjection, legal, locale, t } = this.props;

    const legalHTML = getValueByLocale(legal, locale);

    const body = (
      <Fragment>
        <Content>
          {!!legalHTML && (
            <Fragment>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: legalHTML }} />
              <Separation />
            </Fragment>
          )}
          <p>{t('pages:legal.first')}</p>
          <p>{t('pages:legal.second')}</p>
          <p>{t('pages:legal.third')}</p>
          <p>{t('pages:legal.fourth')}</p>
        </Content>
      </Fragment>
    );

    if (modalInjection) {
      return (
        <ModalContainer>
          <Title>{t('pages:legal.title')}</Title>
          <WrapIfDesktop Wrapper={<StyledScrollBar />}>
            {body}
          </WrapIfDesktop>
        </ModalContainer>
      );
    }

    return (
      <PageWrapper type="text">
        <Title>{t('pages:legal.title')}</Title>
        {body}
      </PageWrapper>
    );
  }
}

export default Legal;
