/* eslint-disable react/no-multi-comp, react/no-array-index-key */
// =============================
// Imports
// =============================

import { ConditionalWrapper, WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Component, Fragment, forwardRef } from 'react';
import { Draggable as DraggableDnd } from 'react-beautiful-dnd';
import { compose } from 'redux';

import { withTranslation } from '../../../../config/i18n';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import Link from '../../../other/link';
import Details from './details';
import DownloadDropdown from './downloaddropdown';
import WaveformWrapper from './waveformwrapper';

import { getBaseRoute, getUrl } from '../../../../helpers/entity';
import { getAlbumCoverUrl } from '../../../../helpers/entity/album';
import { presentDuration, presentTags } from '../../../../helpers/entity/common';
import {
  getNextSeekValue,
  getTrackAlbum,
  getTrackDisplayArtists,
  getTrackTitle,
} from '../../../../helpers/entity/track';
import getViewProps from '../../../../helpers/front/getviewprops';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { encodeAndRemovePercent } from '../../../../helpers/misc';
import { Option, OptionLink } from '../../dropdown/styles';
import {
  Actions,
  AddToFavoriteIcon,
  AddToPlaylistIcon,
  ArrowDropdownIcon,
  AuthorsList,
  DeleteIcon,
  Description,
  DetailsWrapper,
  DownloadIcon,
  Duration,
  MagicWandIcon,
  MusicItemWrapper,
  OutsideInfo,
  RecentlyPlayedIcon,
  ShareIcon,
  StyledAddToPlaylist,
  StyledCover,
  StyledDropdownButton,
  StyledLink,
  StyledShareButton,
  StyledTag,
  StyledTooltip,
  Synchro,
  SynchroBar,
  Tags,
  ThreeDotIcon,
  Title,
  TitleAuthors,
  ToggleMusicItem,
  VersionCount,
  Versions,
  Wrapper,
} from './styles';

// =============================
// Component
// =============================

class MusicItem extends Component {
  static displayName = 'MusicItemDesktop';

  static propTypes = {
    /** User can download audiofiles */
    canDownload: PropTypes.bool.isRequired,
    /** User can download stems */
    canDownloadStems: PropTypes.bool.isRequired,
    /** Id of the player context */
    contextId: PropTypes.string,
    /** Name of the player context */
    contextName: PropTypes.string,
    /** Position of the track within player context */
    contextPosition: PropTypes.number,
    /** Player current time */
    getCurrentTime: PropTypes.func,
    /** Function that gets track files datas */
    getFilesDatas: PropTypes.func.isRequired,
    /** Player getMedia function */
    getMedia: PropTypes.func.isRequired,
    /** Function to trigger download archive */
    handleDownloadArchive: PropTypes.func.isRequired,
    /** Function to trigger download audiofile with metadata */
    handleDownloadAudiofile: PropTypes.func.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Used to pass ref to parent */
    innerRef: PropTypes.func,
    /** Asserts if the track is current */
    isCurrent: PropTypes.func.isRequired,
    /** Asserts versions are fetching */
    isFetchingVersions: PropTypes.bool.isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Asserts if the track is being played */
    isPlaying: PropTypes.func.isRequired,
    /** Lazy load */
    lazyload: PropTypes.bool,
    /** Locale used by user */
    locale: PropTypes.string,
    /** Music object */
    music: pth.track.isRequired,
    /** Function that notifies download */
    notifyFileDownload: PropTypes.func.isRequired,
    /** Callback function called when user click on the Heart button */
    onAddToFavorites: PropTypes.func.isRequired,
    /** Callback function called when user click on the Delete button */
    onDelete: PropTypes.func,
    /** Handle click on MagicWand icon */
    onMagicWand: PropTypes.func.isRequired,
    /** Function that fetches track versions */
    onOpenDetailsPassthrough: PropTypes.func.isRequired,
    /** Function that fetches track files */
    onOpenFilesPassthrough: PropTypes.func.isRequired,
    /** Handle click on pause icon */
    onPause: PropTypes.func.isRequired,
    /** Player play function */
    play: PropTypes.func.isRequired,
    /** Playlist is owned by user */
    playlistIsOwnedByUser: PropTypes.bool,
    /** Playlist token */
    pitchToken: PropTypes.string.isRequired,
    /** Router props */
    router: PropTypes.shape({
      route: PropTypes.string,
    }).isRequired,
    /** Search query */
    search: pth.searchquery.isRequired,
    /** Player seek function */
    seek: PropTypes.func.isRequired,
    /** Open track lyrics modal */
    setTrackLyricsOpen: PropTypes.func.isRequired,
    /** If true, show an extra information on musicitem */
    showExtraOptionsDescription: PropTypes.bool.isRequired,
    showExtraOptionsTags: PropTypes.bool.isRequired,
    /** Style */
    style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Tags full list */
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    /** Music item variant */
    variant: PropTypes.oneOf(['global']),
    /** Versions */
    versions: PropTypes.arrayOf(pth.track).isRequired,
  };

  static displayName = 'MusicItemDesktop';

  static defaultProps = {
    contextId: null,
    contextName: null,
    contextPosition: null,
    getCurrentTime: null,
    innerRef: null,
    lazyload: true,
    locale: null,
    onDelete: null,
    playlistIsOwnedByUser: false,
    style: {},
    variant: 'global',
  };

  state = {
    opened: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { style, ...props } = this.props;

    return (
      Object.keys(props).some(k => props[k] !== nextProps[k])
      || this.state !== nextState
      || !_isEqual(style, nextProps.style)
    );
  }

  toggleDetails = (opened) => {
    const { onOpenDetailsPassthrough } = this.props;

    if (opened === true) {
      onOpenDetailsPassthrough();
    }

    this.setState(() => ({
      opened,
    }));
  };

  renderAlternativeTitle = (music) => {
    const { locale, pitchToken } = this.props;

    const version = getValueByLocale(_get(music, 'version.names', []), locale);

    if (version) {
      const content = getTrackTitle(music);

      if (getUrl('tracks')(music) && !pitchToken) {
        return (
          <StyledTooltip content={content} fullWidth>
            <StyledLink inline route={getBaseRoute('tracks')} nextAs={getUrl('tracks')(music)}>
              <Title isVersionTitle={!!version}>{content}</Title>
            </StyledLink>
          </StyledTooltip>
        );
      }

      return (
        <StyledTooltip content={content} fullWidth>
          <Title isVersionTitle>{content}</Title>
        </StyledTooltip>
      );
    }

    return '';
  };

  renderTags = () => {
    const { locale, music, tags } = this.props;

    const displayTags = presentTags(
      _get(music, 'tags', [])
        .map(({ id }) => tags.find(tag => tag.id === id)),
      locale,
    );

    return displayTags.map(tag => <StyledTag key={tag.id} data={tag} />);
  };

  renderDescription = () => {
    const { locale, music } = this.props;

    return getValueByLocale(music.descriptions, locale);
  };

  notifyDownload = (trackId, type) => {
    const { isLogged, pitchToken, notifyFileDownload } = this.props;

    if (isLogged || pitchToken) {
      notifyFileDownload(trackId, type);
    }
  };

  renderFilesDropdown = (music) => {
    const {
      isLogged,
      canDownload,
      canDownloadStems,
      getFilesDatas,
      onOpenFilesPassthrough,
      pitchToken,
      handleDownloadAudiofile,
      handleDownloadArchive,
      t,
    } = this.props;

    if (!isLogged && !pitchToken) {
      return (
        <StyledTooltip content={t('components:music_item.download')}>
          <Link route="/login">
            <DownloadIcon />
          </Link>
        </StyledTooltip>
      );
    }

    const options = [];
    const filesDatas = getFilesDatas(music);

    // NOTE: 1 means that there are no alternative versions
    const nbVersions = _get(music, 'versions', 1) - 1;

    if (_get(filesDatas, 'data.audiofile.original.url')) {
      options.push({
        action: () => handleDownloadAudiofile(music, 'original'),
        name: 'original',
        label: t('components:music_item.download_original_audiofile'),
      });
    }

    if (isLogged && (nbVersions || _get(filesDatas, 'data.audiofile.original.url'))) {
      options.push({
        action: () => handleDownloadArchive(music),
        name: 'high_quality_archive_with_versions',
        label: t('components:music_item.download_high_quality_archive_with_versions'),
      });
    }

    if (_get(filesDatas, 'data.audiofile.hdMp3.url')) {
      options.push({
        action: () => handleDownloadAudiofile(music),
        name: 'hdmp3',
        label: t('components:music_item.download_hd_mp3'),
      });
    }

    if (isLogged && (nbVersions || _get(filesDatas, 'data.audiofile.original.url'))) {
      options.push({
        action: () => handleDownloadArchive(music, 'hd_mp3'),
        name: 'low_quality_archive_with_versions',
        label: t('components:music_item.download_low_quality_archive_with_versions'),
      });
    }

    if (_get(filesDatas, 'data.stems.original.url')) {
      options.push({
        name: 'stems',
        link: true,
        component: (
          <OptionLink
            download
            target="_blank"
            href={_get(filesDatas, 'data.stems.original.url')}
            rel="noopener noreferrer"
            onClickPassthrough={() => this.notifyDownload(music.id, 'stems')}
          >
            {t('components:music_item.download_stems')}
          </OptionLink>
        ),
      });
    }

    const fetchFunction = () => {
      if (canDownload || canDownloadStems || !!pitchToken) onOpenFilesPassthrough(music);
    };

    return (
      /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
      <div onClick={fetchFunction}>
        <DownloadDropdown
          canDownload={canDownload || canDownloadStems || !!pitchToken}
          isLoading={_get(filesDatas, 'isFetching', false)}
          options={options}
          togglerElement={(
            <StyledTooltip content={t('components:music_item.download')}>
              <DownloadIcon />
            </StyledTooltip>
          )}
        />
      </div>
    );
  };

  renderVersion = () => {
    const { locale, music } = this.props;
    // NOTE: 1 means that there are no alternative versions
    const nbVersions = _get(music, 'versions', 1) - 1;
    const verName = getValueByLocale(_get(music, 'version.names', []), locale);

    if (!nbVersions) return verName;

    return (
      <Fragment>
        <StyledTooltip content={verName} withCounter>
          {verName}
        </StyledTooltip>
        <VersionCount>{`+${nbVersions}`}</VersionCount>
      </Fragment>
    );
  };

  renderDisplayArtists = () => {
    const { music, pitchToken } = this.props;

    const displayArtists = getTrackDisplayArtists(music);

    return !pitchToken
      ? displayArtists.reduce((acc, da, i) => {
        acc.push(
          <StyledLink inline route={getBaseRoute('artists')} nextAs={getUrl('artists')(da, da.name)} key={`da-${da.id}-${i}`}>
            {da.name}
          </StyledLink>,
        );

        if (i < displayArtists.length - 1) acc.push(', ');

        return acc;
      }, [])
      : displayArtists.reduce((acc, da, i) => {
        acc.push(da.name);

        if (i < displayArtists.length - 1) acc.push(', ');

        return acc;
      }, []);
  };

  renderOption = (option) => {
    const { isLogged } = this.props;

    if (option.authRequired && !isLogged) {
      return <OptionLink route="/login">{option.label}</OptionLink>;
    }

    return <Option>{option.label}</Option>;
  };

  renderDropdownButton = () => {
    const {
      handleDownloadCopyright,
      isLogged,
      music,
      setTrackLyricsOpen,
      t,
      pitchToken,
    } = this.props;
    const options = [];

    // Show lyrics
    if (_get(music, 'lyrics')) {
      options.push({
        action: () => setTrackLyricsOpen(true, music),
        label: t('components:music_item.see_lyrics'),
        name: 'see_lyrics',
      });
    }

    // Download Copyright
    options.push({
      action: (isLogged || pitchToken) ? () => handleDownloadCopyright(music) : null,
      label: t('components:music_item.download_copyright'),
      name: 'download_copyright',
      authRequired: !pitchToken,
    });

    return (
      <StyledDropdownButton
        noButton
        options={options}
        renderOption={this.renderOption}
        togglerElement={(
          <StyledTooltip content={t('components:music_item.other_actions')}>
            <ThreeDotIcon />
          </StyledTooltip>
        )}
      />
    );
  };

  renderDynamicPart = () => {
    const { opened } = this.state;

    const {
      contextId,
      contextName,
      contextPosition,
      getCurrentTime,
      getMedia,
      hasAccess,
      isCurrent: isCurrentFnc,
      isLogged,
      isPlaying: isPlayingFnc,
      music,
      onAddToFavorites,
      onDelete,
      onMagicWand,
      seek,
      showExtraOptionsDescription,
      showExtraOptionsTags,
      pitchToken,
      t,
      variant,
    } = this.props;

    // Check if track is playing
    const isCurrent = isCurrentFnc(music.id, contextName, contextId);
    const isPlaying = isPlayingFnc(music.id, contextName, contextId);

    const magicWandQuery = encodeAndRemovePercent(JSON.stringify([music.id, getTrackTitle(music)]));

    // Seek function
    const seekFnc = isCurrent
      ? seekValue => seek(getNextSeekValue(_get(music, 'duration', 0), seekValue))
      : () => getMedia(music.id, contextName, contextId, contextPosition);

    // Only show magic search if duration is above 10 seconds and below 20 minutes
    const hasMagicSearch = _get(music, 'duration', 0) >= 10 && _get(music, 'duration', 0) <= 1200;

    return (
      <Fragment>
        <MusicItemWrapper variant={variant}>
          <TitleAuthors>
            <StyledTooltip content={getTrackTitle(music)}>
              <ConditionalWrapper
                condition={!pitchToken && !!getUrl('tracks')(music)}
                Wrapper={<StyledLink inline route={getBaseRoute('tracks')} nextAs={getUrl('tracks')(music)} />}
              >
                <Title>{getTrackTitle(music)}</Title>
              </ConditionalWrapper>
            </StyledTooltip>
            <AuthorsList>{this.renderDisplayArtists()}</AuthorsList>
          </TitleAuthors>
          <Duration>{music.duration && presentDuration(music.duration)}</Duration>
          <Versions>{this.renderVersion()}</Versions>
          <WaveformWrapper
            music={music}
            isCurrent={isCurrent}
            isPlaying={isPlaying}
            getMedia={getMedia}
            hasAccess={hasAccess}
            seek={seek}
            seekFnc={seekFnc}
            getCurrentTime={getCurrentTime}
          />

          {hasAccess && (
            <Actions>
              {hasMagicSearch && !pitchToken && (
                <StyledTooltip content={t('components:music_item.magical_search')}>
                  <StyledLink route={`/search?track_id=${magicWandQuery}`}>
                    <MagicWandIcon />
                  </StyledLink>
                </StyledTooltip>
              )}

              {this.renderFilesDropdown(music)}

              {isLogged && !pitchToken && (
                <Fragment>
                  <StyledAddToPlaylist
                    type="track"
                    itemId={music.id}
                    togglerElement={(
                      <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                        <AddToPlaylistIcon />
                      </StyledTooltip>
                    )}
                  />
                  <StyledTooltip content={t('components:music_item.add_to_favorite')}>
                    <AddToFavoriteIcon
                      checked={isLogged && music.isFavorite}
                      onClick={() => {
                        onAddToFavorites(music);
                      }}
                    />
                  </StyledTooltip>
                </Fragment>
              )}

              {!isLogged && !pitchToken && (
                <Fragment>
                  <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                    <Link route="/login">
                      <AddToPlaylistIcon />
                    </Link>
                  </StyledTooltip>
                  <StyledTooltip content={t('components:music_item.add_to_favorite')}>
                    <Link route="/login">
                      <AddToFavoriteIcon />
                    </Link>
                  </StyledTooltip>
                </Fragment>
              )}

              {!pitchToken && (
                <StyledShareButton
                  url={getUrl('tracks')(music)}
                  text={getTrackTitle(music)}
                  togglerElement={(
                    <StyledTooltip content={t('components:music_item.share')}>
                      <ShareIcon />
                    </StyledTooltip>
                  )}
                />
              )}

              {this.renderDropdownButton()}

              <ArrowDropdownIcon opened={opened} onClick={() => this.toggleDetails(!opened)} />
              {onDelete && <DeleteIcon onClick={() => onDelete(music)} />}
            </Actions>
          )}

          {!hasAccess && (
            <Actions alignRight>
              {this.renderFilesDropdown(music)}

              {this.renderDropdownButton()}

              <ArrowDropdownIcon opened={opened} onClick={() => this.toggleDetails(!opened)} />
            </Actions>
          )}
        </MusicItemWrapper>

        {showExtraOptionsDescription && <Description>{this.renderDescription()}</Description>}
        {showExtraOptionsTags && <Tags>{this.renderTags()}</Tags>}
      </Fragment>
    );
  };

  render() {
    const {
      contextId,
      contextName,
      contextPosition,
      getCurrentTime,
      getMedia,
      handleDownloadCopyright,
      hasAccess,
      innerRef,
      isCurrent: isCurrentFnc,
      isFetchingVersions,
      isLogged,
      isPlaying: isPlayingFnc,
      lazyload,
      locale,
      music,
      onAddToFavorites,
      onDelete,
      onMagicWand,
      onPause,
      play,
      playlistIsOwnedByUser,
      router,
      seek,
      search,
      setTrackLyricsOpen,
      style,
      pitchToken,
      t,
      variant,
      versions,
      ...rest
    } = this.props;

    const { opened } = this.state;

    // Check if track is playing
    const isCurrent = isCurrentFnc(music.id, contextName, contextId);
    const isPlaying = isPlayingFnc(music.id, contextName, contextId);

    // Play function
    const playFnc = isCurrent
      ? play
      : () => getMedia(music.id, contextName, contextId, contextPosition);

    // Recently played
    const isRecentlyPlayed = contextName !== playerCtx.USER_RECENTS && _get(music, 'recentlyPlayed', false);

    // Is maia search
    const isMaiaSearch = !!(_get(search, 'options.trackId') || _get(search, 'options.searchId'));

    const base = (
      <Wrapper
        {...getViewProps(rest)}
        isCurrent={isCurrent}
        isSearchPage={router.route === '/search'}
        opened={opened}
        ref={innerRef}
        style={style}
        variant={variant}
        withParent={false}
      >
        <OutsideInfo>
          {isRecentlyPlayed && (
            <RecentlyPlayedIcon title={t('components:music_item.recently_played')} />
          )}

          {isMaiaSearch && (
            <Synchro>
              {new Array(10).fill(1).map((item, i) => (
                <SynchroBar key={`${music.id}-sync-${i}`} filled={i < music.similarity / 10} />
              ))}
            </Synchro>
          )}
        </OutsideInfo>

        <ToggleMusicItem onClick={() => this.toggleDetails(!opened)} variant={variant} />

        {['global'].includes(variant) && (
          <StyledCover
            isCurrent={isCurrent}
            isPlaying={isPlaying}
            lazyload={lazyload}
            onPlay={playFnc}
            onPause={onPause}
            placeholderType="track"
            playButtonSize="musicItem"
            src={getAlbumCoverUrl(music.album, 'small')}
            alt={getTrackAlbum(music).title}
            type="track"
          />
        )}

        <DetailsWrapper>
          {this.renderDynamicPart()}
          <Details
            contextName={contextName}
            displayArtists={this.renderDisplayArtists()}
            getCurrentTime={getCurrentTime}
            getMedia={getMedia}
            handleDownloadCopyright={handleDownloadCopyright}
            hasAccess={hasAccess}
            isCurrent={isCurrentFnc}
            isFetchingVersions={isFetchingVersions}
            isLogged={isLogged}
            isPlaying={isPlayingFnc}
            clickableLinks={!pitchToken}
            locale={locale}
            music={music}
            onAddToFavorites={onAddToFavorites}
            onDelete={onDelete}
            onMagicWand={onMagicWand}
            onPause={onPause}
            opened={opened}
            play={play}
            renderAlternativeTitle={this.renderAlternativeTitle}
            renderFilesDropdown={this.renderFilesDropdown}
            seek={seek}
            setTrackLyricsOpen={setTrackLyricsOpen}
            t={t}
            versions={versions}
            pitchToken={pitchToken}
          />
        </DetailsWrapper>
      </Wrapper>
    );

    if (playlistIsOwnedByUser && contextName === playerCtx.USER_PLAYLIST) {
      return (
        <DraggableDnd draggableId={music.id} index={contextPosition - 1}>
          {provided => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              {base}
            </div>
          )}
        </DraggableDnd>
      );
    }

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.musicItem,
        })}
      >
        {base}
      </WithCustomTheme>
    );
  }
}

function forwardedRef({ i18n: { language }, ...props }, ref) {
  return <MusicItem locale={language} innerRef={ref} {...props} />;
}

forwardedRef.displayName = MusicItem.displayName;

export default compose(
  withRouter,
  withTranslation('components'),
)(forwardRef(forwardedRef));
