// =============================
// Imports
// =============================

import { Icons } from '@mewo/components';
import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const Input = styled.select`
  appearance: none;
  background: ${({ theme }) => theme.colors.input.background};
  border: 0;
  border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '1rem' : '0')};
  color: ${({ theme }) => theme.colors.input.text};
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: 4rem;
  outline: none;
  padding: 0 2rem;
  width: 100%;

  ${({ value }) => value === '' && css`
    font-style: italic;
    color: ${({ theme }) => alpha(theme.colors.input.text, 0.6)};
  `};
`;

export const StyledArrowDropdown = styled(Icons.ArrowDropdown)`
  position: absolute;
  right: 1.2rem;
`;
