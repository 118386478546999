// =============================
// Imports
// =============================

import _get from 'lodash/get';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// =============================
// Page
// =============================

const Hubspot = ({ email, hubId, cookieAnalyticsAccepted }) => {
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    window._hsq = window._hsq || [];

    if (hubId && cookieAnalyticsAccepted && email && !identified) {
      window._hsq.push(['identify', {
        email: email,
      }]);

      setIdentified(true);
    }

    if (!cookieAnalyticsAccepted) {
      window._hsq.push(['doNotTrack']);
    } else {
      window._hsq.push(['doNotTrack', { track: true }]);
    }
  }, [email, cookieAnalyticsAccepted]);

  return null;
};

function mapStateToProps({ config, user, core }) {
  return {
    hubId: _get(config, 'data.integrations.hubspot.hubId'),
    email: user.data?.email,
    cookieAnalyticsAccepted: core.privacyCookies.analytics
  };
}

export default connect(mapStateToProps)(Hubspot);
