// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import CurrentTimeFetcher from '../../../other/currentTime';

import {
  getTrackAlbum,
  getTrackCoverUrl,
  getTrackDisplayArtists,
  getTrackTitle,
} from '../../../../helpers/entity/track';
import * as pth from '../../../../helpers/proptypes';

import {
  Author,
  Authors,
  Content,
  Controls,
  OverlayAction,
  PauseIcon,
  PlayIcon,
  ProgressBar,
  StyledCover,
  ThreeDotIcon,
  Title,
  TitleAuthors,
  Wrapper,
} from './styles';

// =============================
// Component
// =============================

class MusicPlayer extends PureComponent {
  static displayName = 'MusicPlayerMobile';

  static propTypes = {
    current: pth.playertrack,
    getCurrentTime: PropTypes.func,
    isPlaying: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isSimpleLayout: PropTypes.bool.isRequired,
    opened: PropTypes.bool.isRequired,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    toggleMusicPlayerModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: null,
    getCurrentTime: null,
  };

  render() {
    const {
      current,
      getCurrentTime,
      isPaused,
      isPlaying,
      isSimpleLayout,
      opened,
      pause,
      play,
      toggleMusicPlayerModal,
    } = this.props;

    const trackTitle = getTrackTitle(current);

    // Status for get current time
    let status = 0;
    if (isPaused) status = 1;
    if (isPlaying) status = 2;

    if (!opened) return null;

    return (
      <Wrapper hide={isSimpleLayout}>
        <OverlayAction onClick={() => toggleMusicPlayerModal(true, current)} />
        <CurrentTimeFetcher status={status} getCurrentTime={getCurrentTime}>
          {currentTime => (
            <ProgressBar progress={(currentTime / _get(current, 'duration', 0)) * 100} />
          )}
        </CurrentTimeFetcher>
        <Content>
          <StyledCover
            src={getTrackCoverUrl(current, 'small')}
            alt={getTrackAlbum(current).title}
          />
          <TitleAuthors>
            <Title>{trackTitle}</Title>
            <Authors>
              {getTrackDisplayArtists(current).map((da, i, a) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Fragment key={`da-${da.id}-${i}`}>
                  <Author>
                    {da.name}
                  </Author>
                  {i < a.length - 1 && ', '}
                </Fragment>
              ))}
            </Authors>
          </TitleAuthors>
          <ThreeDotIcon onClick={() => toggleMusicPlayerModal(true, current)} />
          <Controls>
            {!isPlaying && <PlayIcon onClick={play} />}
            {isPlaying && <PauseIcon onClick={pause} />}
          </Controls>
        </Content>
      </Wrapper>
    );
  }
}

export default MusicPlayer;
