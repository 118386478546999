// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setUserPlaylistOpen } from '../../../../store/actions/ModalsActions';

import ModalUserPlaylist from '../../../layouts/modals/userplaylist';

// =============================
// Component
// =============================

function mapStateToProps({ modals, userplaylists }) {
  return {
    playlist: userplaylists.playlist.data,
    opened: modals.isUserPlaylistOpen,
  };
}

export default connect(mapStateToProps, {
  closeUserPlaylistDescModal: () => setUserPlaylistOpen(false),
})(ModalUserPlaylist);
