// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import CurrentTimeFetcher from '../../../../other/currentTime';

import * as pth from '../../../../../helpers/proptypes';

import { Waveform, VersionWaveform, MusicItemWaveform } from '../styles';

// =============================
// Component
// =============================

class WaveformWrapper extends Component {
  static propTypes = {
    getCurrentTime: PropTypes.func,
    hasAccess: PropTypes.bool.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    music: pth.track.isRequired,
    seekFnc: PropTypes.func.isRequired,
    type: PropTypes.string,
  };

  static defaultProps = {
    getCurrentTime: null,
    type: 'default',
  };

  render() {
    const {
      getCurrentTime,
      hasAccess,
      isCurrent,
      isPlaying,
      music,
      seekFnc,
      type,
    } = this.props;

    const WaveComponent = type === 'version' ? VersionWaveform : Waveform;
    const waveformSvg = _get(music, 'waveform.small.url', null) || _get(music, 'waveform.large.url', null);

    // Status
    let status = 0;
    if (isCurrent && !isPlaying) status = 1;
    if (isPlaying) status = 2;

    // NOTE: If music has a duration, it means it has been 100% converted through microservices
    return _get(music, 'duration', 0) ? (
      <CurrentTimeFetcher status={status} getCurrentTime={getCurrentTime}>
        {currentTime => (
          <WaveComponent hasAccess={hasAccess}>
            <MusicItemWaveform
              svg={waveformSvg}
              onSeek={seekFnc}
              progress={(currentTime / _get(music, 'duration', 0)) * 100}
            />
          </WaveComponent>
        )}
      </CurrentTimeFetcher>
    ) : (
      <Waveform />
    );
  }
}

export default WaveformWrapper;
