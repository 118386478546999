// =============================
// Imports
// =============================

// External dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import { hotjar } from 'react-hotjar';
import Intercom from 'react-intercom';
import _get from 'lodash/get';

// Helpers
import * as pth from '../helpers/proptypes';

// =============================
// Page
// =============================

class Business extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    user: pth.fulluser,
    privacyCookies: PropTypes.shape({
      mandatory: PropTypes.bool.isRequired,
      analytics: PropTypes.bool.isRequired,
      media: PropTypes.bool.isRequired,
    }).isRequired,
    isPlayerOpened: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    user: null,
  };

  componentDidMount() {
    const { privacyCookies } = this.props;

    if (privacyCookies.analytics) {
      this.initializeAnalytics();
    }
  }

  componentDidUpdate(prevProps) {
    const { router: prevRouter, privacyCookies: prevPrivacyCookies } = prevProps;
    const { router, config, privacyCookies } = this.props;

    const userGaCode = _get(config, 'integrations.google.analyticsCode');

    if (!prevPrivacyCookies.analytics && privacyCookies.analytics) {
      this.initializeAnalytics();

      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('consent', 'default', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
        });

        if (prevRouter.asPath === router.asPath) {
          if (process.env.GA_CODE) {
            window.gtag('config', process.env.GA_CODE, {
              page_path: router.asPath,
            });
          }

          if (userGaCode) {
            window.gtag('config', userGaCode, {
              page_path: router.asPath,
            });
          }
        }
      }
    }

    if (
      typeof window !== 'undefined'
      && window.gtag
      && privacyCookies.analytics
      && prevRouter.asPath !== router.asPath
    ) {
      if (process.env.GA_CODE) {
        window.gtag('config', process.env.GA_CODE, {
          page_path: router.asPath,
        });
      }

      if (userGaCode) {
        window.gtag('config', userGaCode, {
          page_path: router.asPath,
        });
      }
    }

    if (
      privacyCookies.mandatory
      && typeof window !== 'undefined'
      && window.Intercom
      && window.intercomSettings
    ) {
      window.intercomSettings.vertical_padding = this.getIntercomVerticalPadding();
      window.Intercom('update');
    }
  }

  getIntercomVerticalPadding = () => {
    const { config, isPlayerOpened } = this.props;

    const minimized = _get(config, 'customisations.player.size') === 'small';

    if (minimized) {
      return isPlayerOpened ? '150' : '30';
    }

    return isPlayerOpened ? '120' : '30';
  };

  initializeAnalytics = () => {
    const { config } = this.props;

    const websiteUrl = _get(config, 'urlConfig.websiteUrl', '');

    // Full story
    if (process.env.FS_CODE) {
      FullStoryAPI('identify', websiteUrl);
    }

    // Hotjar
    const hotjarTrackingId = _get(config, 'integrations.hotjar.trackingId');

    if (hotjarTrackingId) {
      hotjar.initialize(hotjarTrackingId, 6);
    }
  };

  render() {
    const { children, config, user, privacyCookies } = this.props;

    const intercomAppId = _get(config, 'integrations.intercom.appId');
    const intercomUser = {
      user_id: _get(user, 'id'), // defaults to undefined
      email: _get(user, 'email'), // defaults to undefined
      name: user ? `${_get(user, 'firstName')} ${_get(user, 'lastName')}` : undefined,
    };

    return (
      <div>
        {privacyCookies.analytics && process.env.FS_CODE && (
          <FullStory org={process.env.FS_CODE} />
        )}
        {privacyCookies.mandatory && intercomAppId && (
          <Intercom
            horizontal_padding="30"
            vertical_padding="30"
            appID={intercomAppId}
            {...intercomUser}
          />
        )}
        {children}
      </div>
    );
  }
}

function mapStateToProps({ core, player }) {
  return {
    privacyCookies: core.privacyCookies,
    isPlayerOpened: player.isOpened,
  };
}

export default compose(withRouter, connect(mapStateToProps))(Business);
