// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  token: '',
  pitchToken: '',
  isLogged: false,
  isModifying: false,
  isModifyingPassword: false,
  isSubscribingToNewsletter: false,
  data: undefined,
  realtimeAuthKey: null,
  realtimeChannels: {},
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  switch (action.type) {
    case acts.SET_USER_SESSION_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case acts.SET_USER_PITCH_TOKEN:
      return {
        ...state,
        pitchToken: action.payload,
      };

    case acts.SET_USER_INFO:
      return {
        ...state,
        data: action.payload,
        isLogged: true,
      };

    case acts.MODIFY_USER_LOADING:
      return {
        ...state,
        isModifying: true,
      };

    case acts.MODIFY_USER_SUCCESS:
    case acts.MODIFY_USER_FAILURE:
      return {
        ...state,
        isModifying: false,
      };

    case acts.MODIFY_USER_PASSWORD_LOADING:
      return {
        ...state,
        isModifyingPassword: true,
      };

    case acts.MODIFY_USER_PASSWORD_SUCCESS:
    case acts.MODIFY_USER_PASSWORD_FAILURE:
      return {
        ...state,
        isModifyingPassword: false,
      };

    case acts.SET_REALTIME_AUTH_KEY:
      return {
        ...state,
        realtimeAuthKey: action.payload,
      };

    case acts.SET_REALTIME_CHANNELS:
      return {
        ...state,
        realtimeChannels: action.payload,
      };

    case acts.SUBSCRIBE_TO_NEWSLETTER_LOADING:
      return {
        ...state,
        isSubscribingToNewsletter: true,
      };

    case acts.SUBSCRIBE_TO_NEWSLETTER_SUCCESS:
    case acts.SUBSCRIBE_TO_NEWSLETTER_FAILURE:
      return {
        ...state,
        isSubscribingToNewsletter: false,
      };

    case acts.LOGOUT_USER_SUCCESS:
      // Keep pitch token if user disconnects on pitch
      return {
        ...initialState,
        pitchToken: state.pitchToken,
      };

    default:
      return state;
  }
}
