// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { i18n, withTranslation } from '../../../../config/i18n';

import { getCookieConfig } from '../../../../helpers/misc';
import * as pth from '../../../../helpers/proptypes';

import { Languages, Lang } from './styles';

// =============================
// Component
// =============================

class UserLanguages extends Component {
  static displayName = 'UserLanguagesDesktop';

  static propTypes = {
    className: PropTypes.string,
    /** App config. */
    config: pth.config.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Location protocol, like https. */
    locationProtocol: PropTypes.string.isRequired,
    /** Modify user language */
    modifyUserLanguage: PropTypes.func.isRequired,
    /** Opened */
    opened: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  setUserLocale = async (locale) => {
    const { isLogged, locationProtocol, modifyUserLanguage } = this.props;

    if (isLogged) {
      await modifyUserLanguage(locale);
    }

    Cookies.set(
      'next-i18next',
      locale,
      getCookieConfig(3, locationProtocol),
    );

    i18n.changeLanguage(locale);
  };

  render() {
    const {
      className,
      config,
      opened,
      i18n: { language },
    } = this.props;

    return (
      <Languages className={className} opened={opened}>
        {config.languages.map((languageOption, i, arr) => (
          <Fragment key={languageOption}>
            <Lang
              selected={languageOption === language}
              onClick={() => this.setUserLocale(languageOption)}
            >
              {languageOption}
            </Lang>
            {i + 1 < arr.length && '/'}
          </Fragment>
        ))}
      </Languages>
    );
  }
}

export default withTranslation()(UserLanguages);
