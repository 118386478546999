// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: flex-start;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.4rem #777;
  display: flex;
  height: 1rem;
  left: 0;
  resize: both;
  overflow: auto;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 1rem;
  z-index: ${({ theme }) => theme.zIndex.devTools};
`;

export const EnvViewer = styled.pre`
  margin: 0;
`;
