// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  data: {
    languages: {},
    territories: [],
    albumtypes: [],
    tonalitykeys: [],
    trackversions: [],
    tempos: [],
    rightstypes: [],
    tags: [],
    tags_sc: [],
    tags_only: [],
    tags_colors_cat_names: [],
    mechanicalrightssocieties: [],
    neighboringrightssocieties: [],
    performingrightssocieties: [],
    userindustries: [],
  },
};

// =============================
// Reducer
// =============================

export default function options(state = initialState, action) {
  switch (action.type) {
    case acts.GET_OPTIONS_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.SET_OPTIONS: {
      const data = {};
      Object.keys(action.payload).forEach((key) => {
        data[key] = action.payload[key];
      });

      return {
        ...state,
        data,
      };
    }

    case acts.GET_OPTIONS_SUCCESS:
    case acts.GET_OPTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    default:
      return state;
  }
}
