// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons, InputBase } from '@mewo/components';
import { alpha } from '@material-ui/core';

import Button from '../../presentationals/button';
import { DropdownList } from '../../presentationals/dropdown';
import Link from '../link';

import { isHoverPrimary } from '../../../helpers/front/getHoverType';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(DropdownList).attrs(({ placement }) => ({
  closeOnClick: false,
  dropdownStyles: css`
    width: 30rem;
  `,
  optionsContainerStyles: css`
    max-height: 18.5rem;
  `,
  placement: placement || 'bottom',
  withFilter: true,
  withoutStyles: true,
}))``;

export const Option = styled.div`
  align-items: center;
  display: flex;
  height: 2.8rem;
  justify-content: space-between;
  padding: 0 2.4rem;
  width: 100%;
`;

export const OptionAdded = styled(Icons.Check).attrs(({ theme }) => ({
  fill: theme.colors.type.success,
  width: '1rem',
}))`
  margin-right: 1rem;
`;

export const AddButton = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.dropdown.text};
  cursor: pointer;
  transition: color ${({ theme }) => theme.transition.medium},
    opacity ${({ theme }) => theme.transition.medium};
  width: 17rem;

  ${({ theme, disabled }) => {
    if (disabled) {
      return css`
        pointer-events: none;
        opacity: 0.7;
      `;
    }

    if (isHoverPrimary(theme.colors.dropdown)) {
      return css`
        &:hover {
          color: ${theme.colors.dropdown.primary};
        }
      `;
    }

    return css`
      &:hover {
        opacity: 0.7;
      }
    `;
  }};
`;

export const GoToPlaylist = styled(Icons.ExternalLink).attrs(({ theme }) => ({
  fill: theme.colors.dropdown.text,
  width: '1.3rem',
}))``;

export const StyledLink = styled(Link).attrs({
  noHover: true,
})`
  ${({ theme }) => (isHoverPrimary(theme.colors.dropdown)
    ? css`
      &:hover {
        svg path {
          fill: ${theme.colors.dropdown.primary};
        }
      }
    `
    : css`
      &:hover {
        svg path {
          fill-opacity: 0.7;
        }
      }
    `)};
`;

export const Header = styled.div`
  width: 100%;
`;

export const CreatePlaylistWrapper = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.2)};
  display: flex;
  padding: 1.2rem 2.4rem;
  width: 100%;
`;

export const CreatePlaylistToggler = styled(CreatePlaylistWrapper)`
  align-items: center;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `};

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};
`;

export const CreatePlaylistForm = styled(CreatePlaylistWrapper)`
  flex-direction: column;
`;

export const InputAndBtnWrapper = styled.div`
  align-items: flex-end;
  display: flex;
`;

export const NewPlaylistLabel = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
`;

export const NewPlaylistInput = styled(InputBase)`
  margin-right: 0.8rem;
  background-color: ${({ theme }) => alpha(theme.colors.text, 0.1)};
  color: ${({ theme }) => theme.colors.text};
  padding: 1.2rem 1.6rem;
`;

export const AddToPlaylistBtn = styled(Button).attrs({
  color: 'primary',
  fillVariant: 'plain',
  size: 'small',
})`
  align-items: center;
  height: 3rem;
  flex-shrink: 0;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-left: auto;
  padding: 0 0.8rem;
  width: 3rem;
`;

export const AddToPlaylistBtnPlusIcon = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.primaryText,
}))``;

export const PlusIcon = styled(Icons.PlusOutline).attrs({
  width: '1.4rem',
})`
  margin-right: 0.8rem;
`;
