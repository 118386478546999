// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import {
  CustomPageWrapper,
  EntityWrapper,
  PitchWrapper,
  PlaylistWrapper,
  SearchWrapper,
  TextWrapper,
} from './styles';

// =============================
// Notes
// =============================

/**
 * This component is used on every page to assure that
 * the last item on the page as enough space to be visible
 * in all situation.
 */

// =============================
// Component
// =============================

class PageWrapper extends PureComponent {
  static displayName = 'PageWrapperDesktop';

  static propTypes = {
    /* Children. */
    children: PropTypes.node.isRequired,
    /** If True, render PageWrapper without padding-top. Only used on customPage type. */
    noPaddingTop: PropTypes.bool,
    /** If true, Player is minimized. */
    playerIsMinimized: PropTypes.bool.isRequired,
    /** If true, Player is opened. */
    playerIsOpened: PropTypes.bool.isRequired,
    /** Type of children. */
    type: PropTypes.oneOf([
      'customPage',
      'entity',
      'playlist',
      'pitch',
      'search',
      'text',
    ]).isRequired,
  };

  static defaultProps = {
    noPaddingTop: false,
  }

  render() {
    const { children, noPaddingTop, playerIsMinimized, playerIsOpened, type } = this.props;

    if (type === 'customPage') {
      return (
        <CustomPageWrapper
          noPaddingTop={noPaddingTop}
          playerIsMinimized={playerIsMinimized}
          playerIsOpened={playerIsOpened}
        >
          {children}
        </CustomPageWrapper>
      );
    }

    if (type === 'entity') {
      return (
        <EntityWrapper playerIsMinimized={playerIsMinimized} playerIsOpened={playerIsOpened}>
          {children}
        </EntityWrapper>
      );
    }

    if (type === 'playlist') {
      return (
        <PlaylistWrapper playerIsMinimized={playerIsMinimized} playerIsOpened={playerIsOpened}>
          {children}
        </PlaylistWrapper>
      );
    }

    if (type === 'pitch') {
      return (
        <PitchWrapper playerIsMinimized={playerIsMinimized} playerIsOpened={playerIsOpened}>
          {children}
        </PitchWrapper>
      );
    }

    if (type === 'search') {
      return (
        <SearchWrapper playerIsMinimized={playerIsMinimized} playerIsOpened={playerIsOpened}>
          {children}
        </SearchWrapper>
      );
    }

    if (type === 'text') {
      return (
        <TextWrapper playerIsMinimized={playerIsMinimized} playerIsOpened={playerIsOpened}>
          {children}
        </TextWrapper>
      );
    }

    return null;
  }
}

function mapStateToProps({ config, player }) {
  return {
    playerIsMinimized: _get(config.data, 'customisations.player.size') === 'small',
    playerIsOpened: player.isOpened,
  };
}

export default connect(mapStateToProps)(PageWrapper);
