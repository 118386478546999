// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import { withTranslation } from '../../../../../../config/i18n';

import getViewProps from '../../../../../../helpers/front/getviewprops';

import PresearchCloseButton from '../../../../../containers/modals/presearchCloseButton';

import { Wrapper, Content, Overlay } from './styles';

// =============================
// Component
// =============================

class Modal extends Component {
  static propTypes = {
    /** Modal content. */
    children: PropTypes.node,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      isVisible: PropTypes.bool,
    }),
    /** If True, we use the small presearch. */
    minimized: PropTypes.bool.isRequired,
    /** Callback function use to close Modal. */
    onClose: PropTypes.func,
    /** If True, Modal is opened. */
    opened: PropTypes.bool,
    /** Show close button */
    showCloseButton: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
    fullSizeCoverModule: null,
    onClose: null,
    opened: false,
    showCloseButton: true,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { onClose, opened } = this.props;

    // If escape button & opened
    if (opened && onClose && e.keyCode === 27) {
      onClose();
    }
  };

  render() {
    const {
      children,
      fullSizeCoverModule,
      minimized,
      onClose,
      opened,
      showCloseButton,
      t,
      ...rest
    } = this.props;

    return (
      <PoseGroup flipMove={false}>
        {opened && (
          <Wrapper opened={opened} {...getViewProps(rest)} key="0">
            <Content fullSizeCoverModule={fullSizeCoverModule}>
              <Overlay
                fullSizeCoverModule={fullSizeCoverModule}
                minimized={minimized}
                onClick={onClose}
              />
              {!fullSizeCoverModule && <PresearchCloseButton />}
              {children}
            </Content>
          </Wrapper>
        )}
      </PoseGroup>
    );
  }
}

export default withTranslation('components')(Modal);
