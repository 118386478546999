// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a playlist
 * @param {object} playlist - Playlist object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getPlaylistCoverUrl = getGenericDocCoverUrl;

/**
 * Get covers of tracks from a playlist
 * @param {object} playlist - Playlist object
 * @param {string} oneSize - Image size if there is only one cover
 * can be "large", "small" or "xsmall"
 * @param {string} multipleSize - Image size if there are multiple covers
 * can be "large", "small" or "xsmall"
 * @return {string[]} - Cover url
 */
export const getPlaylistTracksCoversUrl = (playlist, oneSize = 'large', multipleSize = 'small') => {
  const covers = _get(playlist, 'covers', []) || [];
  const size = covers.length < 4 ? oneSize : multipleSize;

  return covers.map((cover) => {
    switch (size) {
      case 'xsmall':
        return (
          _get(cover, `[${size}].url`)
          || _get(cover, 'small.url')
          || _get(cover, 'large.url')
          || _get(cover, 'original.url')
        );

      case 'small':
        return (
          _get(cover, `[${size}].url`) || _get(cover, 'large.url') || _get(cover, 'original.url')
        );

      case 'large':
        return _get(cover, `[${size}].url`) || _get(cover, 'original.url');

      default:
        return _get(cover, 'original.url');
    }
  });
};

/**
 * Get playlist title
 * @param {object} playlist - Playlist object
 * @return {string} - Playlist title
 */
export const getPlaylistTitle = playlist => _get(playlist, 'title');

/**
 * Get user playlist creator name
 * @param {object} playlist - Playlist object
 * @return {string} - Playlist creator name
 */
export const getUserPlaylistCreator = (playlist) => {
  const company = _get(playlist, 'user.companyName');
  const fullName = `${_get(playlist, 'user.firstName')} ${_get(playlist, 'user.lastName')}`;

  if (company) return `${fullName} (${company})`;

  return fullName;
};

/**
 * Asserts if user playlist is owned by the current user
 * @param {object} user - User object
 * @param {object} playlist - Playlist object
 * @return {boolean} - Is owned by used
 */
export const isUserOwnedPlaylist = (user, playlist) => !!(user && _get(user, 'id') === _get(playlist, 'user.id'));

/**
 * Get user playlist title
 * @param {object} playlist - Playlist object
 * @return {string} - Playlist title
 */
export const getUserPlaylistName = playlist => _get(playlist, 'name');

/**
 * Get user playlist description
 * @param {object} playlist - Playlist object
 * @return {string} - Playlist description
 */
export const getUserPlaylistDesc = playlist => _get(playlist, 'description');
