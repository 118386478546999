// =============================
// Imports
// =============================

import styled from 'styled-components';
import Link from '../../../other/link';

// =============================
// Styles
// =============================

export const NoLogo = styled.div`
  color: ${({ theme }) => theme.colors.text};
  transition: ${({ theme }) => theme.transition.medium};
`;

export const StyledLink = styled(Link).attrs(({ noHover }) => ({
  inline: true,
  noHover: noHover || false,
}))`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;
