// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import dayjs from 'dayjs';
import { Icons } from '@mewo/components';

// Config
import { i18n } from '../config/i18n';

// Helpers
import { buildDownloadTextNode } from './locales';

// =============================
// Helpers
// =============================

function getMessageData(key, data, status) {
  switch (key) {
    case 'PUBLIC_ARCHIVE': {
      const entityType = data.context;

      if (status === 'CREATED') {
        return {
          entity_type: i18n.t(`common:notifications.entities.${entityType}`),
          name: data.name,
        };
      }

      if (status === 'PROCESSING') {
        return {
          entity_type: i18n.t(`common:notifications.entities.${entityType}`),
          name: data.name,
          progress: data.progress,
        };
      }

      if (status === 'COMPLETED') {
        return {
          entity_type: i18n.t(`common:notifications.entities.${entityType}`),
          name: data.name,
          file: data.file,
        };
      }

      return {
        entity_type: i18n.t(`common:notifications.entities.${entityType}`),
        name: data.name,
        error_key: data.error_key,
      };
    }

    default:
      return null;
  }
}

function getMessage(key, data, lastUpdatedAt, status) {
  const finalKey = status ? `${key}_${status}` : key;
  const defaultText = i18n.t(`common:notifications.${finalKey}`, getMessageData(key, data, status));

  switch (key) {
    case 'PUBLIC_ARCHIVE': {
      if (status === 'CREATED' || status === 'PROCESSING') {
        return defaultText;
      }

      const messageData = getMessageData(key, data, status);

      if (status === 'COMPLETED') {
        // Archives are only stored for 5 days, so if the archive was created
        // more than 5 days, the link is expired
        if (dayjs(lastUpdatedAt).isBefore(dayjs().subtract(5, 'day'))) {
          return i18n.t(`common:notifications.${key}_EXPIRED`, messageData);
        }

        return buildDownloadTextNode(i18n.t(`common:notifications.${finalKey}`, messageData), messageData.file);
      }

      return i18n.t(`common:notifications.${finalKey}.${messageData.error_key}`, messageData);
    }

    default:
      return defaultText;
  }
}

function getIcon(key) {
  switch (key) {
    case 'PUBLIC_ARCHIVE':
      return <Icons.Download />;

    default:
      return null;
  }
}

function transformNotifications(notifications = []) {
  return notifications
    .map((notification) => {
      const date = _get(notification, 'updated_at', new Date().toISOString());

      return {
        date,
        message: getMessage(notification.type, notification.data, date, notification.status),
        icon: getIcon(notification.key),
        seen: _get(notification, 'seen', false),
        id: _get(notification, 'id', ''),
        ...notification,
      };
    });
}

export default transformNotifications;
