// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router, { withRouter } from 'next/router';
import isURL from 'validator/lib/isURL';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

import {
  setSearchValue as setSearchValueBase,
  getPreSearchData as getPreSearchDataBase,
  resetPreSearchData as resetPreSearchDataBase,
  requestMaiaTextSearch as requestMaiaTextSearchBase,
  setSearchInDescription as setSearchInDescriptionBase,
  requestMaiaAudioSearch as requestMaiaAudioSearchBase,
} from '../../../../store/actions/SearchActions';
import { setPreSearchOpen } from '../../../../store/actions/ModalsActions';

import SearchBar from '../../../presentationals/searchbar';
import ModalPreSearch from '../../../containers/modals/presearch';
import FilterBar from '../../../containers/filterbar';

import { hasAccessToPrivate } from '../../../../helpers/user';
import { getEntitySearchUrl } from '../../../../helpers/entity';
import { composeQuery } from '../../../../helpers/search';
import * as pth from '../../../../helpers/proptypes';

// =============================
// Component
// =============================

class SearchBarModal extends Component {
  static displayName = 'SearchBarModalDesktop';

  static propTypes = {
    /** Config data */
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      isVisible: PropTypes.bool,
    }),
    /** Search functions */
    getPreSearchData: PropTypes.func.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** true if presearch was already done */
    isFetchedOnce: PropTypes.bool.isRequired,
    /** Pre search modal is opened */
    isPreSearchOpen: PropTypes.bool.isRequired,
    /** Maia audio signal status */
    isSendingMaiaAudioSignal: PropTypes.bool.isRequired,
    /** isSimpleLayout */
    isSimpleLayout: PropTypes.bool.isRequired,
    /** Presearch data */
    preSearchDatas: pth.preSearchDatas.isRequired,
    requestMaiaTextSearch: PropTypes.func.isRequired,
    requestMaiaAudioSearch: PropTypes.func.isRequired,
    resetPreSearchData: PropTypes.func.isRequired,
    /** Router props */
    router: PropTypes.shape({
      route: PropTypes.string,
      asPath: PropTypes.string,
    }).isRequired,
    /** Search query */
    search: pth.searchquery.isRequired,
    /** Search with description status */
    searchInDescription: PropTypes.bool.isRequired,
    /** Search query. */
    searchValue: PropTypes.string,
    setSearchInDescription: PropTypes.func.isRequired,
    /** Function used to change searchValue. */
    setSearchValue: PropTypes.func.isRequired,
    /** Toggle pre search modal */
    togglePreSearchModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
    searchValue: '',
  };

  handleFocus = () => {
    const { isFetchedOnce, getPreSearchData, searchValue } = this.props;

    if (searchValue && !isFetchedOnce) getPreSearchData(searchValue);
  };

  handleSearchOnChange = (value) => {
    const { getPreSearchData, resetPreSearchData, searchValue, setSearchValue } = this.props;

    const valueIsExternal = isURL(value);

    if (value && valueIsExternal) {
      // No need to fetch presearch data
      setSearchValue(value);
    } else if (value && value !== searchValue) {
      // If the value has changed and it is truthy
      setSearchValue(value);
      getPreSearchData(value);
    } else if (searchValue) {
      resetPreSearchData();
    }
  };

  handleSearchOnSubmit = () => {
    const {
      requestMaiaTextSearch,
      search,
      facets,
      preSearchDatas,
      router,
      searchValue,
      isPreSearchOpen,
      togglePreSearchModal,
    } = this.props;

    let nextRoute = '';

    // NOTE: When value is null and route is not within search, redirect to all tracks
    if (!searchValue && router.route !== '/search') {
      nextRoute = '/search';
    } else {
      // Check presearch column results
      const presearchColWithResults = [];

      Object.keys(preSearchDatas).forEach((key) => {
        if (_get(preSearchDatas, `${key}.total.value`) > 0) {
          presearchColWithResults.push(key);
        }
      });

      // If there is only one column left
      if (presearchColWithResults.length === 1) {
        const key = presearchColWithResults[0];

        if (key !== 'tags') {
          // For all columns except tags, go to "see list" page
          nextRoute = getEntitySearchUrl(key)(searchValue);
        } else if (_get(preSearchDatas, `${key}.total.value`) === 1) {
          // For tags, add tag to query when not used or remove it from query
          const tagId = _get(preSearchDatas, `${key}.data.0.id`);

          const filters = _get(search, 'options.filters', {});
          let tagFilters = _get(filters, 'tags', []);
          const otherFilters = _omit(filters, ['tags']);

          const tagAvailable = Object.keys(_get(facets, 'tags', {})).indexOf(tagId) !== -1;

          let changeRoute = true;

          if (tagFilters.indexOf(tagId) !== -1) {
            tagFilters = tagFilters.filter(tag => tag !== tagId);
          } else if (tagAvailable) {
            tagFilters.push(tagId);
          } else {
            changeRoute = false;
          }

          if (changeRoute) {
            nextRoute = `/search?${composeQuery(search, { tags: tagFilters, ...otherFilters })}`;
          }
        }
      }
    }

    if (nextRoute && nextRoute !== router.asPath) {
      return Router.push(nextRoute);
    }

    if (nextRoute && nextRoute === router.asPath && isPreSearchOpen) {
      return togglePreSearchModal(false);
    }

    if (!isURL(searchValue)) return null;
    return requestMaiaTextSearch(searchValue, searchValue);
  };

  resetSearch = () => {
    const { resetPreSearchData, setSearchValue } = this.props;

    setSearchValue('');
    resetPreSearchData();
  };

  render() {
    const {
      config,
      isPreSearchOpen,
      searchInDescription,
      togglePreSearchModal,
      setSearchInDescription,
      requestMaiaAudioSearch,
      isSendingMaiaAudioSignal,
      isSimpleLayout,
      hasAccess,
      router,
      fullSizeCoverModule,
      searchValue,
    } = this.props;

    // Do not render when user has no access to website
    if (!hasAccess) return null;

    const isExternal = isURL(searchValue);

    return (
      <Fragment>
        {!isSimpleLayout && (
          <Fragment>
            {!fullSizeCoverModule?.isVisible && (
              <SearchBar
                value={searchValue}
                withGuidedSearch={_get(config, 'customisations.guidedSearch.active', false)}
                searchInDescription={searchInDescription}
                isPreSearchOpen={isPreSearchOpen}
                isSendingMaiaAudioSignal={isSendingMaiaAudioSignal}
                onFocus={this.handleFocus}
                onChange={this.handleSearchOnChange}
                onSubmit={this.handleSearchOnSubmit}
                setSearchInDescription={setSearchInDescription}
                requestMaiaAudioSearch={requestMaiaAudioSearch}
                togglePreSearchModal={togglePreSearchModal}
              />
            )}
            <ModalPreSearch
              searchValue={searchValue}
              isExternal={isExternal}
              resetSearch={this.resetSearch}
            />
          </Fragment>
        )}
        <FilterBar searchValue={searchValue} isSearchPage={router.route === '/search'} />
      </Fragment>
    );
  }
}

function mapStateToProps({ modals, search, user, config }) {
  // Retrieve 1st visible FullSizeCoverModule
  const currentFullSizeCoverModule = _find(
    search.fullSizeCoverModule,
    item => item.isVisible,
    null,
  );

  return {
    config: config.data,
    facets: search.tracksSearch.data.facets,
    fullSizeCoverModule: currentFullSizeCoverModule,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    isFetchedOnce: search.preSearch.isFetchedOnce,
    isPreSearchOpen: modals.isPreSearchOpen,
    isSendingMaiaAudioSignal: search.tracksSearch.isSendingMaiaAudioSignal,
    preSearchDatas: search.preSearch.data,
    search: search.tracksSearch.query,
    searchInDescription: search.searchInDescription,
    searchValue: search.preSearch.searchValue,
  };
}

export default connect(mapStateToProps, {
  getPreSearchData: getPreSearchDataBase,
  requestMaiaAudioSearch: requestMaiaAudioSearchBase,
  requestMaiaTextSearch: requestMaiaTextSearchBase,
  resetPreSearchData: resetPreSearchDataBase,
  setSearchInDescription: setSearchInDescriptionBase,
  setSearchValue: setSearchValueBase,
  togglePreSearchModal: setPreSearchOpen,
})(withRouter(SearchBarModal));
