// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Div, Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';
import { motion } from 'framer-motion';

import LinkBase from '../../../other/link';
import UserLanguages from '../../../containers/userLanguages';

import { isHoverPrimary } from '../../../../helpers/front/getHoverType';
import { svgHoverCirclePath } from '../../../../helpers/front/svg';

import { Link } from '../desktop/menucontent/styles';

import { headerHeight, headerInnerHeight } from '../../header/mobile/variables';

// =============================
// Styles
// =============================

const paddingX = '1.2rem';

export const Wrapper = styled(Div)`
  background: ${({ theme }) => theme.colors.background};
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.scMenu};

  ${({ opened }) => (opened
    ? css`
          opacity: 1;
          pointer-events: auto;
        `
    : css`
          opacity: 0;
          pointer-events: none;
        `)};
`;

export const MenuHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  height: ${headerHeight};
  justify-content: flex-start;
  padding: ${paddingX};
`;

export const MenuButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-right: auto;
  position: relative;
  text-transform: uppercase;
  user-select: none;

  ${({ colors }) => css`
    ${svgHoverCirclePath(colors.text, colors.background, false)};
  `};
`;

export const CloseMenuButton = styled(Icons.CrossOutline).attrs(() => ({
  fill: 'inherit',
  width: headerInnerHeight,
}))``;

export const MenuContent = styled.div`
  padding: ${paddingX};
  padding-bottom: 3.2rem;
`;

export const StyledLink = styled(Link)``;

export const BlogLink = styled(StyledLink)`
  font-size: 1.6rem;
`;

export const MenuLinkCounter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  font-size: 2rem;
  height: 4rem;
  margin-left: 1.2rem;
  min-width: 4rem;
  padding: 0 1.2rem;
  transition: color ${({ theme }) => theme.transition.medium},
    background-color ${({ theme }) => theme.transition.medium};
  width: max-content;
`;

export const MenuSubLinkCounter = styled(MenuLinkCounter)`
  font-size: 1.6rem;
  height: 3rem;
  min-width: 3rem;
`;

export const MenuLink = styled(LinkBase)`
  display: flex;
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 3.8rem;
  /**
    We use max-width because with the max-content width, words would not be broken when
    overflowing.
  */
  max-width: calc(100vw - ${paddingX} * 2);

  ${({ inactive }) => (inactive
    ? css`
          color: ${({ theme }) => alpha(theme.colors.text, 0.6)};
        `
    : css`
          color: ${({ theme }) => theme.colors.text};

          &:hover {
            color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};

            & ${MenuLinkCounter} {
              background-color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
              color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primaryText : theme.colors.background)};
            }
          }
        `)};
`;

export const MenuLinkContainer = styled.div``;

export const StyledArrowDropdown = styled(Icons.ArrowDropdown).attrs(({ opened }) => ({
  dir: opened ? 'north' : 'south',
  width: '2rem',
}))`
  margin-left: auto;
  /** Use to extend arrow's hitbox. */
  padding-left: 2rem;
`;

export const MenuSubLinkContainer = styled(motion.div).attrs(({ theme }) => ({
  initial: 'close',
  variants: {
    close: {
      height: 0,
      overflow: 'hidden',
      transition: {
        ease: theme.transitionTiming.pose.easeInOut,
        duration: 0.4,
      },
    },
    open: {
      height: 'auto',
      transition: {
        ease: theme.transitionTiming.pose.easeInOut,
        duration: 0.4,
      },
      transitionEnd: {
        overflow: 'visible',
      },
    },
  },
}))``;

export const MenuSubLink = styled(MenuLink)`
  font-size: 2.4rem;
  line-height: 3rem;
  margin-left: 1.6rem;

  /** We need to take the extra 1.6rem from subLink margin-left. */
  max-width: calc(100vw - 1.6rem - ${paddingX} * 2);

  &:first-child {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &:last-child {
    margin-bottom: 0.5rem;
  }
`;

export const MenuLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.2rem;

  &:not(:last-child) {
    margin-bottom: 5rem;
  }
`;

export const SocialLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;

export const FooterLinks = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  justify-content: center;
  line-height: 1.8rem;
`;

export const StyledUserLanguages = styled(UserLanguages)`
  &:not(:last-child) {
    margin: 0 auto 5rem;
  }
`;
