// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../../config/i18n';

import { Option } from '../../../dropdown';

import { StyledDropdown } from './styles';

// =============================
// Component
// =============================

class DownloadDropdown extends Component {
  static propTypes = {
    /** Can download */
    canDownload: PropTypes.bool,
    /** If True, items are being fetched. */
    isLoading: PropTypes.bool,
    /** Options list */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.node,
        name: PropTypes.string.isRequired,
      }),
    ),
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Dropdown toggler */
    togglerElement: PropTypes.node.isRequired,
  };

  static defaultProps = {
    canDownload: false,
    isLoading: false,
    options: [],
  };

  renderNoOption = () => {
    const { canDownload, isLoading, options, t } = this.props;

    if (!canDownload) return <Option noHover>{t('components:music_item.cant_download')}</Option>;

    if (canDownload && isLoading) return <Option noHover>{t('components:music_item.fetching_files')}</Option>;

    if (canDownload && !isLoading && options.length === 0) return <Option noHover>{t('components:music_item.nothing_to_download')}</Option>;

    return null;
  };

  renderOptions = () => {
    const { canDownload, isLoading, options } = this.props;

    if (!(canDownload && !isLoading && options.length > 0)) return [];

    return options;
  };

  renderOption = (option) => {
    if (option.link) {
      return option.component;
    }

    return <Option key={option.name}>{option.label}</Option>;
  };

  render() {
    const { togglerElement } = this.props;

    return (
      <StyledDropdown
        togglerElement={togglerElement}
        noOption={this.renderNoOption()}
        options={this.renderOptions()}
        renderOption={this.renderOption}
      />
    );
  }
}

export default withTranslation('components')(DownloadDropdown);
