// =============================
// Imports
// =============================

import styled from 'styled-components';
import { FilterBar as FilterBarBase } from '@mewo/components';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';
import { filterBarWidth } from './variables';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const FilterBar = styled(FilterBarBase).attrs(() => ({
  classNames: {
    content: 'contentClassName',
    header: 'headerClassName',
  },
}))`
  margin-bottom: 2rem;
  width: ${filterBarWidth};

  & .headerClassName {
    padding: 0 ${paddingX};
  }

  & .contentClassName {
    padding: 0 ${paddingX};
  }
`;
