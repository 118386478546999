// =============================
// Imports
// =============================

// External dependencies
import _get from 'lodash/get';

// Actions
import { setNotification, clearNotification } from '../actions/NotificationActions';

// Constants
import * as acts from '../constants/ActionTypes';
import * as ntfs from '../constants/NotificationTypes';

// =============================
// Middleware
// =============================

const notificationsMiddleware = store => next => (action) => {
  switch (true) {
    // ======================
    // Config notifications
    // ======================
    case action.type === acts.SEND_CONTACT_EMAIL_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SEND_CONTACT_EMAIL_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    // ======================
    // Auth notifications
    // ======================
    case action.type === acts.GET_USER_SESSION_TOKEN_FAILURE:
    case action.type === acts.REGISTER_USER_FAILURE:
    case action.type === acts.RESET_PASSWORD_FAILURE:
    case action.type === acts.SET_PASSWORD_FAILURE:
      if (_get(action, 'payload.context') === 'auth_invalid_access') {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.AUTH_INVALID_ACCESS_INFO,
            message: action.payload.message,
            reqId: action.payload.reqId,
            type: 'warning',
            duration: 12000,
          }),
        );
      }

      return next(action);

    case action.type === acts.REGISTER_USER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REGISTER_USER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ACTIVATE_ACCOUNT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ACTIVATE_ACCOUNT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.ACTIVATE_ACCOUNT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ACTIVATE_ACCOUNT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    // ======================
    // Modify account notifications
    // ======================
    case action.type === acts.SUBSCRIBE_TO_NEWSLETTER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SUBSCRIBE_TO_NEWSLETTER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.SUBSCRIBE_TO_NEWSLETTER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.SUBSCRIBE_TO_NEWSLETTER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );
      return next(action);

    /* Clear old notifications when modifying user */
    case action.type === acts.MODIFY_USER_LOADING:
      store.dispatch(clearNotification(ntfs.MODIFY_USER_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.MODIFY_USER_FAILURE_INFO));
      return next(action);

    case action.type === acts.MODIFY_USER_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    /* Clear old notifications when modifying user password */
    case action.type === acts.MODIFY_USER_PASSWORD_LOADING:
      store.dispatch(clearNotification(ntfs.MODIFY_USER_PASSWORD_SUCCESS_INFO));
      store.dispatch(clearNotification(ntfs.MODIFY_USER_PASSWORD_FAILURE_INFO));
      return next(action);

    case action.type === acts.MODIFY_USER_PASSWORD_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_PASSWORD_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );
      return next(action);

    case action.type === acts.MODIFY_USER_PASSWORD_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_PASSWORD_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );
      return next(action);

    // ======================
    // Favorites notifications
    // ======================
    case action.type === acts.ADD_TO_FAVORITES_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ADD_TO_FAVORITES_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Archive notifications
    // ======================
    case action.type === acts.DOWNLOAD_ARCHIVES_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_ARCHIVES_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DOWNLOAD_ARCHIVES_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_ARCHIVES_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.POLLING_DOWNLOAD_ARCHIVE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.POLLING_DOWNLOAD_ARCHIVE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Copyright notifications
    // ======================
    case action.type === acts.DOWNLOAD_COPYRIGHT_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_COPYRIGHT_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DOWNLOAD_COPYRIGHT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_COPYRIGHT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // User playlist notifications
    // ======================
    case action.type === acts.CREATE_USER_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.CREATE_USER_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.MODIFY_USER_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.MODIFY_USER_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.ADD_TRACK_TO_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.ADD_TRACK_TO_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.REORDER_TRACK_IN_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REORDER_TRACK_IN_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DUPLICATE_USER_PLAYLIST_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_USER_PLAYLIST_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DUPLICATE_USER_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_USER_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Playlist notifications
    // ======================
    case action.type === acts.DUPLICATE_PLAYLIST_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_PLAYLIST_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DUPLICATE_PLAYLIST_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_PLAYLIST_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Pitch notifications
    // ======================
    case action.type === acts.DUPLICATE_PITCH_SUCCESS:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_PITCH_SUCCESS_INFO,
          message: action.payload.message,
          type: 'success',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.DUPLICATE_PITCH_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DUPLICATE_PITCH_FAILURE_INFO,
          message: action.payload.message,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Music item notifications
    // ======================
    case action.type === acts.GET_FILES_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_FILES_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: action.payload.type,
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Download audiofile notifications
    // ======================
    case action.type === acts.DOWNLOAD_AUDIOFILE_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.DOWNLOAD_AUDIOFILE_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: action.payload.type,
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Player notifications
    // ======================
    case action.type === acts.GET_MEDIA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_MEDIA_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'warning',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Search notifications
    // ======================
    case action.type === acts.REQUEST_MAIA_TEXT_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REQUEST_MAIA_TEXT_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.REQUEST_MAIA_AUDIO_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.REQUEST_MAIA_AUDIO_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.GET_PRE_SEARCH_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_PRE_SEARCH_DATA_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.GET_ENTITY_SEARCH_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_ENTITY_SEARCH_DATA_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.GET_TRACKS_SEARCH_DATA_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_TRACKS_SEARCH_DATA_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    case action.type === acts.GET_USER_PLAYLISTS_FAILURE:
      store.dispatch(
        setNotification({
          notificationKey: ntfs.GET_USER_PLAYLISTS_FAILURE_INFO,
          message: action.payload.message,
          reqId: action.payload.reqId,
          type: 'error',
          duration: 12000,
        }),
      );

      return next(action);

    // ======================
    // Other errors (those that should not happen, or server errors)
    // ======================
    case action.type === acts.SEND_CONTACT_EMAIL_FAILURE:
    case action.type === acts.GET_SUGGESTED_ALBUMS_FAILURE:
    case action.type === acts.GET_SUGGESTED_PLAYLISTS_FAILURE:
    case action.type === acts.GET_FAVORITES_FAILURE:
    case action.type === acts.REMOVE_FROM_FAVORITES_FAILURE:
    case action.type === acts.GET_VERSIONS_FAILURE:
    case action.type === acts.NOTIFY_FILE_DOWNLOAD_FAILURE:
    case action.type === acts.GET_USER_NOTIFICATIONS_FAILURE:
    case action.type === acts.CHECK_USER_NOTIFICATIONS_FAILURE:
    case action.type === acts.GET_REALTIME_CHANNELS_FAILURE:
    case action.type === acts.GET_USER_PLAYLISTS_LIST_FAILURE:
    case action.type === acts.REMOVE_USER_PLAYLIST_FAILURE:
    case action.type === acts.REMOVE_TRACK_FROM_PLAYLIST_FAILURE:
    case action.type === acts.GET_INITIAL_FACETS_FAILURE:
    case action.type === acts.GET_RECENT_PLAYS_FAILURE:
    case action.type === acts.ADD_TO_RECENT_PLAYS_FAILURE:
      if (process.browser) {
        store.dispatch(
          setNotification({
            notificationKey: ntfs.OTHER_ERRORS_INFO,
            message: action.payload.message,
            reqId: action.payload.reqId,
            type: 'error',
            duration: 12000,
          }),
        );
      }

      return next(action);

    default:
      return next(action);
  }
};

export default notificationsMiddleware;
