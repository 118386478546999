// =============================
// Imports
// =============================

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { connect } from 'react-redux';
import url from 'url';
import _startsWith from 'lodash/startsWith';
import _get from 'lodash/get';

import { LinkWrapper, Wrapper } from './styles';

// =============================
// Component
// =============================

class Link extends Component {
  static displayName = 'Link';

  static propTypes = {
    /** Link content */
    children: PropTypes.node,
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /**
     * Forwarded ref, we need to use a different name than "ref" or for some reasons it won't work.
     * That's why we called it "forwardedRef".
     */
    forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    /** If True, wrapper will use inlined styles */
    inline: PropTypes.bool,
    /** The path inside pages directory */
    href: PropTypes.string,
    /**
     * The path that will be rendered in the browser URL bar. Used for dynamic routes.
     * NextJs & Styled Components both us the "as" props, so we need to use nextAs to fix
     * prop not being passed correctly. */
    nextAs: PropTypes.string,
    /** If True, hover styles won't be applied */
    noHover: PropTypes.bool,
    /** onClick handler */
    onClick: PropTypes.func,
    /** Function to execute onClick */
    onClickPassthrough: PropTypes.func,
    /** Alias for href */
    route: PropTypes.string,
    /** If True, will add text ellipsis styles to links */
    textEllipsis: PropTypes.bool,
    /** Modo Config website url */
    websiteUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: null,
    className: '',
    forwardRef: null,
    inline: false,
    href: null,
    nextAs: '',
    noHover: true,
    onClick: null,
    onClickPassthrough: null,
    route: null,
    textEllipsis: false,
  };

  handleOnClick = () => {
    const { onClick, onClickPassthrough } = this.props;

    if (onClickPassthrough) {
      onClickPassthrough();
    }

    if (onClick) onClick();
  };

  getInternalLink = (_baseRoute, nextAs) => {
    let baseRoute = _baseRoute;

    if (_startsWith(_baseRoute, 'http') || _startsWith(_baseRoute, '//')) {
      baseRoute = url.parse(_baseRoute).path;
    } else if (!_startsWith(_baseRoute, '/')) {
      baseRoute = `/${baseRoute}`;
    }

    const nextRoute = nextAs || baseRoute;

    // Handle internal links
    switch (true) {
      case baseRoute.indexOf('/page/') === 0:
        return { route: '/page/[...slug]', nextAs: nextRoute };

      case baseRoute.indexOf('/albums/') === 0:
        return { route: '/albums/[...albumId]', nextAs: nextRoute };

      case baseRoute.indexOf('/artists/') === 0:
        return { route: '/artists/[...artistId]', nextAs: nextRoute };

      case baseRoute.indexOf('/catalogs/') === 0:
        return { route: '/catalogs/[...catalogId]', nextAs: nextRoute };

      case baseRoute.indexOf('/drop/') === 0:
        return { route: '/drop/[...pitchId]', nextAs: nextRoute };

      case baseRoute.indexOf('/userplaylist/') === 0:
        return { route: '/userplaylist/[...playlistId]', nextAs: nextRoute };

      default:
        return { route: nextRoute };
    }
  };

  render() {
    const {
      children,
      className,
      forwardRef,
      inline,
      href,
      nextAs,
      noHover,
      onClick,
      onClickPassthrough,
      route,
      textEllipsis,
      websiteUrl,
      ...rest
    } = this.props;

    const targetLink = href || route;

    const baseProps = {
      className,
      inline,
      noHover,
      onClick: this.handleOnClick,
      textEllipsis,
    };

    // Empty Link. This used for entities without links (tracks without albums have no link).
    if (!targetLink) {
      return (
        <Wrapper ref={forwardRef} {...baseProps} {...rest}>
          {children}
        </Wrapper>
      );
    }

    // External Link & Protocol Link.
    if (
      (_startsWith(targetLink, 'http') || _startsWith(targetLink, '//'))
      // If hostname is the same as websiteUrl, it is actually an internal link
      && url.parse(targetLink).hostname !== websiteUrl
    ) {
      return (
        <LinkWrapper
          href={targetLink}
          ref={forwardRef}
          rel="noopener noreferrer"
          target="_blank"
          {...baseProps}
          {...rest}
        >
          {children}
        </LinkWrapper>
      );
    }

    const internalLink = this.getInternalLink(targetLink, nextAs);

    // Internal Link
    return (
      <NextLink as={internalLink.nextAs} href={internalLink.route} passHref>
        <LinkWrapper ref={forwardRef} {...baseProps} {...rest}>
          {children}
        </LinkWrapper>
      </NextLink>
    );
  }
}

function mapStateToProps({ config }) {
  return {
    websiteUrl: _get(config, 'data.urlConfig.websiteUrl', ''),
  };
}

const ConnectedLink = connect(mapStateToProps, null, null, {
  forwardRef: true,
})(Link);

function forwardedRef(props, ref) {
  return <ConnectedLink forwardRef={ref} {...props} />;
}

forwardedRef.displayName = Link.displayName;

export default React.forwardRef(forwardedRef);
