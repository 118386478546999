/* Single Media */
export const SEARCH = 'search';
export const MUSIC_ITEM_VERSION = 'music-item-version';

/* Playlists */
export const CATALOG = 'catalog';
export const ALBUM = 'album';
export const ARTIST = 'artist';
export const PLAYLIST = 'playlist';
export const PITCH = 'pitch';
export const USER_PLAYLIST = 'user-playlist';
export const USER_FAVORITES = 'user-favorites';
export const USER_RECENTS = 'user-recents';
