// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import SelectInputMobile from './mobile';
import SelectInputDesktop from './desktop';

// =============================
// Component
// =============================

class SelectInput extends PureComponent {
  static displayName = 'SelectInputResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SelectInputDesktop}
        MobileComponent={SelectInputMobile}
        props={this.props}
      />
    );
  }
}

export default SelectInput;
