// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import { withTranslation } from '../../../../config/i18n';

import getViewProps from '../../../../helpers/front/getviewprops';

import { Wrapper, CloseButton, CloseIcon, Content } from './styles';

// =============================
// Component
// =============================

class Modal extends Component {
  static displayName = 'ModalDesktop';

  static propTypes = {
    /** Modal content. */
    children: PropTypes.node,
    /** Is search modal */
    isSearch: PropTypes.bool,
    /** Disable background fade. */
    noFade: PropTypes.bool,
    /** Callback function use to close Modal. */
    onClose: PropTypes.func,
    /** If True, Modal is opened. */
    opened: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** If True, Modal Content will take full space. */
    wideContent: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    isSearch: false,
    noFade: false,
    onClose: null,
    opened: false,
    wideContent: false,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { opened, onClose } = this.props;

    // If escape button & opened
    if (opened && onClose && e.keyCode === 27) {
      onClose();
    }
  };

  render() {
    const {
      children,
      isSearch,
      noFade,
      onClose,
      opened,
      t,
      wideContent,
      ...rest
    } = this.props;

    return (
      <PoseGroup flipMove={false}>
        {opened && (
          <Wrapper
            isSearch={isSearch}
            opened={opened}
            noFade={noFade}
            {...getViewProps(rest)}
            key="0"
          >
            {onClose && (
              <CloseButton onClick={onClose}>
                {t('components:modal.close')}
                <CloseIcon />
              </CloseButton>
            )}
            <Content wideContent={wideContent}>{children}</Content>
          </Wrapper>
        )}
      </PoseGroup>
    );
  }
}

export default withTranslation('components')(Modal);
