// =============================
// Imports
// =============================

// External dependencies
import Cookies from 'cookies';
import _get from 'lodash/get';
import _some from 'lodash/some';
import MobileDetect from 'mobile-detect';
import App from 'next/app';
import Head from 'next/head';
import { withRouter } from 'next/router';
import { compose } from 'redux';

// Config
import { appWithTranslation } from '../config/i18n';

// Actions
import storeWrapper from '../store';
import { getUserData } from '../store/actions/AuthActions';
import { initializeApp, setPrivacyCookie, setServerContext } from '../store/actions/CoreActions';
import { toggleDesc, toggleTags } from '../store/actions/TrackActions';

// Components
import Business from '../app/business';
import Hubspot from '../app/hubspot';
import Layout from '../app/layout';
import Realtime from '../app/realtime';
import ResponsiveContext from '../app/responsive';
import Error from './_error';

// Helpers
import { rootRoute } from '../helpers/config';
import { MewoError } from '../helpers/errors';
import { getCookieConfig, strToBool } from '../helpers/misc';
import { hasAccessToPrivate } from '../helpers/user';

// =============================
// Helpers
// =============================

const isAuthPage = (routePath) => {
  const authRoutes = ['/login', '/register', '/recover', '/resetpassword', '/setpassword'];
  return _some(authRoutes, e => routePath.includes(e));
};

const hasAccessShorthand = (store) => {
  const { user, config } = store.getState();
  return hasAccessToPrivate(user, config);
};

const getInitialRoute = (store, routePath) => {
  const { user, config } = store.getState();

  const isLogged = _get(user, 'isLogged', false);

  // User has no access (not logged in private website) and
  // is not on an auth page or public playlist. Redirect to login
  if (
    !hasAccessShorthand(store)
    && !isAuthPage(routePath)
    && !routePath.includes('/drop/')
  ) {
    return '/login';
  }

  // User has access and is logged in and
  // is on an auth page. Redirect to root page (search or home)
  if (hasAccessShorthand(store) && isLogged && isAuthPage(routePath)) {
    return rootRoute(config.data);
  }

  // When going to home page but home page is disabled.
  // Redirect to search page (which is the root page when there is no home)
  if (routePath === '/' && _get(config.data, 'customisations.homepage.disableAccess', false)) {
    return rootRoute(config.data);
  }

  // When going to favorites page without being logged in.
  // Redirect to login page.
  if (routePath === '/favorites' && !isLogged) {
    return '/login';
  }

  // When going to user playlists page without being logged in
  // NOTE: We use exact match because of /usersplaylists/:playlistId routes
  if (routePath === '/userplaylists' && !isLogged) {
    return '/login';
  }

  // When going to recently played page without being logged in.
  // Redirect to login page.
  if (routePath === '/recentplays' && !isLogged) {
    return '/login';
  }

  // No where to redirect
  return null;
};

// =============================
// Performance
// =============================

export function reportWebVitals({ id, name, label, value }) {
  if (typeof window !== 'undefined' && window.gtag) {
    // Use `window.gtag` if you initialized Google Analytics as this example:
    // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_document.js
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
  }
}

// =============================
// App
// =============================

class MewoApp extends App {
  static getInitialProps = storeWrapper.getInitialAppProps(store => async (appContext) => {
    const { ctx } = appContext;

    let initIsMobile = false;
    if (!process.browser && ctx.req && ctx.req.headers) {
      initIsMobile = !!new MobileDetect(ctx.req.headers['user-agent']).mobile();
    }

    try {
      // Launch this on server side only
      if (!process.browser) {
        store.dispatch(setServerContext(ctx.req));

        const { serverContext } = store.getState().core;

        const cookies = new Cookies(ctx.req, ctx.res);

        // Get user
        const xAuthCookie = cookies.get('xAuth');

        if (xAuthCookie) {
          try {
            await store.dispatch(getUserData(xAuthCookie));
          } catch (err) {
            // Remove xAuth if getUserData didn't work
            ctx.res.clearCookie(
              'xAuth',
              getCookieConfig(null, serverContext.locationProtocol, true),
            );
          }
        }

        // Set privacy cookies
        store.dispatch(
          setPrivacyCookie('mandatory', !!cookies.get('mandatoryCookiesAccepted'), false),
        );
        store.dispatch(
          setPrivacyCookie('analytics', !!cookies.get('analyticsCookiesAccepted'), false),
        );
        store.dispatch(
          setPrivacyCookie('media', !!cookies.get('mediaCookiesAccepted'), false),
        );

        // Init app
        await store.dispatch(initializeApp());

        // Once config is loaded, set fontFamily and defaultLanguage on req for _document.js
        const user = store.getState().user.data;
        const config = store.getState().config.data;

        ctx.req.fontFamily = _get(config, 'customisations.fontFamily', 'Mulish');
        ctx.req.defaultLanguage = _get(config, 'defaultLanguage', 'en');
        ctx.req.userGaCode = _get(config, 'integrations.google.analyticsCode');
        ctx.req.websiteUrl = _get(config, 'urlConfig.websiteUrl');
        ctx.req.hubspotTrackingCode = (
          _get(config, 'integrations.newsletter') === 'hubspot'
            ? _get(config, 'integrations.hubspot.hubId')
            : null
        );

        // Set up track preferences
        const showTrackDescCookie = strToBool(cookies.get('showTrackDesc'));
        const showTrackTagsCookie = strToBool(cookies.get('showTrackTags'));

        if (showTrackDescCookie.isBoolStr) {
          store.dispatch(toggleDesc(showTrackDescCookie.value));
        } else {
          store.dispatch(
            toggleDesc(_get(config, 'customisations.users.showTracksDescriptions', false)),
          );
        }

        if (showTrackTagsCookie.isBoolStr) {
          store.dispatch(toggleTags(showTrackTagsCookie.value));
        } else {
          store.dispatch(toggleTags(_get(config, 'customisations.users.showTracksTags', true)));
        }

        const languageCookie = cookies.get('next-i18next');
        const nextLanguage = user?.language || ctx.req.defaultLanguage;

        // Set cookie with default language
        // Is a user is logged in, his language is used instead
        if (!languageCookie || (user && languageCookie !== user.language)) {
          // Perform a lang change in case we're not on the right lang
          if (ctx.req.i18n) {
            await ctx.req.i18n.changeLanguage(nextLanguage);
          }

          cookies.set(
            'next-i18next',
            nextLanguage,
            // 1 year
            getCookieConfig(365, serverContext.locationProtocol, true),
          );
        }

        // Get initial route and redirect if necessary
        const initRedirectRoute = getInitialRoute(store, ctx.asPath);

        if (initRedirectRoute) {
          ctx.res.redirect(initRedirectRoute);

          ctx.res.end();
          return {};
        }
      }

      const appProps = await App.getInitialProps(appContext);

      // If it is 404 page, handle it like any other error.
      // is finally set up. This could be improved.
      if (!process.browser && ctx.res.statusCode === 404) {
        throw new MewoError({
          message: ctx.req.t('pages:not_found.desc'),
          statusCode: 404,
          type: 'NOT_FOUND',
        });
      }

      delete appProps.pageProps.initialState;

      return {
        error: undefined,
        isSimpleLayout: isAuthPage(ctx.asPath),
        config: store.getState().config.data,
        user: store.getState().user.data,
        isLogged: store.getState().user.isLogged,
        initIsMobile,
        ...appProps,
      };
    } catch (originalError) {
      // Ensure it's MewoError
      const error = new MewoError(originalError);

      if (!process.browser) ctx.res.statusCode = error.statusCode;

      const pageProps = Error.getInitialProps ? await Error.getInitialProps(ctx) : {};

      return {
        // error serialization to avoid rehydration issues
        error: {
          message: error.message,
          statusCode: error.statusCode,
          type: error.type,
        },
        isSimpleLayout: !store.getState().core.ready || !hasAccessShorthand(store),
        config: store.getState().config.data,
        user: store.getState().user.data,
        isLogged: store.getState().user.isLogged,
        initIsMobile,
        pageProps,
      };
    }
  });

  render() {
    const {
      config,
      user,
      isLogged,
      initIsMobile,
      error,
      isSimpleLayout,
      Component,
      pageProps,
    } = this.props;

    const baseProps = { config, user, isLogged };

    return (
      <ResponsiveContext initIsMobile={initIsMobile}>
        <Business {...baseProps}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1"
            />
          </Head>
          <Hubspot />
          <Realtime>
            <Layout {...baseProps} isSimpleLayout={isSimpleLayout}>
              {error && <Error {...baseProps} error={error} isSimpleLayout={isSimpleLayout} />}
              {!error && <Component {...baseProps} {...pageProps} />}
            </Layout>
          </Realtime>
        </Business>
      </ResponsiveContext>
    );
  }
}

export default compose(storeWrapper.withRedux, appWithTranslation, withRouter)(MewoApp);
