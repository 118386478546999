// =============================
// Imports
// =============================

// External dependencies
import axios, { CancelToken } from 'axios';

// =============================
// Helper
// =============================

const oldRequests = {};

// Cancel an old request without doing a new one
export function cancelRequest(key) {
  const oldRequest = oldRequests[key];

  if (oldRequest && oldRequest.token) {
    oldRequest.token.cancel(`${key}: request canceled`);
  }
}

// Cancel an old request and do it again
export function cancelableRequest(key, _opts) {
  const opts = _opts;
  const oldRequest = oldRequests[key];

  if (oldRequest && oldRequest.token) {
    oldRequest.token.cancel(`${key}: request canceled`);
  }

  const newToken = CancelToken.source();
  opts.cancelToken = newToken.token;

  oldRequests[key] = { token: newToken };

  return axios(opts);
}
