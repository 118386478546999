/* Core */
export const SET_SERVER_CONTEXT = 'SET_SERVER_CONTEXT';
export const SET_PRIVACY_COOKIE = 'SET_PRIVACY_COOKIE';
export const INITIALIZE_APP = 'INITIALIZE_APP';

/* Options */
export const SET_OPTIONS = 'SET_OPTIONS';

export const GET_OPTIONS_LOADING = 'GET_OPTIONS_LOADING';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';

/* Notifications */
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';

// =============================
// Config
// =============================

/* Config */
export const SET_CONFIG = 'SET_CONFIG';

export const GET_CONFIG_LOADING = 'GET_CONFIG_LOADING';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';

/* Config Legal */
export const SET_CONFIG_LEGAL = 'SET_CONFIG_LEGAL';

export const GET_CONFIG_LEGAL_LOADING = 'GET_CONFIG_LEGAL_LOADING';
export const GET_CONFIG_LEGAL_SUCCESS = 'GET_CONFIG_LEGAL_SUCCESS';
export const GET_CONFIG_LEGAL_FAILURE = 'GET_CONFIG_LEGAL_FAILURE';

/* Config Menus */
export const SET_CONFIG_MENUS = 'SET_CONFIG_MENUS';

export const GET_CONFIG_MENUS_LOADING = 'GET_CONFIG_MENUS_LOADING';
export const GET_CONFIG_MENUS_SUCCESS = 'GET_CONFIG_MENUS_SUCCESS';
export const GET_CONFIG_MENUS_FAILURE = 'GET_CONFIG_MENUS_FAILURE';

/* Config Home */
export const SET_CONFIG_HOME = 'SET_CONFIG_HOME';

export const GET_CONFIG_HOME_LOADING = 'GET_CONFIG_HOME_LOADING';
export const GET_CONFIG_HOME_SUCCESS = 'GET_CONFIG_HOME_SUCCESS';
export const GET_CONFIG_HOME_FAILURE = 'GET_CONFIG_HOME_FAILURE';

/* Config Page */
export const SET_CONFIG_PAGE = 'SET_CONFIG_PAGE';

export const GET_CONFIG_PAGE_LOADING = 'GET_CONFIG_PAGE_LOADING';
export const GET_CONFIG_PAGE_SUCCESS = 'GET_CONFIG_PAGE_SUCCESS';
export const GET_CONFIG_PAGE_FAILURE = 'GET_CONFIG_PAGE_FAILURE';

export const RESET_CONFIG_PAGE = 'RESET_CONFIG_PAGE';

/* Send Contact Email */
export const SEND_CONTACT_EMAIL_LOADING = 'SEND_CONTACT_EMAIL_LOADING';
export const SEND_CONTACT_EMAIL_SUCCESS = 'SEND_CONTACT_EMAIL_SUCCESS';
export const SEND_CONTACT_EMAIL_FAILURE = 'SEND_CONTACT_EMAIL_FAILURE';

// =============================
// Auth / User
// =============================

/* Misc: Subscribe to Newsletter */
export const SUBSCRIBE_TO_NEWSLETTER_LOADING = 'SUBSCRIBE_TO_NEWSLETTER_LOADING';
export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS = 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS';
export const SUBSCRIBE_TO_NEWSLETTER_FAILURE = 'SUBSCRIBE_TO_NEWSLETTER_FAILURE';

/* Auth: get user */
export const GET_USER_DATA_LOADING = 'GET_USER_DATA_LOADING';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';

/* User: modify user */
export const MODIFY_USER_LOADING = 'MODIFY_USER_LOADING';
export const MODIFY_USER_FAILURE = 'MODIFY_USER_FAILURE';
export const MODIFY_USER_SUCCESS = 'MODIFY_USER_SUCCESS';

export const MODIFY_USER_PASSWORD_LOADING = 'MODIFY_USER_PASSWORD_LOADING';
export const MODIFY_USER_PASSWORD_FAILURE = 'MODIFY_USER_PASSWORD_FAILURE';
export const MODIFY_USER_PASSWORD_SUCCESS = 'MODIFY_USER_PASSWORD_SUCCESS';

/* Auth: session */
export const SET_USER_SESSION_TOKEN = 'SET_USER_SESSION_TOKEN';
export const SET_USER_PITCH_TOKEN = 'SET_USER_PITCH_TOKEN';
export const SET_USER_INFO = 'SET_USER_INFO';

export const GET_USER_SESSION_TOKEN_LOADING = 'GET_USER_SESSION_TOKEN_LOADING';
export const GET_USER_SESSION_TOKEN_FAILURE = 'GET_USER_SESSION_TOKEN_FAILURE';
export const GET_USER_SESSION_TOKEN_SUCCESS = 'GET_USER_SESSION_TOKEN_SUCCESS';

/* Auth: register user */
export const REGISTER_USER_LOADING = 'REGISTER_USER_LOADING';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';

/* Auth: recover password */
export const RECOVER_PASSWORD_LOADING = 'RECOVER_PASSWORD_LOADING';
export const RECOVER_PASSWORD_FAILURE = 'RECOVER_PASSWORD_FAILURE';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';

/* Auth: reset password */
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

/* Auth: activate account */
export const ACTIVATE_ACCOUNT_LOADING = 'ACTIVATE_ACCOUNT_LOADING';
export const ACTIVATE_ACCOUNT_FAILURE = 'ACTIVATE_ACCOUNT_FAILURE';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVATE_ACCOUNT_SUCCESS';

/* Auth: set password */
export const SET_PASSWORD_LOADING = 'SET_PASSWORD_LOADING';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';

/* User: Realtime */
export const SET_REALTIME_AUTH_KEY = 'SET_REALTIME_AUTH_KEY';
export const SET_REALTIME_CHANNELS = 'SET_REALTIME_CHANNELS';

export const GET_REALTIME_CHANNELS_LOADING = 'GET_REALTIME_CHANNELS_LOADING';
export const GET_REALTIME_CHANNELS_SUCCESS = 'GET_REALTIME_CHANNELS_SUCCESS';
export const GET_REALTIME_CHANNELS_FAILURE = 'GET_REALTIME_CHANNELS_FAILURE';

/* User: Notification */
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const SET_LATEST_USER_NOTIFICATIONS = 'SET_LATEST_USER_NOTIFICATIONS';

export const GET_USER_NOTIFICATIONS_LOADING = 'GET_USER_NOTIFICATIONS_LOADING';
export const GET_USER_NOTIFICATIONS_FAILURE = 'GET_USER_NOTIFICATIONS_FAILURE';
export const GET_USER_NOTIFICATIONS_SUCCESS = 'GET_USER_NOTIFICATIONS_SUCCESS';

export const CHECK_USER_NOTIFICATIONS_LOADING = 'CHECK_USER_NOTIFICATIONS_LOADING';
export const CHECK_USER_NOTIFICATIONS_SUCCESS = 'CHECK_USER_NOTIFICATIONS_SUCCESS';
export const CHECK_USER_NOTIFICATIONS_FAILURE = 'CHECK_USER_NOTIFICATIONS_FAILURE';

/* Auth: log out */
export const LOGOUT_USER_LOADING = 'LOGOUT_USER_LOADING';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

// =============================
// Suggested
// =============================

/* Suggested Albums */
export const SET_SUGGESTED_ALBUMS = 'SET_SUGGESTED_ALBUMS';

export const GET_SUGGESTED_ALBUMS_LOADING = 'GET_SUGGESTED_ALBUMS_LOADING';
export const GET_SUGGESTED_ALBUMS_FAILURE = 'GET_SUGGESTED_ALBUMS_FAILURE';
export const GET_SUGGESTED_ALBUMS_SUCCESS = 'GET_SUGGESTED_ALBUMS_SUCCESS';

/* Suggested Playlists */
export const SET_SUGGESTED_PLAYLISTS = 'SET_SUGGESTED_PLAYLISTS';

export const GET_SUGGESTED_PLAYLISTS_LOADING = 'GET_SUGGESTED_PLAYLISTS_LOADING';
export const GET_SUGGESTED_PLAYLISTS_FAILURE = 'GET_SUGGESTED_PLAYLISTS_FAILURE';
export const GET_SUGGESTED_PLAYLISTS_SUCCESS = 'GET_SUGGESTED_PLAYLISTS_SUCCESS';

// =============================
// Favorites
// =============================

/* Favorites */
export const SET_FAVORITES = 'SET_FAVORITES';
export const REPLACE_FAVORITES_DATA = 'REPLACE_FAVORITES_DATA';

export const GET_FAVORITES_LOADING = 'GET_FAVORITES_LOADING';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';

export const ADD_TO_FAVORITES_LOADING = 'ADD_TO_FAVORITES_LOADING';
export const ADD_TO_FAVORITES_FAILURE = 'ADD_TO_FAVORITES_FAILURE';
export const ADD_TO_FAVORITES_SUCCESS = 'ADD_TO_FAVORITES_SUCCESS';

export const REMOVE_FROM_FAVORITES_LOADING = 'REMOVE_FROM_FAVORITES_LOADING';
export const REMOVE_FROM_FAVORITES_FAILURE = 'REMOVE_FROM_FAVORITES_FAILURE';
export const REMOVE_FROM_FAVORITES_SUCCESS = 'REMOVE_FROM_FAVORITES_SUCCESS';

export const RESET_FAVORITES = 'RESET_FAVORITES';

// =============================
// User Playlists
// =============================

/* Full List */
export const SET_USER_PLAYLISTS_LIST = 'SET_USER_PLAYLISTS_LIST';

export const GET_USER_PLAYLISTS_LIST_LOADING = 'GET_USER_PLAYLISTS_LIST_LOADING';
export const GET_USER_PLAYLISTS_LIST_FAILURE = 'GET_USER_PLAYLISTS_LIST_FAILURE';
export const GET_USER_PLAYLISTS_LIST_SUCCESS = 'GET_USER_PLAYLISTS_LIST_SUCCESS';

export const CREATE_USER_PLAYLIST_LOADING = 'CREATE_USER_PLAYLIST_LOADING';
export const CREATE_USER_PLAYLIST_FAILURE = 'CREATE_USER_PLAYLIST_FAILURE';
export const CREATE_USER_PLAYLIST_SUCCESS = 'CREATE_USER_PLAYLIST_SUCCESS';

export const DUPLICATE_USER_PLAYLIST_LOADING = 'DUPLICATE_USER_PLAYLIST_LOADING';
export const DUPLICATE_USER_PLAYLIST_FAILURE = 'DUPLICATE_USER_PLAYLIST_FAILURE';
export const DUPLICATE_USER_PLAYLIST_SUCCESS = 'DUPLICATE_USER_PLAYLIST_SUCCESS';

export const MODIFY_USER_PLAYLIST_LOADING = 'MODIFY_USER_PLAYLIST_LOADING';
export const MODIFY_USER_PLAYLIST_FAILURE = 'MODIFY_USER_PLAYLIST_FAILURE';
export const MODIFY_USER_PLAYLIST_SUCCESS = 'MODIFY_USER_PLAYLIST_SUCCESS';

export const REMOVE_USER_PLAYLIST_LOADING = 'REMOVE_USER_PLAYLIST_LOADING';
export const REMOVE_USER_PLAYLIST_FAILURE = 'REMOVE_USER_PLAYLIST_FAILURE';
export const REMOVE_USER_PLAYLIST_SUCCESS = 'REMOVE_USER_PLAYLIST_SUCCESS';

export const RESET_USER_PLAYLIST_AND_RELATED_DATA = 'RESET_USER_PLAYLIST_AND_RELATED_DATA';

export const UPDATE_USER_PLAYLIST_FAVORITES = 'UPDATE_USER_PLAYLIST_FAVORITES';
export const UPDATE_USER_PLAYLIST_RECENTS = 'UPDATE_USER_PLAYLIST_RECENTS';

export const REORDER_USER_PLAYLIST_TRACK = 'REORDER_USER_PLAYLIST_TRACK';

/* Paginated List */
export const SET_USER_PLAYLISTS = 'SET_USER_PLAYLISTS';

export const GET_USER_PLAYLISTS_LOADING = 'GET_USER_PLAYLISTS_LOADING';
export const GET_USER_PLAYLISTS_SUCCESS = 'GET_USER_PLAYLISTS_SUCCESS';
export const GET_USER_PLAYLISTS_FAILURE = 'GET_USER_PLAYLISTS_FAILURE';

export const RESET_USER_PLAYLISTS = 'RESET_USER_PLAYLISTS';

/* Add To Playlist */
export const ADD_TRACK_TO_PLAYLIST_LOADING = 'ADD_TRACK_TO_PLAYLIST_LOADING';
export const ADD_TRACK_TO_PLAYLIST_FAILURE = 'ADD_TRACK_TO_PLAYLIST_FAILURE';
export const ADD_TRACK_TO_PLAYLIST_SUCCESS = 'ADD_TRACK_TO_PLAYLIST_SUCCESS';

/* Modify order in Playlist */
export const REORDER_TRACK_IN_PLAYLIST_LOADING = 'REORDER_TRACK_IN_PLAYLIST_LOADING';
export const REORDER_TRACK_IN_PLAYLIST_FAILURE = 'REORDER_TRACK_IN_PLAYLIST_FAILURE';
export const REORDER_TRACK_IN_PLAYLIST_SUCCESS = 'REORDER_TRACK_IN_PLAYLIST_SUCCESS';

/* Remove From Playlist */
export const REMOVE_TRACK_FROM_PLAYLIST_LOADING = 'REMOVE_TRACK_FROM_PLAYLIST_LOADING';
export const REMOVE_TRACK_FROM_PLAYLIST_SUCCESS = 'REMOVE_TRACK_FROM_PLAYLIST_SUCCESS';
export const REMOVE_TRACK_FROM_PLAYLIST_FAILURE = 'REMOVE_TRACK_FROM_PLAYLIST_FAILURE';

export const REPLACE_USER_PLAYLIST_TRACKS_DATA = 'REPLACE_USER_PLAYLIST_TRACKS_DATA';

/* Show playlist */
export const SET_USER_PLAYLIST = 'SET_USER_PLAYLIST';

export const GET_USER_PLAYLIST_LOADING = 'GET_USER_PLAYLIST_LOADING';
export const GET_USER_PLAYLIST_FAILURE = 'GET_USER_PLAYLIST_FAILURE';
export const GET_USER_PLAYLIST_SUCCESS = 'GET_USER_PLAYLIST_SUCCESS';

/* Show playlist tracks */
export const SET_USER_PLAYLIST_TRACKS = 'SET_USER_PLAYLIST_TRACKS';

export const GET_USER_PLAYLIST_TRACKS_LOADING = 'GET_USER_PLAYLIST_TRACKS_LOADING';
export const GET_USER_PLAYLIST_TRACKS_FAILURE = 'GET_USER_PLAYLIST_TRACKS_FAILURE';
export const GET_USER_PLAYLIST_TRACKS_SUCCESS = 'GET_USER_PLAYLIST_TRACKS_SUCCESS';

// =============================
// Tracks
// =============================

/* Track options */
export const SHOW_TRACK_DESC = 'SHOW_TRACK_DESC';
export const SHOW_TRACK_TAGS = 'SHOW_TRACK_TAGS';

/* Versions */
export const SET_VERSIONS = 'SET_VERSIONS';

export const GET_VERSIONS_LOADING = 'GET_VERSIONS_LOADING';
export const GET_VERSIONS_FAILURE = 'GET_VERSIONS_FAILURE';
export const GET_VERSIONS_SUCCESS = 'GET_VERSIONS_SUCCESS';

export const UPDATE_VERSION_FAVORITES = 'UPDATE_VERSION_FAVORITES';
export const UPDATE_VERSION_RECENTS = 'UPDATE_VERSION_RECENTS';

/* Files */
export const SET_FILES = 'SET_FILES';

export const GET_FILES_LOADING = 'GET_FILES_LOADING';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';

/* Downloads */
export const DOWNLOAD_AUDIOFILE_LOADING = 'DOWNLOAD_AUDIOFILE_LOADING';
export const DOWNLOAD_AUDIOFILE_FAILURE = 'DOWNLOAD_AUDIOFILE_FAILURE';
export const DOWNLOAD_AUDIOFILE_SUCCESS = 'DOWNLOAD_AUDIOFILE_SUCCESS';

export const NOTIFY_FILE_DOWNLOAD_LOADING = 'NOTIFY_FILE_DOWNLOAD_LOADING';
export const NOTIFY_FILE_DOWNLOAD_SUCCESS = 'NOTIFY_FILE_DOWNLOAD_SUCCESS';
export const NOTIFY_FILE_DOWNLOAD_FAILURE = 'NOTIFY_FILE_DOWNLOAD_FAILURE';

// =============================
// Entities
// =============================

/* Album */
export const SET_ALBUM = 'SET_ALBUM';

export const GET_ALBUM_LOADING = 'GET_ALBUM_LOADING';
export const GET_ALBUM_FAILURE = 'GET_ALBUM_FAILURE';
export const GET_ALBUM_SUCCESS = 'GET_ALBUM_SUCCESS';

export const RESET_ALBUM_AND_ITS_TRACKS = 'RESET_ALBUM_AND_ITS_TRACKS';

export const UPDATE_ALBUM_FAVORITES = 'UPDATE_ALBUM_FAVORITES';
export const UPDATE_ALBUM_RECENTS = 'UPDATE_ALBUM_RECENTS';

/* Album Tracks */
export const SET_ALBUM_TRACKS = 'SET_ALBUM_TRACKS';

export const GET_ALBUM_TRACKS_LOADING = 'GET_ALBUM_TRACKS_LOADING';
export const GET_ALBUM_TRACKS_FAILURE = 'GET_ALBUM_TRACKS_FAILURE';
export const GET_ALBUM_TRACKS_SUCCESS = 'GET_ALBUM_TRACKS_SUCCESS';

/* Playlist */
export const SET_PLAYLIST = 'SET_PLAYLIST';

export const GET_PLAYLIST_LOADING = 'GET_PLAYLIST_LOADING';
export const GET_PLAYLIST_FAILURE = 'GET_PLAYLIST_FAILURE';
export const GET_PLAYLIST_SUCCESS = 'GET_PLAYLIST_SUCCESS';

export const DUPLICATE_PLAYLIST_LOADING = 'DUPLICATE_PLAYLIST_LOADING';
export const DUPLICATE_PLAYLIST_FAILURE = 'DUPLICATE_PLAYLIST_FAILURE';
export const DUPLICATE_PLAYLIST_SUCCESS = 'DUPLICATE_PLAYLIST_SUCCESS';

export const RESET_PLAYLIST_AND_ITS_TRACKS = 'RESET_PLAYLIST_AND_ITS_TRACKS';

export const UPDATE_PLAYLIST_FAVORITES = 'UPDATE_PLAYLIST_FAVORITES';
export const UPDATE_PLAYLIST_RECENTS = 'UPDATE_PLAYLIST_RECENTS';

/* Playlist Tracks */
export const SET_PLAYLIST_TRACKS = 'SET_PLAYLIST_TRACKS';

export const GET_PLAYLIST_TRACKS_LOADING = 'GET_PLAYLIST_TRACKS_LOADING';
export const GET_PLAYLIST_TRACKS_FAILURE = 'GET_PLAYLIST_TRACKS_FAILURE';
export const GET_PLAYLIST_TRACKS_SUCCESS = 'GET_PLAYLIST_TRACKS_SUCCESS';

/* Pitch */
export const SET_PITCH = 'SET_PITCH';

export const GET_PITCH_LOADING = 'GET_PITCH_LOADING';
export const GET_PITCH_FAILURE = 'GET_PITCH_FAILURE';
export const GET_PITCH_SUCCESS = 'GET_PITCH_SUCCESS';

export const RESET_PITCH_AND_ITS_TRACKS = 'RESET_PITCH_AND_ITS_TRACKS';

export const UPDATE_PITCH_FAVORITES = 'UPDATE_PITCH_FAVORITES';
export const UPDATE_PITCH_RECENTS = 'UPDATE_PITCH_RECENTS';

/* Pitch Tracks */
export const SET_PITCH_TRACKS = 'SET_PITCH_TRACKS';

export const GET_PITCH_TRACKS_LOADING = 'GET_PITCH_TRACKS_LOADING';
export const GET_PITCH_TRACKS_FAILURE = 'GET_PITCH_TRACKS_FAILURE';
export const GET_PITCH_TRACKS_SUCCESS = 'GET_PITCH_TRACKS_SUCCESS';

/* Artist */
export const SET_ARTIST = 'SET_ARTIST';

export const GET_ARTIST_LOADING = 'GET_ARTIST_LOADING';
export const GET_ARTIST_FAILURE = 'GET_ARTIST_FAILURE';
export const GET_ARTIST_SUCCESS = 'GET_ARTIST_SUCCESS';

export const RESET_ARTIST_AND_RELATED_DATA = 'RESET_ARTIST_AND_RELATED_DATA';

/* Artist Tracks */
export const SET_ARTIST_TRACKS = 'SET_ARTIST_TRACKS';

export const GET_ARTIST_TRACKS_LOADING = 'GET_ARTIST_TRACKS_LOADING';
export const GET_ARTIST_TRACKS_FAILURE = 'GET_ARTIST_TRACKS_FAILURE';
export const GET_ARTIST_TRACKS_SUCCESS = 'GET_ARTIST_TRACKS_SUCCESS';

export const UPDATE_ARTIST_FAVORITES = 'UPDATE_ARTIST_FAVORITES';
export const UPDATE_ARTIST_RECENTS = 'UPDATE_ARTIST_RECENTS';

/* Artist Albums */
export const SET_ARTIST_ALBUMS = 'SET_ARTIST_ALBUMS';

export const GET_ARTIST_ALBUMS_LOADING = 'GET_ARTIST_ALBUMS_LOADING';
export const GET_ARTIST_ALBUMS_FAILURE = 'GET_ARTIST_ALBUMS_FAILURE';
export const GET_ARTIST_ALBUMS_SUCCESS = 'GET_ARTIST_ALBUMS_SUCCESS';

/* Artist Albums Appears On */
export const SET_ARTIST_ALBUMS_APPEARS_ON = 'SET_ARTIST_ALBUMS_APPEARS_ON';

export const GET_ARTIST_ALBUMS_APPEARS_ON_LOADING = 'GET_ARTIST_ALBUMS_APPEARS_ON_LOADING';
export const GET_ARTIST_ALBUMS_APPEARS_ON_FAILURE = 'GET_ARTIST_ALBUMS_APPEARS_ON_FAILURE';
export const GET_ARTIST_ALBUMS_APPEARS_ON_SUCCESS = 'GET_ARTIST_ALBUMS_APPEARS_ON_SUCCESS';

/* Artist Playlists Appears On */
export const SET_ARTIST_PLAYLISTS_APPEARS_ON = 'SET_ARTIST_PLAYLISTS_APPEARS_ON';

export const GET_ARTIST_PLAYLISTS_APPEARS_ON_LOADING = 'GET_ARTIST_PLAYLISTS_APPEARS_ON_LOADING';
export const GET_ARTIST_PLAYLISTS_APPEARS_ON_FAILURE = 'GET_ARTIST_PLAYLISTS_APPEARS_ON_FAILURE';
export const GET_ARTIST_PLAYLISTS_APPEARS_ON_SUCCESS = 'GET_ARTIST_PLAYLISTS_APPEARS_ON_SUCCESS';

/* Catalog */
export const SET_CATALOG = 'SET_CATALOG';

export const GET_CATALOG_LOADING = 'GET_CATALOG_LOADING';
export const GET_CATALOG_FAILURE = 'GET_CATALOG_FAILURE';
export const GET_CATALOG_SUCCESS = 'GET_CATALOG_SUCCESS';

export const RESET_CATALOG_AND_RELATED_DATA = 'RESET_CATALOG_AND_RELATED_DATA';

/* Catalog Tracks */
export const SET_CATALOG_TRACKS = 'SET_CATALOG_TRACKS';

export const GET_CATALOG_TRACKS_LOADING = 'GET_CATALOG_TRACKS_LOADING';
export const GET_CATALOG_TRACKS_FAILURE = 'GET_CATALOG_TRACKS_FAILURE';
export const GET_CATALOG_TRACKS_SUCCESS = 'GET_CATALOG_TRACKS_SUCCESS';

export const UPDATE_CATALOG_FAVORITES = 'UPDATE_CATALOG_FAVORITES';
export const UPDATE_CATALOG_RECENTS = 'UPDATE_CATALOG_RECENTS';

/* Catalog Albums */
export const SET_CATALOG_ALBUMS = 'SET_CATALOG_ALBUMS';

export const GET_CATALOG_ALBUMS_LOADING = 'GET_CATALOG_ALBUMS_LOADING';
export const GET_CATALOG_ALBUMS_FAILURE = 'GET_CATALOG_ALBUMS_FAILURE';
export const GET_CATALOG_ALBUMS_SUCCESS = 'GET_CATALOG_ALBUMS_SUCCESS';

/* Download Archive */
export const DOWNLOAD_ARCHIVES_LOADING = 'DOWNLOAD_ARCHIVES_LOADING';
export const DOWNLOAD_ARCHIVES_SUCCESS = 'DOWNLOAD_ARCHIVES_SUCCESS';
export const DOWNLOAD_ARCHIVES_FAILURE = 'DOWNLOAD_ARCHIVES_FAILURE';

/* Download Archive */
export const POLLING_DOWNLOAD_ARCHIVE_LOADING = 'POLLING_DOWNLOAD_ARCHIVE_LOADING';
export const POLLING_DOWNLOAD_ARCHIVE_SUCCESS = 'POLLING_DOWNLOAD_ARCHIVE_SUCCESS';
export const POLLING_DOWNLOAD_ARCHIVE_FAILURE = 'POLLING_DOWNLOAD_ARCHIVE_FAILURE';

/* Download Copyright */
export const DOWNLOAD_COPYRIGHT_LOADING = 'DOWNLOAD_COPYRIGHT_LOADING';
export const DOWNLOAD_COPYRIGHT_SUCCESS = 'DOWNLOAD_COPYRIGHT_SUCCESS';
export const DOWNLOAD_COPYRIGHT_FAILURE = 'DOWNLOAD_COPYRIGHT_FAILURE';

// =============================
// Search
// =============================

/* With description */
export const SEARCH_SET_DESCRIPTION_INCLUSION = 'SEARCH_SET_DESCRIPTION_INCLUSION';

/* Tracks Search */
export const SET_TRACKS_SEARCH_DATA = 'SET_TRACKS_SEARCH_DATA';

export const GET_TRACKS_SEARCH_DATA_LOADING = 'GET_TRACKS_SEARCH_DATA_LOADING';
export const GET_TRACKS_SEARCH_DATA_FAILURE = 'GET_TRACKS_SEARCH_DATA_FAILURE';
export const GET_TRACKS_SEARCH_DATA_SUCCESS = 'GET_TRACKS_SEARCH_DATA_SUCCESS';

export const UPDATE_TRACKS_SEARCH_FAVORITES = 'UPDATE_TRACKS_SEARCH_FAVORITES';
export const UPDATE_TRACKS_SEARCH_RECENTS = 'UPDATE_TRACKS_SEARCH_RECENTS';

export const RESET_TRACKS_SEARCH_DATA = 'RESET_TRACKS_SEARCH_DATA';

/* Maia Tracks Search */
export const SET_MAIA_REQUEST = 'SET_MAIA_REQUEST';

export const REQUEST_MAIA_TEXT_LOADING = 'REQUEST_MAIA_TEXT_LOADING';
export const REQUEST_MAIA_TEXT_FAILURE = 'REQUEST_MAIA_TEXT_FAILURE';
export const REQUEST_MAIA_TEXT_SUCCESS = 'REQUEST_MAIA_TEXT_SUCCESS';

export const SET_MAIA_AUDIO_UPLOAD_PROGRESS = 'SET_MAIA_AUDIO_UPLOAD_PROGRESS';

export const REQUEST_MAIA_AUDIO_LOADING = 'REQUEST_MAIA_AUDIO_LOADING';
export const REQUEST_MAIA_AUDIO_FAILURE = 'REQUEST_MAIA_AUDIO_FAILURE';
export const REQUEST_MAIA_AUDIO_SUCCESS = 'REQUEST_MAIA_AUDIO_SUCCESS';

/* Initial Facets Search */
export const SET_INITIAL_FACETS = 'SET_INITIAL_FACETS';

export const GET_INITIAL_FACETS_LOADING = 'GET_INITIAL_FACETS_LOADING';
export const GET_INITIAL_FACETS_SUCCESS = 'GET_INITIAL_FACETS_SUCCESS';
export const GET_INITIAL_FACETS_FAILURE = 'GET_INITIAL_FACETS_FAILURE';

/* Pre Search */
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_PRE_SEARCH_DATA = 'SET_PRE_SEARCH_DATA';

export const GET_PRE_SEARCH_DATA_LOADING = 'GET_PRE_SEARCH_DATA_LOADING';
export const GET_PRE_SEARCH_DATA_FAILURE = 'GET_PRE_SEARCH_DATA_FAILURE';
export const GET_PRE_SEARCH_DATA_SUCCESS = 'GET_PRE_SEARCH_DATA_SUCCESS';

export const RESET_PRE_SEARCH_DATA = 'RESET_PRE_SEARCH_DATA';

/** Full Size Cover Module Search */
export const SET_FULL_SIZE_COVER_MODULE_DATA = 'SET_FULL_SIZE_COVER_MODULE_DATA';
export const CLEAR_FULL_SIZE_COVER_MODULE_DATA = 'CLEAR_FULL_SIZE_COVER_MODULE_DATA';

/* Entity Search */
export const SET_ENTITY_SEARCH_DATA = 'SET_ENTITY_SEARCH_DATA';

export const GET_ENTITY_SEARCH_DATA_LOADING = 'GET_ENTITY_SEARCH_DATA_LOADING';
export const GET_ENTITY_SEARCH_DATA_FAILURE = 'GET_ENTITY_SEARCH_DATA_FAILURE';
export const GET_ENTITY_SEARCH_DATA_SUCCESS = 'GET_ENTITY_SEARCH_DATA_SUCCESS';

export const RESET_ENTITY_SEARCH_DATA = 'RESET_ENTITY_SEARCH_DATA';

// =============================
// Modals
// =============================

export const MODALS_SET_TRACK_LYRICS_OPEN = 'MODALS_SET_TRACK_LYRICS_OPEN';
export const MODALS_SET_USER_PLAYLIST_OPEN = 'MODALS_SET_USER_PLAYLIST_OPEN';
export const MODALS_SET_ALBUM_OPEN = 'MODALS_SET_ALBUM_OPEN';
export const MODALS_SET_ARTIST_OPEN = 'MODALS_SET_ARTIST_OPEN';
export const MODALS_SET_CATALOG_OPEN = 'MODALS_SET_CATALOG_OPEN';
export const MODALS_SET_PLAYLIST_OPEN = 'MODALS_SET_PLAYLIST_OPEN';
export const MODALS_SET_PITCH_OPEN = 'MODALS_SET_PITCH_OPEN';
export const MODALS_SET_ACCOUNT_OPEN = 'MODALS_SET_ACCOUNT_OPEN';
export const MODALS_SET_MENU_OPEN = 'MODALS_SET_MENU_OPEN';
export const MODALS_SET_LEGAL_OPEN = 'MODALS_SET_LEGAL_OPEN';
export const MODALS_SET_COOKIES_OPEN = 'MODALS_SET_COOKIES_OPEN';
export const MODALS_SET_PRE_SEARCH_OPEN = 'MODALS_SET_PRE_SEARCH_OPEN';
export const MODALS_SET_VIDEO_OPEN = 'MODALS_SET_VIDEO_OPEN';
export const MODALS_SET_USER_MENU_OPEN = 'MODALS_SET_USER_MENU_OPEN';
export const MODALS_SET_MUSIC_ITEM_OPEN = 'MODALS_SET_MUSIC_ITEM_OPEN';
export const MODALS_REQUEST_MUSIC_PLAYER_OPEN = 'MODALS_REQUEST_MUSIC_PLAYER_OPEN';
export const MODALS_SET_MUSIC_PLAYER_OPEN = 'MODALS_SET_MUSIC_PLAYER_OPEN';
export const MODALS_SET_ADD_TO_PLAYLIST_OPEN = 'MODALS_SET_ADD_TO_PLAYLIST_OPEN';
export const MODALS_SET_TRACK_ARTISTS_OPEN = 'MODALS_SET_TRACK_ARTISTS_OPEN';
export const MODALS_SET_TRACK_OWNERSHIPS_OPEN = 'MODALS_SET_TRACK_OWNERSHIPS_OPEN';
export const MODALS_SET_TRACK_TAGS_OPEN = 'MODALS_SET_TRACK_TAGS_OPEN';
export const MODALS_SET_TRACK_VERSIONS_OPEN = 'MODALS_SET_TRACK_VERSIONS_OPEN';
export const MODALS_SET_ENTITY_ACTIONS_OPEN = 'MODALS_SET_ENTITY_ACTIONS_OPEN';

export const UPDATE_MODALS_FAVORITES = 'UPDATE_MODALS_FAVORITES';
export const UPDATE_MODALS_RECENTS = 'UPDATE_MODALS_RECENTS';

export const RESET_ALL_MODALS = 'RESET_ALL_MODALS';

// =============================
// Player
// =============================

/* Player */
export const OPEN_PLAYER = 'OPEN_PLAYER';
export const CLOSE_PLAYER = 'CLOSE_PLAYER';

export const SET_PLAYER_STATE = 'SET_PLAYER_STATE';
export const FULFILL_PLAYER_REQUEST = 'FULFILL_PLAYER_REQUEST';
export const PLAYER_REQUEST_PLAY = 'PLAYER_REQUEST_PLAY';
export const PLAYER_REQUEST_PAUSE = 'PLAYER_REQUEST_PAUSE';
export const PLAYER_REQUEST_GO_TO_PREV_TRACK = 'PLAYER_REQUEST_GO_TO_PREV_TRACK';
export const PLAYER_REQUEST_GO_TO_NEXT_TRACK = 'PLAYER_REQUEST_GO_TO_NEXT_TRACK';
export const PLAYER_REQUEST_SET_SEEKING_TIME = 'PLAYER_REQUEST_SET_SEEKING_TIME';

export const SET_PLAYER_GET_CURRENT_TIME_FNC = 'SET_PLAYER_GET_CURRENT_TIME_FNC';

export const REINITIALIZE_PLAYER = 'REINITIALIZE_PLAYER';

/* Media API */
export const SET_SINGLE_MEDIA = 'SET_SINGLE_MEDIA';
export const SET_MEDIA_PLAYLIST = 'SET_MEDIA_PLAYLIST';

export const UPDATE_CONTEXT_POSITION = 'UPDATE_CONTEXT_POSITION';

export const UPDATE_PLAYER_FAVORITES = 'UPDATE_PLAYER_FAVORITES';
export const UPDATE_PLAYER_RECENTS = 'UPDATE_PLAYER_RECENTS';

export const GET_MEDIA_LOADING = 'GET_MEDIA_LOADING';
export const GET_MEDIA_SUCCESS = 'GET_MEDIA_SUCCESS';
export const GET_MEDIA_FAILURE = 'GET_MEDIA_FAILURE';

// =============================
// Recently played
// =============================

/* Get recent plays */
export const SET_RECENT_PLAYS = 'SET_RECENT_PLAYS';

export const GET_RECENT_PLAYS_LOADING = 'GET_RECENT_PLAYS_LOADING';
export const GET_RECENT_PLAYS_SUCCESS = 'GET_RECENT_PLAYS_SUCCESS';
export const GET_RECENT_PLAYS_FAILURE = 'GET_RECENT_PLAYS_FAILURE';

export const UPDATE_RECENT_PLAYS_FAVORITES = 'UPDATE_RECENT_PLAYS_FAVORITES';

export const RESET_RECENT_PLAYS = 'RESET_RECENT_PLAYS';

/* Add to recent plays */
export const ADD_TO_RECENT_PLAYS_LOADING = 'ADD_TO_RECENT_PLAYS_LOADING';
export const ADD_TO_RECENT_PLAYS_SUCCESS = 'ADD_TO_RECENT_PLAYS_SUCCESS';
export const ADD_TO_RECENT_PLAYS_FAILURE = 'ADD_TO_RECENT_PLAYS_FAILURE';
