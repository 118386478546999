// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../../config/i18n';

import * as pth from '../../../../../../helpers/proptypes';

import { CloseButton, CloseIcon } from './styles';

// =============================
// Component
// =============================

class PresearchModalCloseButton extends Component {
  static propTypes = {
    /** Config */
    config: pth.config.isRequired,
    /** Callback function use to close Modal. */
    onClose: PropTypes.func,
    /** If True, a Presearch modal is opened. */
    opened: PropTypes.bool.isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onClose: null,
  };

  showCloseButton = () => {
    const { config } = this.props;

    const minimized = !_get(config, 'customisations.preSearch.fullpage', false);

    return !minimized;
  };

  render() {
    const { onClose, opened, t } = this.props;

    if (!this.showCloseButton() || !onClose || !opened) return null;

    return (
      <CloseButton onClick={onClose}>
        {t('components:modal.close')}
        <CloseIcon />
      </CloseButton>
    );
  }
}

export default withTranslation('components')(PresearchModalCloseButton);
