// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import pose from 'react-pose';
import { Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';

import { svgHoverCirclePath } from '../../../../helpers/front/svg';

import { headerHeight } from '../../header/desktop/variables';

// =============================
// Styles
// =============================

export const Wrapper = pose(styled.div`
  align-items: center;
  background-color: ${({ theme, noFade }) => (noFade ? theme.colors.background : alpha(theme.colors.background, 0.9))};
  bottom: 0;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};


  ${({ isSearch }) => isSearch
    && css`
      z-index: -1;
    `}

  ${({ opened }) => (opened
    ? css`
          pointer-events: auto;
        `
    : css`
          opacity: 0; /** Fix SSR render */
          pointer-events: none;
        `)};
`)({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 300,
      duration: 500,
    },
  },
});

export const CloseButton = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  position: fixed;
  right: 3.2rem;
  top: 1.7rem;
  user-select: none;
`;

export const CloseIcon = styled(Icons.CrossOutline)`
  margin-left: 1.6rem;
  width: 4.5rem;

  ${({ theme }) => css`
    ${svgHoverCirclePath(theme.colors.text, theme.colors.background, false)};
  `};
`;

export const Content = pose(styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${({ wideContent }) => wideContent
    && css`
      height: 100%;
      padding-top: ${headerHeight};
      width: 100%;
    `};
`)({
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
});
