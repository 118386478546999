// =============================
// Imports
// =============================

import {
  required,
  composeValidators,
  mustBeEmail,
  minLength,
  mustBeComplexPassword,
  mustBeEqualTo,
} from '../index';

// =============================
// Validator
// =============================

const validator = {
  firstName: required,
  lastName: required,
  email: composeValidators(required, mustBeEmail),
  phoneNumber: required,
  currentPassword: composeValidators(required, minLength(8), mustBeComplexPassword),
  password: composeValidators(required, minLength(8), mustBeComplexPassword),
  passwordConfirmation: (compareVal, fieldName) => composeValidators(
    required,
    minLength(8),
    mustBeComplexPassword,
    mustBeEqualTo(compareVal, fieldName),
  ),
};

export default validator;
