// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import MusicPlayerDesktop from './desktop';
import MusicPlayerMobile from './mobile';

// =============================
// Component
// =============================

class MusicPlayer extends PureComponent {
  static displayName = 'MusicPlayerResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={MusicPlayerDesktop}
        MobileComponent={MusicPlayerMobile}
        props={this.props}
      />
    );
  }
}

export default MusicPlayer;
