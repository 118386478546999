/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// =============================
// Helpers
// =============================

/**
 * Checks if user has access to a private website
 * NOTE: This function is supposed to be used on reducer(i.e. in mapStateToProps)
 * @param {object} user - User store
 * @param {object} config - Config store
 * @return {boolean} - Returns true if user has access
 */
export const hasAccessToPrivate = (user, config) => !_get(config, 'data.privateAccess', true)
  || (_get(user, 'isLogged', false) && _get(user, 'data.canAccess', false));
