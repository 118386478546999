// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import pose from 'react-pose';
import { Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';

import { musicPlayerHeight } from '../../musicplayer/mobile/variables';

import { svgHoverCirclePath } from '../../../../helpers/front/svg';

// =============================
// Styles
// =============================

export const Wrapper = pose(styled.div`
  bottom: 0;
  color: ${({ theme }) => theme.colors.text};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  // Presearch must be under other modals in case modals such as
  // trackVersions or musicPlayer are opened while on presearch
  z-index: ${({ theme, name }) => (name === 'presearch' ? theme.zIndex.modal - 1 : theme.zIndex.modal)};

  ${props => ((props.name === 'presearch') && (props.playerIsOpened)) && css`
    bottom: ${musicPlayerHeight};
  `};

  ${({ bottomCloseBtn }) => bottomCloseBtn && css`
    padding-bottom: 6rem;
  `};

  ${({ noBackground, noFade, theme }) => !noBackground && css`
    backdrop-filter: blur(8px);
    background-color: ${(noFade ? theme.colors.background : alpha(theme.colors.background, 0.8))};
  `};

  ${({ noTopMask }) => !noTopMask && css`
    &:after {
      background-color: ${({ theme, noFade }) => (noFade ? theme.colors.background : alpha(theme.colors.background, 0.9))};
      content: "";
      display: block;
      flex-shrink: 0;
      height: 6rem;
      left:0;
      mask-image: linear-gradient(0deg, transparent 0%, #fff 6rem, #fff);
      mask-mode: luminance;
      mask-repeat: repeat-x;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  `};

  ${({ isSearch }) => isSearch
    && css`
      z-index: -1;
    `}

  ${({ opened }) => (opened
    ? css`
          pointer-events: auto;
        `
    : css`
          opacity: 0; /** Fix SSR render */
          pointer-events: none;
        `)};
`)({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 300,
      duration: 500,
    },
  },
});

export const CloseButton = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  position: fixed;
  right: 3.2rem;
  top: 1.7rem;
  user-select: none;
`;

export const CloseIcon = styled(Icons.CrossOutline)`
  margin-left: 1.6rem;
  width: 4.5rem;

  ${({ theme }) => css`
    ${svgHoverCirclePath(theme.colors.text, theme.colors.background, false)};
  `};
`;

export const Content = pose(styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ${({ noBottomFade }) => !noBottomFade && css`
    mask-image: linear-gradient(0deg, transparent 0%, #fff 6rem, #fff);
    mask-mode: luminance;
    mask-repeat: repeat-x;

    /** Use to counter balance with the mask-image **/
    &:after {
      content: "";
      display: block;
      height: 6rem;
      width: 100%;
    }
  `};
`)({
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
});

export const CloseModalBtn = styled.div`
  align-items: flex-start;
  bottom: 0;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  flex-shrink: 0;
  height: 6rem;
  justify-content: center;
  left: 0;
  margin-top: auto;
  padding-top: 0.8rem;
  position: absolute;
  width: 100%;
  z-index: 1;

  &:after {
    background: linear-gradient(
      0deg,
      ${({ theme }) => theme.colors.background} 0%,
      ${({ theme }) => alpha(theme.colors.background, 0)} 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    margin-left: calc(-50vw + 50%);
    top: 0;
    width: 100vw;
    z-index: -1;
  }
`;
