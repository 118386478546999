// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledScrollBar } from './styles';

// =============================
// Component
// =============================

class Scrollbar extends Component {
  static propTypes = {
    /** Props used by styled component to override styles. */
    className: PropTypes.string,
    /** If True, no scrollbar will show */
    noScrollBar: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    noScrollBar: false,
  };

  render() {
    const { className, noScrollBar, ...rest } = this.props;

    return <StyledScrollBar className={className} noScrollBar={noScrollBar} {...rest} />;
  }
}

export default Scrollbar;
