// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { DropdownContext, Icons } from '@mewo/components';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'next/router';

import withResponsive from '../../hoc/withResponsive';
import { withTranslation } from '../../../config/i18n';

import transformNotifications from '../../../helpers/notifications';
import * as pth from '../../../helpers/proptypes';

import Notification from './notification';

import { Header, Title, MarkAllRead, Content, NoNotification, StyledDropdown } from './styles';

// =============================
// Component
// =============================

class NotificationManager extends PureComponent {
  static propTypes = {
    /** Props used by styled-component to override style */
    className: PropTypes.string,
    /** Function used to get notifications */
    getUserNotifications: PropTypes.func.isRequired,
    /** Notifications store */
    hasMoreNotifications: PropTypes.bool.isRequired,
    /** If True, user is on a mobile device. */
    isMobile: PropTypes.bool.isRequired,
    /** Function used to mark all notifications as read */
    markAllRead: PropTypes.func.isRequired,
    /** Notifications store */
    notifications: PropTypes.arrayOf(pth.userNotification).isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Router object */
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    className: '',
  };

  static Notification = Notification;

  constructor(props) {
    super(props);

    this.closeDropdown = null;
  }

  componentDidUpdate(prevProps) {
    const { router: { asPath: prevAsPath } } = prevProps;
    const { router: { asPath: nextAsPath } } = this.props;

    if (
      prevAsPath !== nextAsPath
      && this.closeDropdown
      && typeof this.closeDropdown === 'function'
    ) {
      this.closeDropdown();
    }
  }

  handleMarkAllRead = (closeDropdown) => {
    const { markAllRead } = this.props;

    closeDropdown();
    markAllRead();
  };

  render() {
    const {
      className,
      getUserNotifications,
      hasMoreNotifications,
      isMobile,
      notifications,
      t,
    } = this.props;

    return (
      <StyledDropdown
        className={className}
        placement="bottom-start"
        header={(
          <DropdownContext.Consumer>
            {({ closeDropdown }) => {
              this.closeDropdown = closeDropdown;

              return (
                <Header>
                  <Title>{t('components:notification_manager.notifications')}</Title>
                  {!!notifications.find(n => !n.seen) && (
                    <MarkAllRead onClick={() => this.handleMarkAllRead(closeDropdown)}>
                      {t('components:notification_manager.mark_all_read')}
                    </MarkAllRead>
                  )}
                </Header>
              );
            }}
          </DropdownContext.Consumer>
        )}
        togglerElement={
          <Icons.NotificationBell hasNotifications={!!notifications.find(n => !n.seen)} />
        }
      >
        <InfiniteScroll
          hasMore={hasMoreNotifications}
          initialLoad={!isMobile}
          loadMore={getUserNotifications}
          threshold={250}
          useWindow={false}
        >
          <Content>
            {transformNotifications(notifications).map(notification => (
              <Notification
                date={notification.date}
                icon={notification.icon}
                key={notification.id}
                message={notification.message}
                seen={notification.seen}
              />
            ))}

            {!notifications.length && (
              <NoNotification>
                {t('components:notification_manager.no_notifications')}
              </NoNotification>
            )}
          </Content>
        </InfiniteScroll>
      </StyledDropdown>
    );
  }
}

export default compose(
  withRouter,
  withResponsive,
  withTranslation(['components']),
)(NotificationManager);
