// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  notifications: {
    isFetching: false,
    isChecking: false,
    page: 0,
    nbPages: 0,
    data: [],
    total: 0,
  },
};

// =============================
// Reducer
// =============================

export default function user(state = initialState, action) {
  switch (action.type) {
    /* User notifications */
    case acts.GET_USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isFetching: true,
        },
      };

    case acts.GET_USER_NOTIFICATIONS_SUCCESS:
    case acts.GET_USER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isFetching: false,
        },
      };

    case acts.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
          data: [
            ...state.notifications.data,
            ...action.payload.data,
          ],
        },
      };

    case acts.SET_LATEST_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.payload,
        },
      };

    case acts.CHECK_USER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isChecking: true,
        },
      };

    case acts.CHECK_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isChecking: false,
          data: state.notifications.data.map(notification => ({
            ...notification,
            seen: true,
          })),
        },
      };

    case acts.CHECK_USER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isChecking: false,
        },
      };

    case acts.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        notifications: initialState.notifications,
      };

    default:
      return state;
  }
}
