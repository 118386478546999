// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import ModalTrackArtists from '../../../layouts/modals/trackartists';

import {
  setTrackArtistsOpen,
  setMusicItemOpen,
  setMusicPlayerOpen,
} from '../../../../store/actions/ModalsActions';

// =============================
// Component
// =============================

function mapStateToProps({ modals }) {
  return {
    opened: modals.trackArtists.isOpen,
    track: modals.trackArtists.data,
  };
}

export default connect(mapStateToProps, {
  closeTrackArtistsModal: () => setTrackArtistsOpen(false),
  closeMusicItemModal: () => setMusicItemOpen(false),
  closeMusicPlayerModal: () => setMusicPlayerOpen(false),
})(ModalTrackArtists);
