// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  albums: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
  playlists: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
  },
};

// =============================
// Reducer
// =============================

export default function suggested(state = initialState, action) {
  switch (action.type) {
    case acts.SET_SUGGESTED_ALBUMS:
      return {
        ...state,
        albums: {
          ...state.albums,
          data: action.payload,
        },
      };

    case acts.GET_SUGGESTED_ALBUMS_LOADING:
      return {
        ...state,
        albums: {
          ...state.albums,
          isFetching: true,
        },
      };

    case acts.GET_SUGGESTED_ALBUMS_SUCCESS:
      return {
        ...state,
        albums: {
          ...state.albums,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_SUGGESTED_ALBUMS_FAILURE:
      return {
        ...state,
        albums: {
          ...state.albums,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    case acts.SET_SUGGESTED_PLAYLISTS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          data: action.payload,
        },
      };

    case acts.GET_SUGGESTED_PLAYLISTS_LOADING:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetching: true,
        },
      };

    case acts.GET_SUGGESTED_PLAYLISTS_SUCCESS:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_SUGGESTED_PLAYLISTS_FAILURE:
      return {
        ...state,
        playlists: {
          ...state.playlists,
          isFetchedOnce: false,
          isFetching: false,
        },
      };

    default:
      return state;
  }
}
