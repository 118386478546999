// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip } from '@mewo/components';

import Button from '../../../../../presentationals/button';
import Filters from '../../../../../containers/filters';

// =============================
// Styles
// =============================

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 2rem;
`;

export const StyledFilters = styled(Filters)`
  margin-bottom: 3.2rem;

  &:not(:empty) {
    margin-bottom: 5rem;
  }
`;

export const Tag = styled(Chip)`
  margin: 0.4rem 0.6rem 0.6rem 0;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.7;
    `};
`;

export const SeeResultButton = styled(Button)`
  margin-left: auto;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -0.3rem;
  padding: 0 12rem;

  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }
`;

export const Tabs = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.text};
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const Tab = styled.div`
  align-items: center;
  border-bottom: 0.2rem solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  justify-content: flex-start;
  height: 4rem;
  margin-right: 1.2rem;
  user-select: none;

  ${({ active, theme }) => active
    && css`
      border-bottom: 0.2rem solid ${theme.colors.text};
      cursor: default;
    `};

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 2rem;
  `};

  ${({ theme }) => theme.mediaAbove.uxga`
    margin-right: 4rem;
  `};
`;

export const TabCounter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  flex-shrink: 0;
  font-size: 1.1rem;
  height: 2.2rem;
  justify-content: center;
  margin-left: 0.8rem;
  width: 2.2rem;
`;
