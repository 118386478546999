// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import { WrapIfDesktop } from '../../../other/responsive';

import {
  getUserPlaylistName,
  getUserPlaylistDesc,
  getPlaylistTracksCoversUrl,
} from '../../../../helpers/entity/playlist';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  StyledScrollBar,
  Title,
  TitleWrapper,
  TextWithLineBreaks,
} from '../styles';

// =============================
// Stories
// =============================

class ModalUserPlaylist extends PureComponent {
  static propTypes = {
    /** Function used to close modal */
    closeUserPlaylistDescModal: PropTypes.func.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** User playlist */
    playlist: pth.userplaylist,
  };

  static defaultProps = {
    opened: false,
    playlist: null,
  };

  render() {
    const { closeUserPlaylistDescModal, opened, playlist } = this.props;

    if (!playlist) return null;

    const description = getUserPlaylistDesc(playlist);

    return (
      <DefaultModal
        bottomCloseBtn
        name="userPlaylist"
        onClose={closeUserPlaylistDescModal}
        opened={opened}
        wideContent
      >
        <Wrapper>
          <Informations>
            <ModalCover
              lazyload={false}
              placeholderType="playlist"
              src={getPlaylistTracksCoversUrl(playlist, 'large', 'small')}
              type="playlist"
              alt={getUserPlaylistName(playlist)}
            />
          </Informations>
          <Content>
            <WrapIfDesktop Wrapper={<StyledScrollBar />}>
              <TitleWrapper>
                <Title>{getUserPlaylistName(playlist)}</Title>
              </TitleWrapper>
              <TextWithLineBreaks>{description}</TextWithLineBreaks>
            </WrapIfDesktop>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalUserPlaylist);
