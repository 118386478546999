// =============================
// Imports
// =============================

import { cloneElement, isValidElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// =============================
// Helpers
// =============================

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  return isMobile ? children : null;
};

export const WrapIfDesktop = ({ Wrapper, children }) => {
  const shouldRender = useMediaQuery({ minWidth: 1024 });
  return shouldRender ? cloneElement(Wrapper, { children }) : children;
};

export const WrapIfMobile = ({ Wrapper, children }) => {
  const shouldRender = useMediaQuery({ maxWidth: 1023 });
  return shouldRender ? cloneElement(Wrapper, { children }) : children;
};

// =============================
// Components
// =============================

export const WrapperSwitcher = ({ DesktopComponent, MobileComponent, ...rest }) => {
  const shouldRenderMobile = useMediaQuery({ maxWidth: 1023 });

  if (shouldRenderMobile) {
    if (isValidElement(MobileComponent)) {
      return cloneElement(MobileComponent, { ...rest });
    }

    return <MobileComponent {...rest} />;
  }

  if (isValidElement(DesktopComponent)) {
    return cloneElement(DesktopComponent, { ...rest });
  }

  return <DesktopComponent {...rest} />;
};

WrapperSwitcher.propTypes = {
  DesktopComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]).isRequired,
  MobileComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
};

WrapperSwitcher.defaultProps = {
  props: {},
};

const Responsive = ({ DesktopComponent, MobileComponent, props }) => (
  <Fragment>
    {DesktopComponent && (
      <Desktop>
        <DesktopComponent {...props} />
      </Desktop>
    )}
    {MobileComponent && (
      <Mobile>
        <MobileComponent {...props} />
      </Mobile>
    )}
  </Fragment>
);

Responsive.propTypes = {
  DesktopComponent: PropTypes.elementType,
  MobileComponent: PropTypes.elementType,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object.isRequired,
};

Responsive.defaultProps = {
  DesktopComponent: null,
  MobileComponent: null,
};

export default Responsive;
