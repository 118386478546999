// =============================
// Imports
// =============================

// External Dependencies
import PubNub from 'pubnub';

// =============================
// Business
// =============================

const pubNub = { client: null };

export default function getClient(locationProtocol, userId, authKey) {
  if (!pubNub.client) {
    pubNub.client = new PubNub({
      subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
      ssl: locationProtocol === 'https:',
      uuid: userId,
      authKey,
    });
  }

  return pubNub.client;
}
