// =============================
// Imports
// =============================

// External dependencies
import _get from 'lodash/get';

// Actions
import {
  open as openPlayer,
  fulfillRequest as fulfillPlayerRequest,
  seek,
  getMedia,
  updateContextPosition,
} from '../actions/PlayerActions';
import { requestMusicPlayerOpen, setMusicPlayerOpen } from '../actions/ModalsActions';
import { addRecentlyPlayedTrack } from '../actions/RecentPlaysActions';

// Constants
import * as acts from '../constants/ActionTypes';
import * as requests from '../constants/PlayerRequests';
import * as playerCtx from '../constants/PlayerContexts';

// =============================
// Middleware
// =============================

const playerMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.SET_MEDIA_PLAYLIST:
    case action.type === acts.SET_SINGLE_MEDIA: {
      const trackId = _get(action.payload, 'current.id');

      if (trackId) {
        const { isLogged } = store.getState().user;

        if (isLogged && action.payload.contextName !== 'user-recents') {
          store.dispatch(addRecentlyPlayedTrack(trackId));
        }

        const { isOpened } = store.getState().player;

        if (!isOpened) {
          store.dispatch(openPlayer());
        }

        const { musicPlayer: { requestOpen } } = store.getState().modals;

        if (requestOpen) {
          store.dispatch(requestMusicPlayerOpen(false));
          store.dispatch(setMusicPlayerOpen(true, action.payload.current));
        }
      }

      return next(action);
    }

    case action.type === acts.PLAYER_REQUEST_SET_SEEKING_TIME:
    case action.type === acts.PLAYER_REQUEST_PLAY: {
      const { isOpened } = store.getState().player;

      if (!isOpened) {
        store.dispatch(openPlayer());
      }

      return next(action);
    }

    case action.type === acts.PLAYER_REQUEST_GO_TO_PREV_TRACK: {
      const { prev, contextName, contextId, contextPosition } = store.getState().player;

      if (prev) {
        store.dispatch(getMedia(null, contextName, contextId, contextPosition - 1));
      } else {
        store.dispatch(seek(0));
      }

      store.dispatch(fulfillPlayerRequest(requests.PREV_TRACK));

      return next(action);
    }

    case action.type === acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK: {
      const { next: nextTrack, contextName, contextId, contextPosition } = store.getState().player;

      if (nextTrack) {
        store.dispatch(getMedia(null, contextName, contextId, contextPosition + 1));
      }

      store.dispatch(fulfillPlayerRequest(requests.NEXT_TRACK));

      return next(action);
    }

    case action.type === acts.REORDER_TRACK_IN_PLAYLIST_SUCCESS: {
      const { current: currentTrack, contextName, contextId } = store.getState().player;

      if (
        currentTrack
        && contextName === playerCtx.USER_PLAYLIST
        && contextId === action.payload.playlistId
        && currentTrack.id === action.payload.trackId
      ) {
        store.dispatch(updateContextPosition(action.payload.nextPlayerPosition));
      }

      return next(action);
    }

    default:
      return next(action);
  }
};

export default playerMiddleware;
