// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import ArtistItemMobile from './mobile';

// =============================
// Component
// =============================

class ArtistItem extends PureComponent {
  static displayName = 'ArtistItemResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={null}
        MobileComponent={ArtistItemMobile}
        props={this.props}
      />
    );
  }
}

export default ArtistItem;
