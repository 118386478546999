// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Div } from '@mewo/components';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  cursor: pointer;
  max-height: 4rem;
  position: relative;
  user-select: none;
`;

export const Logo = styled.img`
  max-height: inherit;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: ${({ theme }) => theme.transition.medium};
  width: auto;
`;

export const VariantLogo = styled(Logo)`
  left: 0;
  position: absolute;
  top: 0;
`;

export const ModalLogo = styled(Logo)`
  left: 0;
  position: absolute;
  top: 0;
`;

export const FilterBarLogo = styled(Logo)`
  left: 0;
  position: absolute;
  top: 0;
`;
