// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import FilterBarDesktop from './desktop';
import FilterBarMobile from './mobile';

// =============================
// Component
// =============================

class FilterBar extends PureComponent {
  static displayName = 'FilterBarResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={FilterBarDesktop}
        MobileComponent={FilterBarMobile}
        props={this.props}
      />
    );
  }
}

export default FilterBar;
