// =============================
// Imports
// =============================

import styled from 'styled-components';
import SelectInput from '../../inputs/selectinput';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledSelectInput = styled(SelectInput)`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-width: 25rem;
  width: 60%;
`;
