// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import PresearchDesktop from './desktop';
import PresearchMobile from './mobile';

// =============================
// Component
// =============================

class Presearch extends PureComponent {
  static displayName = 'PresearchResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={PresearchDesktop}
        MobileComponent={PresearchMobile}
        props={this.props}
      />
    );
  }
}

export default Presearch;
