// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getTrackTitle, getTrackCoverUrl, getTrackAlbum } from '../../../../../helpers/entity/track';
import * as pth from '../../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  StyledScrollBar,
  TitleWrapper,
  Title,
  ActionButton,
  DownloadLink,
  TextWithLineBreaks,
} from '../../styles';

// =============================
// Stories
// =============================

class ModalTrackLyrics extends PureComponent {
  static displayName = 'ModalTrackLyricsDesktop';

  static propTypes = {
    /** Function used to close modal */
    closeTrackLyricsModal: PropTypes.func.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Track */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  generateData = () => {
    const { track } = this.props;

    const lyrics = _get(track, 'lyrics');

    return `data:text/plain;charset=utf-8,${encodeURIComponent(lyrics)}`;
  };

  render() {
    const { closeTrackLyricsModal, opened, t, track } = this.props;

    if (!track) return null;

    const lyrics = _get(track, 'lyrics');

    return (
      <DefaultModal
        bottomCloseBtn
        name="trackLyrics"
        onClose={closeTrackLyricsModal}
        opened={opened}
        wideContent
      >
        <Wrapper>
          <Informations>
            <ModalCover
              placeholderType="track"
              type="track"
              src={getTrackCoverUrl(track)}
              lazyload={false}
              alt={getTrackAlbum(track).title}
            />
          </Informations>
          <Content>
            <StyledScrollBar>
              <TitleWrapper>
                <Title withAction>{getTrackTitle(track)}</Title>
                <DownloadLink href={this.generateData()} download={getTrackTitle(track)}>
                  <ActionButton>{t('pages:entity.download_lyrics')}</ActionButton>
                </DownloadLink>
              </TitleWrapper>
              <TextWithLineBreaks>{lyrics}</TextWithLineBreaks>
            </StyledScrollBar>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('pages')(ModalTrackLyrics);
