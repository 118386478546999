// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Div, Icons, InputBase } from '@mewo/components';

import { headerHeight } from '../../header/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.presearch.background};
  box-shadow: 0 0.2rem 1.5rem 0 ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  height: ${headerHeight};
  left: 0;
  padding: 1.2rem;
  right: 0;
  top: 0;
  position: fixed;
  // Presearch must be under other modals in case modals such as
  // trackVersions or musicPlayer are opened while on presearch
  z-index: ${({ theme }) => theme.zIndex.modal - 1};
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 0;
  border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '1.5rem' : 'O.2rem')};
  color: ${({ error, theme }) => (error ? theme.colors.type.error : theme.colors.text)};
  display: flex;
  fill: ${({ error, theme }) => (error ? theme.colors.type.error : theme.colors.text)};
  height: 4.5rem;
  padding: 0 2rem;
  width: 100%;
`;

export const CancelBtn = styled(Icons.Close).attrs(() => ({
  width: '1.2rem',
}))`
  flex-shrink: 0;
  margin-left: 1.2rem;
`;

export const CloseBtn = styled.div`
  flex-shrink: 0;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-left: 1.2rem;
`;

export const SearchInput = styled.div`
  display: flex;
  flex: 1 1 auto;

  input {
    color: inherit;
    cursor: pointer !important;
    font-weight: 700;

    &::-webkit-input-placeholder {
      ${({ theme }) => theme.helpers.textEllipsis};
      font-style: italic;
      font-weight: 700;
    }

    &:-moz-placeholder {
      ${({ theme }) => theme.helpers.textEllipsis};
      font-style: italic;
      font-weight: 700;
    }

    &::-moz-placeholder {
      ${({ theme }) => theme.helpers.textEllipsis};
      font-style: italic;
      font-weight: 700;
    }

    &:-ms-input-placeholder {
      ${({ theme }) => theme.helpers.textEllipsis};
      font-style: italic;
      font-weight: 700;
    }
  }

  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }
`;

export const StyledInput = styled(InputBase)``;

export const SearchMusicIcon = styled(Icons.Search).attrs({
  width: '1.8rem',
})`
  flex-shrink: 0;
  margin-right: 1.2rem;
`;
