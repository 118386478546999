// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router, { withRouter } from 'next/router';
import isURL from 'validator/lib/isURL';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _some from 'lodash/some';

import { setPreSearchOpen } from '../../../../store/actions/ModalsActions';
import {
  setSearchValue as setSearchValueBase,
  getPreSearchData as getPreSearchDataBase,
  resetPreSearchData as resetPreSearchDataBase,
  requestMaiaTextSearch as requestMaiaTextSearchBase,
  setSearchInDescription as setSearchInDescriptionBase,
  requestMaiaAudioSearch as requestMaiaAudioSearchBase,
} from '../../../../store/actions/SearchActions';

import SearchBar from '../../../presentationals/searchbar';
import ModalPreSearch from '../../../containers/modals/presearch';

import { hasAccessToPrivate } from '../../../../helpers/user';
import { getEntitySearchUrl } from '../../../../helpers/entity';
import { composeQuery } from '../../../../helpers/search';
import * as pth from '../../../../helpers/proptypes';

// =============================
// Component
// =============================

class SearchBarModal extends Component {
  static displayName = 'SearchBarModalMobile';

  static propTypes = {
    /** Config data */
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      isVisible: PropTypes.bool,
    }),
    /** Search functions */
    getPreSearchData: PropTypes.func.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** true if presearch was already done */
    isFetchedOnce: PropTypes.bool.isRequired,
    /** If True, PreSearch modal is open. */
    isPreSearchOpen: PropTypes.bool.isRequired,
    /** isSimpleLayout */
    isSimpleLayout: PropTypes.bool.isRequired,
    /** Presearch data */
    preSearchDatas: pth.preSearchDatas.isRequired,
    requestMaiaTextSearch: PropTypes.func.isRequired,
    resetPreSearchData: PropTypes.func.isRequired,
    /** Router props */
    router: PropTypes.shape({
      asPath: PropTypes.string,
      route: PropTypes.string,
    }).isRequired,
    /** Search query */
    search: pth.searchquery.isRequired,
    /** Search query. */
    searchValue: PropTypes.string,
    /** Function used to change searchValue. */
    setSearchValue: PropTypes.func.isRequired,
    /** Toggle pre search modal */
    togglePreSearchModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
    searchValue: '',
  };

  handleFocus = () => {
    const { getPreSearchData, isFetchedOnce, searchValue } = this.props;

    if (searchValue && !isFetchedOnce) getPreSearchData(searchValue);
  };

  handleSearchOnChange = (value) => {
    const { getPreSearchData, resetPreSearchData, searchValue, setSearchValue } = this.props;

    const valueIsExternal = isURL(value);

    if (value && valueIsExternal) {
      // No need to fetch presearch data
      setSearchValue(value);
    } else if (value && value !== searchValue) {
      // If the value has changed and it is truthy
      setSearchValue(value);
      getPreSearchData(value);
    } else if (searchValue) {
      resetPreSearchData();
    }
  };

  handleSearchOnSubmit = () => {
    const {
      facets,
      isPreSearchOpen,
      preSearchDatas,
      requestMaiaTextSearch,
      router,
      search,
      searchValue,
      togglePreSearchModal,
    } = this.props;

    let nextRoute = '';

    // NOTE: When value is null and route is not within search, redirect to all tracks
    if (!searchValue && router.route !== '/search') {
      nextRoute = '/search';
    } else {
      // Check presearch column results
      const presearchColWithResults = [];

      Object.keys(preSearchDatas).forEach((key) => {
        if (_get(preSearchDatas, `${key}.total.value`) > 0) {
          presearchColWithResults.push(key);
        }
      });

      // If there is only one column left
      if (presearchColWithResults.length === 1) {
        const key = presearchColWithResults[0];

        if (key !== 'tags') {
          // For all columns except tags, go to "see list" page
          nextRoute = getEntitySearchUrl(key)(searchValue);
        } else if (_get(preSearchDatas, `${key}.total.value`) === 1) {
          // For tags, add tag to query when not used or remove it from query
          const tagId = _get(preSearchDatas, `${key}.data.0.id`);

          const filters = _get(search, 'options.filters', {});
          let tagFilters = _get(filters, 'tags', []);
          const otherFilters = _omit(filters, ['tags']);

          const tagAvailable = Object.keys(_get(facets, 'tags', {})).indexOf(tagId) !== -1;

          let changeRoute = true;

          if (tagFilters.indexOf(tagId) !== -1) {
            tagFilters = tagFilters.filter(tag => tag !== tagId);
          } else if (tagAvailable) {
            tagFilters.push(tagId);
          } else {
            changeRoute = false;
          }

          if (changeRoute) {
            nextRoute = `/search?${composeQuery(search, { tags: tagFilters, ...otherFilters })}`;
          }
        }
      }
    }

    if (nextRoute && nextRoute !== router.asPath) {
      return Router.push(nextRoute);
    }

    if (nextRoute && nextRoute === router.asPath && isPreSearchOpen) {
      return togglePreSearchModal(false);
    }

    if (!isURL(searchValue)) return null;
    return requestMaiaTextSearch(searchValue, searchValue);
  };

  handleSearchOnCancel = () => {
    const { resetPreSearchData, setSearchValue } = this.props;

    setSearchValue('');
    resetPreSearchData();
  };

  shouldRenderModalBackground = () => {
    const { config, preSearchDatas, searchValue } = this.props;

    const withGuidedSearch = _get(config, 'customisations.guidedSearch.active', false);

    return _some([
      withGuidedSearch,
      _get(config, 'customisations.guidedSearch.active', false),
      preSearchDatas.albums.data.length,
      preSearchDatas.artists.data.length,
      preSearchDatas.catalogs.data.length,
      preSearchDatas.labels.data.length,
      preSearchDatas.playlists.data.length,
      preSearchDatas.publishers.data.length,
      preSearchDatas.tracks.data.length,
      preSearchDatas.tags.data.length,
      searchValue !== '',
    ]);
  }

  render() {
    const {
      hasAccess,
      isPreSearchOpen,
      isSimpleLayout,
      searchValue,
      togglePreSearchModal,
    } = this.props;

    // Do not render when user has no access to website
    if (!hasAccess) return null;

    const isExternal = isURL(searchValue);

    return (
      <Fragment>
        {!isSimpleLayout && (
          <Fragment>
            <ModalPreSearch
              isExternal={isExternal}
              noBackground={!this.shouldRenderModalBackground()}
              searchValue={searchValue}
            />

            {/**
              Search bar needs to be under modalpresearch
             to be visible since they use the same zindex
             */}
            <SearchBar
              isPreSearchOpen={isPreSearchOpen}
              onChange={this.handleSearchOnChange}
              onCancel={this.handleSearchOnCancel}
              onClose={() => togglePreSearchModal(false)}
              onFocus={this.handleFocus}
              onSubmit={this.handleSearchOnSubmit}
              value={searchValue}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps({ modals, search, user, config }) {
  return {
    config: config.data,
    facets: search.tracksSearch.data.facets,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    isFetchedOnce: search.preSearch.isFetchedOnce,
    isPreSearchOpen: modals.isPreSearchOpen,
    preSearchDatas: search.preSearch.data,
    search: search.tracksSearch.query,
    searchValue: search.preSearch.searchValue,
  };
}

export default connect(mapStateToProps, {
  getPreSearchData: getPreSearchDataBase,
  requestMaiaAudioSearch: requestMaiaAudioSearchBase,
  requestMaiaTextSearch: requestMaiaTextSearchBase,
  resetPreSearchData: resetPreSearchDataBase,
  setSearchInDescription: setSearchInDescriptionBase,
  setSearchValue: setSearchValueBase,
  togglePreSearchModal: setPreSearchOpen,
})(withRouter(SearchBarModal));
