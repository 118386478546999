// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import { alpha } from '@material-ui/core';
import { Div, Flex, Icons, Waveform as UnstyledWaveform } from '@mewo/components';
import { motion } from 'framer-motion';

import { svgHoverCirclePath } from '../../../../helpers/front/svg';
import { isHoverPrimary } from '../../../../helpers/front/getHoverType';

import AddToPlaylist from '../../../other/addToPlaylist';
import Cover from '../../cover';
import Link from '../../../other/link';
import ShareButton from '../../../other/shareButton';
import Tag from '../../../other/tag';

import { ContainerSearchPaddingRight, DropdownButton } from '../../../layouts/entity.styles';

// =============================
// /!\        WARNING        /!\
// =============================

/**
 *
 * Things to know before changing anything.
 *
 * - If we have a onDelete prop then a Delete Icon is added to the list of Actions
 * - Different actions are displayed if user has access to website or not.
 *
 * - We are using styledContainerQuery for responsive, which is based on Wrapper
 * width. And which prevent us from interpolating variable inside a &:container mediaqueries.
 *
 * - Every column from the musicItem should be always aligned with the column in
 * the musicItem details (VersionMusicItem).
 *
 * - Title & Version Title should always be aligned
 * - Duration & Version Duration should always be aligned
 * - Version name & Version version name should always be aligned
 * -  Waveform & Waveform version should always be aligned
 * - Actions & Versions Actions should always be aligned
 *
 * I made multiple breakpoint with corresponding variable name
 *
 * 700px => varName70
 * 800px => varName80
 * 900px => varName90
 * 1000px => varName100
 * 1100px => varName110
 * 1200px => varName120
 * 1300px => varName130
 *
 * */

// =============================
// Notes
// =============================

/**
 * Note 1:
 * We need to set z-index to 1 because of the ToggleMusicItem
 * If we don't, ToggleMusicItem will be on top and will prevent
 * user to interact with component (like Tooltip). And we can't set ToggleMusicItem
 * z-index to -1 because we wouldn't be able to interact with it.
 * */

const fixToggleMusicItem = `
  position: relative;
  z-index: 1;
`;

// =============================
// Styles
// =============================

const musicItemHeight = '5.5rem';
const versionHeight = '4rem';

// Initial Width Values
const columnMarginRight = '1rem';
const titleAuthorsWidth = '14rem';
const durationWidth = '5rem';
const versionWidth = '11rem';
const actionsIconWidth = '2.8rem';

// ArrowDropdown & DeleteIcon have different width than other action's icon.
const arrowDropdownIconWidth = '3rem';
const deleteIconWidth = '1.8rem';
const deleteIconMarginLeft = '0.4rem'; // DeleteIcon is spaced from other actions.

// Responsive Width Values
const titleAuthorsWidth70 = '18rem';
const versionWidth70 = '14rem';

const titleAuthorsWidth80 = '26rem';
const versionWidth80 = '16rem';

// Width are reduced to allow waveform to be displayed.
const titleAuthorsWidth90 = '22rem';
const versionWidth90 = '15.5rem';
const waveformWidth90 = '17rem';

const titleAuthorsWidth100 = '22rem';
const waveformWidth100 = '20rem';

const titleAuthorsWidth110 = '24rem';
const waveformWidth110 = '30rem';

const titleAuthorsWidth120 = '34rem';

const titleAuthorsWidth130 = '38rem';

// Play / Pause icon
const controlsIconWidth = '3.5rem';

export const ToggleMusicItem = styled.div`
  height: ${musicItemHeight};
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;

export const StyledCover = styled(Cover).attrs({
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  placeholderType: 'track',
  size: musicItemHeight,
})`
  height: ${musicItemHeight};
  margin-right: 2rem;
`;

export const Column = styled(Flex)`
  height: ${musicItemHeight};
  flex-shrink: 0;
`;

export const TitleAuthors = styled(Column)`
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};

  /** If isVersionTitle True, it means the title is used for a Version name. */
  ${({ isVersionTitle }) => (isVersionTitle
    ? css`
          font-size: 1.3rem;
        `
    : css`
          font-size: 1.5rem;

          &:not(:last-child) {
            margin-bottom: 0.4rem;
          }
        `)};
`;

export const AuthorsList = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: 0.2rem;
  opacity: 0.7;
`;

export const Duration = styled(Column)`
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Versions = styled(Column)`
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const VersionCount = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-shrink: 0;
  font-size: 0.7em;
  height: 2rem;
  justify-content: center;
  margin-left: auto;
  min-width: 2rem;
  padding: 0.4rem;
`;

export const Waveform = styled(Column)`
  align-items: center;
`;

export const Actions = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  &:not(:last-child) {
    margin-right: auto;
  }

  ${({ alignRight }) => alignRight
    && css`
      justify-content: flex-end;
    `}

  /** Used to keep a nice spacing between icons. */
  > * {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  /**
    We only want button icon to hover,
    not icons that might be in a dropdown opened by the button.
  */
  ${({ isHeartIcon, noHover, theme }) => !noHover
    && (isHoverPrimary(theme.colors)
      ? css`
          > *:hover .opacityHover {
            svg path {
              fill: ${theme.colors.primary};
            }
          }
          > *:hover .opacityHoverHeart {
            svg path {
              fill: ${theme.colors.primary};
              fill-opacity: 1;
              stroke: ${theme.colors.primary};
            }
          }
        `
      : css`
          > *:hover .opacityHover {
            svg path {
              fill-opacity: 0.7;

              ${isHeartIcon
              && css`
                fill: ${theme.colors.text};
                fill-opacity: 1;
                stroke: ${theme.colors.text};

                /** We use opacity because the Heart SVG reacts strangely when we change his fill-opacity to 0.7. */
                opacity: 0.7;
              `};
            }
          }
          > *:hover .opacityHoverHeart {
            svg path {
              fill: ${theme.colors.text};
              fill-opacity: 1;
              stroke: ${theme.colors.text};
              /** We use opacity because the Heart SVG reacts strangely when we change his fill-opacity to 0.7. */
              opacity: 0.7;
            }
          }
        `)}
`;

const iconStyles = css``;

export const MagicWandIcon = styled(Icons.MagicWand).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  width: '1.8rem',
}))`
  ${iconStyles};
  ${fixToggleMusicItem}
`;

export const DownloadIcon = styled(Icons.Download).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  width: '2rem',
}))`
  ${iconStyles};
`;

export const AddToPlaylistIcon = styled(Icons.Plus).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  width: '2rem',
}))`
  ${iconStyles};
`;

export const AddToFavoriteIcon = styled(Icons.Heart).attrs(
  ({ checked = false, className, theme }) => ({
    className: `${className} opacityHoverHeart`,
    checked,
    fill: theme.colors.text,
    width: '2rem',
  }),
)`
  ${iconStyles};
`;

export const ArrowDropdownIcon = styled(Icons.ArrowDropdown).attrs(
  ({ className, opened, theme }) => ({
    className: `${className} opacityHover`,
    fill: theme.colors.text,
    dir: opened ? 'north' : 'south',
    width: arrowDropdownIconWidth,
  }),
)`
  ${iconStyles};
  height: ${arrowDropdownIconWidth};
  width: ${arrowDropdownIconWidth};
  top: -1px;

  svg {
    width: 1.7rem;
  }
`;

export const ShareIcon = styled(Icons.Share).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  width: '2rem',
}))`
  ${iconStyles};
`;

export const ThreeDotIcon = styled(Icons.ThreeDot).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  fill: theme.colors.text,
  noButton: true,
  width: '2rem',
}))`
  ${iconStyles};
`;

export const DeleteIcon = styled(Icons.TrashCan).attrs(({ className, theme }) => ({
  className: `${className} opacityHover`,
  backgroundColor: theme.colors.type.error,
  fill: theme.colors.text,
  width: deleteIconWidth,
}))`
  ${iconStyles};
  top: -1px;
`;

export const VersionColumn = styled(Column)`
  align-items: center;
  height: ${versionHeight};
`;

export const VersionRecentlyPlayedIcon = styled(Icons.Clock).attrs({
  width: '1.2rem',
})`
  position: absolute;
  top: calc(${versionHeight} / 2);
  transform: translate3d(0, -50%, 0);
  left: calc(-2rem - 1.2rem);
`;

/** Version name width = TitleAuthor - PlayIcon - Column Margin */
export const VersionName = styled(VersionColumn)``;

export const VersionDuration = styled(VersionColumn)``;

export const VersionVersions = styled(VersionColumn)``;

export const VersionWaveform = styled(VersionColumn)`
  ${({ hasAccess }) => !hasAccess && css`
    margin-right: calc((${arrowDropdownIconWidth} - ${columnMarginRight}) * -1) !important;
  `};
`;

export const VersionActions = styled(VersionColumn)`
  justify-content: flex-end;
  margin-left: auto;

  ${({ hasAccess }) => !hasAccess && css`
    margin-right: ${arrowDropdownIconWidth} !important;
  `};

  ${({ hasDeleteIcon }) => hasDeleteIcon && css`
    &:after {
      background: transparent;
      content: '';
      display: block;
      height: 1rem;
      width: calc(${deleteIconWidth} + ${deleteIconMarginLeft});
    }
  `};

  /** Used to keep a nice spacing between icons. */
  > * {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  /**
    We only want button icon to hover,
    not icons that might be in a dropdown opened by the button.
  */
  ${({ isHeartIcon, noHover, theme }) => !noHover
    && (isHoverPrimary(theme.colors)
      ? css`
          > *:hover .opacityHover {
            svg path {
              fill: ${theme.colors.primary};
            }
          }
          > *:hover .opacityHoverHeart {
            svg path {
              fill: ${theme.colors.primary};
              fill-opacity: 1;
              stroke: ${theme.colors.primary};
            }
          }
        `
      : css`
          > *:hover .opacityHover {
            svg path {
              fill-opacity: 0.7;

              ${isHeartIcon
              && css`
                fill: ${theme.colors.text};
                fill-opacity: 1;
                stroke: ${theme.colors.text};

                /** We use opacity because the Heart SVG reacts strangely when we change his fill-opacity to 0.7. */
                opacity: 0.7;
              `};
            }
          }
          > *:hover .opacityHoverHeart {
            svg path {
              fill: ${theme.colors.text};
              fill-opacity: 1;
              stroke: ${theme.colors.text};
              /** We use opacity because the Heart SVG reacts strangely when we change his fill-opacity to 0.7. */
              opacity: 0.7;
            }
          }
        `)};
`;

export const DetailsColumn = styled(Column)`
  display: block;
  height: auto;
  min-height: 0;

  ${({ fullWidth }) => {
    if (fullWidth) {
      return css`
        width: 100%;
      `;
    }

    return null;
  }};
`;

export const AlbumColumn = styled(DetailsColumn)``;

export const YearColumn = styled(DetailsColumn)``;

export const LabelColumn = styled(DetailsColumn)``;

export const PublisherColumn = styled(DetailsColumn)``;

export const BPMColumn = styled(DetailsColumn)``;

export const PRSColumn = styled(DetailsColumn)``;

export const ArtistColumn = styled(DetailsColumn).attrs({
  fullWidth: true,
})``;

export const ArtistRole = styled.span`
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export const Tags = styled.div`
  font-size: 1rem;
  padding: 0.8rem 0;
  width: 100%;

  &:empty {
    display: none;
  }
`;

export const StyledTag = styled(Tag)`
  min-height: 2.2rem;
`;

export const Description = styled.div`
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5rem;
  padding: 0.8rem 0;
  width: 100%;

  &:empty {
    display: none;
  }
`;

export const MusicItemWrapper = styled(Flex)`
  justify-content: flex-start;
  font-size: 1.4rem;
`;

export const DetailsAnimation = styled(motion.div).attrs(({ theme }) => ({
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants: {
    enter: {
      height: 'auto',
      overflow: 'hidden',
      transition: {
        duration: theme.transition.numeric.medium,
        ease: theme.transitionTiming.pose.easeInOut,
        staggerChildren: 0.05,
        delayChildren: 0.1,
      },
      transitionEnd: {
        overflow: 'visible',
      },
    },
    exit: {
      height: 0,
      overflow: 'hidden',
      transition: {
        duration: theme.transition.numeric.medium,
        ease: theme.transitionTiming.pose.easeInOut,
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: 'afterChildren',
      },
    },
  },
}))``;

// NOTE: For some reason, from framer-motion 4.X version onwards
// having the props `initial` and `exit` here prevents parent from
// propagating the `animate` property. By removing them, it seems that
// this inherits the parent `initial` and `exit` properties while receiving
// the `animate` proprety through propagation
const DetailsItemAnimation = {
  variants: {
    enter: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.4,
        ease: [0.64, 0.04, 0.35, 1],
      },
    },
    exit: {
      x: 20,
      opacity: 0,
      transition: {
        type: 'tween',
        duration: 0.4,
        ease: [0.64, 0.04, 0.35, 1],
      },
    },
  },
};

export const VersionTitle = styled(motion.div).attrs(DetailsItemAnimation)`
  /** We use fade because we are already animating opacity with motion. */
  color: ${({ theme }) => alpha(theme.colors.text, 0.7)};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 2rem;
  width: 100%;
`;

export const DetailsWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Details = styled(motion.div).attrs(DetailsItemAnimation)`
  display: grid;
  grid-auto-flow: column;
  /** We want our column to be 200px wide at maximum. So if we only have 2 columns, they will be aligned to the left. */
  grid-auto-columns: minmax(0, 20rem);
  height: auto;
  min-height: 0;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  &:first-of-type {
    margin-top: 3rem;
  }

  &:empty {
    display: none;
  }

  ${({ fullWidth }) => fullWidth
    && css`
      grid-auto-columns: minmax(0, 1fr);
    `};
`;

export const VersionMusicItem = styled(motion.div).attrs(DetailsItemAnimation)`
  align-items: center;
  display: flex;
  height: 4rem;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 1.5rem;
  }

  > * {
    height: 4rem;
  }
`;

export const DetailsTitle = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.4rem;
  opacity: 0.7;
  overflow-wrap: break-word;
`;

export const DetailsValue = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  line-height: 1.8rem;
  overflow-wrap: break-word;
`;

export const PlayIcon = styled(Icons.PlayOutline).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: controlsIconWidth,
}))`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: controlsIconWidth,
}))`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const StyledTooltip = styled.span.attrs(({ content }) => ({
  title: content,
}))`
  position: relative;

  ${({ fullWidth }) => fullWidth
    && css`
      width: 100%;
    `};

  ${({ withCounter }) => withCounter
    && css`
      margin-right: 0.8rem;
    `};
`;

export const StyledLink = styled(Link)`
  ${fixToggleMusicItem};
  display: inline;
  /** Used for text-ellipsis. */
  max-width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledShareButton = styled(ShareButton).attrs({
  placement: 'bottom-end',
})``;

export const StyledAddToPlaylist = styled(AddToPlaylist).attrs({
  placement: 'bottom-end',
})``;

export const StyledDropdownButton = styled(DropdownButton).attrs({
  placement: 'bottom-end',
})``;

export const MusicItemWaveform = styled(UnstyledWaveform).attrs(({ theme }) => ({
  color: theme.colors.primary,
  height: '4rem',
  width: '100%',
}))``;

export const OutsideInfo = styled.div`
  align-items: center;
  display: flex;
  height: ${musicItemHeight};
  left: -2rem;
  position: absolute;
  transform: translate3d(-100%, 0, 0);
  top: 0;

  > *:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

export const RecentlyPlayedIcon = styled(Icons.Clock).attrs({
  width: '1.4rem',
})``;

export const Synchro = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
`;

export const SynchroBar = styled.div`
  background-color: ${({ filled, theme }) => (filled ? theme.colors.text : alpha(theme.colors.text, 0.3))};
  height: 0.1rem;
  width: 1rem;

  &:not(:last-child) {
    margin-top: 0.2rem;
  }
`;

export const Wrapper = styledContainerQuery(Div)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  padding-bottom: 1rem;
  position: relative;

  &:before {
    display: block;
    background: ${({ theme }) => alpha(theme.colors.text, 0.1)};
    content: '';
    height: calc(100% + 1rem);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity ${({ theme }) => theme.transition.medium};
    width: 100vw;

    ${({ isSearchPage }) => (isSearchPage
    ? css`
            right: 0;
            transform: translate3d(${ContainerSearchPaddingRight}, -1rem, 0);
          `
    : css`
          left: 50%;
          transform: translate3d(-50%, -1rem, 0);
        `)};

  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.1)};
    margin-bottom: 1rem;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  ${Column} {
    &:not(:last-child) {
      margin-right: ${columnMarginRight};
    }
  }

  ${TitleAuthors} {
    width: ${titleAuthorsWidth};
  }
  ${VersionName} {
    /** Calc needed to keep TitleAuthors & VersionName aligned. */
    width: calc(${titleAuthorsWidth} - ${controlsIconWidth} - ${columnMarginRight});
  }

  ${Duration},
  ${VersionDuration} {
    width: ${durationWidth};
  }
  ${Versions},
  ${VersionVersions} {
    width: ${versionWidth};
  }
  ${Waveform},
  ${VersionWaveform} {
    display: none;
  }

  ${Actions} {
    width: auto;

    > * {
      height: ${actionsIconWidth};
      width: ${actionsIconWidth};
    }
  }
  ${VersionActions} {
    width: auto;
    margin-left: auto;

    /** Used to keep VersionActions aligned with Actions. */
    margin-right: ${arrowDropdownIconWidth};

    /** Used to keep a nice spacing between icons. */
    > * {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: ${actionsIconWidth};
      width: ${actionsIconWidth};
    }
  }

  ${DeleteIcon} {
    margin-left: ${deleteIconMarginLeft};
    width: ${deleteIconWidth}
  }

  &:container(min-width: 700px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth70};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth70} - ${controlsIconWidth} - ${columnMarginRight});
    }

    ${Versions},
    ${VersionVersions} {
      width: ${versionWidth70};
    }
  }

  &:container(min-width: 800px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth80};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth80} - ${controlsIconWidth} - ${columnMarginRight});
    }

    ${Versions},
    ${VersionVersions} {
      width: ${versionWidth80};
    }
  }

  &:container(min-width: 900px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth90};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth90} - ${controlsIconWidth} - ${columnMarginRight});
    }

    ${Versions},
    ${VersionVersions} {
      width: ${versionWidth90};
    }
    ${Waveform},
    ${VersionWaveform} {
      display: flex;
      margin-left: auto;
      width: ${waveformWidth90};
    }
    ${DeleteIcon} {
      margin-left: ${deleteIconMarginLeft};
    }
  }

  &:container(min-width: 1000px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth100};
    }

    ${VersionName} {
      width: calc(${titleAuthorsWidth100} - ${controlsIconWidth} - ${columnMarginRight});
    }

    ${Waveform},
    ${VersionWaveform} {
      width: ${waveformWidth100};
    }
  }

  &:container(min-width: 1100px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth110};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth110} - ${controlsIconWidth} - ${columnMarginRight});
    }
    ${Waveform},
    ${VersionWaveform} {
      width: ${waveformWidth110};
    }
  }

  &:container(min-width: 1200px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth120};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth120} - ${controlsIconWidth} - ${columnMarginRight});
    }
  }

  &:container(min-width: 1300px) {
    ${TitleAuthors} {
      width: ${titleAuthorsWidth130};
    }
    ${VersionName} {
      width: calc(${titleAuthorsWidth130} - ${controlsIconWidth} - ${columnMarginRight});
    }
  }
`;
