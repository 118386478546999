// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import AnimationFrame from 'animation-frame';

// =============================
// Component
// =============================

class withCurrentTime extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    /** Get current time function */
    getCurrentTime: PropTypes.func,
    /** 0 for stopped, 1 for paused, 2 for playing */
    status: PropTypes.oneOf([0, 1, 2]).isRequired,
  };

  static defaultProps = {
    getCurrentTime: () => null,
  };

  state = {
    currentTime: 0,
  };

  constructor(props) {
    super(props);
    this.animationFrame = undefined;
    this.animationFrameId = undefined;
  }

  componentDidMount() {
    const { status } = this.props;

    if (status === 2) {
      this.startAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    const { status } = this.props;

    // Going from stopped or paused to playing
    if (prevProps.status !== 2 && status === 2) {
      this.startAnimation();
    }

    // Going from playing to paused or stopped
    if (prevProps.status === 2 && status !== 2) {
      this.stopAnimation();

      // Going from playing to stopped
      if (status === 0) {
        this.resetCurrentTime();
      }
    }

    // Going from paused to stopped
    if (prevProps.status === 1 && status === 0) {
      this.resetCurrentTime();
    }
  }

  componentWillUnmount() {
    this.stopAnimation();
  }

  resetCurrentTime = () => {
    this.setState({ currentTime: 0 });
  };

  startAnimation = () => {
    const { getCurrentTime } = this.props;

    if (!this.animationFrame) {
      this.animationFrame = new AnimationFrame(60);
    }

    this.animationFrameId = this.animationFrame.request(() => {
      if (getCurrentTime && getCurrentTime() >= 0) {
        this.setState({ currentTime: getCurrentTime() });
      }

      this.startAnimation();
    });
  };

  stopAnimation = () => {
    if (this.animationFrameId) {
      this.animationFrame.cancel(this.animationFrameId);
      this.animationFrameId = undefined;
    }

    this.animationFrame = undefined;
  };

  render() {
    const { children } = this.props;
    const { currentTime } = this.state;

    return children(currentTime);
  }
}

export default withCurrentTime;
