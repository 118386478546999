// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { Icons } from '@mewo/components';

import Link from '../../../other/link';

// =============================
// Component
// =============================

export const Content = styled.div`
  align-self: flex-end;
  background: ${({ theme }) => theme.colors.dropdown.background};
  border-radius: 2rem 2rem 0 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.dropdown.text};
  min-height: 60%;
  max-height: 75%;
  overflow-y: scroll;
  padding: 2rem;
  position: absolute;
  width: 100%;
`;

const itemStyles = css`
  align-items: center;
  display: flex;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  height: 5rem;
  justify-content: space-between;
  line-height: 2rem;
  user-select: none;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.dropdown.text, 0.1)};
  }
`;

export const Item = styled.div`
  ${itemStyles};
`;

export const LinkItem = styled(Link)`
  ${itemStyles};
`;

export const ItemArrow = styled(Icons.ArrowDropdown).attrs(() => ({
  dir: 'east',
  width: '1.6rem',
}))``;
