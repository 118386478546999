// =============================
// Imports
// =============================

import { Component } from 'react';
import _pick from 'lodash/pick';

import LanguageSwitcher from '../../containers/languageSwitcher';

import { Wrapper, EnvViewer } from './styles';

// =============================
// Component
// =============================

class MewoDevTools extends Component {
  state = {
    enabled: false,
  };

  componentDidMount() {
    this.setState(() => ({ enabled: true }));
  }

  render() {
    const { enabled } = this.state;
    if (!enabled) return false;

    if (process.env.APP_ENV === 'prod') return null;

    const configs = _pick(process.env, [
      'APP_ENV',
      'PORT',
      'API_URL',
      'GOOGLE_API_KEY',
      'SENTRY_DSN',
      'GA_CODE',
      'FS_CODE',
      'PUBNUB_SUBSCRIBE_KEY',
      'NODE_ENV',
    ]);

    return (
      <Wrapper>
        <LanguageSwitcher languages={['en', 'fr', 'es', 'de']} />
        <EnvViewer>{JSON.stringify(configs, 0, 2)}</EnvViewer>
      </Wrapper>
    );
  }
}
export default MewoDevTools;
