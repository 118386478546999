// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import pose from 'react-pose';
import { alpha } from '@material-ui/core';
import { Icons } from '@mewo/components';

import { svgHoverCirclePath } from '../../../../../../helpers/front/svg';

import { headerHeight } from '../../../../../presentationals/header/desktop/variables';
import { titleSearchBarHeight } from '../../../../../presentationals/modules/fullSizeCover/variables';

// =============================
// Styles
// =============================

export const Wrapper = pose(styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.scPresearch};

  ${({ opened }) => (opened
    ? css`
      pointer-events: auto;
    `
    : css`
      opacity: 0; /** Fix SSR render */
      pointer-events: none;
    `)};
`)({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      delay: 300,
      duration: 500,
    },
  },
});

export const CloseButton = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  height: 4.5rem;
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  position: fixed;
  right: 3.2rem;
  top: calc(${headerHeight} + 3rem);
  user-select: none;
  z-index: ${({ theme }) => theme.zIndex.scPresearchCloseButton};
`;

export const CloseIcon = styled(Icons.CrossOutline)`
  margin-left: 1.6rem;
  width: 4.5rem;

  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const Content = pose(styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: ${headerHeight};
  transition: 300ms;
  width: 100%;

  ${({ fullSizeCoverModule }) => fullSizeCoverModule?.isVisible && css`
    /**
      We divide titleSearchBarHeight by 2 because TitleSearch is set to translateY -50%
      to be centered.
    */
    padding-top: calc((100vh - 80vh) + (${titleSearchBarHeight} / 2) + 3.2rem);
  `};
`)({
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
});

export const Overlay = styled.div`
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;

  ${({ minimized, theme }) => (minimized
    ? css`
      background-color: ${alpha(theme.colors.background, 0.8)};

      &:hover {
        background-color: ${alpha(theme.colors.background, 0.8)};
      }
    `
    : css`
      background-color: ${theme.colors.background};
    `)};

    ${({ fullSizeCoverModule }) => fullSizeCoverModule?.isVisible && css`
      opacity: 0;
    `};
`;
