// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';

import MenuContent from './menucontent';

import getViewProps from '../../../../helpers/front/getviewprops';
import * as pth from '../../../../helpers/proptypes';

import { Wrapper, MenuBackground, Relative } from './styles';

// =============================
// Component
// =============================

class Menu extends PureComponent {
  static displayName = 'MenuDesktop';

  static propTypes = {
    /** Callback function use to close Menu. */
    closeMenu: PropTypes.func.isRequired,
    /** App config. */
    config: pth.config.isRequired,
    /** Menu data. */
    data: pth.configMenus.isRequired,
    /** User is logged. */
    isLogged: PropTypes.bool.isRequired,
    /** Number of user playlists. */
    nbPlaylists: PropTypes.number.isRequired,
    /** If true, Menu is opened. */
    opened: PropTypes.bool.isRequired,
    /** Callback function use to toggle Menu. */
    toggleMenu: PropTypes.func.isRequired,
    /** User data. */
    user: pth.fulluser,
    /** If True, Menu is visible. */
    visible: PropTypes.bool,
  };

  static defaultProps = {
    user: null,
    visible: true,
  };

  state = {
    animationComplete: false,
  };

  handleClose = () => {
    const { closeMenu } = this.props;

    closeMenu();
  };

  onAnimationComplete = () => {
    const { opened } = this.props;

    if (typeof window !== 'undefined') {
      window.dispatchEvent(new Event('resize'));
    }

    this.setState({ animationComplete: opened });
  };

  render() {
    const {
      closeMenu,
      config,
      data,
      isLogged,
      nbPlaylists,
      opened,
      toggleMenu,
      user,
      visible,
      ...rest
    } = this.props;
    const { animationComplete } = this.state;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.menu,
        })}
      >
        <Wrapper opened={opened} {...getViewProps(rest)}>
          <Relative>
            <MenuBackground opened={opened} />
            <MenuContent
              onAnimationComplete={this.onAnimationComplete}
              animationComplete={animationComplete}
              opened={opened}
              config={config}
              isLogged={isLogged}
              data={data}
              nbPlaylists={nbPlaylists}
              closeMenu={this.handleClose}
            />
          </Relative>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default Menu;
