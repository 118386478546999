// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Div, Flex, Icons, TextToInput } from '@mewo/components';

import MusicItemOptionsToggler from '../containers/musicitemoptionstoggler';
import Cover from '../containers/cover';
import Button from '../presentationals/button';
import { DropdownList } from '../presentationals/dropdown';
import Input from '../presentationals/inputs/input';
import Link from '../other/link';
import Tag from '../other/tag';

import { headerHeight } from '../presentationals/header/mobile/variables';
import { paddingX } from '../other/pagewrapper/desktop/variables';
import { paddingX as paddingXMobile } from '../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

// We use this "Base" so its easier to override styles on playlist page.
export const ContainerBase = styled(Div)`
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding-left: ${paddingXMobile};
    padding-right: ${paddingXMobile};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding-left: ${paddingX};
    padding-right: ${paddingX};
  `};

  ${/* TODO: Why don't we use a variable for the width here ? */
  ({ theme }) => theme.mediaAbove.wxgaplus`
    margin: 0 auto;
    width: 120rem;
  `};
`;

export const Container = styled(ContainerBase)`
  padding-top: calc(${headerHeight} + ${paddingX});

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding-top: 12rem;
  `};
`;

export const ContainerSearchPaddingRight = '4rem';

export const ContainerSearch = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    padding: calc(${headerHeight} + 4rem) 1.2rem 0 1.2rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding-right: ${ContainerSearchPaddingRight};
    /**
      34rem was the original padding-left value
      but we needed to add some space for the new
      RecentlyPlayed icon on musicItem which has a
      width of 3.4rem
    */
    padding-left: calc(34rem + 3.4rem);
    padding-top: 12rem;
    transition: padding-left ${theme.transition.medium};
    width: 100%;

    max-width: 150rem;
  `};

  ${({ theme }) => theme.mediaAbove.wuxga`
    margin: 0 auto;
    max-width: 170rem;
  `};
`;

export const Breadcrumb = styled(Link)`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
`;

// Entity Header part wrapper
export const HeaderBase = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
`;

export const Header = styled(HeaderBase)`
  flex-direction: column;
  margin-bottom: 5rem;

  ${({ theme }) => theme.mediaAbove.minWidth`
    flex-direction: row;
    margin-bottom: 10rem;
  `};
`;

// Entity Name
export const nameBase = css`
  color: ${({ theme }) => theme.colors.text};
  font-size: 4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 5rem;
  margin-bottom: 0;

  ${({ theme }) => theme.mediaAbove.minWidth`
    line-height: 1.2;
    margin-bottom: 0.8rem;
  `};
`;

export const Name = styled.h1`
  ${nameBase}

  ${({ asPageTitle }) => asPageTitle
    && css`
      margin-bottom: 3.2rem;

      ${({ theme }) => theme.mediaAbove.minWidth`
        margin-bottom: 5rem;
      `};
    `}
`;

// Editable Name (duplicated for simplicity)
export const EditableName = styled(TextToInput)``;

// Line under Entity Name
export const SubName = styled(Div)`
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 2.4rem;
  `};

  ${({ inline }) => inline
    && css`
      display: inline-block;
    `}
`;

export const SubNameTitle = styled.div`
  display: inline;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  max-width: 100%;
  overflow-wrap: break-word;

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-weight: ${theme.fontWeight.extrabold};
  `};
`;

// Line under Entity Name
export const CreatedAt = styled(Div)`
  display: block;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 2.4rem;
  `};
`;

const descStyles = css`
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 2rem;
`;

export const EntityDesc = styled.h2`
  ${descStyles};
`;

export const EditableDesc = styled(TextToInput).attrs({
  multiline: true,
  allowEmpty: true,
})`
  ${descStyles};
`;

export const DescSeeMore = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 1.2rem;
`;

export const EntityCover = styled(Cover).attrs(({ rounded, size }) => ({
  noBoxShadow: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  rounded: rounded || false,
  size: size || '30rem',
}))`
  ${({ theme }) => theme.mediaBelow.minWidth`
    margin: 0 auto 5rem;
    padding: 0 2rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin: 0;
    margin-right: 3rem;
    padding: 0;
  `};
`;

export const Informations = styled(Div)`
  ${({ theme }) => theme.mediaAbove.minWidth`
    max-width: 60rem;
    padding-top: 2rem;
    width: 100%;
  `};
`;

// Entity page, list item title
export const Title = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  justify-content: space-between;
  line-height: 2rem;
  margin-bottom: 4rem;
  opacity: 0.9;
  user-select: none;

  /** Needed for search page on mobile. */
  ${({ withPadding }) => withPadding && css`
    padding: 0 ${paddingXMobile};
  `};
`;

export const StyledMusicItemOptionsToggler = styled(MusicItemOptionsToggler)`
  justify-content: flex-end;
`;

export const ListContent = styled.div`
  ${({ col, goVerticalOnMobile, theme }) => theme.mediaBelow.minWidth`
    ${
  goVerticalOnMobile === true
    ? css`
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;

      && > * {
        width: calc((100% - (2rem * ${col - 1})) / ${col});

        ${`
          &:nth-of-type(${col}n) {
            margin-right: 0;
          }
        `};

        ${col > 1 && css`
          margin-bottom: 2rem;
          margin-right: 2rem;
        `};
      }
    `
    : css`
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;

      > * {
        width: 40%;

        &:not(:last-child) {
          margin-right: 1.2rem;
        }
      }
    `
};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-bottom: 4rem;
      margin-right: 3rem;
      width: calc((100% - 9rem) / 4);

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  `};
`;

ListContent.propTypes = {
  col: PropTypes.number,
};

ListContent.defaultProps = {
  col: 2,
};

export const ListFooter = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem auto 0;

  /** Needed to keep the same scroll position when loading more results (Chrome bug). */
  overflow-anchor: none;
`;

export const SeeMoreButton = styled(Button).attrs({
  centered: true,
  color: 'primary',
  fillVariant: 'outline',
})`
  justify-content: center;
  width: 40rem;
`;

const PlayButtonBase = styled(Button).attrs({
  color: 'primary',
  fillVariant: 'plain',
  size: 'small',
})``;

const PlayButtonIcon = styled(Icons.ArrowSorting).attrs(({ theme }) => ({
  fill: theme.colors.primaryText,
  dir: 'east',
  width: '1rem',
}))`
  margin-right: 0.8rem;
`;

export const PlayButton = ({ children, ...rest }) => (
  <PlayButtonBase {...rest}>
    <PlayButtonIcon />
    {children}
  </PlayButtonBase>
);

PlayButton.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Default margin between list is 10rem.
 * All covers in list have a margin-bottom of 4rem and we can't cancel it on the last row.
 * So we use a 6rem margin-bottom on the EntityItemList to equal 10rem.
 * On the other hand track lists don't have this problem so we need to set a 10rem
 * margin-bottom for them.
 * For the last item margin, when its a cover list we set a -4rem margin-bottom
 * to get the default 0 margin on last items.
 */
export const EntityItemList = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-bottom: 10rem;
  `};

  ${({ hasMoreBtn }) => (hasMoreBtn ? css`
    margin-bottom: 10rem;
  ` : css`
    margin-bottom: 6rem;
  `)};

  ${({ trackList }) => trackList
    && css`
      margin-bottom: 10rem;
    `}

  &:last-child {
    ${({ theme }) => theme.mediaBelow.minWidth`
      margin-bottom: 0;
    `};
    ${({ theme, trackList }) => theme.mediaAbove.minWidth`
      ${({ hasMoreBtn }) => (hasMoreBtn ? css`
        margin-bottom: 0;
      ` : css`
        margin-bottom: -4rem;
      `)};

      ${trackList
        && css`
          margin-bottom: 0;
        `}
    `};

    ${({ trackList }) => trackList
    && css`
      margin-bottom: 0;
    `}
  }

  ${ListFooter} {
    margin: 1rem auto 0;
  }
`;

export const AlbumCover = styled(Cover).attrs({
  noTranslateY: true,
  type: 'album',
  placeholderType: 'album',
})``;

export const PlaylistCover = styled(Cover).attrs({
  noTranslateY: true,
  type: 'playlist',
  placeholderType: 'playlist',
})``;

export const Actions = styled(Flex)`
  align-items: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-top: 3.2rem;
  `};

  > *:not(:last-child) {
    margin-right: 1.2rem;
  }
`;

const ThreeDotIcon = styled(Icons.ThreeDot).attrs({
  noButton: true,
  width: '1.8rem',
})`
  padding: 0.8rem 0;
`;

export const DropdownButton = styled(DropdownList).attrs(({ togglerElement }) => ({
  togglerElement: togglerElement || <ThreeDotIcon />,
}))``;

export const NoResults = styled.div`
  color: ${({ theme }) => theme.colors.text};

  ${({ center }) => center
    && css`
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    `};
`;

export const NoResultsText = styled.div`
  margin-bottom: 3.2rem;
  margin-top: 3.2rem;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;

export const TagsList = styled.div`
  margin-bottom: 2rem;
`;

export const StyledTag = styled(Tag).attrs({})`
  &:not(:last-child) {
    margin-bottom: 0.4rem;
    margin-right: 1.2rem;
  }
`;

export const Duration = styled.div`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export const StyledInput = styled(Input).attrs({
  containerClassName: 'containerClassName',
  variant: 'background',
})`
  & .containerClassName {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
  }
`;

export const DeleteButton = styled(Icons.TrashCan).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '1.8rem',
}))``;
