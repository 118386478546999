// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import * as entity from '../../../../helpers/entity';
import { getTrackDisplayArtists } from '../../../../helpers/entity/track';
import * as pth from '../../../../helpers/proptypes';

import ModalCloser from '../../../other/modalCloser';

import {
  CloseModalBtn,
  Content,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../mobile.styles';
import { DefaultModal } from '../styles';
import { StyledLink } from './styles';

// =============================
// Layout
// =============================

class ModalTrackArtists extends PureComponent {
  static propTypes = {
    /** Function used to close track artists modal. */
    closeMusicItemModal: PropTypes.func.isRequired,
    /** Function used to close track artists modal. */
    closeMusicPlayerModal: PropTypes.func.isRequired,
    /** Function used to close track artists modal. */
    closeTrackArtistsModal: PropTypes.func.isRequired,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Translations. */
    t: PropTypes.func.isRequired,
    /** Track object. */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  renderDisplayArtists = () => {
    const {
      closeMusicItemModal,
      closeMusicPlayerModal,
      closeTrackArtistsModal,
      track,
    } = this.props;

    const displayArtists = getTrackDisplayArtists(track);

    return displayArtists.reduce((acc, da, i) => {
      acc.push(
        <ModalCloser
          onClose={() => {
            closeTrackArtistsModal();
            closeMusicItemModal();
            closeMusicPlayerModal();
          }}
          path={entity.getUrl('artists')(da, da.name)}
        >
          {onClose => (
            <StyledLink
              inline
              route={entity.getBaseRoute('artists')}
              nextAs={entity.getUrl('artists')(da, da.name)}
              // eslint-disable-next-line react/no-array-index-key
              key={`da-${da.id}-${i}`}
              onClickPassthrough={onClose}
            >
              {da.name}
            </StyledLink>
          )}
        </ModalCloser>,
      );

      return acc;
    }, []);
  };

  render() {
    const { closeTrackArtistsModal, opened, t, track } = this.props;

    if (!track) return null;

    return (
      <DefaultModal
        name="trackArtists"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeTrackArtistsModal}
        opened={opened}
        wideContent
      >
        <ClickAwayListener onClickAway={closeTrackArtistsModal}>
          <Wrapper>
            <Header>
              <CloseModalBtn onClick={closeTrackArtistsModal}>
                {t('components:modal.close')}
              </CloseModalBtn>
              <Title>{t('components:modal_track_artists.artists')}</Title>
              <EmptySpacePlaceholder />
            </Header>
            <Content>{this.renderDisplayArtists()}</Content>
          </Wrapper>
        </ClickAwayListener>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalTrackArtists);
