// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import handleViewport from 'react-in-viewport';

import vynil from '@mewo/components/assets/images/vynil_500x500.png?webp';
import trackplaceholder from '@mewo/components/assets/images/placeholders/track_500x500.png?webp';
import albumplaceholder from '@mewo/components/assets/images/placeholders/album_500x500.png?webp';
import artistplaceholder from '@mewo/components/assets/images/placeholders/artist_500x500.png?webp';
import playlistplaceholder from '@mewo/components/assets/images/placeholders/playlist_500x500.png?webp';
import catalogplaceholder from '@mewo/components/assets/images/placeholders/catalog_500x500.png?webp';
import labelpublisherplaceholder from '@mewo/components/assets/images/placeholders/label-publisher_500x500.png?webp';
import postplaceholder from '@mewo/components/assets/images/placeholders/custom_500x500.png?webp';
import filter from '@mewo/components/assets/images/coverfilter.png?webp';

import { Cover as CoverBase } from '@mewo/components';

// =============================
// Component
// =============================

const images = {
  albumplaceholder,
  artistplaceholder,
  catalogplaceholder,
  filter,
  labelplaceholder: labelpublisherplaceholder,
  playlistplaceholder,
  postplaceholder,
  publisherplaceholder: labelpublisherplaceholder,
  trackplaceholder,
  vynil,
};

class CoverLazyInViewport extends Component {
  static propTypes = {
    forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    inViewport: PropTypes.bool,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  };

  static defaultProps = {
    forwardedRef: undefined,
    inViewport: false,
    src: undefined,
  };

  state = {
    inViewport: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.inViewport && props.inViewport) return { inViewport: true };
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_isEqual(
        _omit(nextProps, ['inViewport', 'enterCount', 'leaveCount']),
        _omit(this.props, ['inViewport', 'enterCount', 'leaveCount']),
      ) || nextState !== this.state
    );
  }

  render() {
    const { forwardedRef, src: baseSrc, ...props } = this.props;

    const { inViewport } = this.state;
    const src = inViewport ? baseSrc : undefined;

    return <CoverBase ref={forwardedRef} src={src} images={images} {...props} />;
  }
}

const CoverLazyLoaded = handleViewport(CoverLazyInViewport, undefined, { disconnectOnLeave: true });

const Cover = ({ lazyload, ...props }) => {
  if (lazyload) return <CoverLazyLoaded {...props} />;
  return <CoverLazyInViewport inViewport {...props} />;
};

Cover.propTypes = {
  lazyload: PropTypes.bool,
};

Cover.defaultProps = {
  lazyload: true,
};

export default Cover;
