// =============================
// Imports
// =============================

import { forwardRef, Component } from 'react';

import Responsive from '../../other/responsive';
import LogoDesktop from './desktop';
import LogoMobile from './mobile';

// =============================
// Component
// =============================

class LogoBase extends Component {
  static displayName = 'LogoBaseResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={LogoDesktop}
        MobileComponent={LogoMobile}
        props={this.props}
      />
    );
  }
}

function forwardedRef(props, ref) {
  return <LogoBase innerRef={ref} {...props} />;
}

forwardedRef.displayName = LogoBase.displayName;

export default forwardRef(forwardedRef);
