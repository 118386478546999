// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setAccountOpen } from '../../../../store/actions/ModalsActions';
import { modifyUserInfo, modifyUserPassword } from '../../../../store/actions/UserActions';

import ModalAccount from '../../../layouts/modals/account';

import { isNewsletterActive } from '../../../../helpers/config';

// =============================
// Component
// =============================

function mapStateToProps({ config, user, modals, options }) {
  return {
    user: user.data,
    opened: modals.isAccountOpen,
    territories: options.data.territories,
    userIndustries: options.data.userindustries,
    newsletterActive: isNewsletterActive(config.data),
  };
}

export default connect(mapStateToProps, {
  closeAccountModal: () => setAccountOpen(false),
  modifyUserInfo,
  modifyUserPassword,
})(ModalAccount);
