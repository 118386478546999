// =============================
// Imports
// =============================

// Constants
import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  CLEAR_ALL_NOTIFICATIONS,
} from '../constants/ActionTypes';

// =============================
// Actions
// =============================

/**
 * @description Triggers a notification
 *
 * @param {string} notificationKey - Notification key
 * @param {number} duration - Display duration of notification
 * @param {string} type - Notification type. Can be 'error', 'success' or 'warning'
 * @param {string} message - Notification message
 * @param {string} reqId -  Request ID
 * @param {array} actions - List of actions to execute
 */
export function setNotification({ notificationKey, duration, type, message = '', reqId, actions }) {
  return {
    type: SET_NOTIFICATION,
    payload: {
      notificationKey,
      duration,
      type,
      message,
      reqId,
      actions,
    },
  };
}

/**
 * @description Deletes a notification
 *
 * @param {string} notificationKey - Notification key
 */
export function clearNotification(notificationKey) {
  return {
    type: CLEAR_NOTIFICATION,
    payload: notificationKey,
  };
}

/**
 * @description Deletes all notifications
 */
export function clearAllNotifications() {
  return {
    type: CLEAR_ALL_NOTIFICATIONS,
  };
}
