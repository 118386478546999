// =============================
// Imports
// =============================

import { Component } from 'react';

import Responsive from '../responsive';

import PageWrapperDesktop from './desktop';
import PageWrapperMobile from './mobile';

// =============================
// Component
// =============================

class PageWrapper extends Component {
  static displayName = 'PageWrapperResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={PageWrapperDesktop}
        MobileComponent={PageWrapperMobile}
        props={this.props}
      />
    );
  }
}

export default PageWrapper;
