// =============================
// Imports
// =============================

// External Dependencies
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

// All of our reducers
import * as reducers from './reducers';

// All of our middlewares
import notificationsMiddleware from './middlewares/notificationsMiddleware';
import favoritesMiddleware from './middlewares/favoritesMiddleware';
import recentsMiddleware from './middlewares/recentsMiddleware';
import authMiddleware from './middlewares/authMiddleware';
import userPlaylistsMiddleware from './middlewares/userPlaylistsMiddleware';
import playerMiddleware from './middlewares/playerMiddleware';
import maiaSearchMiddleware from './middlewares/maiaSearchMiddleware';

// =============================
// Store
// =============================

// Middelwares
const middlewares = [
  thunk,
  notificationsMiddleware,
  favoritesMiddleware,
  recentsMiddleware,
  authMiddleware,
  userPlaylistsMiddleware,
  playerMiddleware,
  maiaSearchMiddleware,
];

const bindMiddlewares = () => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middlewares));
  }

  return applyMiddleware(...middlewares);
};

// Reducers
const combinedReducers = combineReducers(reducers);

const reducer = (state, action) => {
  // NOTE: Since we do not use "getServerSideProps"
  // or "getStaticProps", we only need to hydrate once on init
  if (action.type === HYDRATE && !state.core.hydrated) {
    console.info('[Redux]: Hydrating state'); // eslint-disable-line no-console

    return {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      core: {
        ...action.payload.core,
        hydrated: true,
      },
    };
  }

  return combinedReducers(state, action);
};

// Make store
const makeStore = () => createStore(reducer, bindMiddlewares());
const storeWrapper = createWrapper(makeStore, { debug: false });

export default storeWrapper;
