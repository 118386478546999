// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  isLoading: false,
  register: {
    isLoading: false,
  },
  recover: {
    isLoading: false,
  },
  resetPassword: {
    isLoading: false,
  },
  setPassword: {
    isLoading: false,
  },
  activateAccount: {
    isLoading: false,
  },
};

// =============================
// Reducer
// =============================

export default function auth(state = initialState, action) {
  switch (action.type) {
    case acts.GET_USER_SESSION_TOKEN_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case acts.GET_USER_SESSION_TOKEN_SUCCESS:
    case acts.GET_USER_SESSION_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case acts.REGISTER_USER_LOADING:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: true,
        },
      };

    case acts.REGISTER_USER_SUCCESS:
    case acts.REGISTER_USER_FAILURE:
      return {
        ...state,
        register: {
          ...state.register,
          isLoading: false,
        },
      };

    case acts.RECOVER_PASSWORD_LOADING:
      return {
        ...state,
        recover: {
          ...state.recover,
          isLoading: true,
        },
      };

    case acts.RECOVER_PASSWORD_SUCCESS:
    case acts.RECOVER_PASSWORD_FAILURE:
      return {
        ...state,
        recover: {
          ...state.recover,
          isLoading: false,
        },
      };

    case acts.RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isLoading: true,
        },
      };

    case acts.RESET_PASSWORD_SUCCESS:
    case acts.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isLoading: false,
        },
      };

    case acts.SET_PASSWORD_LOADING:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          isLoading: true,
        },
      };

    case acts.SET_PASSWORD_SUCCESS:
    case acts.SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          isLoading: false,
        },
      };

    case acts.ACTIVATE_ACCOUNT_LOADING:
      return {
        ...state,
        activateAccount: {
          ...state.activateAccount,
          isLoading: true,
        },
      };

    case acts.ACTIVATE_ACCOUNT_SUCCESS:
    case acts.ACTIVATE_ACCOUNT_FAILURE:
      return {
        ...state,
        activateAccount: {
          ...state.activateAccount,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
