/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

import { css } from 'styled-components';

// =============================
// Helpers
// =============================

/**
 * Most of our icons are SVG with a surrounding circle and a inner path
 * Since we can't use inherit along with transition because of Webkit
 * https://stackoverflow.com/a/40512935
 * So we need to target circle and path directly which is verbose and so
 * we made this helper to apply an initial and an hover color
 * @param {string} initialColor - Initial color (any css compatible format)
 * @param {string} hoverColor - Hover color (any css compatible format)
 * @param {boolean} reverse - Reverse colors
 * @return {string} CSS
 */
export const svgHoverCirclePath = (initialColor, hoverColor, reverse = false) => css`
  ${reverse
    ? css`
        svg circle {
          fill: ${initialColor};
          fill-opacity: 1;
          stroke: ${initialColor};
        }

        svg path {
          fill: ${hoverColor};
        }

        &:hover {
          svg circle {
            fill-opacity: 0;
            stroke: ${initialColor};
          }
          svg path {
            fill: ${initialColor};
          }
        }
      `
    : css`
        svg circle {
          fill: ${initialColor};
          fill-opacity: 0;
          stroke: ${initialColor};
        }
        svg path {
          fill: ${initialColor};
        }

        &:hover {
          svg circle {
            fill-opacity: 1;
          }

          svg path {
            fill: ${hoverColor};
          }
        }
      `};
`;
