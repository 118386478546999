// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../../../other/link';

// =============================
// Component
// =============================

export const StyledLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  width: 100%;
`;

export const OwnershipCategory = styled.div`
  margin-bottom: 4rem;
  width: 100%;
`;

export const OwnershipTitle = styled.div`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2.4rem;
`;

export const Ownership = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Role = styled.div`
  display: inline;
  font-style: italic;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;
