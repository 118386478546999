// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import { SelectInput as Unwrapped } from '@mewo/components';

// Components
import { withTranslation } from '../../../../../config/i18n';

// =============================
// Component
// =============================

const SelectInput = ({ t, ...rest }) => (
  <Unwrapped
    dropdownClassName="ignore-react-onclickoutside"
    lang={{
      selectAll: t('components:select_input.select_all'),
      unselectAll: t('components:select_input.unselect_all'),
      noOptions: t('components:select_input.no_options'),
      Label: {
        optional: t('components:mewo_inputs.Label.optional'),
      },
    }}
    {...rest}
  />
);

SelectInput.displayName = 'SelectInputDesktop';

SelectInput.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('components')(SelectInput);
