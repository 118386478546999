// =============================
// Imports
// =============================

// External Dependencies
import { Fragment } from 'react';

// Components
import Link from '../components/other/link';

// =============================
// Helpers
// =============================

// eslint-disable-next-line import/prefer-default-export
export function buildDownloadTextNode(text, src) {
  const [beginning, link, end] = text.split(/\[|\]/);

  return (
    <Fragment>
      {beginning}
      <Link inline href={src} download>
        {link}
      </Link>
      {end}
    </Fragment>
  );
}
