// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Router, { withRouter } from 'next/router';

import { withTranslation } from '../../../../config/i18n';

import {
  getTrackTitle,
  getTrackCoverUrl,
  getTrackDisplayArtists as getTrackDisplayArtistsHelper,
  getTrackAlbum,
} from '../../../../helpers/entity/track';
import { getBaseRoute, getUrl } from '../../../../helpers/entity';
import { encodeAndRemovePercent } from '../../../../helpers/misc';
import * as pth from '../../../../helpers/proptypes';

import ModalCloser from '../../../other/modalCloser';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  TitleWrapper,
  Title,
  SubTitle,
  MobileActionList,
  MobileAction,
  SimilarIcon,
  LyricsIcon,
  AddToPlaylistIcon,
  AddToFavoritesIcon,
  TagsIcon,
  CopyrightIcon,
  VersionIcon,
  AlbumIcon,
  ArtistsIcon,
} from '../styles';

// =============================
// Stories
// =============================

class ModalMusicItem extends PureComponent {
  static propTypes = {
    /** Function used to add track to favorites. */
    addToFavorites: PropTypes.func.isRequired,
    /** Function used to open modal */
    closeModal: PropTypes.func.isRequired,
    /** If True, user is logged in. */
    isLogged: PropTypes.bool.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Function used to open add to playlist modal. */
    openAddToPlaylistModal: PropTypes.func.isRequired,
    /** Function used to open track artists modal. */
    openTrackArtistsModal: PropTypes.func.isRequired,
    /** Function used to open lyrics modal. */
    openTrackLyricsModal: PropTypes.func.isRequired,
    /** Function used to open track ownerships modal. */
    openTrackOwnershipsModal: PropTypes.func.isRequired,
    /** Function used to open track tags modal. */
    openTrackTagsModal: PropTypes.func.isRequired,
    /** Function used to open track versions modal. */
    openTrackVersionsModal: PropTypes.func.isRequired,
    /** If True, playlist is owned by user. */
    playlistIsOwnedByUser: PropTypes.bool,
    /** Function used to remove track to favorites. */
    removeFromFavorites: PropTypes.func.isRequired,
    /** Function used to remove track from playlist. */
    removeTrackFromPlaylist: PropTypes.func.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
      query: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }).isRequired,
    /** Function used to translate. */
    t: PropTypes.func.isRequired,
    /** Track */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    playlistIsOwnedByUser: false,
    track: null,
  };

  getSubTitle = () => {
    const { track } = this.props;

    const album = getTrackAlbum(track);

    if (album.id) {
      return <SubTitle centered>{album.title}</SubTitle>;
    }

    return null;
  };

  getTrackDisplayArtists = () => {
    const { track } = this.props;

    const displayArtists = getTrackDisplayArtistsHelper(track);

    if (!displayArtists) return null;

    return displayArtists.reduce((acc, da, i) => {
      if (i === 0) acc.push(' - ');

      acc.push(da.name);

      if (i < displayArtists.length - 1) acc.push(', ');

      return acc;
    }, []);
  };

  handleMagicWand = () => {
    const { track } = this.props;

    const query = encodeAndRemovePercent(JSON.stringify([track.id, getTrackTitle(track)]));

    Router.push(`/search?track_id=${query}`);
  };

  handleFavorite = () => {
    const { addToFavorites, isLogged, removeFromFavorites, track } = this.props;

    if (isLogged) {
      if (track.isFavorite) {
        removeFromFavorites(track.id);
      } else {
        addToFavorites(track.id);
      }
    }
  };

  renderRemoveTrackFromPlaylist = () => {
    const {
      closeModal,
      isLogged,
      playlistIsOwnedByUser,
      removeTrackFromPlaylist,
      router,
      t,
      track,
    } = this.props;

    if (
      isLogged
      && playlistIsOwnedByUser
      && router.asPath.indexOf('/userplaylist/') === 0
    ) {
      const { playlistId } = router.query;

      return (
        <MobileAction
          onClick={() => {
            closeModal();
            return removeTrackFromPlaylist(playlistId, track.id);
          }}
        >
          <AddToPlaylistIcon />
          {t('components:music_modal.remove_track_from_playlist')}
        </MobileAction>
      );
    }

    return null;
  };

  render() {
    const {
      closeModal,
      isLogged,
      opened,
      openAddToPlaylistModal,
      openTrackArtistsModal,
      openTrackLyricsModal,
      openTrackOwnershipsModal,
      openTrackTagsModal,
      openTrackVersionsModal,
      t,
      track,
    } = this.props;

    if (!track) return null;

    const trackOwnerships = [
      ..._get(track, 'artistsMasterOwnerships', []),
      ..._get(track, 'artistsPublishingOwnerships', []),
      ..._get(track, 'masterOwnerships', []),
      ..._get(track, 'publishingOwnerships', []),
    ];

    return (
      <DefaultModal
        bottomCloseBtn
        name="musicItem"
        onClose={closeModal}
        opened={opened}
      >
        <Wrapper>
          <Informations>
            <ModalCover
              lazyload={false}
              placeholderType="track"
              src={getTrackCoverUrl(track)}
              type="track"
              alt={getTrackAlbum(track).title}
            />
          </Informations>
          <Content>
            <TitleWrapper>
              <Title centered>
                {getTrackTitle(track)}
                {this.getTrackDisplayArtists()}
              </Title>
              {this.getSubTitle()}
            </TitleWrapper>

            <MobileActionList>
              <MobileAction onClick={this.handleMagicWand}>
                <SimilarIcon />
                {t('components:music_modal.search_similar_tracks')}
              </MobileAction>
              <MobileAction
                isLink={!isLogged}
                route="/login"
                onClick={this.handleFavorite}
              >
                <AddToFavoritesIcon checked={track.isFavorite} />
                {track.isFavorite
                  ? t('components:music_modal.remove_from_favorites')
                  : t('components:music_modal.add_to_favorites')}
              </MobileAction>
              {this.renderRemoveTrackFromPlaylist()}
              <MobileAction
                isLink={!isLogged}
                route="/login"
                onClick={!isLogged ? null : () => openAddToPlaylistModal(true, track, 'track')}
              >
                <AddToPlaylistIcon />
                {t('components:entity_actions.add_to_playlist')}
              </MobileAction>
              {!_isEmpty(track.lyrics) && (
                <MobileAction onClick={() => openTrackLyricsModal(true, track)}>
                  <LyricsIcon />
                  {t('components:music_modal.lyrics')}
                </MobileAction>
              )}
              {getUrl('tracks')(track) && (
                <ModalCloser onClose={closeModal} path={getUrl('tracks')(track)}>
                  {onClose => (
                    <MobileAction
                      isLink
                      onClickPassthrough={onClose}
                      nextAs={getUrl('tracks')(track)}
                      route={getBaseRoute('tracks')}
                    >
                      <AlbumIcon />
                      {t('components:music_modal.go_to_album')}
                    </MobileAction>
                  )}
                </ModalCloser>
              )}
              {!!track.displayArtists.length && (
                <MobileAction onClick={() => openTrackArtistsModal(true, track)}>
                  <ArtistsIcon />
                  {t('components:music_modal.see_artists')}
                </MobileAction>
              )}
              {track.versions > 1 && (
                <MobileAction onClick={() => openTrackVersionsModal(true, track)}>
                  <VersionIcon>
                    +
                    {track.versions - 1}
                  </VersionIcon>
                  {t('components:music_modal.versions')}
                </MobileAction>
              )}
              {!!track.tags.length && (
                <MobileAction onClick={() => openTrackTagsModal(true, track)}>
                  <TagsIcon />
                  {t('components:music_modal.tags')}
                </MobileAction>
              )}
              {!!trackOwnerships.length && (
                <MobileAction onClick={() => openTrackOwnershipsModal(true, track)}>
                  <CopyrightIcon />
                  {t('components:music_modal.ownerships')}
                </MobileAction>
              )}
            </MobileActionList>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(['components', 'pages']),
)(ModalMusicItem);
