// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { Icons } from '@mewo/components';

import Button from '../../../presentationals/button';

// =============================
// Component
// =============================

export const NewPlaylistBtn = styled(Button).attrs({
  size: 'small',
})`
  flex-shrink: 0;
  margin: 0 auto 2rem;
`;

export const PlaylistAdded = styled(Icons.Check).attrs(({ theme }) => ({
  fill: theme.colors.type.success,
  width: '1rem',
}))`
  margin-right: 1rem;
`;

export const PlaylistItem = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 5rem;
  justify-content: flex-start;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 0.2rem solid ${({ theme }) => alpha(theme.colors.modal.text, 0.2)};
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.7;
  `};
`;
