// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  isFetching: false,
  data: undefined,
  legal: {
    isFetching: false,
    data: [],
  },
  menus: {
    isFetching: false,
    data: {},
  },
  home: {
    isFetching: false,
    isFetchedOnce: false,
    data: {},
  },
  page: {
    isFetching: false,
    data: {},
  },
  contactEmail: {
    isLoading: false,
  },
};

// =============================
// Reducer
// =============================

export default function config(state = initialState, action) {
  switch (action.type) {
    case acts.GET_CONFIG_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.SET_CONFIG: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case acts.GET_CONFIG_SUCCESS:
    case acts.GET_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case acts.GET_CONFIG_LEGAL_LOADING:
      return {
        ...state,
        legal: {
          ...state.legal,
          isFetching: true,
        },
      };

    case acts.SET_CONFIG_LEGAL:
      return {
        ...state,
        legal: {
          ...state.legal,
          data: action.payload,
        },
      };

    case acts.GET_CONFIG_LEGAL_SUCCESS:
    case acts.GET_CONFIG_LEGAL_FAILURE:
      return {
        ...state,
        legal: {
          ...state.legal,
          isFetching: false,
        },
      };

    case acts.GET_CONFIG_MENUS_LOADING:
      return {
        ...state,
        menus: {
          ...state.menus,
          isFetching: true,
        },
      };

    case acts.SET_CONFIG_MENUS:
      return {
        ...state,
        menus: {
          ...state.menus,
          data: action.payload,
        },
      };

    case acts.GET_CONFIG_MENUS_SUCCESS:
    case acts.GET_CONFIG_MENUS_FAILURE:
      return {
        ...state,
        menus: {
          ...state.menus,
          isFetching: false,
        },
      };

    case acts.SET_CONFIG_HOME:
      return {
        ...state,
        home: {
          ...state.home,
          data: action.payload,
        },
      };

    case acts.GET_CONFIG_HOME_LOADING:
      return {
        ...state,
        home: {
          ...state.home,
          isFetching: true,
        },
      };

    case acts.GET_CONFIG_HOME_SUCCESS:
      return {
        ...state,
        home: {
          ...state.home,
          isFetchedOnce: true,
          isFetching: false,
        },
      };

    case acts.GET_CONFIG_HOME_FAILURE:
      return {
        ...state,
        home: {
          ...state.home,
          isFetching: false,
        },
      };

    case acts.SET_CONFIG_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          data: action.payload,
        },
      };

    case acts.GET_CONFIG_PAGE_LOADING:
      return {
        ...state,
        page: {
          ...state.page,
          isFetching: true,
        },
      };

    case acts.GET_CONFIG_PAGE_SUCCESS:
      return {
        ...state,
        page: {
          ...state.page,
          isFetching: false,
        },
      };

    case acts.GET_CONFIG_PAGE_FAILURE:
      return {
        ...state,
        page: {
          ...state.page,
          isFetching: false,
        },
      };

    case acts.RESET_CONFIG_PAGE:
      return {
        ...state,
        page: initialState.page,
      };

    case acts.SEND_CONTACT_EMAIL_LOADING:
      return {
        ...state,
        contactEmail: {
          ...state.page,
          isLoading: true,
        },
      };

    case acts.SEND_CONTACT_EMAIL_SUCCESS:
    case acts.SEND_CONTACT_EMAIL_FAILURE:
      return {
        ...state,
        contactEmail: {
          ...state.page,
          isLoading: false,
        },
      };

    default:
      return state;
  }
}
