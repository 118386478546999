// =============================
// Imports
// =============================

import styled from 'styled-components';

import Link from '../../../other/link';

// =============================
// Component
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledLink = styled(Link)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
