// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { CheckboxInput, Div, Icons, InputBase, Tooltip } from '@mewo/components';
import { alpha } from '@material-ui/core';

import { headerHeight } from '../../header/desktop/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled(Div)`
  align-items: center;
  display: flex;
  height: ${headerHeight};
  left: 50%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  transform: translate3d(-50%, 0, 0);
  z-index: ${({ theme }) => theme.zIndex.scSearchBar};
`;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 0;
  border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '1.5rem' : 'O.2rem')};
  color: ${({ error, theme }) => (error ? theme.colors.type.error : theme.colors.text)};
  display: flex;
  fill: ${({ error, theme }) => (error ? theme.colors.type.error : theme.colors.text)};
  font-size: 1.2rem;
  height: 4.5rem;
  outline: none;
  padding: 0 2rem;
  transition: background-color ${({ theme }) => theme.transition.medium},
    width ${({ theme }) => theme.transition.medium};
  width: 35rem;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.colors.background, 0.9)};
  }

  ${({ isFocus }) => isFocus
    && css`
      width: 40rem;

      ${({ theme }) => theme.mediaAbove.sxga`
        width: 55rem;
      `};
    `};
`;

export const SearchInput = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-right: 1.2rem;

  input {
    color: inherit;
    cursor: pointer !important;
    font-weight: 700;
    height: auto !important;
    width: auto !important;
    position: relative !important;

    &::-webkit-input-placeholder {
      font-style: italic;
      font-weight: 700;
    }

    &:-moz-placeholder {
      font-style: italic;
      font-weight: 700;
    }

    &::-moz-placeholder {
      font-style: italic;
      font-weight: 700;
    }

    &:-ms-input-placeholder {
      font-style: italic;
      font-weight: 700;
    }
  }

  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }

  ${({ isFocus }) => isFocus
    && css`
      input {
        cursor: text !important;
    `};
`;

export const StyledInput = styled(InputBase)``;

export const SearchMusicIcon = styled(Icons.Search).attrs({
  width: '1.8rem',
})`
  flex-shrink: 0;
  margin-right: 1.2rem;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin-right: 3.2rem;

  svg path {
    fill: ${({ checked, theme }) => (checked ? theme.colors.primary : theme.colors.text)};
  }

  ${({ isFocus }) => isFocus
    && css`
      svg path {
        fill: ${({ checked, theme }) => (checked ? theme.colors.primary : theme.colors.text)};
      }
    `};
`;

export const AudioFileInput = styled.div`
  input {
    display: none;
    pointer-events: none;
  }
`;

export const AudioFileIcon = styled(Icons.AudioFile).attrs(({ theme }) => ({
  backgroundFill: theme.colors.text,
  fill: theme.colors.background,
  width: '1.4rem',
}))``;

export const StyledTooltip = styled(Tooltip).attrs({
  enterDelay: 300,
})``;
