// =============================
// Imports
// =============================

// External Dependencies
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Howl } from 'howler';

// Actions
import {
  getMedia as getMediaBase,
  reinitializePlayer as reinitializePlayerBase,
  fulfillRequest as fulfillRequestBase,
  setPlayerState as setPlayerStateBase,
  setGetCurrentTime as setGetCurrentTimeBase,
} from '../../../store/actions/PlayerActions';

// Constants
import * as requests from '../../../store/constants/PlayerRequests';
import * as states from '../../../store/constants/PlayerStates';

// Helpers
import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

class PlayerCore extends Component {
  static propTypes = {
    // Player State
    current: pth.playertrack,
    next: pth.playertrack,
    playerState: PropTypes.string.isRequired,
    requestPause: PropTypes.bool.isRequired,
    requestPlay: PropTypes.bool.isRequired,
    requestSeek: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /** Id of the player context */
    contextId: PropTypes.string,
    /** Name of the player context */
    contextName: PropTypes.string,
    /** Position of the track within player context */
    contextPosition: PropTypes.number,
    // Player Actions
    fulfillRequest: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,
    reinitializePlayer: PropTypes.func.isRequired,
    setGetCurrentTime: PropTypes.func.isRequired,
    setPlayerState: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: null,
    next: null,
    requestSeek: false,
    contextName: null,
    contextId: null,
    contextPosition: null,
  };

  componentDidUpdate(prevProps) {
    const { current, requestPlay, requestPause, requestSeek, fulfillRequest } = this.props;

    // All theses actions can only be triggered when currentTrack exists
    if (current) {
      // When user requests a play
      if (!prevProps.requestPlay && requestPlay) {
        if (
          prevProps.playerState === states.PAUSED
          || prevProps.playerState === states.STOPPED
          || prevProps.playerState === states.ENDED
          || prevProps.playerState === states.ERROR
        ) {
          // When PAUSED, a play will resume
          // When STOPPED, a play will restart track
          this.play();
          fulfillRequest(requests.PLAY);
        } else {
          // If the state of the player is none
          // of the above, we can simply fulfill this request
          // without doing anything
          fulfillRequest(requests.PLAY);
        }
      }

      // When user requests a pause
      if (!prevProps.requestPause && requestPause) {
        // A pause is only possible when state
        // of player is PLAYING
        if (prevProps.playerState === states.PLAYING) {
          this.pause();
          fulfillRequest(requests.PAUSE);
        } else {
          fulfillRequest(requests.PAUSE);
        }
      }

      // When user requests a seek
      if (!prevProps.requestSeek && (requestSeek || requestSeek === 0)) {
        if (prevProps.playerState === states.PLAYING || prevProps.playerState === states.PAUSED) {
          // A seek is only possible when state
          // of player is PLAYING or PAUSED
          this.seek(requestSeek);
          fulfillRequest(requests.SEEK);
        } else if (
          prevProps.playerState === states.ERROR
          || prevProps.playerState === states.STOPPED
          || prevProps.playerState === states.ENDED
        ) {
          // If user tries to seek within theses states
          // he is actually trying to play the track again
          this.play();
          fulfillRequest(requests.SEEK);
        } else {
          fulfillRequest(requests.SEEK);
        }
      }
    }

    // The first time when a track is loaded, a new instance of
    // Howler has to be created
    if (!prevProps.current && current) {
      this.initHowler();
    }

    // Every other time
    if (prevProps.current && current) {
      // When current track changes, create a new howler instance
      if (prevProps.current.id !== current.id) {
        this.destroyHowler();
        this.initHowler();
      }
    }
  }

  componentWillUnmount() {
    this.destroyHowler();
  }

  // Events
  onPlay = () => {
    const { setPlayerState } = this.props;

    // Set PLAYING state
    setPlayerState(states.PLAYING);
  };

  onLoad = () => {
    const { setPlayerState } = this.props;

    // Set LOADED state
    setPlayerState(states.LOADED);

    // A track is played as soon as
    // it is loaded
    this.play();
  };

  onPause = () => {
    const { setPlayerState } = this.props;

    // Set PAUSED state
    setPlayerState(states.PAUSED);
  };

  onEnd = () => {
    const { contextId, contextName, contextPosition, getMedia, next, setPlayerState } = this.props;

    // Set ENDED state
    setPlayerState(states.ENDED);

    // If next exists
    if (next) {
      getMedia(null, contextName, contextId, contextPosition + 1);
    }
  };

  onStop = () => {
    const { setPlayerState } = this.props;

    // When all tracks in trackList have been
    // played, set STOPPED state
    setPlayerState(states.STOPPED);
  };

  onLoadError = (id, err) => {
    const { reinitializePlayer, setPlayerState } = this.props;
    // Start by setting the state
    setPlayerState(states.ERROR);

    // Reinitialize the player
    reinitializePlayer();

    // Show error in console
    console.log(`[PlayerCore] error: ${err}`); // eslint-disable-line no-console
  };

  // Initialize Howler
  initHowler = () => {
    const { current, setGetCurrentTime, setPlayerState } = this.props;

    // From instanciation until the track is loaded
    // the state of the player is LOADING
    setPlayerState(states.LOADING);

    // New howler instance
    this.howler = new Howl({
      src: current.audiofile,
      format: ['mp3'],
      html5: true,
      onend: () => {
        this.onEnd();
      },
      onplay: () => {
        this.onPlay();
      },
      onpause: () => {
        this.onPause();
      },
      onstop: () => {
        this.onStop();
      },
      onload: () => {
        this.onLoad();
      },
      onloaderror: (id, err) => {
        this.onLoadError(id, err);
      },
    });

    // Expose the get current time function
    setGetCurrentTime(() => this.howler.seek());
  };

  // Actions
  play = () => {
    this.howler.play();
  };

  pause = () => {
    this.howler.pause();
  };

  seek = (pos = null) => {
    const { playerState } = this.props;

    if (pos || pos === 0) {
      this.howler.seek(pos);

      if (playerState === states.PAUSED) {
        this.play();
      }
    }
  };

  // Destroy Howler instance
  destroyHowler = () => {
    if (this.howler) {
      this.howler.off(); // Remove event listener
      this.howler.stop(); // Stop playback
      this.howler.unload(); // Remove sound from pool
      this.howler = null; // Destroy it
    }
  };

  // No need to render anything
  render() {
    return null;
  }
}

function mapStateToProps({ player }) {
  return {
    contextId: player.contextId,
    contextName: player.contextName,
    contextPosition: player.contextPosition,
    current: player.current,
    next: player.next,
    playerState: player.playerState,
    requestPause: player.request[requests.PAUSE],
    requestPlay: player.request[requests.PLAY],
    requestSeek: player.request[requests.SEEK],
  };
}

export default connect(mapStateToProps, {
  fulfillRequest: fulfillRequestBase,
  getMedia: getMediaBase,
  reinitializePlayer: reinitializePlayerBase,
  setGetCurrentTime: setGetCurrentTimeBase,
  setPlayerState: setPlayerStateBase,
})(PlayerCore);
