// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setPitchOpen } from '../../../../store/actions/ModalsActions';

import ModalPitch from '../../../layouts/modals/pitch';

// =============================
// Component
// =============================

function mapStateToProps({ modals, entities }) {
  return {
    pitch: entities.pitch.data,
    opened: modals.isPitchOpen,
  };
}

export default connect(mapStateToProps, {
  closePitchDescModal: () => setPitchOpen(false),
})(ModalPitch);
