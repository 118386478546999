// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip, Icons } from '@mewo/components';

import { headerHeight as headerHeightMobile } from '../../../../presentationals/header/mobile/variables';

import ArtistItem from '../../../../presentationals/artistitem';
import Button from '../../../../presentationals/button';
import Cover from '../../../../containers/cover';
import Filters from '../../../../containers/filters';
import Link from '../../../../other/link';
import MusicItem from '../../../../containers/musicitem';
import Tag from '../../../../other/tag';

import { marginAfterHeader, paddingX } from '../../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  flex-direction: column;
  padding-top: calc(${headerHeightMobile} + ${marginAfterHeader});
`;

export const EntityCategory = styled.div`
  &:not(:last-child) {
    margin-bottom: 5rem;
  }
`;

export const EntityCategoryLink = styled(Link).attrs(({ withoutLink }) => ({
  as: withoutLink ? 'div' : Link,
}))`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.6rem;
  margin-right: auto;
  padding: 0 ${paddingX};
`;

export const EntityCategoryName = styled.div`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-right: auto;
`;

export const SeeMoreArrow = styled(Icons.ArrowDropdown).attrs(() => ({
  dir: 'east',
  width: '1.6rem',
}))`
  margin-left: 2rem;
`;

export const EntityCategoryContent = styled.div`
  padding: 0 ${paddingX};

  ${({ horizontal }) => horizontal
    && css`
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;

      /** Used to give margin to last item. */
      &::after {
        content: ' ';
        display: block;
        flex-shrink: 0;
        height: 1rem;
        width: 1.2rem;
      }

      > * {
        &:not(:last-child) {
          margin-right: 1.2rem;
        }
      }
    `};
`;

export const StyledLink = styled(Link)``;

export const TagCategory = styled(Chip)`
  margin: 0.4rem 0.6rem 0.6rem 0;
`;

export const StyledTag = styled(Tag)`
  margin: 0.4rem 0.6rem 0.6rem 0;
`;

export const StyledArtistItem = styled(ArtistItem).attrs(() => ({
  withCover: true,
  withSearchFilterBtn: true,
}))``;

export const StyledMusicItem = styled(MusicItem).attrs(() => ({
  coverStyle: 'cover',
  disableExtraOptions: true,
  withActions: true,
  withDuration: false,
  withVersions: true,
  withVersionsStyle: 'count',
}))``;

export const EntityCover = styled(Cover).attrs({
  size: '14rem',
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTranslateY: true,
  playButtonSize: 'musicItem',
})`
  font-size: 0;
`;

export const Caption = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  line-height: 1.8rem;
  padding: 0 ${paddingX};
  text-align: center;

  ${({ withBtn }) => withBtn && css`
    flex-direction: column;
  `};
`;

export const StyledFilters = styled(Filters)`
  margin-bottom: 2rem;
  padding: 0 ${paddingX};

  &:not(:empty) {
    margin-bottom: 2rem;
  }
`;

export const CaptionBtn = styled(Button).attrs(() => ({
  size: 'small',
}))`
  margin: 1.2rem auto 0;
`;
