// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import ModalTrackTags from '../../../layouts/modals/tracktags';

import {
  setMusicItemOpen,
  setMusicPlayerOpen,
  setTrackTagsOpen,
} from '../../../../store/actions/ModalsActions';

// =============================
// Component
// =============================

function mapStateToProps({ modals, options }) {
  return {
    opened: modals.trackTags.isOpen,
    track: modals.trackTags.data,
    tags: options.data.tags_only,
  };
}

export default connect(mapStateToProps, {
  closeMusicItemModal: () => setMusicItemOpen(false),
  closeMusicPlayerModal: () => setMusicPlayerOpen(false),
  closeTrackTagsModal: () => setTrackTagsOpen(false),
})(ModalTrackTags);
