// =============================
// Imports
// =============================

// External Dependencies
// import { detect as detectBrowser } from 'detect-browser';

// =============================
// Helpers
// =============================

const downloadLink = (contentUrl) => {
  const link = document.createElement('a');
  link.href = contentUrl;

  if (link.download !== undefined) {
    link.download = contentUrl.substring(contentUrl.lastIndexOf('/') + 1, contentUrl.length);
  }

  /*
    NOTE: It seems that on chrome we didn't need to trigger click
    at some point, we disabled it to prevent double clicking
    if (browser.name !== 'chrome') {
      link.click();
    }
  */

  link.click();

  // Remove element
  link.remove();
};

export default downloadLink;
