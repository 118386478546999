// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getBaseRoute, getCoverUrl, getUrl } from '../../../../helpers/entity';
import { getTrackTitle, getTrackDisplayArtists, getTrackAlbum } from '../../../../helpers/entity/track';
import * as pth from '../../../../helpers/proptypes';

import { DefaultModal } from '../styles';
import {
  StyledWrapper,
  Informations,
  VideoInfo,
  VideoTitle,
  VideoDescription,
  TrackInfo,
  TrackTitle,
  GoToAlbumLink,
  AlbumCover,
  Flex,
  VideoPlayer,
  VideoContainer,
  Container,
  KeepGoodSize,
  StyledLink,
  TrackAuthors,
} from './styles';

// =============================
// Stories
// =============================

class ModalVideo extends PureComponent {
  static propTypes = {
    /** Function used to close modal */
    closeVideoModal: PropTypes.func.isRequired,
    /** Video data */
    data: PropTypes.shape({
      videoData: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
      }),
      trackData: PropTypes.shape({
        title: PropTypes.string,
        descriptions: pth.descriptions,
      }),
    }),
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    data: {},
    opened: false,
  };

  renderDisplayArtists = (track) => {
    if (!track) return null;

    const displayArtists = getTrackDisplayArtists(track);

    if (!displayArtists) return null;

    return (
      <TrackAuthors>
        {displayArtists.reduce((acc, da, i) => {
          acc.push(
            <StyledLink
              inline
              route={getBaseRoute('artists')}
              nextAs={getUrl('artists')(da, da.name)}
              // eslint-disable-next-line react/no-array-index-key
              key={`da-${da.id}-${i}`}
            >
              {da.name}
            </StyledLink>,
          );

          if (i < displayArtists.length - 1) acc.push(', ');

          return acc;
        }, [])}
      </TrackAuthors>
    );
  };

  render() {
    const {
      closeVideoModal,
      data,
      opened,
      t,
    } = this.props;

    const videoData = _get(data, 'videoData');
    const trackData = _get(data, 'trackData');

    return (
      <DefaultModal name="video" onClose={closeVideoModal} opened={opened} wideContent>
        <StyledWrapper>
          <KeepGoodSize>
            <ClickAwayListener onClickAway={closeVideoModal}>
              {videoData ? (
                <Container>
                  <VideoContainer>
                    <VideoPlayer url={videoData.url} />
                  </VideoContainer>

                  <Informations>
                    <VideoInfo>
                      <VideoTitle>{videoData.title}</VideoTitle>
                      {videoData.description && (
                        <VideoDescription>{videoData.description}</VideoDescription>
                      )}
                    </VideoInfo>

                    {trackData && (
                      <TrackInfo>
                        <Flex>
                          <TrackTitle>{getTrackTitle(trackData)}</TrackTitle>
                          {this.renderDisplayArtists(trackData)}
                          {getUrl('tracks')(trackData) && (
                            <GoToAlbumLink
                              nextAs={getUrl('tracks')(trackData)}
                              route={getBaseRoute('tracks')}
                            >
                              {t('pages:custom_page.discover_album')}
                            </GoToAlbumLink>
                          )}
                        </Flex>
                        <AlbumCover
                          linkProps={
                            getUrl('tracks')(trackData)
                              ? {
                                route: getBaseRoute('tracks'),
                                nextAs: getUrl('tracks')(trackData),
                              }
                              : {}
                          }
                          src={getCoverUrl('tracks')(trackData)}
                          alt={getTrackAlbum(trackData).title}
                        />
                      </TrackInfo>
                    )}
                  </Informations>
                </Container>
              ) : (
                <div /** ClickAwayListener require a child. */ />
              )}
            </ClickAwayListener>
          </KeepGoodSize>
        </StyledWrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation(['pages'])(ModalVideo);
