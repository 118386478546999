// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _find from 'lodash/find';
import _get from 'lodash/get';

import { logout } from '../../../store/actions/AuthActions';
import { resetModals, setAccountOpen, setMenuOpen, setPreSearchOpen, setUserMenuOpen } from '../../../store/actions/ModalsActions';
import { getUserNotifications, checkUserNotifications } from '../../../store/actions/PagesActions';

import Header from '../../presentationals/header';

import { rootRoute } from '../../../helpers/config';
import { hasAccessToPrivate } from '../../../helpers/user';

// =============================
// Component
// =============================

function mapStateToProps({ config, search, user, pages }) {
  // Retrieve 1st visible FullSizeCoverModule
  const currentFullSizeCoverModule = _find(
    search.fullSizeCoverModule,
    item => item.isVisible,
    null,
  );

  return {
    availableLanguages: _get(config, 'data.languages', []),
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    /** Data about the current fullSizeCoverModule displayed on screen. */
    fullSizeCoverModule: currentFullSizeCoverModule,
    notifications: pages.notifications,
    rightHeaderLinks: _get(config, 'menus.data.header.customLinks.right', []),
    rootRoute: rootRoute(config.data),
    user: user.data,
  };
}

export default connect(mapStateToProps, {
  checkUserNotifications,
  getUserNotifications,
  logout,
  openMenu: () => setMenuOpen(true),
  openUserMenu: () => setUserMenuOpen(true),
  resetModals,
  toggleAccountModal: setAccountOpen,
  togglePreSearchModal: setPreSearchOpen,
})(Header);
