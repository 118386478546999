/* eslint-disable react/no-array-index-key */
// =============================
// Imports
// =============================

import { createRef, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import Address from '../../../../other/address';
import MenuItem from '../menuitem';

import { getValueByLocale } from '../../../../../helpers/i18n';
import { getLinkRouteProps, isExternalLink, isLinkActive } from '../../../../../helpers/link';
import * as pth from '../../../../../helpers/proptypes';

import {
  MenuCenter,
  MenuItemCounter,
  SideMenuWrapper,
  SideMenu,
  SideMenuTitle,
  SideMenuItem,
  Link,
  DotIcon,
  FooterLinks,
  SocialLinks,
  AppleIcon,
  DeezerIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SpotifyIcon,
  TwitterIcon,
  YoutubeIcon,
  StyledUserLanguages,
  MenuContainer,
} from './styles';

// =============================
// Component
// =============================

class MenuContent extends PureComponent {
  static propTypes = {
    /** If True, animation is complete. */
    animationComplete: PropTypes.bool.isRequired,
    /** Callback function use to close Menu */
    closeMenu: PropTypes.func.isRequired,
    /** App config. */
    config: pth.config.isRequired,
    /** Menu data */
    data: pth.configMenus.isRequired,
    /** User locale. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, user is logged. */
    isLogged: PropTypes.bool.isRequired,
    /** Number of user playlists */
    nbPlaylists: PropTypes.number.isRequired,
    /** Callback function to update popperjs instance. */
    onAnimationComplete: PropTypes.func.isRequired,
    /** If true, Menu is opened */
    opened: PropTypes.bool.isRequired,
    /** Router props. */
    router: PropTypes.shape({
      asPath: PropTypes.string,
    }).isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.menuCenterRef = createRef;
    this.sideMenuRef = createRef;
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { closeMenu, opened } = this.props;

    // If escape button & opened
    if (opened && e.keyCode === 27) {
      closeMenu();
    }
  };

  getBlogUrl = () => {
    const { config } = this.props;

    return _get(config, 'blogUrl') || null;
  };

  getLinkCounter = (link) => {
    const { nbPlaylists } = this.props;

    if (link.slug === 'userplaylists') {
      if (nbPlaylists) return <MenuItemCounter>{nbPlaylists}</MenuItemCounter>;
    }

    return null;
  };

  handleCloseMenu = (link) => {
    const {
      closeMenu,
      router: { asPath },
    } = this.props;

    // External link always close menu when clicked on.
    if (isExternalLink(link)) {
      return closeMenu();
    }

    /**
     * Closing menu for internal link is handled by nextRouting on the AppShell,
     * so we don't need to close the menu by ourselves, except when already
     * on destination page (because the route won't change), in that case, we need
     * to pass the closeMenu function to the Link.
     * We also need to prepend a "/" to link.slug to test equality
     * because router asPath has a trailing "/" that link.slug don't.
     * */
    let linkSlug = '/'; // Homepage
    if (link.page && link.slug) linkSlug = `/page/${link.slug}`;
    if (!link.page && link.slug) linkSlug = `/${link.slug}`;

    if (linkSlug === asPath) {
      return closeMenu();
    }

    return null;
  };

  renderFooterLinks = () => {
    const {
      data,
      i18n: { language },
      isLogged,
      t,
    } = this.props;

    const footerLinks = [
      ..._get(data, 'mainMenu.customLinks.footer', []),
      {
        names: [
          { locale: 'en', value: t('components:menu.legal', { lng: 'en' }) },
          { locale: 'fr', value: t('components:menu.legal', { lng: 'fr' }) },
          { locale: 'es', value: t('components:menu.legal', { lng: 'es' }) },
          { locale: 'de', value: t('components:menu.legal', { lng: 'de' }) },
        ],
        slug: '/legal',
        page: null,
      },
      {
        names: [
          { locale: 'en', value: t('components:menu.cookies', { lng: 'en' }) },
          { locale: 'fr', value: t('components:menu.cookies', { lng: 'fr' }) },
          { locale: 'es', value: t('components:menu.cookies', { lng: 'es' }) },
          { locale: 'de', value: t('components:menu.cookies', { lng: 'de' }) },
        ],
        slug: 'cookies',
        page: null,
      },
    ];

    const nbFooterLinks = footerLinks.length;

    return footerLinks.map((link, i) => (
      <Fragment key={`footer-${link.slug}-${i}`}>
        <Link
          onClickPassthrough={() => this.handleCloseMenu(link)}
          inactive={!isLinkActive(link, isLogged)}
          {...getLinkRouteProps(link, isLogged)}
        >
          {getValueByLocale(link.names, language)}
        </Link>
        {i !== nbFooterLinks - 1 && <DotIcon />}
      </Fragment>
    ));
  };

  renderMainLinks = () => {
    const {
      animationComplete,
      data,
      i18n: { language },
      isLogged,
    } = this.props;

    const mainLinks = _get(data, 'mainMenu.customLinks.main', []);
    const nbMainLinks = mainLinks.length;

    if (!nbMainLinks) return null;

    return mainLinks.map((link, mainIndex) => (
      <MenuItem
        key={mainIndex}
        animationComplete={animationComplete}
        getLinkCounter={this.getLinkCounter}
        getValueByLocale={getValueByLocale}
        handleCloseMenu={this.handleCloseMenu}
        index={mainIndex}
        isLogged={isLogged}
        language={language}
        link={link}
        sideMenuRef={this.sideMenuRef}
        menuCenterRef={this.menuCenterRef}
      />
    ));
  };

  getSocialLinkIcon = (key) => {
    switch (key) {
      case 'applemusic':
        return <AppleIcon />;
      case 'deezer':
        return <DeezerIcon />;
      case 'facebook':
        return <FacebookIcon />;
      case 'instagram':
        return <InstagramIcon />;
      case 'linkedin':
        return <LinkedinIcon />;
      case 'spotify':
        return <SpotifyIcon />;
      case 'twitter':
        return <TwitterIcon />;
      case 'youtube':
        return <YoutubeIcon />;
      default:
        return key;
    }
  };

  renderSocialLinks = () => {
    const { closeMenu, data, isLogged, t } = this.props;

    const socialLinks = _get(data, 'mainMenu.socialLinks', []);
    const nbSocialLinks = Object.keys(socialLinks).length;
    const blogUrl = this.getBlogUrl();

    // No Social Links & no blog url.
    if (!nbSocialLinks && !blogUrl) return null;

    const LinksToRender = [];

    // Social links and blog url can't be inactive
    Object.keys(socialLinks).forEach((key, i) => {
      const link = { slug: socialLinks[key] };

      LinksToRender.push(
        <Fragment key={`social-${link.slug}-${i}`}>
          <Link
            onClickPassthrough={() => this.handleCloseMenu(link)}
            {...getLinkRouteProps(link, isLogged)}
          >
            {this.getSocialLinkIcon(key)}
          </Link>
        </Fragment>,
      );
    });

    if (blogUrl) {
      LinksToRender.push(
        <Link onClickPassthrough={closeMenu} key="blog" route={blogUrl}>
          {t('components:menu.blog')}
        </Link>,
      );
    }

    return LinksToRender;
  };

  render() {
    const { closeMenu, config, onAnimationComplete, opened, t } = this.props;

    const hasContactAddress = !!(
      _get(config, 'globalContact.address') || _get(config, 'globalContact.addressComplement')
    );

    const hasContactInfo = !!(
      _get(config, 'globalContact.email') || _get(config, 'globalContact.phone')
    );

    return (
      <MenuContainer key="menuContainer" opened={opened} onAnimationComplete={onAnimationComplete}>
        <MenuCenter
          key="MenuCenter"
          ref={(node) => {
            this.menuCenterRef = node;
          }}
        >
          {this.renderMainLinks()}
        </MenuCenter>

        <SideMenuWrapper
          ref={(node) => {
            this.sideMenuRef = node;
          }}
        >
          {hasContactAddress && (
            <SideMenu>
              <SideMenuTitle>{t('components:menu.localisation')}</SideMenuTitle>
              <Address placeId={_get(config, 'globalContact.address')}>
                {address => address && <SideMenuItem>{address}</SideMenuItem>}
              </Address>
              {_get(config, 'globalContact.addressComplement') && (
                <SideMenuItem>{_get(config, 'globalContact.addressComplement')}</SideMenuItem>
              )}
            </SideMenu>
          )}

          {hasContactInfo && (
            <SideMenu>
              <SideMenuTitle>{t('components:menu.contact')}</SideMenuTitle>
              {_get(config, 'globalContact.email') && (
                <SideMenuItem>{_get(config, 'globalContact.email')}</SideMenuItem>
              )}
              {_get(config, 'globalContact.phone') && (
                <SideMenuItem>{_get(config, 'globalContact.phone')}</SideMenuItem>
              )}
            </SideMenu>
          )}
        </SideMenuWrapper>

        <StyledUserLanguages opened={opened} />

        <FooterLinks>{this.renderFooterLinks()}</FooterLinks>

        <SocialLinks onClick={closeMenu}>{this.renderSocialLinks()}</SocialLinks>
      </MenuContainer>
    );
  }
}

export default compose(withRouter, withTranslation('components'))(MenuContent);
