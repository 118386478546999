// =============================
// Imports
// =============================

import styled from 'styled-components';
import posed from 'react-pose';

// =============================
// Styles
// =============================

export const Content = styled.div`
  ${({ theme }) => theme.mediaAbove.minWidth`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 100vh;
    min-width: ${theme.bp.minWidth}px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: filter ${theme.transition.medium};
    width: 100%;
    z-index: ${theme.zIndex.scAppShellContent};
  `};
`;

export const RouteContainer = posed(styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaAbove.minWidth`
    z-index: ${theme.zIndex.scRouteContainer};
  `};
`)({
  enter: {
    opacity: 1,
    delay: ({ noOpacityChange }) => (noOpacityChange ? 0 : 300),
    beforeChildren: true,
  },
  exit: {
    opacity: ({ noOpacityChange }) => (noOpacityChange ? 1 : 0),
  },
});
