// =============================
// Imports
// =============================

import styled from 'styled-components';

import { headerHeight } from '../../../presentationals/header/mobile/variables';
import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaBelow.minWidth`
    padding-left: ${paddingX};
    padding-right: ${paddingX};
    padding-top: ${headerHeight};
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 60rem;
  `};
`;

export const DownloadStatus = styled.div`
  font-size: 1rem;
  margin-bottom: 5rem;
`;

export const Status = styled.span`
  color: ${({ canDownload, theme }) => (canDownload ? theme.colors.type.success : theme.colors.type.error)};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export const TripleInputWrapper = styled.div`
  display: grid;
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaAbove.minWidth`
    grid-template-columns: repeat(3, 1fr);
  `};
`;
