// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setTrackLyricsOpen } from '../../../../store/actions/ModalsActions';

import ModalTrack from '../../../layouts/modals/trackLyrics';

// =============================
// Component
// =============================

function mapStateToProps({ modals }) {
  return {
    track: modals.trackLyrics.data,
    opened: modals.trackLyrics.isOpen,
  };
}

export default connect(mapStateToProps, {
  closeTrackLyricsModal: () => setTrackLyricsOpen(false),
})(ModalTrack);
