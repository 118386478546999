// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a pitch
 * @param {object} pitch - Pitch object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getPitchCoverUrl = getGenericDocCoverUrl;

/**
 * Get covers of tracks from a pitch
 * @param {object} pitch - Pitch object
 * @param {string} oneSize - Image size if there is only one cover
 * can be "large", "small" or "xsmall"
 * @param {string} multipleSize - Image size if there are multiple covers
 * can be "large", "small" or "xsmall"
 * @return {string[]} - Cover url
 */
export const getPitchTracksCoversUrl = (pitch, oneSize = 'large', multipleSize = 'small') => {
  const covers = _get(pitch, 'covers', []) || [];
  const size = covers.length < 4 ? oneSize : multipleSize;

  return covers.map((cover) => {
    switch (size) {
      case 'xsmall':
        return (
          _get(cover, `[${size}].url`)
          || _get(cover, 'small.url')
          || _get(cover, 'large.url')
          || _get(cover, 'original.url')
        );

      case 'small':
        return (
          _get(cover, `[${size}].url`) || _get(cover, 'large.url') || _get(cover, 'original.url')
        );

      case 'large':
        return _get(cover, `[${size}].url`) || _get(cover, 'original.url');

      default:
        return _get(cover, 'original.url');
    }
  });
};

/**
 * Get pitch title
 * @param {object} pitch - Pitch object
 * @return {string} - Pitch title
 */
export const getPitchTitle = pitch => _get(pitch, 'title');
