// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';

import { StyledArrowDropdown, Wrapper, Input } from './styles';

// =============================
// Component
// =============================

const SelectInput = ({ className, onChange, options, placeholder, value }) => {
  if (!options.length) return null;

  return (
    <Wrapper className={className}>
      <Input onChange={onChange} value={value}>
        {placeholder && (<option disabled value="">{placeholder}</option>)}
        {options.map(item => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </Input>
      <StyledArrowDropdown />
    </Wrapper>
  );
};

SelectInput.displayName = 'SelectInputMobile';

SelectInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  /** Options array */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

SelectInput.defaultProps = {
  className: '',
  placeholder: '',
  value: '',
};

export default SelectInput;
