export const OTHER_ERRORS_INFO = 'OTHER_ERRORS_INFO';

/* Config */
export const SEND_CONTACT_EMAIL_SUCCESS_INFO = 'SEND_CONTACT_EMAIL_SUCCESS_INFO';

/* User */
export const AUTH_INVALID_ACCESS_INFO = 'AUTH_INVALID_ACCESS_INFO';
export const REGISTER_USER_SUCCESS_INFO = 'REGISTER_USER_SUCCESS_INFO';
export const ACTIVATE_ACCOUNT_SUCCESS_INFO = 'ACTIVATE_ACCOUNT_SUCCESS_INFO';
export const ACTIVATE_ACCOUNT_FAILURE_INFO = 'ACTIVATE_ACCOUNT_FAILURE_INFO';

export const SUBSCRIBE_TO_NEWSLETTER_SUCCESS_INFO = 'SUBSCRIBE_TO_NEWSLETTER_SUCCESS_INFO';
export const SUBSCRIBE_TO_NEWSLETTER_FAILURE_INFO = 'SUBSCRIBE_TO_NEWSLETTER_FAILURE_INFO';

export const MODIFY_USER_SUCCESS_INFO = 'MODIFY_USER_SUCCESS_INFO';
export const MODIFY_USER_FAILURE_INFO = 'MODIFY_USER_FAILURE_INFO';

export const MODIFY_USER_PASSWORD_SUCCESS_INFO = 'MODIFY_USER_PASSWORD_SUCCESS_INFO';
export const MODIFY_USER_PASSWORD_FAILURE_INFO = 'MODIFY_USER_PASSWORD_FAILURE_INFO';

/* User Favorites */
export const ADD_TO_FAVORITES_FAILURE_INFO = 'ADD_TO_FAVORITES_FAILURE_INFO';

/* User Playlist */
export const CREATE_USER_PLAYLIST_FAILURE_INFO = 'CREATE_USER_PLAYLIST_FAILURE_INFO';

export const MODIFY_USER_PLAYLIST_FAILURE_INFO = 'MODIFY_USER_PLAYLIST_FAILURE_INFO';

export const ADD_TRACK_TO_PLAYLIST_FAILURE_INFO = 'ADD_TRACK_TO_PLAYLIST_FAILURE_INFO';

export const REORDER_TRACK_IN_PLAYLIST_FAILURE_INFO = 'REORDER_TRACK_IN_PLAYLIST_FAILURE_INFO';

export const DUPLICATE_PLAYLIST_SUCCESS_INFO = 'DUPLICATE_PLAYLIST_SUCCESS_INFO';
export const DUPLICATE_PLAYLIST_FAILURE_INFO = 'DUPLICATE_PLAYLIST_FAILURE_INFO';
export const DUPLICATE_PITCH_SUCCESS_INFO = 'DUPLICATE_PITCH_SUCCESS_INFO';
export const DUPLICATE_PITCH_FAILURE_INFO = 'DUPLICATE_PITCH_FAILURE_INFO';
export const DUPLICATE_USER_PLAYLIST_SUCCESS_INFO = 'DUPLICATE_USER_PLAYLIST_SUCCESS_INFO';
export const DUPLICATE_USER_PLAYLIST_FAILURE_INFO = 'DUPLICATE_USER_PLAYLIST_FAILURE_INFO';

/* Track Media & Files */
export const GET_FILES_FAILURE_INFO = 'GET_FILES_FAILURE_INFO';
export const GET_MEDIA_FAILURE_INFO = 'GET_MEDIA_FAILURE_INFO';

/* Maia Search */
export const REQUEST_MAIA_TEXT_FAILURE_INFO = 'REQUEST_MAIA_TEXT_FAILURE_INFO';
export const REQUEST_MAIA_AUDIO_FAILURE_INFO = 'REQUEST_MAIA_AUDIO_FAILURE_INFO';
export const GET_PRE_SEARCH_DATA_FAILURE_INFO = 'GET_PRE_SEARCH_DATA_FAILURE_INFO';
export const GET_ENTITY_SEARCH_DATA_FAILURE_INFO = 'GET_ENTITY_SEARCH_DATA_FAILURE_INFO';
export const GET_TRACKS_SEARCH_DATA_FAILURE_INFO = 'GET_TRACKS_SEARCH_DATA_FAILURE_INFO';
export const GET_USER_PLAYLISTS_FAILURE_INFO = 'GET_USER_PLAYLISTS_FAILURE_INFO';

/* Other */
export const DOWNLOAD_AUDIOFILE_FAILURE_INFO = 'DOWNLOAD_AUDIOFILE_FAILURE_INFO';

export const DOWNLOAD_ARCHIVES_SUCCESS_INFO = 'DOWNLOAD_ARCHIVES_SUCCESS_INFO';
export const DOWNLOAD_ARCHIVES_FAILURE_INFO = 'DOWNLOAD_ARCHIVES_FAILURE_INFO';

export const POLLING_DOWNLOAD_ARCHIVE_FAILURE_INFO = 'POLLING_DOWNLOAD_ARCHIVE_FAILURE_INFO';

export const DOWNLOAD_COPYRIGHT_SUCCESS_INFO = 'DOWNLOAD_COPYRIGHT_SUCCESS_INFO';
export const DOWNLOAD_COPYRIGHT_FAILURE_INFO = 'DOWNLOAD_COPYRIGHT_FAILURE_INFO';

export const COPY_SHARE_LINK_SUCCESS = 'COPY_SHARE_LINK_SUCCESS';
