// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import { i18n, withTranslation } from '../../../config/i18n';

import { DropdownList } from '../dropdown';

import { Wrapper, ActualLang } from './styles';

import { getCookieConfig } from '../../../helpers/misc';

// =============================
// Component
// =============================

class LanguageSwitcher extends Component {
  static propTypes = {
    className: PropTypes.string,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Available languages. */
    languages: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Location protocol, like https. */
    locationProtocol: PropTypes.string.isRequired,
    /** Modify user language */
    modifyUserLanguage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  setUserLocale = async (locale) => {
    const { isLogged, locationProtocol, modifyUserLanguage } = this.props;

    if (isLogged) {
      await modifyUserLanguage(locale);
    }

    Cookies.set(
      'next-i18next',
      locale,
      getCookieConfig(3, locationProtocol),
    );

    i18n.changeLanguage(locale);
  };

  render() {
    const {
      className,
      i18n: { language },
      languages,
    } = this.props;

    const languagesOptions = [
      {
        action: () => this.setUserLocale('en'),
        label: 'English',
        name: 'en',
      },
      {
        action: () => this.setUserLocale('fr'),
        label: 'Français',
        name: 'fr',
      },
      {
        action: () => this.setUserLocale('de'),
        label: 'Deutsch',
        name: 'de',
      },
      {
        action: () => this.setUserLocale('es'),
        label: 'Español',
        name: 'es',
      },
    ].filter(lang => languages.includes(lang.name));

    return (
      <Wrapper className={className}>
        <DropdownList
          options={languagesOptions}
          togglerElement={<ActualLang>{language}</ActualLang>}
        />
      </Wrapper>
    );
  }
}

export default withTranslation()(LanguageSwitcher);
