// =============================
// Imports
// =============================

import styled from 'styled-components';

import MusicItem from '../../../containers/musicitem';

// =============================
// Component
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledMusicItem = styled(MusicItem).attrs(() => ({
  disableExtraOptions: true,
  withActions: false,
  withDuration: false,
  withVersions: true,
  withVersionsStyle: 'name',
}))``;
