// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { headerHeight } from '../presentationals/header/desktop/variables';
import { wrapperWidth, wrapperWidthSxga, wrapperWidthWxgaPlus, wrapperWidthWuxga } from './common.variables';

// =============================
// Styles
// =============================

export const InheritSize = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: ${wrapperWidth};
  width: 100%;

  ${({ noPaddingTop }) => !noPaddingTop && css`
    padding-top: ${headerHeight};
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    max-width: ${wrapperWidthSxga};
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    max-width: ${wrapperWidthWxgaPlus};
  `};

  ${/* We set it on wuxga because if we set it before, content can go behind Menu SVG gradient. */
  ({ theme }) => theme.mediaAbove.wuxga`
    margin: 0 auto;
    max-width: ${wrapperWidthWuxga};
    width: 100%;
  `};
`;

export const OneLineHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
