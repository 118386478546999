/* eslint-disable jsx-a11y/click-events-have-key-events, react/sort-comp */
// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Router, { withRouter } from 'next/router';
import { WithCustomTheme } from '@mewo/components';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

import { withTranslation } from '../../../../../config/i18n';

import GuidedSearch from './guidedSearch';
import Modal from '../../../../presentationals/modal';
import ModalCloser from '../../../../other/modalCloser';
import Link from '../../../../other/link';

import * as playerCtx from '../../../../../store/constants/PlayerContexts';

import * as entity from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';
import { presentTags } from '../../../../../helpers/entity/common';
import { composeQuery, transformTotalValueToString } from '../../../../../helpers/search';
import { getValueByLocale } from '../../../../../helpers/i18n';

import {
  EntityCategory,
  EntityCategoryLink,
  EntityCategoryName,
  Wrapper,
  SeeMoreArrow,
  StyledArtistItem,
  StyledMusicItem,
  EntityCategoryContent,
  EntityCover,
  TagCategory,
  StyledTag,
  Caption,
  CaptionBtn,
  StyledFilters,
} from './styles';

// =============================
// Stories
// =============================

class ModalPreSearch extends PureComponent {
  static displayName = 'ModalPreSearchMobile';

  static propTypes = {
    closePreSearchModal: PropTypes.func.isRequired,
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Initial search filters (even those non available anymore due to filtering). */
    initialFacets: pth.facets.isRequired,
    /** If True, search value is an external link. */
    isExternal: PropTypes.bool.isRequired,
    /** If True, search result are being fetch. */
    isFetching: PropTypes.bool.isRequired,
    noBackground: PropTypes.bool.isRequired,
    opened: PropTypes.bool,
    playerIsOpened: PropTypes.bool.isRequired,
    requestMaiaTextSearch: PropTypes.func.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string,
    }).isRequired,
    searchDatas: pth.preSearchDatas.isRequired,
    searchQuery: pth.searchquery.isRequired,
    searchTracksTotal: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }).isRequired,
    searchValue: PropTypes.string,
    t: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    /** All existing tags subCategory. (Guided search only show subCategory tags). */
    tagsSc: PropTypes.arrayOf(pth.tagSubCategory).isRequired,
  };

  static defaultProps = {
    opened: false,
    searchValue: '',
  };

  componentDidUpdate(prevProps) {
    const {
      router: { asPath: prevPath },
    } = prevProps;

    const {
      config,
      closePreSearchModal,
      opened,
      router: { asPath: path },
      searchValue,
    } = this.props;

    const withGuidedSearch = _get(config, 'customisations.guidedSearch.active', false);

    const changedPath = prevPath !== path;
    const nextPathIsSearch = path.indexOf('/search') !== -1;
    const nextPathIsSimilaritySearch = nextPathIsSearch && path.indexOf('search_id') !== -1;

    const guidedSearchShouldClose = withGuidedSearch
      && (searchValue || !nextPathIsSearch || nextPathIsSimilaritySearch || path === '/search');

    if (changedPath && opened && (!withGuidedSearch || guidedSearchShouldClose)) {
      closePreSearchModal();
    }
  }

  handleSubCatClick = (subCatId) => {
    const { searchQuery } = this.props;

    const filters = _get(searchQuery, 'options.filters', {});
    let tagsOrFilters = _get(filters, 'tags_or', []);
    const otherFilters = _omit(filters, ['tags_or']);

    if (tagsOrFilters.indexOf(subCatId) === -1) {
      tagsOrFilters.push(subCatId);
    } else {
      tagsOrFilters = tagsOrFilters.filter(subCat => subCat !== subCatId);
    }

    const query = composeQuery(searchQuery, { tags_or: tagsOrFilters, ...otherFilters });

    Router.push(`/search?${query}`);
  };

  renderTagCategoriesResults = (data) => {
    const { facets, i18n: { language }, searchQuery } = this.props;

    const facetsTags = Object.keys(facets.tags);
    const activeSubCats = _get(searchQuery, 'options.filters.tags_or', []);

    return data.map((tagSc) => {
      const isAvailable = !!_filter(tagSc.tags, t => _includes(facetsTags, t.id)).length;

      return (
        <TagCategory
          key={tagSc.id}
          name={getValueByLocale(_get(tagSc, 'names', []), language)}
          disabled={!isAvailable}
          indicationColor={tagSc.color}
          color={activeSubCats.indexOf(tagSc.id) !== -1 ? 'primary' : 'default'}
          onClick={() => this.handleSubCatClick(tagSc.id)}
        />
      );
    });
  };

  renderPreSearch = () => {
    const {
      closePreSearchModal,
      i18n: { language },
      isExternal,
      isFetching,
      requestMaiaTextSearch,
      searchDatas,
      searchValue,
      t,
      tags,
      tagsSc,
    } = this.props;

    const noData = _every(
      [
        _get(searchDatas, 'tags.total.value'),
        _get(searchDatas, 'tracks.total.value'),
        _get(searchDatas, 'albums.total.value'),
        _get(searchDatas, 'artists.total.value'),
        _get(searchDatas, 'playlists.total.value'),
        _get(searchDatas, 'catalogs.total.value'),
        _get(searchDatas, 'labels.total.value'),
        _get(searchDatas, 'publishers.total.value'),
      ],
      e => !e,
    );

    if (isExternal) {
      return (
        <Caption withBtn>
          {t('pages:search.pre_search_is_external')}
          <CaptionBtn onClick={() => requestMaiaTextSearch(searchValue, searchValue)}>
            {t('pages:search.launch_search')}
          </CaptionBtn>
        </Caption>
      );
    }

    if (noData && !isFetching && searchValue) return <Caption>{t('pages:search.pre_search_no_results')}</Caption>;

    const displayTagCategories = presentTags(
      _get(searchDatas, 'tagCategories.data', [])
        .map(({ id }) => tagsSc.find(tagSc => tagSc.id === id)),
      language,
    );

    const displayTags = presentTags(
      _get(searchDatas, 'tags.data', [])
        .map(({ id }) => tags.find(tag => tag.id === id)),
      language,
    );

    return (
      <Fragment>
        {!isFetching && searchValue && <StyledFilters />}

        {!!searchDatas.tagCategories.data.length && (
          <EntityCategory>
            <EntityCategoryLink withoutLink>
              <EntityCategoryName>
                {t('pages:search.tags_categories')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tagCategories.total)})`}
              </EntityCategoryName>
            </EntityCategoryLink>

            <EntityCategoryContent>
              {this.renderTagCategoriesResults(displayTagCategories)}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.tags.data.length && (
          <EntityCategory>
            <EntityCategoryLink withoutLink>
              <EntityCategoryName>
                {t('pages:search.tags')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tags.total)})`}
              </EntityCategoryName>
            </EntityCategoryLink>

            <EntityCategoryContent>
              {displayTags.map(tag => (
                <StyledTag
                  key={tag.id}
                  data={tag}
                  onClickPassthrough={closePreSearchModal}
                  checkIfUsable
                  stringSearchValue={searchValue}
                  withSynonyms
                />
              ))}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.tracks.data.length && (
          <EntityCategory>
            <EntityCategoryLink route={entity.getEntitySearchUrl('tracks')(searchValue)}>
              <EntityCategoryName>
                {t('pages:search.tracks')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tracks.total)})`}
              </EntityCategoryName>
              <SeeMoreArrow />
            </EntityCategoryLink>

            <EntityCategoryContent>
              {searchDatas.tracks.data.map(track => (
                <StyledMusicItem
                  key={track.id}
                  music={track}
                  contextName={playerCtx.SEARCH}
                />
              ))}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.playlists.data.length && (
          <EntityCategory>
            <EntityCategoryLink route={entity.getEntitySearchUrl('playlists')(searchValue)}>
              <EntityCategoryName>
                {t('pages:search.playlists')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.playlists.total)})`}
              </EntityCategoryName>
              <SeeMoreArrow />
            </EntityCategoryLink>
            <EntityCategoryContent horizontal>
              {searchDatas.playlists.data.map(playlist => (
                <ModalCloser
                  onClose={closePreSearchModal}
                  path={entity.getUrl('playlists')(playlist)}
                  key={playlist.id}
                >
                  {onClose => (
                    <EntityCover
                      alt={entity.getTitle('playlists')(playlist)}
                      lazyload={false}
                      LinkComponent={Link}
                      linkProps={{
                        route: entity.getBaseRoute('playlists'),
                        nextAs: entity.getUrl('playlists')(playlist),
                        onClickPassthrough: onClose,
                      }}
                      placeholderType="playlist"
                      src={entity.getMultiCoverUrls('playlists', 'small')(playlist)}
                      title={entity.getTitle('playlists')(playlist)}
                      type="playlist"
                    />
                  )}
                </ModalCloser>
              ))}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.albums.data.length && (
          <EntityCategory>
            <EntityCategoryLink route={entity.getEntitySearchUrl('albums')(searchValue)}>
              <EntityCategoryName>
                {t('pages:search.albums')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.albums.total)})`}
              </EntityCategoryName>
              <SeeMoreArrow />
            </EntityCategoryLink>
            <EntityCategoryContent horizontal>
              {searchDatas.albums.data.map(album => (
                <ModalCloser
                  onClose={closePreSearchModal}
                  path={entity.getUrl('albums')(album)}
                  key={album.id}
                >
                  {onClose => (
                    <EntityCover
                      alt={entity.getTitle('albums')(album)}
                      description={entity.getDescription('albums')(album, language)}
                      lazyload={false}
                      LinkComponent={Link}
                      linkProps={{
                        route: entity.getBaseRoute('albums'),
                        nextAs: entity.getUrl('albums')(album),
                        onClickPassthrough: onClose,
                      }}
                      placeholderType="album"
                      src={entity.getCoverUrl('albums')(album)}
                      title={entity.getTitle('albums')(album)}
                      type="album"
                    />
                  )}
                </ModalCloser>
              ))}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.artists.data.length && (
          <EntityCategory>
            <EntityCategoryLink route={entity.getEntitySearchUrl('artists')(searchValue)}>
              <EntityCategoryName>
                {t('pages:search.artists')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.artists.total)})`}
              </EntityCategoryName>
              <SeeMoreArrow />
            </EntityCategoryLink>
            <EntityCategoryContent>
              {searchDatas.artists.data.map(artist => (
                <ModalCloser
                  onClose={closePreSearchModal}
                  path={entity.getUrl('artists')(artist)}
                  key={artist.id}
                >
                  {closePreSearch => (
                    <ModalCloser
                      onClose={closePreSearchModal}
                      path={entity.getSearchUrl('artists')(artist)}
                      key={artist.id}
                    >
                      {closePreSearchFilter => (
                        <StyledArtistItem
                          artist={artist}
                          key={artist.id}
                          onClickPassthrough={closePreSearch}
                          onAddFilterClickPassthrough={closePreSearchFilter}
                        />
                      )}
                    </ModalCloser>
                  )}
                </ModalCloser>
              ))}
            </EntityCategoryContent>
          </EntityCategory>
        )}

        {!!searchDatas.catalogs.data.length && (
          <ModalCloser
            onClose={closePreSearchModal}
            path={entity.getEntitySearchUrl('catalogs')(searchValue)}
          >
            {onClose => (
              <EntityCategoryLink
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('catalogs')(searchValue)}
              >
                <EntityCategoryName>
                  {t('pages:search.see_catalogs_results')}
                  &nbsp;
                  {`(${transformTotalValueToString(searchDatas.catalogs.total)})`}
                </EntityCategoryName>
                <SeeMoreArrow />
              </EntityCategoryLink>
            )}
          </ModalCloser>
        )}

        {!!searchDatas.labels.data.length && (
          <ModalCloser
            onClose={closePreSearchModal}
            path={entity.getEntitySearchUrl('labels')(searchValue)}
          >
            {onClose => (
              <EntityCategoryLink
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('labels')(searchValue)}
              >
                <EntityCategoryName>
                  {t('pages:search.see_labels_results')}
                  &nbsp;
                  {`(${transformTotalValueToString(searchDatas.labels.total)})`}
                </EntityCategoryName>
                <SeeMoreArrow />
              </EntityCategoryLink>
            )}
          </ModalCloser>
        )}

        {!!searchDatas.publishers.data.length && (
          <ModalCloser
            onClose={closePreSearchModal}
            path={entity.getEntitySearchUrl('publishers')(searchValue)}
          >
            {onClose => (
              <EntityCategoryLink
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('publishers')(searchValue)}
              >
                <EntityCategoryName>
                  {t('pages:search.see_publishers_results')}
                  &nbsp;
                  {`(${transformTotalValueToString(searchDatas.publishers.total)})`}
                </EntityCategoryName>
                <SeeMoreArrow />
              </EntityCategoryLink>
            )}
          </ModalCloser>
        )}
      </Fragment>
    );
  };

  render() {
    const {
      closePreSearchModal,
      config,
      facets,
      i18n: { language },
      initialFacets,
      isExternal,
      isFetching,
      noBackground,
      opened,
      playerIsOpened,
      searchQuery,
      searchTracksTotal,
      searchValue,
      t,
      tagsSc,
    } = this.props;

    const withGuidedSearch = _get(config, 'customisations.guidedSearch.active', false);
    const guidedSearchActive = !isExternal && !isFetching && !searchValue && withGuidedSearch;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.presearch,
        })}
      >
        <Modal
          name="presearch"
          noBackground={noBackground}
          noBottomFade
          noTopMask
          noFade
          onClose={closePreSearchModal}
          opened={opened}
          playerIsOpened={playerIsOpened}
        >
          <Wrapper>
            {guidedSearchActive && (
              <GuidedSearch
                config={config}
                locale={language}
                tagsSc={tagsSc}
                searchQuery={searchQuery}
                searchTracksTotal={searchTracksTotal}
                facets={facets}
                initialFacets={initialFacets}
                closePreSearchModal={closePreSearchModal}
                t={t}
              />
            )}
            {this.renderPreSearch()}
          </Wrapper>
        </Modal>
      </WithCustomTheme>
    );
  }
}

export default compose(withTranslation('pages'), withRouter)(ModalPreSearch);
