// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';
import * as states from '../constants/PlayerStates';
import * as requests from '../constants/PlayerRequests';

// =============================
// Initial state
// =============================

const initialState = {
  /* Player */
  isOpened: false,
  getCurrentTime: null,
  previousPlayerState: null,
  playerState: states.STOPPED,
  request: {
    [requests.PLAY]: false,
    [requests.PAUSE]: false,
    [requests.SEEK]: false,
    [requests.PREV_TRACK]: false,
    [requests.NEXT_TRACK]: false,
  },
  /* Media */
  isFetching: false,
  prev: null,
  current: null,
  next: null,
  trackId: null,
  contextName: null,
  contextId: null,
  contextPosition: null,
};

// =============================
// Reducer
// =============================

export default function player(state = initialState, action) {
  switch (action.type) {
    // =============================
    // Player actions
    // =============================
    case acts.OPEN_PLAYER:
      return {
        ...state,
        isOpened: true,
      };

    case acts.CLOSE_PLAYER:
      return {
        ...state,
        isOpened: false,
      };

    case acts.FULFILL_PLAYER_REQUEST:
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload]: false,
        },
      };

    case acts.SET_PLAYER_STATE:
      return {
        ...state,
        previousPlayerState: state.playerState,
        playerState: action.payload,
      };

    case acts.SET_PLAYER_GET_CURRENT_TIME_FNC:
      return {
        ...state,
        getCurrentTime: action.payload,
      };

    case acts.PLAYER_REQUEST_PLAY:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PLAY]: true,
        },
      };

    case acts.PLAYER_REQUEST_PAUSE:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PAUSE]: true,
        },
      };

    case acts.PLAYER_REQUEST_SET_SEEKING_TIME:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.SEEK]: action.payload,
        },
      };

    case acts.PLAYER_REQUEST_GO_TO_PREV_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.PREV_TRACK]: true,
        },
      };

    case acts.PLAYER_REQUEST_GO_TO_NEXT_TRACK:
      return {
        ...state,
        request: {
          ...state.request,
          [requests.NEXT_TRACK]: true,
        },
      };

    // =============================
    // Media API actions
    // =============================
    case acts.GET_MEDIA_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.SET_SINGLE_MEDIA:
      return {
        ...state,
        prev: null,
        next: null,
        contextId: null,
        contextPosition: null,
        current: action.payload.current,
        trackId: action.payload.trackId,
        contextName: action.payload.contextName,
      };

    case acts.SET_MEDIA_PLAYLIST:
      return {
        ...state,
        trackId: null,
        prev: action.payload.prev,
        current: action.payload.current,
        next: action.payload.next,
        contextName: action.payload.contextName,
        contextId: action.payload.contextId,
        contextPosition: action.payload.contextPosition,
      };

    case acts.GET_MEDIA_SUCCESS:
    case acts.GET_MEDIA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case acts.UPDATE_CONTEXT_POSITION:
      return {
        ...state,
        contextPosition: action.payload,
      };

    case acts.UPDATE_PLAYER_FAVORITES: {
      const nextCurrent = state.current && state.current.id === action.payload.id
        ? { ...state.current, isFavorite: action.payload.isFavorite }
        : state.current;

      return {
        ...state,
        current: nextCurrent,
      };
    }

    case acts.UPDATE_PLAYER_RECENTS: {
      const nextCurrent = state.current && state.current.id === action.payload.id
        ? { ...state.current, recentlyPlayed: true }
        : state.current;

      return {
        ...state,
        current: nextCurrent,
      };
    }

    // =============================
    // Global actions
    // =============================
    case acts.REINITIALIZE_PLAYER:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
