// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropdownContext } from '@mewo/components';
import Clipboard from 'react-clipboard.js';

import { withTranslation } from '../../../config/i18n';

import { setNotification as setNotificationBase } from '../../../store/actions/NotificationActions';

import * as ntfs from '../../../store/constants/NotificationTypes';

import * as pth from '../../../helpers/proptypes';

import { StyledDropdown, BaseOption, OptionAsLink, NoResult } from './styles';

// =============================
// Component
// =============================

class ShareButton extends Component {
  static propTypes = {
    placement: pth.dropdownPlacement,
    togglerElement: PropTypes.node.isRequired,
    text: PropTypes.string,
    url: PropTypes.string,
    isHttps: PropTypes.bool.isRequired,
    xHost: PropTypes.string.isRequired,
    setNotification: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placement: 'bottom',
    text: '',
    url: '',
  };

  renderOptions = () => {
    const { isHttps, xHost, text, url, setNotification, t } = this.props;

    if (!url) {
      return (
        <NoResult>{t('components:share_button.no_share_link_available')}</NoResult>
      );
    }

    const formattedUrl = url.indexOf('/') === 0 ? url.substring(1) : url;
    const targetUrl = `${isHttps ? 'https' : 'http'}://${xHost}/${formattedUrl}`;

    return (
      <DropdownContext.Consumer>
        {({ closeDropdown }) => (
          <Fragment>
            <OptionAsLink
              href={`https://www.facebook.com/sharer/sharer.php?u=${targetUrl}`}
              onClick={closeDropdown}
              target="_blank"
            >
              Facebook
            </OptionAsLink>
            <OptionAsLink
              href={`http://twitter.com/share?url=${targetUrl}&text=${text}`}
              onClick={closeDropdown}
              target="_blank"
            >
              Twitter
            </OptionAsLink>
            <OptionAsLink
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${targetUrl}`}
              onClick={closeDropdown}
              target="_blank"
            >
              Linkedin
            </OptionAsLink>
            <BaseOption
              onClick={() => {
                setNotification({
                  notificationKey: ntfs.COPY_SHARE_LINK_SUCCESS,
                  message: t('components:share_button.copy_share_link_success'),
                  type: 'success',
                  duration: 12000,
                });

                closeDropdown();
              }}
            >
              <Clipboard component="div" data-clipboard-text={targetUrl}>
                {t('components:share_button.copy_share_link')}
              </Clipboard>
            </BaseOption>
          </Fragment>
        )}
      </DropdownContext.Consumer>
    );
  };

  render() {
    const { placement, togglerElement } = this.props;

    return (
      <StyledDropdown placement={placement} togglerElement={togglerElement}>
        {this.renderOptions()}
      </StyledDropdown>
    );
  }
}

function mapStateToProps({ core }) {
  return {
    isHttps: core.serverContext.locationProtocol === 'https:',
    xHost: core.serverContext.xHost,
  };
}

export default compose(
  connect(mapStateToProps, {
    setNotification: setNotificationBase,
  }),
  withTranslation('components'),
)(ShareButton);
