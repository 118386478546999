// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Flex, Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';

import Cover from '../../cover';

// =============================
// Notes
// =============================

// =============================
// Styles
// =============================

const musicItemHeight = '5rem';
const musicItemHeightWithouthBorder = '3.8rem';

const outsideInfoWidth = '1.6rem';
const actionsWidth = '2rem';
const versionsCountWidth = '4rem';
const versionsNameWidth = '12rem';
const durationWidth = '4rem';

export const Main = styled.div`
  align-items: center;
  display: grid;
  justify-content: flex-start;
  grid-gap: 0.8rem;
  height: ${musicItemHeightWithouthBorder};
  width: 100%;

  ${({ hasOutsideInfo, withActions, withDuration, withVersions, withVersionsStyle }) => css`
    grid-template-columns: ${hasOutsideInfo && outsideInfoWidth} ${musicItemHeightWithouthBorder} minmax(10rem, 1fr) ${withDuration && durationWidth} ${(withVersions && withVersionsStyle === 'count') && versionsCountWidth} ${(withVersions && withVersionsStyle === 'name') && versionsNameWidth} ${withActions && actionsWidth};
  `};

  @media (min-width: 360px) {
    grid-gap: 1.6rem;
  }
`;

export const StyledCover = styled(Cover).attrs({
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  placeholderType: 'track',
  size: musicItemHeightWithouthBorder,
})`
  height: ${musicItemHeightWithouthBorder};
`;

export const Column = styled(Flex)`
  flex-shrink: 0;
  height: ${musicItemHeightWithouthBorder};
`;

export const TitleAuthors = styled(Column)`
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size:  1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.9rem;

  &:not(:last-child) {
    ${({ theme }) => theme.mediaBelow.minWidth`
      margin-bottom: 0.2rem;
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      margin-bottom: 0.4rem;
    `};
  }
`;

export const AuthorsList = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5rem;
  opacity: 0.7;
`;

export const Duration = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: center;
  font-style: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Version = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export const VersionCount = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 2.5rem;
  justify-content: center;
  min-width: 2.5rem;
  padding: 0.2rem;
`;

export const VersionName = styled.div`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ThreeDotWrapper = styled(Column)`
  align-items: center;
  currsor: pointer;
  display: flex;
  justify-content: center;
`;

export const ThreeDotIcon = styled(Icons.ThreeDot).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const Description = styled.div`
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: 0.8rem;
`;

export const Wrapper = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.1)};
  justify-content: flex-start;
  min-height: ${musicItemHeight};
  padding-bottom: 1.2rem;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const PlayButton = styled(Icons.PlayOutline).attrs(() => ({
  width: musicItemHeightWithouthBorder,
}))``;

export const PauseButton = styled(Icons.PauseOutline).attrs(() => ({
  width: musicItemHeightWithouthBorder,
}))``;

export const OutsideInfo = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Synchro = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  &:first-child {
    margin: auto;
  }
`;

export const SynchroBar = styled.div`
  background-color: ${({ filled, theme }) => (filled ? theme.colors.text : alpha(theme.colors.text, 0.3))};
  height: 0.1rem;
  width: 1rem;

  &:not(:last-child) {
    margin-top: 0.2rem;
  }
`;
