// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';

import Button from '../button';

// =============================
// Styled Components
// =============================

export const Wrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.notifications};

  ${({ theme }) => theme.mediaBelow.minWidth`
    bottom: 1.2rem;
    left: 1.2rem;
    right: 1.2rem;
    width: calc(100% - 2.4rem);
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    bottom: 8rem;
    left: 3rem;
  `};
`;

export const ClearNotificationsButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
})`
  box-shadow: 0 0.2rem 2rem 0 ${({ theme }) => theme.colors.boxShadow};
  font-size: 1.1rem;
  margin-top: 1.2rem;
`;
