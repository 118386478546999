// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Component
// =============================

export const Svg = styled.svg`
  bottom: 0;
  left: 0;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: ${({ theme }) => theme.zIndex.scAppShellBackground};
`;

export const SvgDefs = styled.svg`
  /* Hide SVG defs, display none would break main SVG visibilty. */
  height: 0;
  position: absolute;
  width: 0;
`;
