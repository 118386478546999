// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import posed from 'react-pose';
import { Div, Icons, Tooltip, Waveform } from '@mewo/components';

import AddToPlaylist from '../../../other/addToPlaylist';
import ShareButton from '../../../other/shareButton';
import Cover from '../../../containers/cover';
import Link from '../../../other/link';

import { isHoverPrimary } from '../../../../helpers/front/getHoverType';
import { svgHoverCirclePath } from '../../../../helpers/front/svg';

import { DropdownButton } from '../../../layouts/entity.styles';

import { musicPlayerHeight, musicPlayerHeightMinimized } from './variables';

// =============================
// Styles
// =============================

export const Wrapper = posed(styled(Div)`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 8px 30px 0 ${({ theme }) => theme.colors.boxShadow};
  display: flex;
  opacity: 0;
  padding: 1.5rem 3rem;
  position: fixed;
  transform-origin: right;
  width: 0;
  z-index: ${({ theme }) => theme.zIndex.scMusicPlayer};

  ${({ minimized, theme }) => (minimized
    ? css`
      bottom: 3rem;
      height: ${musicPlayerHeightMinimized};
      right: 3rem;

      ${theme.edgeVariant === 'round'
      ? css`
        border-radius: 1.5rem;
      `
      : css`
        border-radius: 0.2rem;
      `};
    `
    : css`
      bottom: 0;
      height: ${musicPlayerHeight};
      right: 0;
    `)}
`)({
  exit: {
    width: 0,
    opacity: 0,
    transition: {
      duration: 500,
      ease: 'easeOut',
    },
  },
  enter: {
    delayChildren: 100,
    opacity: 1,
    staggerChildren: 0,
    width: ({ minimized }) => (minimized ? '70rem' : '100%'),
    transition: {
      duration: 500,
      ease: 'easeOut',
    },
  },
});

export const StyledCover = styled(Cover).attrs(({ minimized }) => ({
  noRadius: true,
  noBoxShadow: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  onPause: null,
  onPlay: null,
  size: minimized ? '5rem' : '5.5rem',
  type: 'track',
  placeholderType: 'track',
}))`
  flex-shrink: 0;
  margin-right: 1.2rem;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 2rem;
  `};
`;

export const TitleAuthors = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ minimized }) => (minimized
    ? css`
          width: 15rem;
        `
    : css`
          margin-right: 1.2rem;
          width: 20rem;

          ${({ theme }) => theme.mediaAbove.sxga`
        margin-right: 10rem;
      `};
        `)}
`;

export const baseTitle = css`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.4rem;
`;

export const Title = styled.div`
  ${baseTitle}
`;

export const TitleLink = styled(Link)`
  ${baseTitle};
`;

export const Authors = styled.span`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  opacity: 0.7;
`;

export const Author = styled(Link)`
  cursor: pointer;
  display: inline;
  flex-shrink: 0;
  line-height: 1.2;

  &:hover {
    text-decoration: underline;
  }
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;

  ${({ minimized }) => (minimized
    ? css`
          margin: 0 auto;
        `
    : css`
          width: 12rem;
        `)}
`;

export const PrevNext = styled.div`
  padding: 0.8rem;

  svg path {
    fill: ${({ theme }) => theme.colors.text};
  }

  ${({ disabled }) => (disabled
    ? css`
          pointer-events: none;

          svg path {
            opacity: 0.7;
          }
        `
    : css`
          &:hover {
            opacity: 0.7;
          }
        `)};
`;

export const PrevIcon = styled(PrevNext).attrs({
  as: Icons.Previous,
  width: '0.8rem',
})``;

export const NextIcon = styled(PrevNext).attrs({
  as: Icons.Next,
  width: '0.8rem',
})``;

export const PauseIcon = styled(Icons.PauseOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const PlayIcon = styled(Icons.PlayOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const WaveformContainer = posed(styled(Div)`
  ${({ minimized }) => (minimized
    ? css`
      height: 2rem;
      left: 5.5rem;
      overflow: hidden;
      position: absolute;
      top: 6rem;
      width: 64rem;
    `
    : css`
      align-items: center;
      display: flex;
      margin: 0 3.2rem;
      width: 48rem;

      ${({ theme }) => theme.mediaAbove.wxgaplus`
        flex: 1 1 auto;
        margin: 0 4rem;
      `};

      ${({ theme }) => theme.mediaAbove.uxga`
        margin: 0 8rem;
      `};
    `)}
`)({
  enter: {
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 100,
      ease: 'easeOut',
    },
  },
});

export const Timeline = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 1.1rem;
  justify-content: space-between;
  margin-right: 2rem;

  ${({ theme }) => theme.mediaAbove.sxga`
    margin-right: 4rem;
  `};
`;

export const CurrentTime = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-right: 0.8rem;
`;

export const TrackTime = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Interactions = styled.div`
  align-items: center;
  display: flex;

  & > *:not(:last-child) {
    margin-right: 0.8rem;

    ${({ theme }) => theme.mediaAbove.sxga`
      margin-right: 1.2rem;
    `};
  }
`;

export const AddToPlaylistIcon = styled(Icons.Plus).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const AddToFavoriteIcon = styled(Icons.Heart).attrs(({ theme, checked = false }) => ({
  checked,
  fill: theme.colors.text,
  fillChecked: isHoverPrimary(theme.colors) ? theme.colors.primary : theme.colors.text,
  width: '2rem',
}))``;

export const MagicWandIcon = styled(Icons.MagicWand).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '1.8rem',
}))``;

export const DownloadIcon = styled(Icons.Download).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const ShareIcon = styled(Icons.Share).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const ThreeDotIcon = styled(Icons.ThreeDot).attrs(({ theme }) => ({
  fill: theme.colors.text,
  noButton: true,
  width: '2rem',
}))``;

export const ClosePlayerIcon = styled(Icons.ClosePlayer).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '1.1rem',
}))`
  position: relative;
  top: -1px;

  ${({ theme }) => (isHoverPrimary(theme.colors)
    ? css`
      &:hover {
        svg path {
          fill: ${theme.colors.primary};
        }
      }
    `
    : css`
      &:hover {
        svg path {
          fill-opacity: 0.7;
        }
      }
    `)};
`;

export const StyledTooltip = styled(Tooltip).attrs(({ placement }) => ({
  enterDelay: 300,
  placement: placement || 'top-end',
}))`
  ${({ isHeartIcon, noHover, theme }) => !noHover
    && (isHoverPrimary(theme.colors)
      ? css`
          &:hover {
            svg path {
              fill: ${theme.colors.primary};

              ${isHeartIcon
              && css`
                fill-opacity: 1;
                stroke: ${theme.colors.primary};
              `};
            }
          }
        `
      : css`
          &:hover {
            svg path {
              fill-opacity: 0.7;

              ${isHeartIcon
              && css`
                fill: ${theme.colors.text};
                fill-opacity: 1;
                stroke: ${theme.colors.text};

                /** We use opacity because the Heart SVG reacts strangely when we change his fill-opacity to 0.7. */
                opacity: 0.7;
              `};
            }
          }
        `)};

  & .tooltip {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 1rem;
  }
`;

/**
 * Track name take all the available place so when a track title is short,
 * the Tooltip which has a placement of top-end is not positioned right.
 * To prevent this, we just change the placement to top-start, so the
 * tooltip is alway positioned right.
 * */
export const TrackNameTooltip = styled(StyledTooltip).attrs({
  placement: 'top-start',
})``;

export const StyledShareButton = styled(ShareButton).attrs({
  placement: 'top',
})``;

export const StyledAddToPlaylist = styled(AddToPlaylist).attrs({
  placement: 'top',
})``;

export const StyledDropdownButton = styled(DropdownButton).attrs({
  placement: 'top',
})``;

export const MusicPlayerWaveform = styled(Waveform).attrs(({ theme }) => ({
  color: theme.colors.primary,
  width: '100%',
}))`
  width: ${({ minimized }) => (minimized ? '62rem' : '48rem')};
`;
