// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import Legal from '../../legal';
import { AuthModal } from '../styles';

// =============================
// Layout
// =============================

class ModalLegal extends PureComponent {
  static propTypes = {
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Function used to close modal */
    closeLegalModal: PropTypes.func.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Translations */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      opened,
      closeLegalModal,
      i18n: { language },
      ...rest
    } = this.props;

    return (
      <AuthModal bottomCloseBtn name="legal" noFade onClose={closeLegalModal} opened={opened}>
        <Legal modalInjection locale={language} {...rest} />
      </AuthModal>
    );
  }
}

export default withTranslation('pages')(ModalLegal);
