// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import MenuDesktop from './desktop';
import MenuMobile from './mobile';

// =============================
// Component
// =============================

class Menu extends PureComponent {
  static displayName = 'MenuResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={MenuDesktop}
        MobileComponent={MenuMobile}
        props={this.props}
      />
    );
  }
}

export default Menu;
