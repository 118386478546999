// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CustomPageWrapper, EntityWrapper, PitchWrapper, PlaylistWrapper, SearchWrapper, TextWrapper } from './styles';

// =============================
// Notes
// =============================

/**
 * This component is used on every page to assure that
 * the last item on the page as enough space to be visible
 * in all situation.
 */

// =============================
// Component
// =============================

class PageWrapper extends PureComponent {
  static displayName = 'PageWrapperMobile';

  static propTypes = {
    /* Children. */
    children: PropTypes.node.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool,
    /** If True, render PageWrapper without padding-top. Only used on customPage type. */
    noPaddingTop: PropTypes.bool,
    /** If true, Player is opened. */
    playerIsOpened: PropTypes.bool.isRequired,
    /** Type of children. */
    type: PropTypes.oneOf([
      'customPage',
      'entity',
      'playlist',
      'search',
      'text',
    ]).isRequired,
  };

  static defaultProps = {
    noPaddingTop: false,
    isDoingMaiaSearch: false,
  }

  render() {
    const { children, isDoingMaiaSearch, noPaddingTop, playerIsOpened, type } = this.props;

    if (type === 'customPage') {
      return (
        <CustomPageWrapper noPaddingTop={noPaddingTop} playerIsOpened={playerIsOpened}>
          {children}
        </CustomPageWrapper>
      );
    }
    if (type === 'entity') {
      return (
        <EntityWrapper playerIsOpened={playerIsOpened}>
          {children}
        </EntityWrapper>
      );
    }

    if (type === 'playlist') {
      return (
        <PlaylistWrapper playerIsOpened={playerIsOpened}>
          {children}
        </PlaylistWrapper>
      );
    }

    if (type === 'pitch') {
      return (
        <PitchWrapper playerIsOpened={playerIsOpened}>
          {children}
        </PitchWrapper>
      );
    }

    if (type === 'search') {
      return (
        <SearchWrapper playerIsOpened={playerIsOpened} isDoingMaiaSearch={isDoingMaiaSearch}>
          {children}
        </SearchWrapper>
      );
    }

    if (type === 'text') {
      return (
        <TextWrapper playerIsOpened={playerIsOpened}>
          {children}
        </TextWrapper>
      );
    }

    return null;
  }
}

function mapStateToProps({ player }) {
  return {
    playerIsOpened: player.isOpened,
  };
}

export default connect(mapStateToProps)(PageWrapper);
