// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get profil picture of an artist
 * @param {object} artist - Artist object
 * @param {string} size - Image size, can be "large" or "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getArtistCoverUrl = getGenericDocCoverUrl;

/**
 * Get artist name
 * @param {object} artist - Artist object
 * @return {string} - Artist name
 */
export const getArtistName = (artist) => {
  const aliases = _get(artist, 'aliases', []) || [];

  if (aliases.length) return aliases[0];
  return artist.fullName;
};

/**
 * Get display artist name
 * @param {object} da - Artist object
 * @return {string} - Artist name
 */
export const getDisplayArtistName = da => da && (_get(da, 'alias') || _get(da, 'artist.fullName'));
