// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import { getAlbumTitle, getAlbumCoverUrl } from '../../../../helpers/entity/album';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { WrapIfDesktop } from '../../../other/responsive';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  StyledScrollBar,
  Title,
  TitleWrapper,
  TextWithLineBreaks,
  DefaultModal,
} from '../styles';

// =============================
// Stories
// =============================

class ModalAlbum extends PureComponent {
  static propTypes = {
    /** Album */
    album: pth.album.isRequired,
    /** Function used to close modal */
    closeAlbumDescModal: PropTypes.func.isRequired,
    /** Locale */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      album,
      closeAlbumDescModal,
      i18n: { language },
      opened,
    } = this.props;

    if (!album) return null;

    const description = getValueByLocale(album.descriptions, language);

    return (
      <DefaultModal bottomCloseBtn name="album" onClose={closeAlbumDescModal} opened={opened} wideContent>
        <Wrapper>
          <Informations>
            <ModalCover
              placeholderType="album"
              type="album"
              src={getAlbumCoverUrl(album)}
              lazyload={false}
              alt={getAlbumTitle(album)}
            />
          </Informations>
          <Content>
            <WrapIfDesktop Wrapper={<StyledScrollBar />}>
              <TitleWrapper>
                <Title>{getAlbumTitle(album)}</Title>
              </TitleWrapper>
              <TextWithLineBreaks>{description}</TextWithLineBreaks>
            </WrapIfDesktop>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalAlbum);
