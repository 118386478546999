// =============================
// Imports
// =============================

import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import Logo from '../../logobase';
import Link from '../../../other/link';

import * as pth from '../../../../helpers/proptypes';

import { NoLogo } from './styles';

// =============================
// Component
// =============================

const LogoWithConfig = ({
  colors,
  config,
  innerRef,
  logo,
  logoFilterBar,
  logoModal,
  logoVariant,
  onClickPassthrough,
  route,
  usedLogo,
  ...rest
}) => {
  const filterBarSrc = _get(logoFilterBar, 'original.url', undefined);
  const modalSrc = _get(logoModal, 'original.url', undefined);
  const src = _get(logo, 'original.url', undefined);
  const variantSrc = _get(logoVariant, 'original.url', undefined);

  if (!src || !variantSrc || !modalSrc || !filterBarSrc) {
    return (
      <Link onClickPassthrough={onClickPassthrough} route={route}>
        <NoLogo colors={colors} ref={innerRef} {...rest}>
          {_get(config, 'websiteTitle')}
        </NoLogo>
      </Link>
    );
  }

  return (
    <Link onClickPassthrough={onClickPassthrough} route={route}>
      <Logo
        filterBarSrc={filterBarSrc}
        modalSrc={modalSrc}
        name={_get(config, 'websiteTitle')}
        ref={innerRef}
        src={src}
        usedLogo={usedLogo}
        variantSrc={variantSrc}
        {...rest}
      />
    </Link>
  );
};

LogoWithConfig.displayName = 'LogoDesktop';

LogoWithConfig.propTypes = {
  colors: PropTypes.shape({
    background: PropTypes.string,
    text: PropTypes.string,
    primary: PropTypes.string,
    primaryText: PropTypes.string,
  }).isRequired,
  config: pth.config,
  innerRef: PropTypes.func,
  logo: pth.image.isRequired,
  logoFilterBar: pth.image.isRequired,
  logoModal: pth.image.isRequired,
  logoVariant: pth.image.isRequired,
  onClickPassthrough: PropTypes.func,
  route: PropTypes.string.isRequired,
  /** Which logo are we using */
  usedLogo: PropTypes.oneOf(['default', 'variant', 'modal', 'filterBar']).isRequired,
};

LogoWithConfig.defaultProps = {
  config: null,
  innerRef: null,
  onClickPassthrough: null,
};

function forwardedRef(props, ref) {
  return <LogoWithConfig innerRef={ref} {...props} />;
}

forwardedRef.displayName = LogoWithConfig.displayName;

export default forwardRef(forwardedRef);
