// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip } from '@mewo/components';

import Button from '../../../../../presentationals/button';
import Filters from '../../../../../containers/filters';
import Link from '../../../../../other/link';

import { paddingX } from '../../../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &::after {
    content: "";
    display: block;
    flex-shrink: 0;
    height: ${paddingX};
    width: 0.1rem;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.6rem;
  padding: 0 ${paddingX};
`;

export const StyledFilters = styled(Filters)`
  margin-bottom: 2rem;
  padding: 0 ${paddingX};

  &:not(:empty) {
    margin-bottom: 2rem;
  }
`;

export const Tag = styled(Chip)`
  margin: 0.4rem 0.6rem 0.6rem 0;

  ${({ disabled }) => disabled
    && css`
      opacity: 0.7;
    `};
`;

export const SeeResultButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  margin: 0 auto;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: -0.3rem;
  padding: 0 ${paddingX};

  &:not(:last-child) {
    margin-bottom: 4.8rem;
  }
`;

export const Tabs = styled.div`
  display: flex;
  margin-bottom: 2rem;
  overflow-x: scroll;

  &::after,
  &::before {
    content: "";
    display: block;
    flex-shrink: 0;
    height: 0.1rem;
    width: ${paddingX};
  }
`;

export const TabsBorder = styled.div`
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.text};
  display: flex;
  flex-shrink: 0;
`;

export const Tab = styled.div`
  align-items: center;
  border-bottom: 0.2rem solid transparent;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  justify-content: flex-start;
  height: 4rem;
  margin-right: 1.2rem;
  user-select: none;

  ${({ active, theme }) => active
    && css`
      border-bottom: 0.2rem solid ${theme.colors.text};
      cursor: default;
    `};
`;

export const TabCounter = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.primaryText};
  display: flex;
  flex-shrink: 0;
  font-size: 1.1rem;
  height: 2.2rem;
  justify-content: center;
  margin-left: 0.8rem;
  width: 2.2rem;
`;

export const SeeResultLink = styled(Link)`
  display: inline-block;
  margin: 0 auto;
`;
