// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _find from 'lodash/find';

import { setPreSearchOpen } from '../../../../store/actions/ModalsActions';

import { requestMaiaTextSearch } from '../../../../store/actions/SearchActions';

import ModalPreSearch from '../../../layouts/modals/presearch';

// =============================
// Component
// =============================

function mapStateToProps({ config, modals, player, search, options }) {
  // Retrieve 1st visible FullSizeCoverModule
  const currentFullSizeCoverModule = _find(
    search.fullSizeCoverModule,
    item => item.isVisible,
    null,
  );

  return {
    audioUploadProgress: search.tracksSearch.audioUploadProgress,
    config: config.data,
    facets: search.tracksSearch.data.facets,
    fullSizeCoverModule: currentFullSizeCoverModule,
    isFetching: search.preSearch.isFetching,
    initialFacets: search.tracksSearch.data.initialFacets,
    isSendingMaiaAudioSignal: search.tracksSearch.isSendingMaiaAudioSignal,
    isSendingMaiaTextSignal: search.tracksSearch.isSendingMaiaTextSignal,
    playerIsOpened: player.isOpened,
    searchDatas: search.preSearch.data,
    searchQuery: search.tracksSearch.query,
    searchTracksTotal: search.tracksSearch.data.total,
    tags: options.data.tags_only,
    tagsSc: options.data.tags_sc,
    opened: modals.isPreSearchOpen,
  };
}

export default connect(mapStateToProps, {
  openPreSearchModal: () => setPreSearchOpen(true),
  closePreSearchModal: () => setPreSearchOpen(false),
  requestMaiaTextSearch,
})(ModalPreSearch);
