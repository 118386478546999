// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a publisher
 * @param {object} publisher - Publisher object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getPublisherCoverUrl = getGenericDocCoverUrl;

/**
 * Get publisher name
 * @param {object} publisher - Publisher object
 * @return {string} - Publisher name
 */
export const getPublisherName = publisher => _get(publisher, 'publisherName');
