// =============================
// Imports
// =============================

import { forwardRef, Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import { getAlbumCoverUrl } from '../../../../helpers/entity/album';
import { presentDuration } from '../../../../helpers/entity/common';
import { getValueByLocale } from '../../../../helpers/i18n';
import { getTrackAlbum, getTrackDisplayArtists, getTrackTitle } from '../../../../helpers/entity/track';
import * as pth from '../../../../helpers/proptypes';

import {
  AuthorsList,
  StyledCover,
  Title,
  TitleAuthors,
  Wrapper,
  VersionCount,
  ThreeDotIcon,
  Description,
  Main,
  Duration,
  Version,
  PauseButton,
  PlayButton,
  VersionName,
  ThreeDotWrapper,
  OutsideInfo,
  Synchro,
  SynchroBar,
} from './styles';

// =============================
// Component
// =============================

class MusicItem extends Component {
  static displayName = 'MusicItemMobile';

  static propTypes = {
    /** Id of the player context */
    contextId: PropTypes.string,
    /** Name of the player context */
    contextName: PropTypes.string,
    /** Position of the track within player context */
    contextPosition: PropTypes.number,
    /** Use controls to show play/pause or cover to show album cover */
    coverStyle: PropTypes.oneOf(['controls', 'cover']),
    /** If True, will not display extra options regardless of user preferences. */
    disableExtraOptions: PropTypes.bool,
    /** Player getMedia function */
    getMedia: PropTypes.func.isRequired,
    /** Asserts if the track is current */
    isCurrent: PropTypes.func.isRequired,
    /** Asserts if the track is being played */
    isPlaying: PropTypes.func.isRequired,
    /** Lazy load */
    lazyload: PropTypes.bool,
    /** Locale used by user */
    locale: PropTypes.string,
    /** Music object */
    music: pth.track.isRequired,
    /** On click passtrough action */
    onClickPassthrough: PropTypes.func,
    /** Handle click on pause icon */
    onPause: PropTypes.func.isRequired,
    /** Player play function */
    play: PropTypes.func.isRequired,
    /** Search query */
    search: pth.searchquery.isRequired,
    /** If true, show an extra information on musicitem */
    showExtraOptionsDescription: PropTypes.bool,
    /** Music item is within search page */
    showSimilarity: PropTypes.bool,
    /** Function used to open music item modal. */
    toggleMusicItemModal: PropTypes.func.isRequired,
    /** If True, will render a "3dot" button to open musicItem modal. */
    withActions: PropTypes.bool,
    /** If True, will display duration of the track. */
    withDuration: PropTypes.bool,
    /** If True, will display versions count of the track. */
    withVersions: PropTypes.bool,
    /** Choose between version name or version count when displaying versions. */
    withVersionsStyle: PropTypes.oneOf(['count', 'name']),
  };

  static displayName = 'MusicItemMobile';

  static defaultProps = {
    contextId: null,
    contextName: null,
    contextPosition: null,
    coverStyle: 'cover',
    disableExtraOptions: false,
    lazyload: true,
    locale: null,
    onClickPassthrough: null,
    showExtraOptionsDescription: false,
    showSimilarity: false,
    withActions: true,
    withDuration: false,
    withVersions: true,
    withVersionsStyle: 'count',
  };

  handleClickOnActions = (e) => {
    const { music, toggleMusicItemModal } = this.props;

    // We only want to trigger the modal apparition.
    e.preventDefault();
    e.stopPropagation();

    toggleMusicItemModal(true, music);
  };

  handlePlayPause = () => {
    const {
      contextId,
      contextName,
      contextPosition,
      getMedia,
      isCurrent: isCurrentFnc,
      isPlaying: isPlayingFnc,
      music,
      onClickPassthrough,
      onPause,
      play,
    } = this.props;

    // Check if track is playing
    const isCurrent = isCurrentFnc(music.id, contextName, contextId);
    const isPlaying = isPlayingFnc(music.id, contextName, contextId);

    if (isPlaying) {
      return onPause();
    }

    if (isCurrent) {
      return play();
    }

    // Only passthrough for non current track
    if (onClickPassthrough) {
      onClickPassthrough();
    }

    return getMedia(music.id, contextName, contextId, contextPosition);
  };

  renderCover = () => {
    const {
      contextId,
      contextName,
      coverStyle,
      isCurrent: isCurrentFnc,
      isPlaying: isPlayingFnc,
      lazyload,
      music,
    } = this.props;

    // Check if track is playing
    const isCurrent = isCurrentFnc(music.id, contextName, contextId);
    const isPlaying = isPlayingFnc(music.id, contextName, contextId);

    if (coverStyle === 'cover') {
      return (
        <StyledCover
          isCurrent={isCurrent}
          isPlaying={isPlaying}
          lazyload={lazyload}
          onPause={this.handlePlayPause}
          onPlay={this.handlePlayPause}
          placeholderType="track"
          playButtonSize="musicItem"
          src={getAlbumCoverUrl(music.album, 'small')}
          type="track"
          alt={getTrackAlbum(music).title}
        />
      );
    }

    // coverStyle = 'controls'
    if (isPlaying) {
      return <PauseButton onClick={this.handlePlayPause} />;
    }

    return <PlayButton onClick={this.handlePlayPause} />;
  };

  renderDescription = () => {
    const { locale, music } = this.props;

    return getValueByLocale(music.descriptions, locale);
  };

  renderDisplayArtists = () => {
    const { music } = this.props;

    const displayArtists = getTrackDisplayArtists(music);

    return displayArtists.reduce((acc, da, i) => {
      acc.push(da.name);

      if (i < displayArtists.length - 1) acc.push(', ');

      return acc;
    }, []);
  };

  renderVersion = () => {
    const { locale, music, withVersionsStyle } = this.props;
    // NOTE: 1 means that there are no alternative versions
    const nbVersions = _get(music, 'versions', 1) - 1;

    if (!nbVersions) return null;

    if (withVersionsStyle === 'count') {
      return <VersionCount>{`+${nbVersions}`}</VersionCount>;
    }

    // withVersionsStyle === 'name'
    const versionName = getValueByLocale(_get(music, 'version.names', []), locale);

    return <VersionName>{versionName}</VersionName>;
  };

  render() {
    const {
      disableExtraOptions,
      music,
      search,
      showSimilarity,
      showExtraOptionsDescription,
      withActions,
      withDuration,
      withVersions,
      withVersionsStyle,
    } = this.props;

    // Is maia search
    const isMaiaSearch = !!(_get(search, 'options.trackId') || _get(search, 'options.searchId')) && showSimilarity;

    return (
      <Wrapper>
        <Main
          hasOutsideInfo={isMaiaSearch}
          onClick={this.handlePlayPause}
          withVersionsStyle={withVersionsStyle}
          withActions={withActions}
          withDuration={withDuration}
          withVersions={withVersions}
        >
          {isMaiaSearch && (
            <OutsideInfo>
              <Synchro>
                {new Array(10).fill(1).map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <SynchroBar key={`${music.id}-sync-${i}`} filled={i < music.similarity / 10} />
                ))}
              </Synchro>
            </OutsideInfo>
          )}
          {this.renderCover()}
          <TitleAuthors>
            <Title>{getTrackTitle(music)}</Title>
            <AuthorsList>{this.renderDisplayArtists()}</AuthorsList>
          </TitleAuthors>
          {withDuration && <Duration>{music.duration && presentDuration(music.duration)}</Duration>}
          {withVersions && (
            <Version onClick={(_get(music, 'versions', 1) - 1) ? this.handleClickOnActions : null}>{this.renderVersion()}</Version>
          )}
          {withActions && (
            <ThreeDotWrapper onClick={this.handleClickOnActions}>
              <ThreeDotIcon />
            </ThreeDotWrapper>
          )}
        </Main>
        {!disableExtraOptions && showExtraOptionsDescription && (
          <Description>{this.renderDescription()}</Description>
        )}
      </Wrapper>
    );
  }
}

function forwardedRef({ i18n: { language }, ...props }, ref) {
  return <MusicItem locale={language} innerRef={ref} {...props} />;
}

forwardedRef.displayName = MusicItem.displayName;

export default compose(
  withRouter,
  withTranslation('components'),
)(forwardRef(forwardedRef));
