// =============================
// Imports
// =============================

import { useMediaQuery } from 'react-responsive';

// =============================
// HOC
// =============================

export default function withResponsive(WrappedComponent) {
  // eslint-disable-next-line react/display-name
  return props => (
    <WrappedComponent
      isMobile={!!(useMediaQuery({ maxWidth: 1023 }))}
      {...props}
    />
  );
}
