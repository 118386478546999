// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import ScrollBar from 'react-scrollbar/dist/no-css';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledScrollBar = styled(ScrollBar)`
  &.scrollarea {
    overflow: hidden;
    position: relative;

    /** Fix button hover being hidden by scrollarea-content overflow hidden. */
    left: -3rem;

    &:hover .scrollbar-container {
      opacity: 0.3;
    }

    .scrollarea-content {
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: relative;
      touch-action: none;

      /** Fix button hover being hidden by .scrollarea-content & .scrollarea overflow hidden. */
      padding-left: 3rem;
    }

    .scrollarea-content:focus {
      outline: 0;
    }

    .scrollbar-container {
      background: none;
      opacity: 0.1;
      position: absolute;
      z-index: 99;
      transition: all 0.4s;

      &:hover {
        background: gray;
        opacity: 0.6 !important;
      }

      &.active {
        background: gray;
        opacity: 0.6 !important;
      }

      &.horizontal {
        bottom: 0;
        height: 0.1rem;
        left: 0;
        width: 100%;

        ${({ noScrollBar }) => noScrollBar
          && css`
            display: none;
          `};

        .scrollbar {
          background: black;
          height: 0.8rem;
          margin-top: 0.1rem;
          width: 2rem;
        }
      }

      &.vertical {
        height: 100%;
        right: 0;
        top: 0;
        width: 0.1rem;

        ${({ noScrollBar }) => noScrollBar
          && css`
            display: none;
          `};

        .scrollbar {
          width: 0.8rem;
          height: 2rem;
          background: black;
          margin-left: 0.1rem;
        }
      }
    }
  }
`;
