// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar, ConditionalWrapper, WithCustomTheme } from '@mewo/components';
import { compose } from 'redux';
import { withTheme } from 'styled-components';
import Router from 'next/router';

import { withTranslation } from '../../../../config/i18n';

import Link from '../../../other/link';
import NotificationManager from '../../notificationmanager';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as LastRoute from '../../../../helpers/lastroute';
import { snakeCaseKeysDeep } from '../../../../helpers/misc';
import * as pth from '../../../../helpers/proptypes';

import { StyledLink } from '../../menu/desktop/menubutton/styles';
import {
  Wrapper,
  Right,
  AvatarWrapper,
  StyledButton,
  StyledDropdownList,
  RightLinks,
  StyledLanguageSwitcher,
  StyledOption,
} from './styles';

// =============================
// Note
// =============================

/*
  Why we use children props in Header ?
  We need to put some Components like Menu into Header because only the Logo remains visible
  when Menu is opened
  And if Menu is not part of the Header, then we have zindex problem to just show the Logo
*/

// =============================
// Component
// =============================

class Header extends PureComponent {
  static displayName = 'HeaderDesktop';

  static propTypes = {
    /* Available languages */
    availableLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Function used to mark all notifiations as seen */
    checkUserNotifications: PropTypes.func.isRequired,
    /** Asserts if filter bar is opened or not */
    filterBarOpened: PropTypes.bool.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      id: PropTypes.string,
      colors: pth.colorsModule,
      isVisible: PropTypes.bool,
    }),
    /** Function used to get notifications */
    getUserNotifications: PropTypes.func.isRequired,
    /** If True, has access to website when private. */
    // hasAccess: PropTypes.bool.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** isSimpleLayout */
    isSimpleLayout: PropTypes.bool.isRequired,
    /** Function used to log out user */
    logout: PropTypes.func.isRequired,
    /** Notifications store */
    notifications: PropTypes.shape({
      page: PropTypes.number,
      nbPages: PropTypes.number,
      data: PropTypes.arrayOf(pth.userNotification),
    }).isRequired,
    /** Header links */
    rightHeaderLinks: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        names: pth.descriptions,
      }),
    ).isRequired,
    /** Home route */
    rootRoute: PropTypes.string.isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Styled component theme */
    theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    /** Function used to open account modal */
    toggleAccountModal: PropTypes.func.isRequired,
    /** User object */
    user: pth.user,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
    user: null,
  };

  getHeaderThemeColors = (theme) => {
    const { fullSizeCoverModule, isSimpleLayout } = this.props;

    if (isSimpleLayout) return theme.colors.pages.auth;

    if (fullSizeCoverModule) return fullSizeCoverModule.colors;

    return theme.colors.header;
  };

  render() {
    const {
      availableLanguages,
      checkUserNotifications,
      filterBarOpened,
      fullSizeCoverModule,
      getUserNotifications,
      i18n: { language },
      isSimpleLayout,
      logout,
      notifications,
      rightHeaderLinks,
      rootRoute,
      t,
      toggleAccountModal,
      theme,
      user,
    } = this.props;

    const { lastAsPath } = LastRoute.get(rootRoute);

    return (
      <WithCustomTheme
        customTheme={currentTheme => ({
          colors: this.getHeaderThemeColors(currentTheme),
        })}
      >
        <Wrapper
          fullSizeCoverModuleIsVisible={fullSizeCoverModule?.isVisible}
          filterBarOpened={filterBarOpened}
          showBoxShadow={theme.colors.showHeaderShadow}
        >
          <Right>
            {!isSimpleLayout && fullSizeCoverModule?.isVisible && !!rightHeaderLinks.length && (
              <RightLinks>
                {rightHeaderLinks.map((rightLink, i) => (
                  <StyledLink
                    href={rightLink.page ? `/page/${rightLink.slug}` : rightLink.slug}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${i}-${rightLink.slug}`}
                    placedOnHeader
                  >
                    {getValueByLocale(rightLink.names, language)}
                  </StyledLink>
                ))}
                <StyledLanguageSwitcher languages={availableLanguages} />
              </RightLinks>
            )}

            <ConditionalWrapper
              Wrapper={(
                <WithCustomTheme
                  customTheme={() => (fullSizeCoverModule.colors.useCustomColor
                    ? {
                      colors: {
                        background: fullSizeCoverModule.colors.background || 'transparent',
                        text: fullSizeCoverModule.colors.text,
                        primary: fullSizeCoverModule.colors.primary,
                        primaryText: fullSizeCoverModule.colors.primaryText,
                        useCustomColor: fullSizeCoverModule.colors.useCustomColor,
                      },
                    }
                    : {
                      colors: {
                        useCustomColor: fullSizeCoverModule.colors.useCustomColor,
                      },
                    })}
                />
              )}
              condition={fullSizeCoverModule?.isVisible || false}
            >
              <Fragment>
                {/* Not auth and logged in and not presearch */}
                {!isSimpleLayout && user && (
                  <Fragment>
                    <NotificationManager
                      notifications={notifications.data}
                      hasMoreNotifications={notifications.nbPages - (notifications.page + 1) > 0}
                      getUserNotifications={getUserNotifications}
                      markAllRead={checkUserNotifications}
                    />

                    <AvatarWrapper>
                      {/*
                        NOTE: We use snake case here because component doesn't handle camel case
                      */}
                      <StyledDropdownList
                        togglerElement={<Avatar user={snakeCaseKeysDeep(user)} />}
                        withFilter={false}
                        options={[
                          {
                            action: () => toggleAccountModal(true),
                            name: 'account',
                            label: t('components:header.my_account'),
                          },
                          {
                            action: null,
                            name: 'userplaylists',
                            label: t('components:header.my_playlists'),
                          },
                          {
                            action: null,
                            name: 'favorites',
                            label: t('components:header.my_favorites'),
                          },
                          {
                            action: null,
                            name: 'recentplays',
                            label: t('components:header.my_recent_plays'),
                          },
                          {
                            action: logout,
                            name: 'logout',
                            label: t('components:header.logout'),
                          },
                        ]}
                        renderOption={(option) => {
                          if (
                            ['userplaylists', 'favorites', 'recentplays'].indexOf(option.name)
                            === -1
                          ) {
                            return <StyledOption>{option.label}</StyledOption>;
                          }

                          return (
                            <StyledOption route={`/${option.name}`}>{option.label}</StyledOption>
                          );
                        }}
                        lang={{}}
                      />
                    </AvatarWrapper>
                  </Fragment>
                )}

                {/* Not auth and not logged in and not presearch */}
                {!isSimpleLayout && !user && (
                  <Link route="/login">
                    <StyledButton>{t('components:header.login')}</StyledButton>
                  </Link>
                )}

                {/* Is auth has access to private and not presearch */}
                {isSimpleLayout && (
                  <StyledButton onClick={() => Router.push(lastAsPath)}>
                    {t('components:header.back_home')}
                  </StyledButton>
                )}
              </Fragment>
            </ConditionalWrapper>
          </Right>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default compose(withTranslation('components'), withTheme)(Header);
