// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a label
 * @param {object} label - Label object
 * @param {string} size - Image size, can be "large" or "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getLabelCoverUrl = getGenericDocCoverUrl;

/**
 * Get label name
 * @param {object} label - Label object
 * @return {string} - Label name
 */
export const getLabelName = label => _get(label, 'labelName');
