// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { motion } from 'framer-motion';

import { isHoverPrimary } from '../../../../../helpers/front/getHoverType';

import LinkBase from '../../../../other/link';

import {
  MenuItemCounter,
  menuCenterMarginRight,
  menuCenterMarginRightSxga,
  menuCenterMarginRightUxga,
} from '../menucontent/styles';

// =============================
// Styles
// =============================

export const MenuLink = styled(LinkBase)`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  font-size: 3.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  opacity: 1;
  position: relative;
  transition: color ${({ theme }) => theme.transition.medium},
    padding-left ${({ theme }) => theme.transition.medium};
  width: max-content;

  &:hover {
    color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};

    & ${MenuItemCounter} {
      background-color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
      color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primaryText : theme.colors.background)};
    }
  }

  ${({ inactive }) => inactive
    && css`
      color: ${({ theme }) => alpha(theme.colors.text, 0.6)};

      &:hover {
        color: ${({ theme }) => alpha(theme.colors.text, 0.6)};
      }
    `};

  > a {
    align-items: center;
    display: inline-flex;
  }
`;

export const SubLinksContainer = styled.div`
  padding-bottom: 2rem;
  width: 30rem;

  &:after {
    content: '';
    background: ${({ theme }) => theme.colors.background};
    display: block;
    height: 200vh;
    left: -${menuCenterMarginRight};
    margin-top: -100vh;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: -1;

    ${({ theme }) => theme.mediaAbove.sxga`
      left: -${menuCenterMarginRightSxga};
    `};

    ${({ theme }) => theme.mediaAbove.uxga`
      left: -${menuCenterMarginRightUxga};
    `};
  }
`;

export const SubLink = styled(motion(LinkBase, { forwardMotionProps: true })).attrs(() => ({}))`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  display: flex;
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: normal;
  width: max-content;

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &:hover {
    color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};

    & ${MenuItemCounter} {
      background-color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primary : alpha(theme.colors.text, 0.7))};
      color: ${({ theme }) => (isHoverPrimary(theme.colors) ? theme.colors.primaryText : theme.colors.background)};
    }
  }

  ${({ inactive }) => inactive
    && css`
      color: ${({ theme }) => alpha(theme.colors.text, 0.6)};

      &:hover {
        color: ${({ theme }) => alpha(theme.colors.text, 0.6)};
      }
    `};
`;

export const SubLinkArrow = styled.div`
  align-items: center;
  display: flex;
  height: 10rem;
  justify-content: center;
  padding: 0 2rem;
  right: 100%;
  top: -0.5rem;
  width: ${({ arrowWidth }) => arrowWidth}px;

  &:after {
    background: ${({ theme }) => theme.colors.text};
    content: '';
    display: block;
    height: 0.1rem;
    width: 100%;
  }
`;

export const Wrapper = styled(motion.div).attrs(({ theme }) => ({
  variants: {
    close: {
      opacity: 0,
      x: -40,
      transition: {
        duration: 0.1,
      },
    },
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.2,
        ease: theme.transitionTiming.pose.easeOut,
      },
    },
  },
}))`
  position: relative;
  width: max-content;

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  ${SubLinksContainer} {
    /**
      We use different transition-duration for SubLinksContainer:after and
      links inside the SubLinksContainer so when a user pass his mouse over
      multiple links, the sideMenuWrapper with contact info does not flash.
    */
    > * {
      opacity: 0;
      pointer-events: none;
      transition: opacity 300ms;
    }

    &:after {
      opacity: 0;
      pointer-events: none;
      transition: 1200ms;
    }
  }

  &:hover {
    /**
      z-index used because we need the current hovered SubLinksContainer to
      be above any other SubLinkContainer:after background
    */
    z-index: 1;

    ${SubLinksContainer} {
      > * {
        opacity: 1;
        pointer-events: auto;
      }

      &:after {
        opacity: 1;
        pointer-events: auto;
        transition: 300ms;
      }
    }
  }
`;
