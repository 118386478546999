// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

// =============================
// Component
// =============================

class ModalCloser extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    path: PropTypes.string.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    onClose: null,
  };

  render() {
    const {
      children,
      onClose,
      path,
      router: { asPath },
    } = this.props;

    const onCloseFnc = asPath === path ? onClose : null;

    return children(onCloseFnc);
  }
}

export default withRouter(ModalCloser);
