// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import axios from 'axios';

import { withTranslation } from '../../../../config/i18n';

import { getUserPlaylistName } from '../../../../helpers/entity/playlist';
import { getApiUrl, getXPreferredLanguage } from '../../../../helpers/misc';
import * as pth from '../../../../helpers/proptypes';

import { DefaultModal } from '../styles';
import {
  CloseModalBtn,
  Content,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../mobile.styles';
import {
  NewPlaylistBtn,
  PlaylistAdded,
  PlaylistItem,
} from './styles';

// =============================
// Layout
// =============================

class ModalAddToPlaylist extends PureComponent {
  static propTypes = {
    /** Function used to add an item from context to playlist. */
    addItemsToPlaylist: PropTypes.func.isRequired,
    /** Function used to close add to playlist modal. */
    closeAddToPlaylistModal: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    entity: PropTypes.object,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Playlists that are currently handling adding or removing tracks */
    isHandlingTracks: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Function used to remove a track from playlist. */
    removeTrackFromPlaylist: PropTypes.func.isRequired,
    /** Translations. */
    t: PropTypes.func.isRequired,
    /** Type of the data to add to playlist. */
    type: PropTypes.oneOf(['album', 'playlist', 'track']),
    /** List of user playlists. */
    userPlaylists: PropTypes.arrayOf(pth.listuserplaylist).isRequired,
    /** API User token */
    userToken: PropTypes.string,
    /** Modo xHost */
    xHost: PropTypes.string.isRequired,
  };

  static defaultProps = {
    opened: false,
    entity: null,
    type: null,
    userToken: null,
  };

  state = {
    tracksPlaylists: [],
  };

  componentDidUpdate(prevProps) {
    const { opened: wasOpened } = prevProps;
    const { opened } = this.props;

    if (!wasOpened && opened) {
      this.fetchTracksPlaylists();
    }
  }

  fetchTracksPlaylists = async () => {
    const { entity, type, userToken, xHost } = this.props;

    if (entity && type && userToken) {
      try {
        const options = {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': xHost,
            'x-auth': userToken,
            'x-preferred-language': getXPreferredLanguage(),
          },
        };

        if (type === 'track') {
          options.method = 'post';
          options.url = getApiUrl('public/tracks/user-playlists/data');
          options.data = { ids: [entity.id] };
        } else {
          options.method = 'get';
          options.url = getApiUrl(`public/${type}s/${entity.id}/user-playlists`);
        }

        const response = await axios(options);

        this.setState({
          tracksPlaylists: [...response.data],
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  };

  handleAddToNewPlaylist = async () => {
    const { addItemsToPlaylist, closeAddToPlaylistModal, entity, type } = this.props;

    await addItemsToPlaylist(type, entity.id);

    closeAddToPlaylistModal();
  };

  handleAddToPlaylist = async (playlistId) => {
    const { addItemsToPlaylist, closeAddToPlaylistModal, entity, type } = this.props;

    await addItemsToPlaylist(type, entity.id, playlistId);

    closeAddToPlaylistModal();
  };

  handleRemoveFromPlaylist = async (playlistId) => {
    const { closeAddToPlaylistModal, removeTrackFromPlaylist, entity } = this.props;

    await removeTrackFromPlaylist(playlistId, entity.id);

    closeAddToPlaylistModal();
  };

  renderPlaylist = (playlist) => {
    const { isHandlingTracks, type } = this.props;
    const { tracksPlaylists } = this.state;

    const added = tracksPlaylists.includes(playlist.id);
    const disabled = isHandlingTracks.includes(playlist.id) || (added && type !== 'track');

    return (
      <PlaylistItem
        key={playlist.id}
        disabled={disabled}
        onClick={async () => {
          if (added) return this.handleRemoveFromPlaylist(playlist.id);
          return this.handleAddToPlaylist(playlist.id);
        }}
      >
        {added && <PlaylistAdded />}
        {getUserPlaylistName(playlist)}
      </PlaylistItem>
    );
  };

  render() {
    const {
      closeAddToPlaylistModal,
      entity,
      isHandlingTracks,
      opened,
      t,
      type,
      userPlaylists,
    } = this.props;

    if (!entity || !type) return null;

    return (
      <DefaultModal
        name="addToPlaylist"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeAddToPlaylistModal}
        opened={opened}
      >
        <ClickAwayListener onClickAway={closeAddToPlaylistModal}>
          <Wrapper>
            <Header>
              <CloseModalBtn onClick={closeAddToPlaylistModal}>
                {t('components:modal.close')}
              </CloseModalBtn>
              <Title>{t('components:modal_add_to_playlist.add_to_playlist')}</Title>
              <EmptySpacePlaceholder />
            </Header>
            <Content>
              <NewPlaylistBtn
                disabled={isHandlingTracks.length}
                onClick={async () => this.handleAddToNewPlaylist()}
              >
                {t('components:modal_add_to_playlist.add_to_new_playlist')}
              </NewPlaylistBtn>

              {userPlaylists.map(playlist => this.renderPlaylist(playlist))}
            </Content>
          </Wrapper>
        </ClickAwayListener>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalAddToPlaylist);
