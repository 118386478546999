// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'next/router';
import _get from 'lodash/get';
import _some from 'lodash/some';

import {
  close,
  play,
  pause,
  seek,
  goToNextTrack,
  goToPrevTrack,
} from '../../../store/actions/PlayerActions';
import {
  downloadArchives as downloadArchivesBase,
  downloadCopyright as downloadCopyrightBase,
} from '../../../store/actions/EntitiesActions';
import {
  getFiles as getFilesBase,
  downloadAudiofile as downloadAudiofileBase,
  notifyFileDownload,
} from '../../../store/actions/TrackActions';
import {
  addToFavorites as addToFavoritesBase,
  removeFromFavorites as removeFromFavoritesBase,
} from '../../../store/actions/FavoritesActions';
import { setTrackLyricsOpen, setMusicPlayerOpen } from '../../../store/actions/ModalsActions';

import * as states from '../../../store/constants/PlayerStates';

import MusicPlayerBase from '../../presentationals/musicplayer';

import { encodeAndRemovePercent } from '../../../helpers/misc';
import { getTrackTitle } from '../../../helpers/entity/track';
import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

class MusicPlayer extends PureComponent {
  static propTypes = {
    current: pth.track,
    filesStore: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    getFiles: PropTypes.func.isRequired,
    addToFavorites: PropTypes.func.isRequired,
    removeFromFavorites: PropTypes.func.isRequired,
    downloadArchives: PropTypes.func.isRequired,
    downloadCopyright: PropTypes.func.isRequired,
    downloadAudiofile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: null,
  };

  addToFavorites = (music) => {
    const { addToFavorites, removeFromFavorites } = this.props;

    if (music.isFavorite) removeFromFavorites(music.id);
    else addToFavorites(music.id);
  };

  handleMagicWand = (music) => {
    const query = encodeAndRemovePercent(JSON.stringify([music.id, getTrackTitle(music)]));
    Router.push(`/search?track_id=${query}`);
  };

  handleDownloadArchive = (music, quality = 'original') => {
    const { downloadArchives } = this.props;

    downloadArchives('track', [music.id], quality, true);
  };

  handleDownloadAudiofile = (music, quality = 'hd_mp3') => {
    const { downloadAudiofile } = this.props;

    downloadAudiofile(music.id, quality);
  };

  handleDownloadCopyright = (music) => {
    const { downloadCopyright } = this.props;

    downloadCopyright('track', music.id, getTrackTitle(music));
  };

  getFilesDatas = (music) => {
    const { filesStore } = this.props;

    return _get(filesStore, music.id, {});
  };

  openFilesPassthrough = (music) => {
    const { getFiles, filesStore } = this.props;

    const isFetchingFiles = _get(filesStore, `[${music.id}].isFetching`, false);
    const isFetchedFiles = _get(filesStore, `[${music.id}].isFetchedOnce`, false);

    // We fetch each time the version in case there was changes
    if (isFetchedFiles || isFetchingFiles) return;
    getFiles(music.id);
  };

  render() {
    return (
      <MusicPlayerBase
        onMagicWand={this.handleMagicWand}
        onAddToFavorites={this.addToFavorites}
        onOpenFilesPassthrough={this.openFilesPassthrough}
        getFilesDatas={this.getFilesDatas}
        handleDownloadArchive={this.handleDownloadArchive}
        handleDownloadAudiofile={this.handleDownloadAudiofile}
        handleDownloadCopyright={this.handleDownloadCopyright}
        {...this.props}
      />
    );
  }
}

function mapStateToProps({ user, track, player, modals, config }) {
  return {
    isLogged: user.isLogged,
    pitchToken: user.pitchToken,
    canDownload: _get(user, 'data.canDownload', false),
    canDownloadStems: _get(user, 'data.canDownloadStems', false),
    filesStore: _get(track, 'files', {}),
    opened: player.isOpened,
    current: player.current,
    hasNext: !!player.next,
    getCurrentTime: player.getCurrentTime,
    isPlaying: player.playerState === states.PLAYING,
    isPaused: player.playerState === states.PAUSED,
    isEnded: player.playerState === states.ENDED,
    minimized: _get(config.data, 'customisations.player.size') === 'small',
    modalHide: _some(
      [
        _get(modals, 'isAccountOpen', false),
        _get(modals, 'isMenuOpen', false),
        _get(modals, 'isCookiesOpen', false),
        _get(modals, 'isLegalOpen', false),
        _get(modals, 'downloadArchive.isOpen', false),
      ],
      e => e,
    ),
  };
}

export default connect(mapStateToProps, {
  close,
  play,
  pause,
  seek,
  goToNextTrack,
  goToPrevTrack,
  addToFavorites: addToFavoritesBase,
  removeFromFavorites: removeFromFavoritesBase,
  getFiles: getFilesBase,
  notifyFileDownload,
  setTrackLyricsOpen,
  downloadArchives: downloadArchivesBase,
  downloadCopyright: downloadCopyrightBase,
  downloadAudiofile: downloadAudiofileBase,
  toggleMusicPlayerModal: setMusicPlayerOpen,
})(MusicPlayer);
