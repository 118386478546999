// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import HeaderMobile from './mobile';
import HeaderDesktop from './desktop';

// =============================
// Component
// =============================

class Header extends PureComponent {
  static displayName = 'HeaderResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={HeaderDesktop}
        MobileComponent={HeaderMobile}
        props={this.props}
      />
    );
  }
}

export default Header;
