// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

const initialState = {
  isAdding: false,
  isFetching: false,
  isFetchedOnce: false,
  data: [],
};

// =============================
// Reducer
// =============================

export default function recentplays(state = initialState, action) {
  switch (action.type) {
    case acts.SET_RECENT_PLAYS:
      return {
        ...state,
        data: action.payload,
      };

    case acts.GET_RECENT_PLAYS_LOADING:
      return {
        ...state,
        isFetching: true,
      };

    case acts.GET_RECENT_PLAYS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchedOnce: true,
      };

    case acts.GET_RECENT_PLAYS_FAILURE:
      return {
        ...state,
        isFetching: false,
        isFetchedOnce: false,
      };

    case acts.UPDATE_RECENT_PLAYS_FAVORITES: {
      return {
        ...state,
        data: state.data.map((track) => {
          if (track.id !== action.payload.id) return track;
          return { ...track, isFavorite: action.payload.isFavorite };
        }),
      };
    }

    case acts.RESET_RECENT_PLAYS:
      return {
        ...state,
        isFetching: initialState.isFetching,
        isFetchedOnce: initialState.isFetchedOnce,
        data: initialState.data,
      };

    case acts.ADD_TO_RECENT_PLAYS_LOADING:
      return {
        ...state,
        isAdding: true,
      };

    case acts.ADD_TO_RECENT_PLAYS_SUCCESS:
    case acts.ADD_TO_RECENT_PLAYS_FAILURE:
      return {
        ...state,
        isAdding: false,
      };

    default:
      return state;
  }
}
