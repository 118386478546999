// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setAlbumOpen } from '../../../../store/actions/ModalsActions';

import ModalAlbum from '../../../layouts/modals/album';

// =============================
// Component
// =============================

function mapStateToProps({ modals, entities }) {
  return {
    album: entities.album.data,
    opened: modals.isAlbumOpen,
  };
}

export default connect(mapStateToProps, {
  closeAlbumDescModal: () => setAlbumOpen(false),
})(ModalAlbum);
