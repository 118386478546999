// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import { compose } from 'redux';
import { withTheme } from 'styled-components';

import Router from 'next/router';
import { withTranslation } from '../../../../config/i18n';

import { snakeCaseKeysDeep } from '../../../../helpers/misc';
import * as LastRoute from '../../../../helpers/lastroute';
import * as pth from '../../../../helpers/proptypes';

import Link from '../../../other/link';

import {
  Left,
  MenuButton,
  Right,
  SearchButton,
  StyledAvatar,
  StyledLogo,
  Wrapper,
  StyledButton,
} from './styles';

// =============================
// Component
// =============================

class Header extends PureComponent {
  static displayName = 'HeaderMobile';

  static propTypes = {
    /** If True, has access to website when private. */
    // hasAccess: PropTypes.bool.isRequired,
    /** isSimpleLayout */
    isSimpleLayout: PropTypes.bool.isRequired,
    /** Open menu function. */
    openMenu: PropTypes.func.isRequired,
    /** Open user menu function. */
    openUserMenu: PropTypes.func.isRequired,
    /** Home route */
    rootRoute: PropTypes.string.isRequired,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Styled components Theme object. */
    // eslint-disable-next-line react/forbid-prop-types
    theme: PropTypes.object.isRequired,
    /** Func used to toggle PreSearch Modal. */
    togglePreSearchModal: PropTypes.func.isRequired,
    /** User object */
    user: pth.user,
  };

  static defaultProps = {
    user: null,
  };

  getHeaderThemeColors = (theme) => {
    const { isSimpleLayout } = this.props;

    if (isSimpleLayout) return theme.colors.pages.auth;

    return theme.colors.header;
  };

  render() {
    const {
      isSimpleLayout,
      openMenu,
      openUserMenu,
      rootRoute,
      t,
      theme,
      togglePreSearchModal,
      user,
    } = this.props;

    const { lastAsPath } = LastRoute.get(rootRoute);

    return (
      <WithCustomTheme
        customTheme={currentTheme => ({
          colors: this.getHeaderThemeColors(currentTheme),
        })}
      >
        <Wrapper>
          <Left>
            {!isSimpleLayout && <MenuButton onClick={openMenu} />}
            <StyledLogo
              usedLogo={theme.colors.logo}
              colors={theme.colors.currentMenuTheme}
            />
          </Left>

          <Right>
            {!isSimpleLayout && <SearchButton onClick={() => togglePreSearchModal(true)} />}
            {/* Not auth and logged in and not presearch */}
            {!isSimpleLayout && user && (
              <StyledAvatar onClick={openUserMenu} user={snakeCaseKeysDeep(user)} />
            )}

            {/* Not auth and not logged in and not presearch */}
            {!isSimpleLayout && !user && (
              <Link route="/login">
                <StyledButton>{t('components:header.login')}</StyledButton>
              </Link>
            )}

            {/* Is auth has access to private and not presearch */}
            {isSimpleLayout && (
              <StyledButton onClick={() => Router.push(lastAsPath)}>
                {t('components:header.back_home')}
              </StyledButton>
            )}
          </Right>
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default compose(withTranslation('components'), withTheme)(Header);
