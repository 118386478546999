// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a catalog
 * @param {object} catalog - Catalog object
 * @param {string} size - Image size, can be "large" or "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getCatalogCoverUrl = getGenericDocCoverUrl;

/**
 * Get catalog name
 * @param {object} catalog - Catalog object
 * @return {string} - Catalog name
 */
export const getCatalogName = catalog => _get(catalog, 'name');
