// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';

import { getValueByLocale } from '../../../helpers/i18n';
import { presentDuration } from '../../../helpers/entity/common';
import { composeQuery } from '../../../helpers/search';
import * as pth from '../../../helpers/proptypes';

import withResponsive from '../../hoc/withResponsive';

import { Wrapper, StyledChip } from './styles';

// =============================
// Components
// =============================

class Filters extends Component {
  static propTypes = {
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, user is using a mobile. */
    isMobile: PropTypes.bool.isRequired,
    searchQuery: pth.searchquery.isRequired,
    t: PropTypes.func.isRequired,
    tagsWithColorsAndCatNames: PropTypes.arrayOf(pth.tagWithColorAndCatNames).isRequired,
    versions: PropTypes.arrayOf(pth.trackVersion).isRequired,
  };

  static defaultProps = {
    className: '',
  };

  handleRemoveQuery = () => {
    const { searchQuery: initialSearch } = this.props;

    const search = _omit(initialSearch, ['query']);
    const filters = _get(initialSearch, 'options.filters', {});

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveSearchId = () => {
    const { searchQuery: initialSearch } = this.props;

    const search = _omit(_get(initialSearch, 'options', {}), ['searchId']);
    const filters = _get(initialSearch, 'options.filters', {});

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveTrackId = () => {
    const { searchQuery: initialSearch } = this.props;

    const search = _omit(_get(initialSearch, 'options', {}), ['trackId']);
    const filters = _get(initialSearch, 'options.filters', {});

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveSearchLyrics = () => {
    const { searchQuery: initialSearch } = this.props;

    const search = _omit(_get(initialSearch, 'options', {}), ['search_lyrics']);
    const filters = _get(initialSearch, 'options.filters', {});

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveRange = (type) => {
    const { searchQuery: search } = this.props;

    const initialFilters = _get(search, 'options.filters', {});
    const filters = _omit(initialFilters, [type]);

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveEntity = (type, id) => {
    const { searchQuery: initialSearch } = this.props;

    const search = {
      ...initialSearch,
      options: {
        ..._get(initialSearch, 'options', {}),
        filters: _omitBy(
          {
            ..._get(initialSearch, 'options.filters', {}),
            [type]: _get(initialSearch, `options.filters[${type}]`, []).filter(a => a[0] !== id),
          },
          value => (Array.isArray(value) ? _isEmpty(value) : !value),
        ),
      },
    };

    const filters = _get(initialSearch, 'options.filters', {});

    const query = composeQuery(search, filters);

    Router.push(`/search?${query}`);
  };

  handleRemoveStems = () => {
    const { searchQuery: search } = this.props;

    const initialFilters = _get(search, 'options.filters', {});
    const filters = _omit(initialFilters, ['stems']);

    const query = composeQuery(search, filters);
    Router.push(`/search?${query}`);
  };

  handleRemoveGenericFilter = (key, id) => {
    const { searchQuery: initialSearch } = this.props;

    const search = {
      ...initialSearch,
      options: {
        ..._get(initialSearch, 'options', {}),
        filters: _omitBy(
          {
            ..._get(initialSearch, 'options.filters', {}),
            [key]: _get(initialSearch, `options.filters[${key}]`, []).filter(a => a !== id),
          },
          value => (Array.isArray(value) ? _isEmpty(value) : !value),
        ),
      },
    };

    const filters = _get(search, 'options.filters', {});

    const query = composeQuery(search, filters);

    // NOTE: Always push with `?` to prevent guided search from closing
    Router.push(`/search?${query}`);
  };

  getRangeTextValue = (type, rangeValue) => {
    // NOTE: These values are set directly in components project
    const maxDictionary = {
      year: new Date().getFullYear(),
      duration: 600,
      bpm: 220,
    };

    let firstVal = '';
    let secondVal = '';

    if (rangeValue.length > 0) {
      firstVal = type === 'duration' ? presentDuration(rangeValue[0]) : rangeValue[0];

      if (rangeValue.length === 2) {
        secondVal = type === 'duration' ? presentDuration(rangeValue[1]) : rangeValue[1];
      } else {
        secondVal = type === 'duration' ? presentDuration(maxDictionary[type]) : maxDictionary[type];
      }
    }

    return firstVal && secondVal ? `${firstVal}-${secondVal}` : '';
  };

  render() {
    const {
      className,
      i18n: { language },
      isMobile,
      searchQuery,
      t,
      tagsWithColorsAndCatNames,
      versions,
    } = this.props;

    const chips = [];
    const filters = _get(searchQuery, 'options.filters', {});

    // Query
    if (_get(searchQuery, 'query')) {
      chips.push(
        <StyledChip
          key="query"
          name={`"${_get(searchQuery, 'query')}"`}
          onClick={isMobile ? this.handleRemoveQuery : null}
          onDelete={this.handleRemoveQuery}
        />,
      );
    }

    // Query
    if (_get(searchQuery, 'options.searchId')) {
      chips.push(
        <StyledChip
          key="searchId"
          name={`${t('components:filters.similar')}: ${_get(searchQuery, 'options.searchId')[1]}`}
          onClick={isMobile ? this.handleRemoveSearchId : null}
          onDelete={this.handleRemoveSearchId}
        />,
      );
    }

    if (_get(searchQuery, 'options.trackId')) {
      chips.push(
        <StyledChip
          key="trackId"
          name={`${t('components:filters.similar')}: ${_get(searchQuery, 'options.trackId')[1]}`}
          onClick={isMobile ? this.handleRemoveTrackId : null}
          onDelete={this.handleRemoveTrackId}
        />,
      );
    }

    if (_get(searchQuery, 'options.search_lyrics')) {
      chips.push(
        <StyledChip
          key="searchLyrics"
          name={t('components:filters.lyrics')}
          onClick={isMobile ? this.handleRemoveSearchLyrics : null}
          onDelete={this.handleRemoveSearchLyrics}
        />,
      );
    }

    // Entity filters
    const entityFilters = _pick(filters, [
      'ref_id',
      'catalogs',
      'labels',
      'publishers',
      'artists',
      'albums',
      'playlists',
    ]);

    Object.keys(entityFilters).forEach((entityKey) => {
      const type = entityKey === 'ref_id' ? entityKey : entityKey.substr(0, entityKey.length - 1);

      entityFilters[entityKey].forEach((value) => {
        chips.push(
          <StyledChip
            key={`${type}-${value[0]}`}
            name={`${t(`filters.${type}`)}: ${value[1]}`}
            onClick={isMobile ? () => this.handleRemoveEntity(entityKey, value[0]) : null}
            onDelete={() => this.handleRemoveEntity(entityKey, value[0])}
          />,
        );
      });
    });

    // Year, Duration, Bpm
    const rangeFilters = _pick(filters, ['year', 'bpm', 'duration']);

    Object.keys(rangeFilters).forEach((type) => {
      chips.push(
        <StyledChip
          key={type}
          name={`${t(`filters.${type}`)}: ${this.getRangeTextValue(type, rangeFilters[type])}`}
          onClick={isMobile ? () => this.handleRemoveRange(type) : null}
          onDelete={() => this.handleRemoveRange(type)}
        />,
      );
    });

    // Stems
    if (filters.stems) {
      chips.push(
        <StyledChip
          key="stems"
          name={t('components:filters.stems')}
          onClick={isMobile ? this.handleRemoveStems : null}
          onDelete={this.handleRemoveStems}
        />,
      );
    }

    // Versions
    const versionFilters = _get(filters, 'versions', []);

    versionFilters.forEach((versionId) => {
      const foundVersion = versions.find(v => v.id === versionId);

      if (foundVersion) {
        chips.push(
          <StyledChip
            key={`version-${versionId}`}
            name={getValueByLocale(foundVersion.names, language)}
            onClick={isMobile ? () => this.handleRemoveGenericFilter('versions', versionId) : null}
            onDelete={() => this.handleRemoveGenericFilter('versions', versionId)}
          />,
        );
      }
    });

    // Versions not
    const versionNotFilters = _get(filters, 'versions_not', []);

    versionNotFilters.forEach((versionId) => {
      const foundVersion = versions.find(v => v.id === versionId);

      if (foundVersion) {
        chips.push(
          <StyledChip
            fillVariant="outline"
            key={`version-not-${versionId}`}
            name={getValueByLocale(foundVersion.names, language)}
            onClick={isMobile ? () => this.handleRemoveGenericFilter('versions_not', versionId) : null}
            onDelete={() => this.handleRemoveGenericFilter('versions_not', versionId)}
          />,
        );
      }
    });

    // Tags or
    // NOTE: Can only be sub categories
    const tagsOrFilters = _get(filters, 'tags_or', []);

    tagsOrFilters.forEach((tagId) => {
      const foundTag = tagsWithColorsAndCatNames.find(e => e.id === tagId);

      if (foundTag) {
        chips.push(
          <StyledChip
            indicationColor={foundTag.color}
            indicationTooltipMessage={getValueByLocale(_get(foundTag, 'categoryNames', []), language)}
            key={`tag-or-${tagId}`}
            name={getValueByLocale(_get(foundTag, 'names', []), language)}
            onClick={isMobile ? () => this.handleRemoveGenericFilter('tags_or', tagId) : null}
            onDelete={() => this.handleRemoveGenericFilter('tags_or', tagId)}
          />,
        );
      }
    });

    // Tags not
    // NOTE: Can be tag or sub category
    const tagsNotFilters = _get(filters, 'tags_not', []);

    tagsNotFilters.forEach((tagId) => {
      const foundTag = tagsWithColorsAndCatNames.find(e => e.id === tagId);

      if (foundTag) {
        chips.push(
          <StyledChip
            indicationColor={foundTag.color}
            indicationTooltipMessage={getValueByLocale(_get(foundTag, 'categoryNames', []), language)}
            fillVariant="outline"
            key={`tag-not-${tagId}`}
            name={getValueByLocale(_get(foundTag, 'names', []), language)}
            onClick={isMobile ? () => this.handleRemoveGenericFilter('tags_not', tagId) : null}
            onDelete={() => this.handleRemoveGenericFilter('tags_not', tagId)}
          />,
        );
      }
    });

    // Tags
    // NOTE: Can be tag or sub category
    const tagsFilters = _get(filters, 'tags', []);

    tagsFilters.forEach((tagId) => {
      const foundTag = tagsWithColorsAndCatNames.find(e => e.id === tagId);

      if (foundTag) {
        chips.push(
          <StyledChip
            key={`tag-${tagId}`}
            name={getValueByLocale(foundTag.names, language)}
            indicationColor={foundTag.color}
            indicationTooltipMessage={getValueByLocale(_get(foundTag, 'categoryNames', []), language)}
            onClick={isMobile ? () => this.handleRemoveGenericFilter('tags', tagId) : null}
            onDelete={() => this.handleRemoveGenericFilter('tags', tagId)}
          />,
        );
      }
    });

    // Reset
    if (chips.length) {
      // NOTE: Always push with `?` to prevent guided search from closing
      chips.unshift(
        <StyledChip
          key="reset"
          name={t('components:filters.reset')}
          onClick={() => Router.push('/search?')}
          color="alternate"
        />,
      );
    }

    return <Wrapper className={className}>{chips}</Wrapper>;
  }
}

export default withResponsive(Filters);
