// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons } from '@mewo/components';
import { alpha } from '@material-ui/core';

import Cover from '../../cover';
import Link from '../../../other/link';

// =============================
// Notes
// =============================

/**
 * We use the OverlayLinkContainer because we actually need
 * 2 links, one targeting artist page, and one targeting search page.
 * The link targeting artist page need to cover all the ArtistItem.
 * So in order to avoid having nested link, we use this OverlayLink
 * alternative.
 */

// =============================
// Styles
// =============================

const artistItemHeight = '5rem';
const artistItemHeightWithouthBorder = '3.8rem';

const searchFeaturesWidth = '4rem';

export const Wrapper = styled.div`
  align-items: center;
  border-bottom: 0.1rem solid ${({ theme }) => alpha(theme.colors.text, 0.1)};
  display: grid;
  justify-content: flex-start;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  grid-gap: 1.6rem;
  line-height: 1.9rem;
  min-height: ${artistItemHeight};
  padding-bottom: 1.2rem;
  position: relative;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  ${({ withCover, withSearchFilterBtn }) => css`
    grid-template-columns: ${withCover && artistItemHeightWithouthBorder} minmax(10rem, 1fr) ${withSearchFilterBtn && searchFeaturesWidth};
  `};
`;

export const OverlayLinkContainer = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const OverlayLink = styled(Link)`
  height: 100%;
  width: 100%;
`;

export const ArtistCover = styled(Cover).attrs({
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  placeholderType: 'artist',
  rounded: true,
  size: artistItemHeightWithouthBorder,
  type: 'artist',
})`
  flex-shrink: 0;
  height: ${artistItemHeightWithouthBorder};
`;

export const ArtistName = styled.div`
  flex: 1 1 auto;
`;

export const AddArtistAsFilterBtn = styled(Link)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  /** Used to be above OverlayLink. */
  z-index: 1;
`;

export const GoToIcon = styled(Icons.ExternalLink).attrs(() => ({
  width: '1.4rem',
}))`
  flex-shrink: 0;
`;
