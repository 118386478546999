// =============================
// Imports
// =============================

import styled from 'styled-components';
import { Icons } from '@mewo/components';

import { headerHeight } from '../../presentationals/header/desktop/variables';
import { paddingX } from '../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    min-height: 100%;
    padding: calc(${headerHeight}) ${paddingX} ${paddingX} ${paddingX};
    position: fixed;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100%;
  `};
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Description = styled.div`
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-bottom: 4.8rem;
`;

export const Error404Icon = styled(Icons.Error404).attrs(({ theme }) => ({
  fill: theme.colors.text,
}))``;
