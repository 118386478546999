// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import { Input as Unwrapped } from '@mewo/components';

// Components
import { withTranslation } from '../../../config/i18n';

// =============================
// Component
// =============================

const Input = ({ t, ...rest }) => (
  <Unwrapped
    lang={{
      Label: {
        optional: t('components:mewo_inputs.Label.optional'),
      },
    }}
    {...rest}
  />
);

Input.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('components')(Input);
