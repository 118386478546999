import styled, { css } from 'styled-components';
import Button from '../button';
import Link from '../../other/link';

export const textEditorContentStyles = css`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.4rem;
    line-height: 1.8rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `};

  p {
    ${({ theme }) => theme.mediaBelow.minWidth`
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    `};
  }

  b {
    color: ${({ theme }) => theme.colors.text};
  }

  h1 {
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    ${({ theme }) => theme.mediaBelow.minWidth`
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 3.2rem;
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      font-size: 3.2rem;
      line-height: 4rem;
      margin-bottom: 5rem;
    `};
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.bold};

    ${({ theme }) => theme.mediaBelow.minWidth`
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: 2rem;

      &:not(:first-child) {
        margin-top: 3rem;
      }
    `};

    ${({ theme }) => theme.mediaAbove.minWidth`
      font-size: 2.2rem;
      line-height: 2.8rem;
      margin-bottom: 2.5rem;

      &:not(:first-child) {
        margin-top: 4.8rem;
      }
    `};
  }

  ul li {
    margin-bottom: 0.8rem;
    margin-left: 1.6rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const NoData = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoDataMsg = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const NoDataLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: true,
}))``;

export const NoDataBtn = styled(Button).attrs(() => ({
  size: 'small',
}))``;
