// =============================
// Imports
// =============================

// External Dependencies
import PropTypes from 'prop-types';
import { SwitchInput as Unwrapped } from '@mewo/components';

// Components
import { withTranslation } from '../../../../config/i18n';

// =============================
// Component
// =============================

const SwitchInput = ({ t, ...rest }) => (
  <Unwrapped
    lang={{
      Label: {
        optional: t('components:mewo_inputs.Label.optional'),
      },
    }}
    {...rest}
  />
);

SwitchInput.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('components')(SwitchInput);
