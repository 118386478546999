// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../responsive';

import SearchBarModalDesktop from './desktop';
import SearchBarModalMobile from './mobile';

// =============================
// Component
// =============================

class SearchBarModal extends PureComponent {
  static displayName = 'SearchBarModalResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SearchBarModalDesktop}
        MobileComponent={SearchBarModalMobile}
        props={this.props}
      />
    );
  }
}

export default SearchBarModal;
