/* eslint-disable jsx-a11y/click-events-have-key-events, react/sort-comp */
// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Router, { withRouter } from 'next/router';
import _every from 'lodash/every';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../../config/i18n';

import Modal from './modal';
import ModalCloser from '../../../../other/modalCloser';
import GuidedSearch from './guidedSearch';

import { composeQuery, transformTotalValueToString } from '../../../../../helpers/search';
import { presentTags } from '../../../../../helpers/entity/common';
import * as entity from '../../../../../helpers/entity';
import * as pth from '../../../../../helpers/proptypes';
import { getValueByLocale } from '../../../../../helpers/i18n';

import {
  EntityWrapper,
  EntityCover,
  EntityTitle,
  EntityTitleLink,
  EntityAuthors,
  EntityAuthor,
  TagCategory,
  IconLink,
  ExternalLinkIcon,
  Caption,
  StyledTooltip,
  StyledButton,
  Column,
  ColumnTitle,
  SeeResult,
  PageTitle,
  StyledScrollBar,
  TagsList,
  StyledTag,
  EntityTitleAuthors,
  PresearchContainer,
  CaptionContainer,
  StyledInfoTooltip,
} from './styles';
import { getTrackAlbum } from '../../../../../helpers/entity/track';

// =============================
// Stories
// =============================

class ModalPreSearch extends PureComponent {
  static displayName = 'ModalPreSearchDesktop';

  static propTypes = {
    audioUploadProgress: PropTypes.number.isRequired,
    closePreSearchModal: PropTypes.func.isRequired,
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      isVisible: PropTypes.bool,
    }),
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Initial search filters (even those non available anymore due to filtering). */
    initialFacets: pth.facets.isRequired,
    /** If True, search value is an external link. */
    isExternal: PropTypes.bool.isRequired,
    /** If True, search result are being fetch. */
    isFetching: PropTypes.bool.isRequired,
    /** Maia audio signal status */
    isSendingMaiaAudioSignal: PropTypes.bool.isRequired,
    isSendingMaiaTextSignal: PropTypes.bool.isRequired,
    opened: PropTypes.bool,
    openPreSearchModal: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    router: PropTypes.shape({
      asPath: PropTypes.string,
    }).isRequired,
    searchValue: PropTypes.string,
    searchTracksTotal: PropTypes.shape({
      relation: PropTypes.oneOf(['gte', 'eq']),
      value: PropTypes.number,
    }).isRequired,
    searchDatas: pth.preSearchDatas.isRequired,
    searchQuery: pth.searchquery.isRequired,
    t: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(pth.tagWithCat).isRequired,
    /** All existing tags subCategory. (Guided search only show subCategory tags). */
    tagsSc: PropTypes.arrayOf(pth.tagSubCategory).isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
    opened: false,
    searchValue: '',
  };

  componentDidUpdate(prevProps) {
    const {
      isSendingMaiaAudioSignal: wasSendingMaiaAudioSignal,
      router: { asPath: prevPath },
    } = prevProps;

    const {
      closePreSearchModal,
      config,
      isSendingMaiaAudioSignal,
      opened,
      openPreSearchModal,
      router: { asPath: path },
      searchValue,
    } = this.props;

    const withGuidedSearch = _get(config, 'customisations.guidedSearch.active', false);

    const changedPath = prevPath !== path;
    const nextPathIsSearch = path.indexOf('/search') !== -1;
    const nextPathIsSimilaritySearch = nextPathIsSearch && path.indexOf('search_id') !== -1;

    const guidedSearchShouldClose = withGuidedSearch
      && (searchValue || !nextPathIsSearch || nextPathIsSimilaritySearch || path === '/search');

    if (changedPath && opened && (!withGuidedSearch || guidedSearchShouldClose)) {
      closePreSearchModal();
    }

    if (!opened && !wasSendingMaiaAudioSignal && isSendingMaiaAudioSignal) {
      openPreSearchModal();
    }
  }

  renderDisplayArtists = (type, r) => {
    const { closePreSearchModal } = this.props;

    return entity
      .getDisplayArtists(type)(r)
      .map(da => (
        <ModalCloser
          key={da.id}
          onClose={closePreSearchModal}
          path={entity.getUrl('artists')(da, da.name)}
        >
          {onClose => (
            <EntityAuthor
              onClickPassthrough={onClose}
              route={entity.getBaseRoute('artists')}
              nextAs={entity.getUrl('artists')(da, da.name)}
              title={da.name}
            >
              {da.name}
            </EntityAuthor>
          )}
        </ModalCloser>
      ));
  };

  renderTagResults = (data) => {
    const { closePreSearchModal, searchValue } = this.props;

    return data.map(tag => (
      <StyledTag
        key={tag.id}
        data={tag}
        onClickPassthrough={closePreSearchModal}
        checkIfUsable
        stringSearchValue={searchValue}
        withSynonyms
      />
    ));
  };

  handleSubCatClick = (subCatId) => {
    const { searchQuery } = this.props;

    const filters = _get(searchQuery, 'options.filters', {});
    let tagsOrFilters = _get(filters, 'tags_or', []);
    const otherFilters = _omit(filters, ['tags_or']);

    if (tagsOrFilters.indexOf(subCatId) === -1) {
      tagsOrFilters.push(subCatId);
    } else {
      tagsOrFilters = tagsOrFilters.filter(subCat => subCat !== subCatId);
    }

    const query = composeQuery(searchQuery, { tags_or: tagsOrFilters, ...otherFilters });

    Router.push(`/search?${query}`);
  };

  renderTagCategoriesResults = (data) => {
    const { facets, i18n: { language }, searchQuery } = this.props;

    const facetsTags = Object.keys(facets.tags);
    const activeSubCats = _get(searchQuery, 'options.filters.tags_or', []);

    return data.map((tagSc) => {
      const isAvailable = !!_filter(tagSc.tags, t => _includes(facetsTags, t.id)).length;

      return (
        <TagCategory
          key={tagSc.id}
          name={getValueByLocale(_get(tagSc, 'names', []), language)}
          disabled={!isAvailable}
          indicationColor={tagSc.color}
          color={activeSubCats.indexOf(tagSc.id) !== -1 ? 'primary' : 'default'}
          onClick={() => this.handleSubCatClick(tagSc.id)}
        />
      );
    });
  };

  renderEntityResults = (type, data) => {
    const { closePreSearchModal, t } = this.props;

    const coverType = type.substr(0, type.length - 1);
    const items = [];

    for (let i = 0, len = Math.min(data.length, 6); i < len; i += 1) {
      const r = data[i];

      items.push(
        <EntityWrapper isTrack={type === 'tracks'} key={`${type}-${r.id}`}>
          <EntityCover
            alt={type === 'tracks' ? getTrackAlbum(r).title : entity.getTitle(type)(r)}
            src={entity.getMultiCoverUrls(type, 'small')(r)}
            type={coverType}
            placeholderType={coverType}
            rounded={type === 'artists'}
            contextName={entity.getPlayerContextName(type)}
            contextId={r.id}
            lazyload={false}
          />

          <EntityTitleAuthors>
            {entity.getSearchUrl(type)(r) ? (
              <ModalCloser onClose={closePreSearchModal} path={entity.getSearchUrl(type)(r)}>
                {onClose => (
                  <EntityTitleLink
                    onClickPassthrough={onClose}
                    route={entity.getSearchUrl(type)(r)}
                    title={entity.getTitle(type)(r)}
                  >
                    {entity.getTitle(type)(r)}
                  </EntityTitleLink>
                )}
              </ModalCloser>
            ) : (
              <EntityTitle title={entity.getTitle(type)(r)}>{entity.getTitle(type)(r)}</EntityTitle>
            )}
            {(type === 'albums' || type === 'tracks') && (
              <EntityAuthors>{this.renderDisplayArtists(type, r)}</EntityAuthors>
            )}
          </EntityTitleAuthors>
          {(entity.getUrl(type)(r) && type !== 'tracks') && (
            <ModalCloser onClose={closePreSearchModal} path={entity.getUrl(type)(r)}>
              {onClose => (
                <IconLink
                  onClickPassthrough={onClose}
                  route={entity.getBaseRoute(type)}
                  nextAs={entity.getUrl(type)(r)}
                >
                  <StyledTooltip content={t('pages:search.go_to_page')}>
                    <ExternalLinkIcon />
                  </StyledTooltip>
                </IconLink>
              )}
            </ModalCloser>
          )}
        </EntityWrapper>,
      );
    }

    return items;
  };

  getEmptyColumnNumber = () => {
    /**
     * In minimized version, we render only 4 column: Tags, Tracks, Albums & Playlists.
     * If a column has no result, we hide it.
     * If a column get hidden for having no result, then we replace it with a column
     * normaly not displayed.
     * In order: Artists, Catalogs, Labels, Publishers
     */
    const { searchDatas } = this.props;
    let emptyColumn = 0;

    if (_get(searchDatas, 'tags.total.value') === 0) emptyColumn += 1;
    if (_get(searchDatas, 'tracks.total.value') === 0) emptyColumn += 1;
    if (_get(searchDatas, 'albums.total.value') === 0) emptyColumn += 1;
    if (_get(searchDatas, 'playlists.total.value') === 0) emptyColumn += 1;

    return emptyColumn;
  };

  renderColumns = () => {
    const {
      closePreSearchModal,
      config,
      fullSizeCoverModule,
      i18n: { language },
      searchDatas,
      searchValue,
      t,
      tags,
      tagsSc,
    } = this.props;

    const minimized = fullSizeCoverModule?.isVisible || !_get(config, 'customisations.preSearch.fullpage', false);

    const columns = [];
    let replacementCol = this.getEmptyColumnNumber();

    const displayTagCategories = presentTags(
      _get(searchDatas, 'tagCategories.data', [])
        .map(({ id }) => tagsSc.find(tagSc => tagSc.id === id)),
      language,
    );

    const displayTags = presentTags(
      _get(searchDatas, 'tags.data', [])
        .map(({ id }) => tags.find(tag => tag.id === id)),
      language,
    );

    // TAGS.
    if (
      _get(searchDatas, 'tagCategories.total.value') > 0
      || _get(searchDatas, 'tags.total.value') > 0
    ) {
      columns.push(
        <Column key="tags" minimized={minimized}>
          {/** Tag categories */}
          {_get(searchDatas, 'tagCategories.total.value') > 0 && (
            <Fragment>
              <ColumnTitle as="div">
                {t('pages:search.tags_categories')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tagCategories.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_tag_categories_info_tooltip')} />
              </ColumnTitle>

              <TagsList>{this.renderTagCategoriesResults(displayTagCategories)}</TagsList>
            </Fragment>
          )}

          {/** Tags */}
          {_get(searchDatas, 'tags.total.value') > 0 && (
            <Fragment>
              <ColumnTitle as="div">
                {t('pages:search.tags')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tags.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_tags_info_tooltip')} />
              </ColumnTitle>

              <TagsList>{this.renderTagResults(displayTags)}</TagsList>
            </Fragment>
          )}
        </Column>,
      );
    }

    // TRACKS.
    if (_get(searchDatas, 'tracks.total.value') > 0) {
      columns.push(
        <Column key="tracks" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('tracks')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('tracks')(searchValue)}
              >
                {t('pages:search.tracks')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.tracks.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_tracks_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('tracks', searchDatas.tracks.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('tracks')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('tracks')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // ALBUMS.
    if (_get(searchDatas, 'albums.total.value') > 0) {
      columns.push(
        <Column key="albums" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('albums')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('albums')(searchValue)}
              >
                {t('pages:search.albums')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.albums.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_albums_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('albums', searchDatas.albums.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('albums')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('albums')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // PLAYLISTS.
    if (_get(searchDatas, 'playlists.total.value') > 0) {
      columns.push(
        <Column key="playlists" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('playlists')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('playlists')(searchValue)}
              >
                {t('pages:search.playlists')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.playlists.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_playlists_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('playlists', searchDatas.playlists.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('playlists')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('playlists')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // If some previous column are empty, add non mandatory column.

    // ARTISTS
    if ((!minimized || replacementCol) && _get(searchDatas, 'artists.total.value') > 0) {
      replacementCol -= 1;

      columns.push(
        <Column key="artists" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('artists')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('artists')(searchValue)}
              >
                {t('pages:search.artists')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.artists.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_artists_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('artists', searchDatas.artists.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('artists')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('artists')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // CATALOGS
    if ((!minimized || replacementCol) && _get(searchDatas, 'catalogs.total.value') > 0) {
      replacementCol -= 1;

      columns.push(
        <Column key="catalogs" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('catalogs')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('catalogs')(searchValue)}
              >
                {t('pages:search.catalogs')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.catalogs.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_catalogs_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('catalogs', searchDatas.catalogs.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('catalogs')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('catalogs')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // LABELS
    if ((!minimized || replacementCol) && _get(searchDatas, 'labels.total.value') > 0) {
      replacementCol -= 1;

      columns.push(
        <Column key="labels" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('labels')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('labels')(searchValue)}
              >
                {t('pages:search.labels')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.labels.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_labels_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('labels', searchDatas.labels.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('labels')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('labels')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    // PUBLISHERS
    if ((!minimized || replacementCol) && _get(searchDatas, 'publishers.total.value') > 0) {
      columns.push(
        <Column key="publishers" minimized={minimized}>
          <ModalCloser
            path={entity.getEntitySearchUrl('publishers')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <ColumnTitle
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('publishers')(searchValue)}
              >
                {t('pages:search.publishers')}
                &nbsp;
                {`(${transformTotalValueToString(searchDatas.publishers.total)})`}
                <StyledInfoTooltip content={t('pages:search.pre_search_header_publishers_info_tooltip')} />
              </ColumnTitle>
            )}
          </ModalCloser>
          {this.renderEntityResults('publishers', searchDatas.publishers.data)}
          <ModalCloser
            path={entity.getEntitySearchUrl('publishers')(searchValue)}
            onClose={closePreSearchModal}
          >
            {onClose => (
              <SeeResult
                onClickPassthrough={onClose}
                route={entity.getEntitySearchUrl('publishers')(searchValue)}
              >
                {t('pages:search.see_more')}
              </SeeResult>
            )}
          </ModalCloser>
        </Column>,
      );
    }

    return columns;
  };

  renderPresearch = () => {
    const { config, fullSizeCoverModule, searchValue, t } = this.props;

    const minimized = fullSizeCoverModule?.isVisible || !_get(config, 'customisations.preSearch.fullpage', false);

    return (
      <Fragment>
        <PageTitle minimized={minimized}>
          {t('pages:search.search_result_for')}
          &nbsp;
          {`"${searchValue}"`}
        </PageTitle>
        <StyledScrollBar fullSizeCoverModule={fullSizeCoverModule} minimized={minimized}>
          {this.renderColumns()}
        </StyledScrollBar>
      </Fragment>
    );
  };

  renderModalContent = () => {
    const {
      audioUploadProgress,
      config,
      closePreSearchModal,
      facets,
      fullSizeCoverModule,
      i18n: { language },
      initialFacets,
      isExternal,
      isFetching,
      isSendingMaiaAudioSignal,
      resetSearch,
      searchDatas,
      searchQuery,
      searchTracksTotal,
      searchValue,
      t,
      tagsSc,
    } = this.props;

    const noData = _every(
      [
        _get(searchDatas, 'tags.total.value'),
        _get(searchDatas, 'tracks.total.value'),
        _get(searchDatas, 'albums.total.value'),
        _get(searchDatas, 'artists.total.value'),
        _get(searchDatas, 'playlists.total.value'),
        _get(searchDatas, 'catalogs.total.value'),
        _get(searchDatas, 'labels.total.value'),
        _get(searchDatas, 'publishers.total.value'),
      ],
      e => !e,
    );

    const minimized = fullSizeCoverModule?.isVisible || !_get(config, 'customisations.preSearch.fullpage', false);
    const withGuidedSearch = _get(config, 'customisations.guidedSearch.active', false);

    if (isSendingMaiaAudioSignal) {
      return (
        <CaptionContainer minimized={minimized}>
          <Caption>
            {t('pages:search.pre_search_uploading', { progress: `${audioUploadProgress}%` })}
            <StyledButton onClick={resetSearch} type="button">
              {t('pages:search.pre_search_cancel_upload')}
            </StyledButton>
          </Caption>
        </CaptionContainer>
      );
    }

    if (!isSendingMaiaAudioSignal && isExternal) {
      return (
        <CaptionContainer minimized={minimized}>
          <Caption>{t('pages:search.pre_search_is_external')}</Caption>
        </CaptionContainer>
      );
    }

    if (
      !isSendingMaiaAudioSignal
      && !isExternal
      && !isFetching
      && !searchValue
      && withGuidedSearch
    ) {
      return (
        <GuidedSearch
          minimized={minimized}
          config={config}
          locale={language}
          tagsSc={tagsSc}
          searchQuery={searchQuery}
          searchTracksTotal={searchTracksTotal}
          facets={facets}
          initialFacets={initialFacets}
          closePreSearchModal={closePreSearchModal}
          t={t}
        />
      );
    }

    if (!isSendingMaiaAudioSignal && !isExternal && !isFetching && searchValue && noData) {
      return (
        <CaptionContainer minimized={minimized}>
          <Caption>{t('pages:search.pre_search_no_results')}</Caption>
        </CaptionContainer>
      );
    }

    // Presearch result
    if (!isSendingMaiaAudioSignal && !isExternal && searchValue && !noData) {
      return (
        <PresearchContainer minimized={minimized}>{this.renderPresearch()}</PresearchContainer>
      );
    }

    return null;
  };

  showCloseButton = () => {
    const { config, fullSizeCoverModule } = this.props;

    const minimized = fullSizeCoverModule?.isVisible || !_get(config, 'customisations.preSearch.fullpage', false);

    return !minimized;
  };

  render() {
    const {
      config,
      closePreSearchModal,
      fullSizeCoverModule,
      isSendingMaiaAudioSignal,
      isSendingMaiaTextSignal,
      opened,
      resetSearch,
    } = this.props;

    const minimized = fullSizeCoverModule?.isVisible || !_get(config, 'customisations.preSearch.fullpage', false);

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.presearch,
        })}
      >
        <Modal
          fullSizeCoverModule={fullSizeCoverModule}
          minimized={minimized}
          onClose={() => {
            closePreSearchModal();

            if (isSendingMaiaAudioSignal || isSendingMaiaTextSignal) {
              resetSearch();
            }
          }}
          showCloseButton={this.showCloseButton()}
          opened={opened}
        >
          {this.renderModalContent()}
        </Modal>
      </WithCustomTheme>
    );
  }
}

export default compose(withTranslation('pages'), withRouter)(ModalPreSearch);
