// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Icons, WithCustomTheme } from '@mewo/components';

import Button from '../../presentationals/button';
import Cover from '../../containers/cover';
import Link from '../../other/link';
import Modal from '../../presentationals/modal';
import ScrollBar from '../../presentationals/scrollbar';

import { headerHeight } from '../../presentationals/header/desktop/variables';
import { headerHeight as headerHeightMobile } from '../../presentationals/header/mobile/variables';

// =============================
// Styles
// =============================

export const DefaultModal = props => (
  <WithCustomTheme
    customTheme={theme => ({
      colors: theme.colors.modal,
    })}
  >
    <Modal {...props} />
  </WithCustomTheme>
);

export const AuthModal = props => (
  <WithCustomTheme
    customTheme={theme => ({
      colors: theme.colors.pages.auth,
    })}
  >
    <Modal {...props} />
  </WithCustomTheme>
);

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    height: auto;
    padding: ${headerHeightMobile} 1.2rem 0;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    align-items: flex-start;
    display: flex;
    padding: ${headerHeight} 10rem 15rem;

    ${theme.mediaAbove.sxga`
      max-width: 110rem;
    `};

    ${theme.mediaAbove.wxgaplus`
      max-width: 130rem;
    `};

    /* We set it on wuxga because if we set it before, content can go behind Menu SVG gradient. */
    ${theme.mediaAbove.wuxga`
      max-width: 140rem;
    `};
  `};
`;

export const ModalCover = styled(Cover).attrs({
  noBoxShadow: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  rounded: false,
  size: '12rem',
})`
  ${({ theme }) => theme.mediaBelow.minWidth`
    margin: 0 auto;
    width: 16rem;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-right: 3rem;
    width: 30rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    width: 40rem;
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    width: 50rem;
  `};
`;

export const TitleWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4rem;
    padding: 0 2rem;
    text-align: center;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-bottom: 5rem;
    padding: 0;
  `};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2rem;
  overflow-wrap: break-word;

  ${({ centered }) => centered
    && css`
      text-align: center;
    `};

  ${({ withAction }) => withAction
    && css`
      margin-bottom: 2rem;
    `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 3rem;
    font-weight: ${theme.fontWeight.extrabold};
    line-height: 3.8rem;
  `};
`;

export const SubTitle = styled.div`
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.8rem;
  opacity: 0.5;

  ${({ centered }) => centered
    && css`
      text-align: center;
    `};
`;

export const Informations = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    display: flex;
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    align-items: center;
    display: flex;
    margin-right: 7rem;
    width: 30rem;
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
    width: 40rem;
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    width: 50rem;
  `};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.4rem;
  line-height: 1.6;

  ${({ theme }) => theme.mediaBelow.minWidth`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    height: 100vh;
    transform: translate3d(0, -15rem, 0);
    width: calc(100% - 12rem - 30rem);
  `};

  ${({ theme }) => theme.mediaAbove.sxga`
      width: calc(100% - 12rem - 40rem);
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
      width: calc(100% - 12rem - 50rem);
  `};
`;

export const StyledScrollBar = styled(ScrollBar).attrs({
  noScrollBar: true,
})`
  height: 100%;
  line-height: 1.6;

  && .scrollarea-content {
    padding-top: 14rem;
    padding-bottom: 7rem;
  }
`;

export const ActionButton = styled(Button).attrs({
  size: 'small',
})``;

export const DownloadLink = styled.a`
  text-decoration: none;
`;

export const TextWithLineBreaks = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  white-space: pre-line;

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin: 0 auto 1.2rem;
    text-align: center;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.4rem;
    line-height: 2.2rem;
  `};
`;

export const MobileActionList = styled.div`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2rem;
  padding: 0 2rem;
  width: 100%;
`;

export const MobileAction = styled.div.attrs(({ isLink }) => ({
  as: isLink ? Link : 'div',
}))`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  transition: opacity ${({ theme }) => theme.transition.fast};
  user-select: none;

  ${({ disabled }) => (disabled
    ? css`
          opacity: 0.7;
        `
    : css`
          &:hover {
            opacity: 0.7;
          }
        `)};

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`;

export const MobileActionIcon = styled.div.attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))`
  margin-right: 1.6rem;
`;

export const AddToFavoritesIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Heart,
}))``;

export const AddToPlaylistIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Plus,
}))``;

export const AlbumIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Album,
  width: '1.8rem',
}))`
  margin-right: 1.8rem;
`;

export const ArtistsIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Artist,
  width: '1.8rem',
}))`
  margin-right: 1.8rem;
`;

export const DuplicateIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Duplicate,
  width: '1.8rem',
}))`
  margin-right: 1.8rem;
`;

export const CopyrightIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Contributors,
}))``;

export const DeleteIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.TrashCan,
}))``;

export const DownloadIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Download,
}))``;

export const LyricsIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Microphone,
  width: '1.5rem',
}))`
  margin-left: 0.2rem;
  margin-right: 1.7rem;
`;

export const SimilarIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.MagicWand,
  width: '1.8rem',
}))`
  margin-right: 1.8rem;
`;

export const TagsIcon = styled(MobileActionIcon).attrs(() => ({
  as: Icons.Tag,
}))``;

export const VersionIcon = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.background};
  display: flex;
  font-size: 0.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: 2rem;
  justify-content: center;
  margin-right: 1.6rem;
  min-width: 2rem;
  padding: 0.2rem;
`;
