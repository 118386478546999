// =============================
// Imports
// =============================

// External dependencies
import { isCancel } from 'axios';
import _mapKeys from 'lodash/mapKeys';
import _get from 'lodash/get';

// Constants
import * as acts from '../../constants/ActionTypes';
import * as rqs from '../../constants/RequestTypes';

// Helpers
import {
  getApiUrl,
  camelCaseKeysDeep,
  getXPreferredLanguage,
} from '../../../helpers/misc';
import determineError from '../../../helpers/errors';
import { cancelableRequest } from '../../helpers/axios';
import { transformSearchToAPISearch } from '../../../helpers/search';
import { i18n } from '../../../config/i18n';

// =============================
// Helpers
// =============================

// NOTE: cache for preserving reference, thus keeping store pure
const excludeDefault = [];

const getExcludeForSearch = ({ reload, getState }) => {
  if (reload) return excludeDefault;
  return _get(getState().search, 'tracksSearch.data.results', []).map(v => v.refId);
};

const getTracksRequest = async ({ getState, data, exclude }) => {
  const { tags } = getState().options.data;

  const reqData = {
    ...data,
    options: {
      ...data.options,
      searchId: _get(data, 'options.searchId[0]'),
      trackId: _get(data, 'options.trackId[0]'),
      search_descriptions: getState().search.searchInDescription,
      filters: {
        ...transformSearchToAPISearch(data, { tags }),
        exclude,
      },
    },
  };

  const response = await cancelableRequest(rqs.GET_TRACKS_SEARCH_DATA, {
    method: 'post',
    url: getApiUrl('public/search'),
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'x-host': getState().core.serverContext.xHost,
      'x-auth': getState().user.token,
      'x-preferred-language': getXPreferredLanguage(),
    },
    data: reqData,
  });

  return camelCaseKeysDeep(response.data);
};

// NOTE: We do not save exclude in the query as
// we need to compare next search with user query
const buildPayload = ({ getState, data, responseData, exclude }) => {
  const statePrevMaiaSearch = _get(getState(), 'search.tracksSearch.prevMaiaSearch');
  const stateSearchId = _get(getState(), 'search.tracksSearch.query.options.searchId', []);
  const searchId = _get(data, 'options.searchId', []);
  const isDoingMaiaSearch = searchId.length > 0
    && responseData.total.value === 0
    && statePrevMaiaSearch !== searchId[0];

  const prevMaiaSearch = (
    (stateSearchId.length
      && searchId.length
      && stateSearchId[0] === searchId[0]
    ) || (!stateSearchId.length && searchId.length)
  ) && responseData.total.value
    ? searchId[0]
    : statePrevMaiaSearch;

  return {
    data: {
      total: responseData.total,
      facets: {
        ...responseData.facets,
        versions: _mapKeys(responseData.facets.versions, (_v, k) => k.toLowerCase()),
        tags: _mapKeys(responseData.facets.tags, (_v, k) => k.toLowerCase()),
      },
      results: responseData.hits,
    },
    query: data,
    exclude,
    isDoingMaiaSearch,
    prevMaiaSearch,
  };
};

// =============================
// Tracks Search Action
// =============================

// NOTE: If reload is false it means that the user is paginating
export default function getTracksSearchData(data, { reload = false } = {}) {
  return async (dispatch, getState) => {
    // NOTE: Need to set isDoingMaiaSearch upfront, otherwise infinite scroll
    // sometimes retriggers search. Also seems right.
    const statePrevMaiaSearch = _get(getState(), 'search.tracksSearch.prevMaiaSearch');
    const searchId = _get(data, 'options.searchId', []);

    const isDoingMaiaSearch = reload && searchId.length > 0 && statePrevMaiaSearch !== searchId[0];

    dispatch({
      type: acts.GET_TRACKS_SEARCH_DATA_LOADING,
      payload: { isDoingMaiaSearch },
    });

    const exclude = getExcludeForSearch({ reload, getState });
    try {
      const responseData = await getTracksRequest({ getState, data, exclude });
      const payload = buildPayload({ getState, data, responseData, exclude });

      dispatch({
        type: acts.SET_TRACKS_SEARCH_DATA,
        payload,
      });

      dispatch({
        type: acts.GET_TRACKS_SEARCH_DATA_SUCCESS,
      });
    } catch (err) {
      if (!isCancel(err)) {
        let errorMsg;

        switch (true) {
          case err.response && err.response.status === 415:
            errorMsg = i18n.t('errors:search.maia_search_error');
            break;

          case err.response && err.response.data.key === 'inactive_subscription':
            errorMsg = i18n.t('errors:search.maia_inactive_subscription');
            break;

          case err.response
            && err.response.status === 400
            && err.response.data.message.includes('must be less than or equal to 500 characters long'):
            errorMsg = i18n.t('errors:search.query_too_long', { max: 500 });
            break;

          default:
            errorMsg = determineError(err);
            break;
        }

        dispatch({
          type: acts.GET_TRACKS_SEARCH_DATA_FAILURE,
          payload: {
            message: errorMsg,
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }
    }
  };
}
