// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../../other/responsive';

import TrackLyricsDesktop from './desktop';
import TrackLyricsMobile from './mobile';

// =============================
// Component
// =============================

class TrackLyrics extends PureComponent {
  static displayName = 'TrackLyricsResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={TrackLyricsDesktop}
        MobileComponent={TrackLyricsMobile}
        props={this.props}
      />
    );
  }
}

export default TrackLyrics;
