// =============================
// Imports
// =============================

import _filter from 'lodash/filter';
import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  searchInDescription: false,
  tracksSearch: {
    isFetching: false,
    data: {
      total: {},
      results: [],
      facets: {},
      initialFacets: {},
    },
    query: {},
    audioUploadProgress: 0,
    isSendingMaiaTextSignal: false,
    isSendingMaiaAudioSignal: false,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: false,
    prevMaiaSearch: null,
  },
  preSearch: {
    isFetching: false,
    isFetchedOnce: false,
    searchValue: '',
    data: {
      albums: {
        data: [],
        total: {},
      },
      catalogs: {
        data: [],
        total: {},
      },
      artists: {
        data: [],
        total: {},
      },
      playlists: {
        data: [],
        total: {},
      },
      labels: {
        data: [],
        total: {},
      },
      publishers: {
        data: [],
        total: {},
      },
      tracks: {
        data: [],
        total: {},
      },
      tags: {
        data: [],
        total: {},
      },
      tagCategories: {
        data: [],
        total: {},
      },
    },
  },
  fullSizeCoverModule: [],
  entitySearch: {
    isFetching: false,
    isFetchedOnce: false,
    data: [],
    total: {},
    page: 0,
    nbPages: 0,
    type: null,
  },
};

// =============================
// Reducer
// =============================

export default function options(state = initialState, action) {
  switch (action.type) {
    case acts.SEARCH_SET_DESCRIPTION_INCLUSION:
      return {
        ...state,
        searchInDescription: action.payload,
      };

    case acts.SET_INITIAL_FACETS:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          data: {
            ...state.tracksSearch.data,
            facets: action.payload.facets,
            initialFacets: action.payload.facets,
          },
        },
      };

    case acts.GET_TRACKS_SEARCH_DATA_LOADING:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isFetching: true,
          isDoingMaiaSearch: action.payload.isDoingMaiaSearch,
        },
      };

    case acts.SET_TRACKS_SEARCH_DATA: {
      // NOTE: As long as no tracks are excluded from search, we are on the first "page"
      if (action.payload.exclude.length === 0) {
        return {
          ...state,
          tracksSearch: {
            ...state.tracksSearch,
            data: {
              ...action.payload.data,
              initialFacets: state.tracksSearch.data.initialFacets,
            },
            query: action.payload.query,
            isDoingMaiaSearch: action.payload.isDoingMaiaSearch,
            prevMaiaSearch: action.payload.prevMaiaSearch,
          },
        };
      }

      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          data: {
            // NOTE: Do not update total and facets here as tracks were excluded
            ...state.tracksSearch.data,
            results: [...state.tracksSearch.data.results, ...action.payload.data.results],
          },
          query: action.payload.query,
          isDoingMaiaSearch: action.payload.isDoingMaiaSearch,
          prevMaiaSearch: action.payload.prevMaiaSearch,
        },
      };
    }

    case acts.GET_TRACKS_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isFetching: false,
        },
      };

    case acts.GET_TRACKS_SEARCH_DATA_FAILURE:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isFetching: false,
          isDoingMaiaSearch: false,
        },
      };

    case acts.REQUEST_MAIA_TEXT_LOADING:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isSendingMaiaTextSignal: true,
        },
      };

    case acts.REQUEST_MAIA_AUDIO_LOADING:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isSendingMaiaAudioSignal: true,
        },
      };

    case acts.SET_MAIA_AUDIO_UPLOAD_PROGRESS:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          audioUploadProgress: action.payload,
        },
      };

    case acts.SET_MAIA_REQUEST:
      return {
        ...state,
        tracksSearch: {
          ...initialState.tracksSearch,
          data: {
            ...initialState.tracksSearch.data,
            total: {},
            results: [],
            facets: state.tracksSearch.data.initialFacets,
            initialFacets: state.tracksSearch.data.initialFacets,
          },
          audioUploadProgress: 0,
        },
      };

    case acts.REQUEST_MAIA_TEXT_SUCCESS:
    case acts.REQUEST_MAIA_TEXT_FAILURE:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isSendingMaiaTextSignal: false,
        },
      };

    case acts.REQUEST_MAIA_AUDIO_SUCCESS:
    case acts.REQUEST_MAIA_AUDIO_FAILURE:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          isSendingMaiaAudioSignal: false,
        },
      };

    case acts.UPDATE_TRACKS_SEARCH_FAVORITES:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          data: {
            ...state.tracksSearch.data,
            results: state.tracksSearch.data.results.map((track) => {
              if (track.id !== action.payload.id) return track;
              return { ...track, isFavorite: action.payload.isFavorite };
            }),
          },
        },
      };

    case acts.UPDATE_TRACKS_SEARCH_RECENTS:
      return {
        ...state,
        tracksSearch: {
          ...state.tracksSearch,
          data: {
            ...state.tracksSearch.data,
            results: state.tracksSearch.data.results.map((track) => {
              if (track.id !== action.payload.id) return track;
              return { ...track, recentlyPlayed: true };
            }),
          },
        },
      };

    case acts.RESET_TRACKS_SEARCH_DATA:
      return {
        ...state,
        tracksSearch: {
          ...initialState.tracksSearch,
          data: {
            ...initialState.tracksSearch.data,
            total: {},
            results: [],
            facets: state.tracksSearch.data.initialFacets,
            initialFacets: state.tracksSearch.data.initialFacets,
          },
        },
      };

    case acts.GET_PRE_SEARCH_DATA_LOADING:
      return {
        ...state,
        preSearch: {
          ...state.preSearch,
          isFetching: true,
        },
      };

    case acts.SET_SEARCH_VALUE:
      return {
        ...state,
        preSearch: {
          ...state.preSearch,
          searchValue: action.payload,
        },
      };

    case acts.SET_PRE_SEARCH_DATA:
      return {
        ...state,
        preSearch: {
          ...state.preSearch,
          data: action.payload,
        },
      };

    case acts.GET_PRE_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        preSearch: {
          ...state.preSearch,
          isFetching: false,
          isFetchedOnce: true,
        },
      };
    case acts.GET_PRE_SEARCH_DATA_FAILURE:
      return {
        ...state,
        preSearch: {
          ...state.preSearch,
          isFetching: false,
          isFetchedOnce: false,
        },
      };

    case acts.RESET_PRE_SEARCH_DATA:
      return {
        ...state,
        preSearch: initialState.preSearch,
        tracksSearch: {
          ...state.tracksSearch,
          searchValue: '',
          audioUploadProgress: 0,
          isSendingMaiaTextSignal: false,
          isSendingMaiaAudioSignal: false,
          isDoingMaiaSearch: false,
        },
      };

    case acts.GET_ENTITY_SEARCH_DATA_LOADING:
      return {
        ...state,
        entitySearch: {
          ...state.entitySearch,
          isFetching: true,
        },
      };

    case acts.SET_FULL_SIZE_COVER_MODULE_DATA:
      return {
        ...state,
        fullSizeCoverModule: [
          ..._filter(state.fullSizeCoverModule, item => item.id !== action.payload.id),
          action.payload,
        ],
      };

    case acts.CLEAR_FULL_SIZE_COVER_MODULE_DATA:
      return {
        ...state,
        fullSizeCoverModule: [
          ..._filter(state.fullSizeCoverModule, item => item.id !== action.payload),
        ],
      };

    case acts.SET_ENTITY_SEARCH_DATA:
      if (action.payload.page === 0) {
        return {
          ...state,
          entitySearch: {
            ...state.entitySearch,
            ...action.payload,
            data: action.payload.data,
          },
        };
      }

      return {
        ...state,
        entitySearch: {
          ...state.entitySearch,
          ...action.payload,
          data: action.payload.data,
        },
      };

    case acts.GET_ENTITY_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        entitySearch: {
          ...state.entitySearch,
          isFetching: false,
          isFetchedOnce: true,
        },
      };

    case acts.GET_ENTITY_SEARCH_DATA_FAILURE:
      return {
        ...state,
        entitySearch: {
          ...state.entitySearch,
          isFetching: false,
          isFetchedOnce: false,
        },
      };

    case acts.RESET_ENTITY_SEARCH_DATA:
      return {
        ...state,
        entitySearch: initialState.entitySearch,
      };

    default:
      return state;
  }
}
