/* eslint-disable react/no-did-update-set-state */
// =============================
// Imports
// =============================

import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import { ClickAwayListener } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import getViewProps from '../../../../helpers/front/getviewprops';

import { Wrapper, Container, SearchMusicIcon, SearchInput, StyledInput, CancelBtn, CloseBtn } from './styles';

// =============================
// Component
// =============================

class SearchBar extends PureComponent {
  static displayName = 'SearchBarMobile';

  static propTypes = {
    className: PropTypes.string,
    /** If True, PreSearch modal is opened. */
    isPreSearchOpen: PropTypes.bool.isRequired,
    /** Cancel search */
    onCancel: PropTypes.func.isRequired,
    /** Function called when user changes input */
    onChange: PropTypes.func.isRequired,
    /** Function called when user click on the SearchBar. */
    onClick: PropTypes.func,
    /** Close presearch modal */
    onClose: PropTypes.func.isRequired,
    /** Focus event */
    onFocus: PropTypes.func.isRequired,
    /** Function called when user presses enter */
    onSubmit: PropTypes.func.isRequired,
    /** Translation strings */
    t: PropTypes.func.isRequired,
    /** value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    className: '',
    onClick: null,
    value: '',
  };

  constructor(props) {
    super(props);

    this.inputRef = createRef();

    this.state = {
      isFocus: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isPreSearchOpen } = this.props;

    // When user open the search, we focus the searchInput.
    if (prevProps.isPreSearchOpen === false && isPreSearchOpen) {
      this.inputRef.current.focus();
    }
  }

  /** Handle input change. */
  handleChange = (event) => {
    const { onChange } = this.props;

    onChange(event.target.value);
  };

  inputHandleKeyDown = (e) => {
    const { onSubmit } = this.props;
    const { isFocus } = this.state;

    // If enter button
    if (e.keyCode === 13) {
      onSubmit();

      if (isFocus) {
        this.inputRef.current.blur();
      }
    }
  };

  handleWrapperClick = () => {
    const { onClick } = this.props;

    if (onClick) onClick();

    this.inputRef.current.focus({ preventScroll: true });
  };

  handleClickAway = () => {
    const { isFocus } = this.state;

    // Unfocus if focused
    if (isFocus) {
      this.setState({ isFocus: false });
    }
  };

  render() {
    const { className, isPreSearchOpen, onCancel, onClose, t, value, ...rest } = this.props;

    if (!isPreSearchOpen) return null;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.searchBar,
        })}
      >
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Wrapper className={className}>
            <Container onClick={this.handleWrapperClick} {...getViewProps(rest)}>
              <SearchInput>
                <SearchMusicIcon />
                <StyledInput
                  innerRef={this.inputRef}
                  onChange={this.handleChange}
                  onKeyDown={this.inputHandleKeyDown}
                  placeholder={t('components:header.searchbar')}
                  value={value}
                />
                {value && <CancelBtn onClick={onCancel} />}
              </SearchInput>
            </Container>
            <CloseBtn onClick={onClose}>
              {t('components:modal.close')}
            </CloseBtn>
          </Wrapper>
        </ClickAwayListener>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('components')(SearchBar);
