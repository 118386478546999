// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _get from 'lodash/get';

import ModalTrackVersions from '../../../layouts/modals/trackversions';

import {
  requestMusicPlayerOpen,
  setTrackVersionsOpen,
  resetModals,
} from '../../../../store/actions/ModalsActions';
import { getVersions } from '../../../../store/actions/TrackActions';

// =============================
// Component
// =============================

// Cache default value, so it reference won't differ
const defaultVersions = [];

function mapStateToProps({ config, modals, track }) {
  const trackId = _get(modals, 'trackVersions.data.id');

  if (trackId) {
    return {
      config: config.data,
      isFetchedVersions: _get(track, `versions[${trackId}].isFetchedOnce`, false),
      isFetchingVersions: _get(track, `versions[${trackId}].isFetching`, false),
      opened: modals.trackVersions.isOpen,
      track: modals.trackVersions.data,
      versions: _get(track, `versions[${trackId}].data`, defaultVersions),
    };
  }

  return {
    config: config.data,
    isFetchedVersions: false,
    isFetchingVersions: false,
    opened: modals.trackVersions.isOpen,
    track: modals.trackVersions.data,
    versions: [],
  };
}

export default connect(mapStateToProps, {
  requestMusicPlayerModalOpen: () => requestMusicPlayerOpen(true),
  closeAllModals: resetModals,
  closeTrackVersionsModal: () => setTrackVersionsOpen(false),
  getVersions,
})(ModalTrackVersions);
