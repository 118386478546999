// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import Cookies from 'js-cookie';
import _get from 'lodash/get';
import _pick from 'lodash/pick';

// Actions
import { getConfig, getConfigLegal, getConfigMenus } from './ConfigActions';
import { getInitialFacets } from './SearchActions';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import {
  camelCaseKeysDeep,
  getApiUrl,
  getCookieConfig,
  getXPreferredLanguage,
  isDev,
  isPreprod,
} from '../../helpers/misc';
import determineError, { MewoError } from '../../helpers/errors';

// =============================
// Actions
// =============================

export function setServerContext(req) {
  return (dispatch) => {
    dispatch({
      type: acts.SET_SERVER_CONTEXT,
      payload: {
        locationProtocol: `${req.protocol}:`,
        xHost: ((isDev() || isPreprod()) && !!process.env.XHOST)
          ? process.env.XHOST
          : req.hostname,
      },
    });
  };
}

export function setPrivacyCookie(name, value = false, setCookie = true) {
  return (dispatch, getState) => {
    const { serverContext } = getState().core;

    if (setCookie) {
      if (value) {
        Cookies.set(
          `${name}CookiesAccepted`,
          true,
          // 1 year
          getCookieConfig(365, serverContext.locationProtocol),
        );
      } else {
        Cookies.remove(
          `${name}CookiesAccepted`,
          getCookieConfig(null, serverContext.locationProtocol),
        );
      }
    }

    dispatch({
      type: acts.SET_PRIVACY_COOKIE,
      payload: { name, value },
    });
  };
}

export const optionsKeys = [
  'languages',
  'territories',
  'albumtypes',
  'tonalitykeys',
  'trackversions',
  'tempos',
  'rightstypes',
  'tags',
  'mechanicalrightssocieties',
  'neighboringrightssocieties',
  'performingrightssocieties',
  'userindustries',
];

export function getOptions(dispatch, getState) {
  dispatch({
    type: acts.GET_OPTIONS_LOADING,
  });

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'x-preferred-language': getXPreferredLanguage(),
      'x-host': getState().core.serverContext.xHost,
    },
  };

  const optionsUrl = getApiUrl('public/options');

  return Promise.all(optionsKeys.map(key => axios.get(`${optionsUrl}/${key}`, config)))
    .then((res) => {
      const data = {};

      res.forEach((v, i) => {
        let optionsData;

        if (optionsKeys[i] === 'tags') {
          optionsData = camelCaseKeysDeep(v.data);
        } else {
          optionsData = v.data;
        }

        data[optionsKeys[i]] = optionsData;

        // NOTE: for tags, we create tags_only & tags_sc & tags_colors_cat_names for further use
        if (optionsKeys[i] === 'tags') {
          data.tags_sc = optionsData.reduce(
            (acc, e) => [
              ...acc,
              ...e.subCategories,
            ],
            [],
          );

          const tagsFromCat = optionsData.reduce(
            (acc, e) => [
              ...acc,
              ...e.tags,
            ],
            [],
          );

          const tagsFromSc = data.tags_sc.reduce(
            (acc, e) => [
              ...acc,
              ...e.tags,
            ],
            [],
          );

          data.tags_only = [...tagsFromCat, ...tagsFromSc];

          data.tags_colors_cat_names = [
            ...data.tags_sc.map(t => _pick(t, ['id', 'color', 'names', 'categoryNames'])),
            ...data.tags_only.map(t => _pick(t, ['id', 'color', 'names', 'categoryNames'])),
          ];
        }
      });

      dispatch({
        type: acts.SET_OPTIONS,
        payload: data,
      });

      dispatch({
        type: acts.GET_OPTIONS_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: acts.GET_OPTIONS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      });

      throw new MewoError({ error: err });
    });
}

export function initializeApp() {
  return async (dispatch, getState) => {
    // Get config first, if this doesn't pass, the rest will not
    await getConfig()(dispatch, getState);

    await Promise.all([
      getOptions(dispatch, getState),
      getConfigLegal()(dispatch, getState),
      getConfigMenus()(dispatch, getState),
      getInitialFacets()(dispatch, getState),
    ]);

    // NOTE: when the app is not loaded, the state of core would not be set as ready
    dispatch({
      type: acts.INITIALIZE_APP,
    });
  };
}
