// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// =============================
// Actions
// =============================

export function setTrackLyricsOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_TRACK_LYRICS_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setUserPlaylistOpen(value) {
  return {
    type: acts.MODALS_SET_USER_PLAYLIST_OPEN,
    payload: value,
  };
}

export function setAlbumOpen(value) {
  return {
    type: acts.MODALS_SET_ALBUM_OPEN,
    payload: value,
  };
}

export function setArtistOpen(value) {
  return {
    type: acts.MODALS_SET_ARTIST_OPEN,
    payload: value,
  };
}

export function setCatalogOpen(value) {
  return {
    type: acts.MODALS_SET_CATALOG_OPEN,
    payload: value,
  };
}

export function setPlaylistOpen(value) {
  return {
    type: acts.MODALS_SET_PLAYLIST_OPEN,
    payload: value,
  };
}

export function setPitchOpen(value) {
  return {
    type: acts.MODALS_SET_PITCH_OPEN,
    payload: value,
  };
}

export function setMenuOpen(value) {
  return {
    type: acts.MODALS_SET_MENU_OPEN,
    payload: value,
  };
}

export function setAccountOpen(value) {
  return {
    type: acts.MODALS_SET_ACCOUNT_OPEN,
    payload: value,
  };
}

export function setLegalOpen(value) {
  return {
    type: acts.MODALS_SET_LEGAL_OPEN,
    payload: value,
  };
}

export function setCookiesOpen(value) {
  return {
    type: acts.MODALS_SET_COOKIES_OPEN,
    payload: value,
  };
}

export function setPreSearchOpen(value) {
  return {
    type: acts.MODALS_SET_PRE_SEARCH_OPEN,
    payload: value,
  };
}

export function setVideoOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_VIDEO_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setUserMenuOpen(value) {
  return {
    type: acts.MODALS_SET_USER_MENU_OPEN,
    payload: value,
  };
}

export function setMusicItemOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_MUSIC_ITEM_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function requestMusicPlayerOpen(value) {
  return {
    type: acts.MODALS_REQUEST_MUSIC_PLAYER_OPEN,
    payload: value,
  };
}

export function setMusicPlayerOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_MUSIC_PLAYER_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function updateModalsFavorites(id, isFavorite) {
  return {
    type: acts.UPDATE_MODALS_FAVORITES,
    payload: { id, isFavorite },
  };
}

export function updateModalsRecents(id) {
  return {
    type: acts.UPDATE_MODALS_RECENTS,
    payload: { id },
  };
}

export function setAddToPlaylistOpen(value, data = null, type = null) {
  return {
    type: acts.MODALS_SET_ADD_TO_PLAYLIST_OPEN,
    payload: {
      isOpen: value,
      data,
      type,
    },
  };
}

export function setTrackArtistsOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_TRACK_ARTISTS_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setTrackOwnershipsOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_TRACK_OWNERSHIPS_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setTrackTagsOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_TRACK_TAGS_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setTrackVersionsOpen(value, data = null) {
  return {
    type: acts.MODALS_SET_TRACK_VERSIONS_OPEN,
    payload: {
      isOpen: value,
      data,
    },
  };
}

export function setEntityActionsOpen(value, data = null, type = null) {
  return {
    type: acts.MODALS_SET_ENTITY_ACTIONS_OPEN,
    payload: {
      isOpen: value,
      data,
      type,
    },
  };
}

export function resetModals() {
  return {
    type: acts.RESET_ALL_MODALS,
  };
}
