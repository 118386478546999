// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';

import { withTranslation } from '../../../../config/i18n';

import * as playerCtx from '../../../../store/constants/PlayerContexts';

import * as pth from '../../../../helpers/proptypes';

import {
  CloseModalBtn,
  Content,
  ContentTitle,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../mobile.styles';
import { DefaultModal } from '../styles';
import { StyledMusicItem } from './styles';

// =============================
// Layout
// =============================

class ModalTrackVersion extends PureComponent {
  static propTypes = {
    /** Function used to close all modals. */
    closeAllModals: PropTypes.func.isRequired,
    /** Function used to close track versions modal. */
    closeTrackVersionsModal: PropTypes.func.isRequired,
    /** Function used to get track versions. */
    getVersions: PropTypes.func.isRequired,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, versions are fetched */
    isFetchedVersions: PropTypes.bool.isRequired,
    /** If True, versions are fetching */
    isFetchingVersions: PropTypes.bool.isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Function used to request music player modal to open after play. */
    requestMusicPlayerModalOpen: PropTypes.func.isRequired,
    /** Translations. */
    t: PropTypes.func.isRequired,
    /** Track. */
    track: pth.track,
    /** Versions */
    versions: PropTypes.arrayOf(pth.track).isRequired,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  componentDidUpdate(prevProps) {
    const { getVersions, isFetchedVersions, isFetchingVersions, opened, track } = this.props;

    const canBeFetched = !isFetchedVersions && !isFetchingVersions && track && track.versions > 1;

    if (canBeFetched && prevProps.opened !== opened) {
      getVersions(track.id);
    }
  }

  renderVersions = () => {
    const {
      closeAllModals,
      isFetchingVersions,
      requestMusicPlayerModalOpen,
      t,
      track,
      versions,
    } = this.props;

    if (isFetchingVersions) return t('components:modal_track_versions.loading_versions');

    if (track.versions <= 1) return null;

    return versions.map(version => (
      <StyledMusicItem
        key={`${track.id}-${version.id}`}
        music={version}
        contextName={playerCtx.SEARCH}
        onClickPassthrough={() => {
          closeAllModals();
          requestMusicPlayerModalOpen();
        }}
      />
    ));
  };

  render() {
    const { closeTrackVersionsModal, opened, t, track } = this.props;

    if (!track) return null;

    return (
      <DefaultModal
        name="trackVersions"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeTrackVersionsModal}
        opened={opened}
      >
        <ClickAwayListener onClickAway={closeTrackVersionsModal}>
          <Wrapper>
            <Header>
              <CloseModalBtn onClick={closeTrackVersionsModal}>
                {t('components:modal.close')}
              </CloseModalBtn>
              <Title>{t('components:modal_track_versions.track_versions')}</Title>
              <EmptySpacePlaceholder />
            </Header>
            <Content>
              <ContentTitle>
                {track.versions - 1}
                &nbsp;
                {t('components:modal_track_versions.versions', { count: track.versions - 1 })}
              </ContentTitle>
              {this.renderVersions()}
            </Content>
          </Wrapper>
        </ClickAwayListener>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalTrackVersion);
