// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import UserMenu from '../../../presentationals/usermenu';

import { DefaultModal } from '../styles';

// =============================
// Layout
// =============================

class ModalUserMenu extends PureComponent {
  static propTypes = {
    /** Function used to close user menu. */
    closeUserMenu: PropTypes.func.isRequired,
    /** Language. */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Function used to log user out. */
    logout: PropTypes.func.isRequired,
    /** Function used to open account modal. */
    openAccountModal: PropTypes.func.isRequired,
    /** If True, Modal is open. */
    opened: PropTypes.bool,
    /** Translations. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      closeUserMenu,
      i18n: { language },
      logout,
      openAccountModal,
      opened,
      ...rest
    } = this.props;

    return (
      <DefaultModal name="userMenu" noBottomFade onClose={closeUserMenu} opened={opened}>
        <UserMenu
          closeUserMenu={closeUserMenu}
          locale={language}
          logout={logout}
          openAccountModal={openAccountModal}
          {...rest}
        />
      </DefaultModal>
    );
  }
}

export default withTranslation('pages')(ModalUserMenu);
