// =============================
// Imports
// =============================

import Cookies from 'js-cookie';
import { Component } from 'react';
import PropTypes from 'prop-types';

import { i18n, withTranslation } from '../../../../config/i18n';
import locales from '../../../../config/locales';

import * as pth from '../../../../helpers/proptypes';
import { getCookieConfig } from '../../../../helpers/misc';

import { StyledSelectInput } from './styles';

// =============================
// Component
// =============================

class UserLanguages extends Component {
  static displayName = 'UserLanguagesMobile';

  static propTypes = {
    className: PropTypes.string,
    /** App config. */
    config: pth.config.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** User is logged */
    isLogged: PropTypes.bool.isRequired,
    /** Location protocol, like https. */
    locationProtocol: PropTypes.string.isRequired,
    /** Modify user language */
    modifyUserLanguage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  setUserLocale = async (e) => {
    const { isLogged, locationProtocol, modifyUserLanguage } = this.props;
    const locale = e.target.value;

    if (isLogged) {
      await modifyUserLanguage(locale);
    }

    Cookies.set(
      'next-i18next',
      locale,
      getCookieConfig(3, locationProtocol),
    );

    i18n.changeLanguage(locale);
  };

  render = () => {
    const { className, config } = this.props;

    if (config.languages.length < 2) return null;

    const languagesOptions = locales.languagesList
      .map(key => ({
        label: locales.languagesNames[key].nativeName,
        value: key,
      }))
      .filter(({ value }) => config.languages.includes(value));

    return (
      <StyledSelectInput
        className={className}
        name="userLanguages"
        onChange={this.setUserLocale}
        options={languagesOptions}
        value={i18n.language}
      />
    );
  };
}

export default withTranslation()(UserLanguages);
