// =============================
// Imports
// =============================

import { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { WrapIfDesktop } from '../../other/responsive';
import PageWrapper from '../../other/pagewrapper';

import { setPrivacyCookie } from '../../../store/actions/CoreActions';

import {
  ModalContainer,
  Content,
  TitleButtonWrapper,
  Title,
  TitleButton,
  StyledScrollBar,
} from '../common.text.styles';

// =============================
// Stories
// =============================

class Cookies extends Component {
  static propTypes = {
    /** If True, will only render content. To be injected in a modal. */
    modalInjection: PropTypes.bool,
    /** Function to open cookie consent */
    openPrivacyPreference: PropTypes.func.isRequired,
    /** Translation function */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modalInjection: false,
  };

  render() {
    const { modalInjection, openPrivacyPreference, t } = this.props;

    const body = (
      <Fragment>
        <Content>
          <h2>{t('pages:cookies.second')}</h2>
          <p>{t('pages:cookies.third')}</p>

          <h2>{t('pages:cookies.fourth')}</h2>
          <p>{t('pages:cookies.fifth')}</p>

          <h2>{t('pages:cookies.sixth')}</h2>
          <p>{t('pages:cookies.seventh')}</p>

          <h2>{t('pages:cookies.eighth')}</h2>
          <ul>
            <li>
              {t('pages:cookies.ninth')}
              <br />
              {t('pages:cookies.tenth')}
            </li>
            <li>
              {t('pages:cookies.eleventh')}
              <br />
              {t('pages:cookies.twelfth')}
            </li>
            <li>
              {t('pages:cookies.thirteenth')}
              <br />
              {t('pages:cookies.fourteenth')}
            </li>
          </ul>

          <h2>{t('pages:cookies.fifteenth')}</h2>
          <p>{t('pages:cookies.sixteenth')}</p>
          <ul>
            <li>
              {t('pages:cookies.seventeenth')}
              <br />
              {t('pages:cookies.eighteenth')}
            </li>
            <li>{t('pages:cookies.nineteenth')}</li>
            <li>{t('pages:cookies.twentieth')}</li>
          </ul>

          <h2>{t('pages:cookies.twentyoneth')}</h2>
          <p>{t('pages:cookies.twentytwoth')}</p>
          <p>{t('pages:cookies.twentythreeth')}</p>
        </Content>
      </Fragment>
    );

    if (modalInjection) {
      return (
        <ModalContainer>
          <TitleButtonWrapper>
            <Title>{t('pages:cookies.first')}</Title>
            <TitleButton onClick={openPrivacyPreference}>
              {t('pages:cookies.open_preferences')}
            </TitleButton>
          </TitleButtonWrapper>
          <WrapIfDesktop Wrapper={<StyledScrollBar />}>{body}</WrapIfDesktop>
        </ModalContainer>
      );
    }

    return (
      <PageWrapper type="text">
        <TitleButtonWrapper>
          <Title>{t('pages:cookies.first')}</Title>
          <TitleButton onClick={openPrivacyPreference}>
            {t('pages:cookies.open_preferences')}
          </TitleButton>
        </TitleButtonWrapper>
        {body}
      </PageWrapper>
    );
  }
}

export default connect(() => ({}), {
  openPrivacyPreference: () => setPrivacyCookie('mandatory', false, false),
})(Cookies);
