// =============================
// Imports
// =============================

import { Avatar, Icons } from '@mewo/components';
import styled from 'styled-components';
import { alpha } from '@material-ui/core';

import Button from '../../button';
import Logo from '../../../containers/logo';

import { headerHeight, headerPadding, headerInnerHeight } from './variables';

// =============================
// Component
// =============================

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => alpha(theme.colors.background, 0.9)};
  display: flex;
  height: ${headerHeight};
  justify-content: flex-start;
  left: 0;
  padding: ${headerPadding};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.scHeader};
`;

export const HeaderPart = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-start;
`;

export const Left = styled(HeaderPart)`
  margin-right: auto;
`;

export const Right = styled(HeaderPart)`
  margin-left: 1.2rem;

  @media (min-width: ${400 / 16}em) {
    margin-left: 3.2rem;
  }
`;

export const MenuButton = styled(Icons.MenuOutline).attrs(() => ({
  width: headerInnerHeight,
}))`
  margin-right: 0.8rem;
`;

export const SearchButton = styled(Icons.Search)`
  margin-right: 1.2rem;

  @media (min-width: ${400 / 16}em) {
    margin-right: 2.4rem;
  }
`;

export const StyledLogo = styled(Logo)`
  cursor: pointer;
  height: 100%;
  max-height: calc(${headerHeight} - 2 * ${headerPadding});
  max-width: 15rem;
  position: relative;
`;

export const StyledAvatar = styled(Avatar).attrs(() => ({
  size: parseFloat(headerHeight) - 2 * parseFloat(headerPadding),
}))``;

export const StyledButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
})``;
