// =============================
// Imports
// =============================

// Externals Dependencies
import Cookies from 'js-cookie';
import Router from 'next/router';

// Helpers
import { getCookieConfig } from './misc';

// =============================
// Helpers
// =============================

/**
 * Set last route cookie
 */
export function capture(locationProtocol) {
  const cookieConfig = getCookieConfig(3, locationProtocol);

  let finalRoute = Router.router.route;
  const asPathQuery = Router.router.asPath.split('?')[1];

  if (finalRoute.includes('login')) return;

  if (asPathQuery) finalRoute += `?${asPathQuery}`;

  Cookies.set('lastRoute', finalRoute, cookieConfig);
  Cookies.set('lastAsPath', Router.router.asPath, cookieConfig);
}

/**
 * Get last route cookie
 * @returns {Object} last route values
 */
export function get(fallback = '/') {
  const lastRouteCookie = Cookies.get('lastRoute');
  const lastRoute = lastRouteCookie || fallback;

  const lastAsPath = lastRouteCookie ? Cookies.get('lastAsPath') || lastRouteCookie : fallback;

  return { lastRoute, lastAsPath };
}
