// =============================
// Imports
// =============================

import { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Wrapper, Message, Date, Content, IconWrapper } from './styles';

// =============================
// Component
// =============================

const Notification = memo(({ date, fullWidth, icon, message, seen }) => (
  <Wrapper fullWidth={fullWidth} seen={seen}>
    {icon && <IconWrapper>{icon}</IconWrapper>}
    <Content>
      <Message>{message}</Message>
      {date && <Date>{dayjs().to(dayjs(date))}</Date>}
    </Content>
  </Wrapper>
));

Notification.displayName = 'Notification';

Notification.propTypes = {
  date: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  seen: PropTypes.bool,
};

Notification.defaultProps = {
  fullWidth: false,
  icon: null,
  message: '',
  date: '',
  seen: false,
};

export default Notification;
