/* eslint-disable implicit-arrow-linebreak */
// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';

// Constants
import * as playerCtx from '../../store/constants/PlayerContexts';

// Helpers
import { presentShortDescription } from './common';
import { getTrackAlbum, getTrackCoverUrl, getTrackDisplayArtists, getTrackTitle } from './track';
import {
  getPlaylistCoverUrl,
  getPlaylistTitle,
  getPlaylistTracksCoversUrl,
  getUserPlaylistName,
} from './playlist';
import { getArtistCoverUrl, getArtistName } from './artist';
import { getCatalogCoverUrl, getCatalogName } from './catalog';
import { getAlbumCoverUrl, getAlbumDisplayArtists, getAlbumTitle } from './album';
import { getLabelCoverUrl, getLabelName } from './label';
import { getPublisherCoverUrl, getPublisherName } from './publisher';
import { getPitchTitle, getPitchTracksCoversUrl, getPitchCoverUrl } from './pitch';
import { encodeAndRemovePercent } from '../misc';

// =============================
// Helpers
// =============================

/**
 * Get title of an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {function}
 */
export const getTitle = type =>
  /**
   * @param {object} doc - Entity document
   * @param {object} options
   * @param {boolean} options.variation - Can be "withAlbumRef"
   * @param {function} options.t - Translation function
   * @return {string} - Entity title
   */
  (doc, options = {}) =>
    ({
      tracks: getTrackTitle,
      albums: () => {
        if (options.variation !== 'withAlbumRef') return getAlbumTitle(doc);
        const albumRef = _get(doc, 'albumRef');

        return albumRef
          ? `${getAlbumTitle(doc)} - ${options.t('entity.ref')} ${albumRef}`
          : getAlbumTitle(doc);
      },
      playlists: getPlaylistTitle,
      artists: getArtistName,
      catalogs: getCatalogName,
      labels: getLabelName,
      publishers: getPublisherName,
      userplaylists: getUserPlaylistName,
      pitchs: getPitchTitle,
    }[type](doc));

/**
 * Get cover url for an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {string} - Cover url
 */
export const getCoverUrl = type =>
  ({
    tracks: getTrackCoverUrl,
    albums: getAlbumCoverUrl,
    playlists: getPlaylistCoverUrl,
    artists: getArtistCoverUrl,
    catalogs: getCatalogCoverUrl,
    labels: getLabelCoverUrl,
    publishers: getPublisherCoverUrl,
    pitchs: getPitchCoverUrl,
  }[type]);

/**
 * Get cover url for an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @param {string} size - Covers size, can be "large", "small" or "xsmall"
 * @return {string} - Cover urls
 */
export const getMultiCoverUrls = (type, size = 'large') =>
  /**
   * @param {object} doc - Entity document
   * @return {string} - Cover urls
   */
  (doc) => {
    if (type === 'playlists') {
      // Playlist track covers when they are multiple shouldn't be bigger than small
      return (
        getCoverUrl(type)(doc, size)
        || getPlaylistTracksCoversUrl(doc, size, size === 'xsmall' ? size : 'small')
      );
    }

    if (type === 'pitchs') {
      // Playlist track covers when they are multiple shouldn't be bigger than small
      return (
        getCoverUrl(type)(doc, size)
        || getPitchTracksCoversUrl(doc, size, size === 'xsmall' ? size : 'small')
      );
    }

    return getCoverUrl(type)(doc, size);
  };

const encodeParam = param => encodeAndRemovePercent(JSON.stringify([param]));

/**
 * Get base route for an entity page
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels", "publishers" or "userplaylists"
 * @return {string} - Base route
 */
export const getBaseRoute = type =>
  ({
    tracks: '/albums/[...albumId]',
    albums: '/albums/[...albumId]',
    playlists: '/playlists/[...playlistId]',
    artists: '/artists/[...artistId]',
    catalogs: '/catalogs/[...catalogId]',
    labels: null,
    publishers: null,
    userplaylists: '/userplaylist/[...playlistId]',
    pitchs: '/drop/[...pitchId]',
  }[type]);

/**
 * Get url for an entity page
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels", "publishers" or "userplaylists"
 * @return {function} - Function to use to get entity page url
 */
export const getUrl = type =>
  ({
    tracks: (e) => {
      const album = getTrackAlbum(e);
      if (!album.id) return null;
      return `/albums/${album.id}/${encodeAndRemovePercent(album.title)}`;
    },
    albums: e => `/albums/${e.id}/${encodeAndRemovePercent(getAlbumTitle(e))}`,
    playlists: e => `/playlists/${e.id}/${encodeAndRemovePercent(getPlaylistTitle(e))}`,
    artists: (e, overrideName) => `/artists/${e.id}/${encodeAndRemovePercent(overrideName || getArtistName(e))}`,
    catalogs: e => `/catalogs/${e.id}/${encodeAndRemovePercent(getCatalogName(e))}`,
    labels: e => `/search?labels=${encodeParam([e.id, getLabelName(e)])}`,
    publishers: e => `/search?publishers=${encodeParam([e.id, getPublisherName(e)])}`,
    userplaylists: (e, overrideName) => `/userplaylist/${e.id}/${encodeAndRemovePercent(overrideName || getUserPlaylistName(e))}`,
    pitchs: e => `/drop/${e.id}`,
  }[type]);

/**
 * Get search url for an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {function} - Function to use to get search url
 */
export const getSearchUrl = type =>
  ({
    tracks: e => `/search?ref_id=${encodeParam([e.refId, getTrackTitle(e)])}`,
    albums: e => `/search?albums=${encodeParam([e.id, getAlbumTitle(e)])}`,
    playlists: e => `/search?playlists=${encodeParam([e.id, getPlaylistTitle(e)])}`,
    artists: e => `/search?artists=${encodeParam([e.id, getArtistName(e)])}`,
    catalogs: e => `/search?catalogs=${encodeParam([e.id, getCatalogName(e)])}`,
    labels: e => `/search?labels=${encodeParam([e.id, getLabelName(e)])}`,
    publishers: e => `/search?publishers=${encodeParam([e.id, getPublisherName(e)])}`,
    artistsOwnerships: e => `/search?artists=${encodeParam([e.id, e.name])}`,
  }[type]);

export const buildEntitySearchUrl = (base, params) => {
  const paramsString = new URLSearchParams(_omitBy(params, p => !p)).toString();
  return paramsString ? `${base}?${paramsString}` : base;
};
/**
 * Get url for an entity search page
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {function} - Function to use to get entity search url
 */
export const getEntitySearchUrl = type =>
  ({
    tracks: query => buildEntitySearchUrl('/search', { query }),
    albums: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'albums', query, page }),
    playlists: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'playlists', query, page }),
    artists: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'artists', query, page }),
    catalogs: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'catalogs', query, page }),
    labels: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'labels', query, page }),
    publishers: (query, page) => buildEntitySearchUrl('/entitysearch', { type: 'publishers', query, page }),
  }[type]);

/**
 * Get player context name for an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {string} - Player context name
 */
export const getPlayerContextName = type =>
  ({
    tracks: playerCtx.SEARCH,
    albums: playerCtx.ALBUM,
    playlists: playerCtx.PLAYLIST,
    pitchs: playerCtx.PITCH,
    artists: playerCtx.ARTIST,
    catalogs: playerCtx.CATALOG,
    labels: null,
    publishers: null,
  }[type]);

/**
 * Get display artists of an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {function}
 */
export const getDisplayArtists = type =>
  /**
   * @param {object} doc - Entity document
   * @param {boolean} asString - If true, will return a string, else return an array
   * @return {array|string} - Array or String of Artists
   */
  (doc, asString = false) => {
    if (type === 'albums') {
      return getAlbumDisplayArtists(doc, asString);
    }

    if (type === 'tracks') {
      return getTrackDisplayArtists(doc, asString);
    }

    if (asString) return '';

    return [];
  };

/**
 * Get description an entity
 * @param {string} type - Entity type, can be
 * "tracks", "albums", "playlists", "artists", "catalogs", "labels" or "publishers"
 * @return {function}
 */
export const getDescription = type =>
  /**
   * @param {object} doc - Entity document
   * @param {string} locale - If true, will return a string, else return an array
   * @return {string} - String of artists (for album) or description
   */
  (doc, locale) => {
    if (type === 'albums') {
      return getAlbumDisplayArtists(doc, true);
    }

    return presentShortDescription(_get(doc, 'descriptions', []) || [], locale, 70);
  };
