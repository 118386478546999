// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getDisplayArtistName } from './artist';
import { getValueByLocale } from '../i18n';

// =============================
// Helpers
// =============================

/**
 * Get cover url from a track
 * @param {object} track - Track object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getTrackCoverUrl = (track, size = 'large') => {
  switch (size) {
    case 'xsmall':
      return (
        _get(track, `album.image[${size}].url`)
        || _get(track, 'album.image.small.url')
        || _get(track, 'album.image.large.url')
        || _get(track, 'album.image.original.url')
      );

    case 'small':
      return (
        _get(track, `album.image[${size}].url`)
        || _get(track, 'album.image.large.url')
        || _get(track, 'album.image.original.url')
      );

    case 'large':
      return _get(track, `album.image[${size}].url`) || _get(track, 'album.image.original.url');

    default:
      return _get(track, 'album.image.original.url');
  }
};

/**
 * Get display artists from a track
 * @param {object} track - Track object
 * @param {boolean} asString - If true, will return a string, else return an array
 * @return {array|string} - Array or String of Artists
 */
export const getTrackDisplayArtists = (track, asString = false) => {
  const displayArtists = ((track && track.displayArtists) || []).map(da => ({
    id: da.artist.id,
    name: getDisplayArtistName(da),
  }));

  if (asString) {
    return displayArtists.map(artist => artist.name).join(', ');
  }

  return displayArtists;
};

/**
 * Get artists master ownerships from a track
 * @param {object} track - Track object
 * @param {string} locale - Locale
 * @param {boolean} asString - If true, will return a string, else return an array
 * @return {array|string} - Array or String of Artists
 */
export const getTrackArtistsMasterOwnerships = (track, locale, asString = false) => {
  let amos = _get(track, 'artistsMasterOwnerships', []) || [];

  amos = amos.map(amo => ({
    id: amo.artist.id,
    name: getDisplayArtistName(amo),
    rightsType: getValueByLocale(_get(amo, 'rightsType.names', []) || [], locale),
  }));

  if (asString) {
    return amos.map(artist => artist.name).join(', ');
  }

  return amos;
};

/**
 * Get artists publishing ownerships from a track
 * @param {object} track - Track object
 * @param {string} locale - Locale
 * @param {boolean} asString - If true, will return a string, else return an array
 * @return {array|string} - Array or String of Artists
 */
export const getTrackArtistsPublishingOwnerships = (track, locale, asString = false) => {
  let apos = _get(track, 'artistsPublishingOwnerships', []) || [];

  apos = apos.map(apo => ({
    id: apo.artist.id,
    name: getDisplayArtistName(apo),
    rightsType: getValueByLocale(_get(apo, 'rightsType.names', []) || [], locale),
  }));

  if (asString) {
    return apos.map(artist => artist.name).join(', ');
  }

  return apos;
};

/**
 * Get track title
 * @param {object} track - Track object
 * @return {string} - Track title
 */
export const getTrackTitle = track => track && (track.displayTitle || track.title);

/**
 * Get track album name
 * @param {object} track - Track object
 * @return {object} retVal - Track album
 * @return {string} retVal.title - Track album title
 * @return {string} retVal.id - Track id
 */
export const getTrackAlbum = track => ({
  title: _get(track, 'album.title'),
  id: _get(track, 'album.id'),
});

/**
 * Get next seek value
 * @param {number} duration - Duration of track
 * @param {number} seekValue - Seek value in percentage
 * @return {number} - Next seek value in seconds
 */
export const getNextSeekValue = (duration, seekValue) => (duration / 100) * seekValue;
