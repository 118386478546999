// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import * as entityHelpers from '../../../../helpers/entity';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  TitleWrapper,
  Title,
  MobileActionList,
  MobileAction,
  DuplicateIcon,
  AddToPlaylistIcon,
  DeleteIcon,
} from '../styles';

// =============================
// Stories
// =============================

class ModalEntityActions extends PureComponent {
  static propTypes = {
    /** Function used to open modal */
    closeModal: PropTypes.func.isRequired,
    /** Function used to duplicate playlist. */
    duplicatePlaylist: PropTypes.func.isRequired,
    /** Function used to duplicate user owned playlist. */
    duplicateUserPlaylist: PropTypes.func.isRequired,
    /** Entity */
    // eslint-disable-next-line react/forbid-prop-types
    entity: PropTypes.object,
    /** If True, user is logged in. */
    isLogged: PropTypes.bool,
    /** Function used to open the AddToPlaylist modal. */
    openAddToPlaylistModal: PropTypes.func.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Function used to delete an user playlist. */
    removeUserPlaylists: PropTypes.func.isRequired,
    /** Function used to translate. */
    t: PropTypes.func.isRequired,
    /** Type of entity. */
    type: PropTypes.oneOf(['album', 'playlist', 'userplaylist', 'pitch']),
    /** If True, user own the current user playlist */
    userPlaylistIsOwnedByUser: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    entity: null,
    isLogged: false,
    opened: false,
    type: null,
  };

  getCover = () => {
    const { entity, type } = this.props;

    return (
      <ModalCover
        lazyload={false}
        placeholderType={['userplaylist', 'pitch'].includes(type) ? 'playlist' : type}
        type={['userplaylist', 'pitch'].includes(type) ? 'playlist' : type}
        src={entityHelpers.getMultiCoverUrls(`${type === 'userplaylist' ? 'playlist' : type}s`, 'large')(entity)}
        alt={entityHelpers.getTitle(`${type === 'userplaylist' ? 'playlist' : type}s`)(entity)}
      />
    );
  };

  renderAddToPlaylistAction = () => {
    const { entity, isLogged, openAddToPlaylistModal, t, type } = this.props;

    if (['album', 'playlist', 'pitch'].includes(type)) {
      return (
        <MobileAction
          isLink={!isLogged}
          route="/login"
          onClick={!isLogged ? null : () => openAddToPlaylistModal(true, entity, type)}
        >
          <AddToPlaylistIcon />
          {t('components:entity_actions.add_to_playlist')}
        </MobileAction>
      );
    }

    return null;
  };

  renderDeletePlaylistAction = () => {
    const { entity, removeUserPlaylists, t, type, userPlaylistIsOwnedByUser } = this.props;

    if (type !== 'userplaylist' || !userPlaylistIsOwnedByUser) return null;

    return (
      <MobileAction onClick={() => removeUserPlaylists([entity.id])}>
        <DeleteIcon />
        {t('components:entity_actions.delete_playlist')}
      </MobileAction>
    );
  };

  renderDuplicateAction = () => {
    const {
      duplicatePlaylist,
      duplicateUserPlaylist,
      entity,
      isLogged,
      t,
      type,
    } = this.props;

    if (!['playlist', 'userplaylist', 'pitch'].includes(type)) return null;

    const nextName = `${entityHelpers.getTitle(`${type}s`)(entity)} (${t(
      'components:entity_actions.duplicate_suffix',
    )})`;

    let action = duplicatePlaylist;
    if (type === 'userplaylist') action = duplicateUserPlaylist;

    const actionArgs = [
      entity.id,
      nextName,
    ];

    return (
      <MobileAction
        isLink={!isLogged}
        onClick={!isLogged ? null : () => action(...actionArgs)}
        route="/login"
      >
        <DuplicateIcon />
        {t('components:entity_actions.duplicate_playlist')}
      </MobileAction>
    );
  };

  render() {
    const { closeModal, entity, opened, type } = this.props;

    if (!entity || !type) return null;

    return (
      <DefaultModal
        bottomCloseBtn
        name="entityActions"
        onClose={closeModal}
        opened={opened}
      >
        <Wrapper>
          <Informations>{this.getCover()}</Informations>
          <Content>
            <TitleWrapper>
              <Title centered>{entityHelpers.getTitle(`${type === 'userplaylist' ? 'playlist' : type}s`)(entity)}</Title>
            </TitleWrapper>

            <MobileActionList>
              {this.renderAddToPlaylistAction()}
              {this.renderDuplicateAction()}
              {this.renderDeletePlaylistAction()}
            </MobileActionList>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation(['components', 'pages'])(ModalEntityActions);
