// =============================
// Imports
// =============================

import styled from 'styled-components';

import { DropdownList } from '../../../dropdown';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledDropdown = styled(DropdownList).attrs(({ placement }) => ({
  placement: placement || 'bottom-end',
}))``;
