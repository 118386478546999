/* eslint-disable react/no-did-update-set-state */
// =============================
// Imports
// =============================

import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { WithCustomTheme } from '@mewo/components';
import { ClickAwayListener } from '@material-ui/core';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import getViewProps from '../../../../helpers/front/getviewprops';

import {
  Wrapper,
  Container,
  AudioFileInput,
  AudioFileIcon,
  SearchMusicIcon,
  SearchInput,
  StyledCheckboxInput,
  StyledInput,
  StyledTooltip,
} from './styles';

// =============================
// Component
// =============================

class SearchBar extends PureComponent {
  static displayName = 'SearchBarDesktop';

  static propTypes = {
    className: PropTypes.string,
    /** Presearch modal is opened */
    isPreSearchOpen: PropTypes.bool.isRequired,
    /** Maia audio signal status */
    isSendingMaiaAudioSignal: PropTypes.bool.isRequired,
    /** Function called when user changes input */
    onChange: PropTypes.func.isRequired,
    /** Function called when user click on the SearchBar. */
    onClick: PropTypes.func,
    /** Focus event */
    onFocus: PropTypes.func.isRequired,
    /** Function called when user presses enter */
    onSubmit: PropTypes.func.isRequired,
    /** Request maia search */
    requestMaiaAudioSearch: PropTypes.func.isRequired,
    /** Search with description status */
    searchInDescription: PropTypes.bool.isRequired,
    /** Toggle search with description */
    setSearchInDescription: PropTypes.func.isRequired,
    /** Translation strings */
    t: PropTypes.func.isRequired,
    /** Toggle presearch modal */
    togglePreSearchModal: PropTypes.func.isRequired,
    /** value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Has guided search functionality */
    withGuidedSearch: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: '',
    onClick: null,
    value: '',
  };

  constructor(props) {
    super(props);

    this.audioFileInput = createRef();
    this.inputRef = createRef();

    this.state = {
      audioFileKey: Date.now(),
      isFocus: false,
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { isSendingMaiaAudioSignal: wasSendingMaiaAudioSignal } = prevProps;
    const { isSendingMaiaAudioSignal } = this.props;

    if (wasSendingMaiaAudioSignal && !isSendingMaiaAudioSignal) {
      this.setState({ audioFileKey: Date.now() });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { isFocus } = this.state;

    // If escape button & focused
    if (isFocus && e.keyCode === 27) {
      this.inputRef.current.blur();
    }
  };

  /** Handle input change. */
  handleChange = (event) => {
    const { isPreSearchOpen, onChange, togglePreSearchModal, withGuidedSearch } = this.props;

    // Open modal when there is at least one character
    if (event.target.value.length > 0 && !isPreSearchOpen) {
      togglePreSearchModal(true);
    }

    // Close modal when there are no more characters and guided search does not exist
    if (event.target.value.length === 0 && isPreSearchOpen && !withGuidedSearch) {
      togglePreSearchModal(false);
    }

    onChange(event.target.value);
  };

  inputHandleKeyDown = (e) => {
    const { onSubmit } = this.props;
    const { isFocus } = this.state;

    // If enter button
    if (e.keyCode === 13) {
      onSubmit();

      if (isFocus) {
        this.inputRef.current.blur();
      }
    }
  };

  handleWrapperClick = () => {
    const { onClick } = this.props;

    if (onClick) onClick();

    this.inputRef.current.focus({ preventScroll: true });

    this.toggleFocus(true);
  };

  handleClickAway = () => {
    const { isFocus } = this.state;

    // Unfocus if focused
    if (isFocus) {
      this.setState({ isFocus: false });
    }
  };

  handleAudioFileClick = () => this.audioFileInput.current.click();

  toggleFocus = () => {
    const { isPreSearchOpen, onFocus, togglePreSearchModal, value, withGuidedSearch } = this.props;
    const { isFocus } = this.state;

    onFocus();
    // Open modal when focused and has value or has guided search
    if (!isPreSearchOpen && (value.length > 0 || withGuidedSearch)) {
      togglePreSearchModal(true);
    }

    // Focus is it's not already
    if (!isFocus) {
      this.setState({ isFocus: true });
    }
  };

  render() {
    const {
      className,
      isPreSearchOpen,
      requestMaiaAudioSearch,
      searchInDescription,
      setSearchInDescription,
      t,
      value,
      ...rest
    } = this.props;
    const { isFocus, audioFileKey } = this.state;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.searchBar,
        })}
      >
        <ClickAwayListener onClickAway={this.handleClickAway}>
          <Wrapper className={className}>
            <Container
              isFocus={isFocus || isPreSearchOpen}
              onClick={this.handleWrapperClick}
              {...getViewProps(rest)}
            >
              <SearchInput isFocus={isFocus || isPreSearchOpen}>
                <SearchMusicIcon />
                <StyledInput
                  innerRef={this.inputRef}
                  onChange={this.handleChange}
                  onFocus={this.toggleFocus}
                  onKeyDown={this.inputHandleKeyDown}
                  placeholder={t('components:header.searchbar')}
                  value={value}
                />
              </SearchInput>
              {(isFocus || isPreSearchOpen) && (
                <StyledCheckboxInput
                  checked={searchInDescription}
                  isFocus={isFocus || isPreSearchOpen}
                  label={t('components:header.searchbar_description')}
                  onChange={() => setSearchInDescription(!searchInDescription)}
                  type="checkbox"
                />
              )}
              <AudioFileInput>
                <StyledTooltip content={t('components:header.searchbar_audio_file_input_helper')}>
                  <AudioFileIcon
                    isFocus={isFocus || isPreSearchOpen}
                    onClick={this.handleAudioFileClick}
                  />
                </StyledTooltip>
                <input
                  accept=".mp3,.aac,.m4a,.m4p,.m4b,.mp4,.3gp,.aif,.aiff,.aifc,.ogg,.ogv,.oga,.ogx,.spx,.opus,.ogm,.flac,.oga,.alac,.wav"
                  key={audioFileKey}
                  onChange={(event) => {
                    if (_get(event, 'target.files.length')) {
                      requestMaiaAudioSearch(event.target.files[0]);
                    }
                  }}
                  ref={this.audioFileInput}
                  type="file"
                />
              </AudioFileInput>
            </Container>
          </Wrapper>
        </ClickAwayListener>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('components')(SearchBar);
