// =============================
// Imports
// =============================

import _get from 'lodash/get';
import { alpha } from '@material-ui/core';

// =============================
// Settings
// =============================

/**
 * Get theme of dark modals
 * @param {Object} colors - Colors from modo config
 * @param {String} variant - Name of variant as written in modo config "variantSettings"
 */
function getThemeForVariant(colors, variant) {
  return _get(colors, _get(colors, `variantSettings.${variant}`));
}

/**
 * Get theme of dark modals
 * @param {Object} colors - Colors from modo config
 */
function getDarkModalTheme(colors) {
  // NOTE: Primary & primary text should work well on top of black and white
  return {
    // NOTE: Background is always black and text is always white in dark modal
    default: {
      background: '#000000',
      text: '#ffffff',
      primary: colors.modal.primary,
      primaryText: colors.modal.primaryText,
      boxShadow: 'rgba(255, 255, 255, 0.1)',
      input: {
        background: alpha('#ffffff', 0.1),
        text: '#ffffff',
        helpers: {
          background: '#ffffff',
          text: '#000000',
        },
      },
    },
    // NOTE: This is a hard coded construction used for components that can be
    // above a dark modal and that need to use a theme other than the current theme
    // eg: Notification, Tooltip...
    other: {
      background: '#ffffff',
      text: '#323232',
      primary: colors.modal.primary,
      primaryText: colors.modal.primaryText,
      boxShadow: 'rgba(0, 0, 0, 0.1)',
      input: {
        background: alpha('#323232', 0.1),
        text: '#323232',
        helpers: {
          background: '#323232',
          text: '#ffffff',
        },
      },
    },
  };
}

/**
 * Asserts what is the current theme and other theme
 * @param {Object} colors - Colors from modo config
 * @param {Boolean} isSimpleLayout - Asserts if we are on an auth page
 * @param {Boolean} darkModalsOpened - Asserts if a dark modal is opened
 * @param {Boolean} otherModalsOpened - Asserts if another modal is opened (menu, account..)
 */
function getCurrentTheme(colors, isSimpleLayout, darkModalsOpened, otherModalsOpened) {
  // NOTE: Since otherModals (menu) can be above darkModals, we need to follow this order for themes
  if (otherModalsOpened) {
    return {
      colors: getThemeForVariant(colors, 'other'),
      otherColors: getThemeForVariant(colors, 'default'),
    };
  }

  if (darkModalsOpened) {
    const darkModalTheme = getDarkModalTheme(colors);

    return {
      colors: darkModalTheme.default,
      otherColors: darkModalTheme.other,
    };
  }

  if (isSimpleLayout) {
    return {
      colors: getThemeForVariant(colors, 'other'),
      otherColors: getThemeForVariant(colors, 'default'),
    };
  }

  return {
    colors: getThemeForVariant(colors, 'default'),
    otherColors: getThemeForVariant(colors, 'other'),
  };
}

/**
 * Asserts what is the current theme for the menu
 * @param {Object} colors - Colors from modo config
 * @param {Boolean} isSimpleLayout - Asserts if we are on an auth page
 * @param {Boolean} darkModalsOpened - Asserts if a dark modal is opened
 * @param {Boolean} otherModalsOpened - Asserts if another modal is opened (menu, account..)
 * @param {Boolean} isSearchPage - Asserts if we are currently on the search page
 * @param {Boolean} isPreSearchOpen - Asserts if the pre search modal is opened
 * @param {Object} fullSizeCoverModule - Current full size cover if it exists
 */
function getMenuCurrentTheme(
  colors,
  isSimpleLayout,
  darkModalsOpened,
  otherModalsOpened,
  isSearchPage,
  isPreSearchOpen,
  fullSizeCoverModule,
) {
  // NOTE: Since otherModals (menu) can be above darkModals, we need to follow this order for themes
  if (otherModalsOpened) {
    return getThemeForVariant(colors, 'other');
  }

  if (darkModalsOpened) {
    const darkModalTheme = getDarkModalTheme(colors);

    return darkModalTheme.default;
  }

  if (fullSizeCoverModule && _get(fullSizeCoverModule, 'colors.useCustomColor', false)) {
    return fullSizeCoverModule.colors;
  }

  if (isPreSearchOpen) {
    return colors.header;
  }

  if (isSearchPage && _get(colors, 'filterBar.headerFollowsFilterBar', false)) {
    return colors.filterBar;
  }

  if (isSimpleLayout) {
    return getThemeForVariant(colors, 'other');
  }

  return colors.header;
}

/**
 * Asserts what is the current logo
 * @param {Object} colors - Colors from modo config
 * @param {Boolean} isSimpleLayout - Asserts if we are on an auth page
 * @param {Boolean} darkModalsOpened - Asserts if a dark modal is opened
 * @param {Boolean} otherModalsOpened - Asserts if another modal is opened (menu, account..)
 * @param {Boolean} isPreSearchOpen - Asserts if pre search is opened
 * @param {Boolean} isSearchPage - Asserts if we are currently on the search page
 * @param {Object} fullSizeCoverModule - Current full size cover if it exists
 */
function getCurrentLogo(
  colors,
  isSimpleLayout,
  darkModalsOpened,
  otherModalsOpened,
  isPreSearchOpen,
  isSearchPage,
  fullSizeCoverModule,
) {
  // NOTE: Since otherModals (menu) can be above darkModals, we need to follow this order for themes
  if (otherModalsOpened) return 'variant';

  if (darkModalsOpened) return 'modal';

  if (isPreSearchOpen) return 'default';

  if (isSearchPage && _get(colors, 'filterBar.headerFollowsFilterBar', false)) {
    return 'filterBar';
  }

  if (isSimpleLayout) return 'variant';

  if (fullSizeCoverModule) {
    if (fullSizeCoverModule.headerLogo === 'logo') {
      return 'default';
    }

    if (fullSizeCoverModule.headerLogo === 'logo_modal') {
      return 'modal';
    }

    if (fullSizeCoverModule.headerLogo === 'logo_variant') {
      return 'variant';
    }
  }

  return 'default';
}

/**
 * Get color config to be used globally
 * @param {Object} config - Full modo config
 * @param {Boolean} isSimpleLayout - Asserts if we are on an auth page
 * @param {Boolean} darkModalsOpened - Asserts if a dark modal is opened
 * @param {Boolean} otherModalsOpened - Asserts if another modal is opened (menu, account..)
 * @param {Boolean} isSearchPage - Asserts if we are currently on the search page
 * @param {Boolean} isPreSearchOpen - Asserts if the pre search modal is opened
 * @param {Object} fullSizeCoverModule - Current full size cover if it exists
 */
export function getColorConfig(
  config,
  isSimpleLayout,
  darkModalsOpened,
  otherModalsOpened,
  isSearchPage,
  isPreSearchOpen,
  fullSizeCoverModule,
) {
  // Fallback to default colors of API when website is private
  const configColors = _get(config, 'customisations.colors', {
    variantSettings: {
      default: 'dark',
      other: 'light',
      player: 'light',
    },
    light: {
      background: '#ffffff',
      text: '#323232',
      primary: '#d6b580',
      primaryText: '#ffffff',
      boxShadow: 'dark',
    },
    dark: {
      background: '#d6b580',
      text: '#ffffff',
      primary: '#ffffff',
      primaryText: '#d6b580',
      boxShadow: 'light',
    },
    header: {
      background: '#d6b580',
      text: '#ffffff',
      primary: '#ffffff',
      primaryText: '#d6b580',
      boxShadow: 'light',
    },
    searchBar: {
      background: '#ffffff',
      text: '#323232',
      primary: '#d6b580',
      primaryText: '#ffffff',
    },
    chip: {
      background: '#ffffff',
      text: '#323232',
      outlineBackground: '#ffffff',
      outlineText: '#ffffff',
    },
    filterBar: {
      headerFollowsFilterBar: true,
      background: '#ffffff',
      text: '#323232',
      primary: '#d6b580',
      primaryText: '#ffffff',
    },
    playlistWide: {
      background: '#000000',
      text: '#ffffff',
      primary: '#ffffff',
      primaryText: '#d6b580',
    },
    modal: {
      primary: '#ffffff',
      primaryText: '#d6b580',
    },
    type: {
      cancel: '#828282',
      error: '#e5414f',
      success: '#44bd32',
      warning: '#fa9917',
    },
  });

  const colors = {
    ...configColors,
    light: {
      ..._get(configColors, 'light'),
      boxShadow:
        _get(configColors, 'light.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
      boxShadowHover:
        _get(config, 'customisations.colors.light.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.3)'
          : 'rgba(0, 0, 0, 0.3)',
    },
    dark: {
      ..._get(configColors, 'dark'),
      boxShadow:
        _get(configColors, 'dark.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
      boxShadowHover:
        _get(config, 'customisations.colors.light.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.3)'
          : 'rgba(0, 0, 0, 0.3)',
    },
    header: {
      ..._get(configColors, 'header'),
      boxShadow:
        _get(configColors, 'header.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(0, 0, 0, 0.1)',
      boxShadowHover:
        _get(config, 'customisations.colors.light.boxShadow') === 'light'
          ? 'rgba(255, 255, 255, 0.3)'
          : 'rgba(0, 0, 0, 0.3)',
    },
  };

  const currentTheme = getCurrentTheme(colors, isSimpleLayout, darkModalsOpened, otherModalsOpened);
  const currentMenuTheme = getMenuCurrentTheme(
    colors,
    isSimpleLayout,
    darkModalsOpened,
    otherModalsOpened,
    isSearchPage,
    isPreSearchOpen,
    fullSizeCoverModule,
  );

  const defaultTheme = getThemeForVariant(colors, 'default');
  const otherTheme = getThemeForVariant(colors, 'other');
  const chipTheme = colors.chip;

  return {
    colors: {
      ...defaultTheme,
      currentTheme: currentTheme.colors,
      currentMenuTheme,

      // ===================================
      // Theme depends on variant settings
      // Theme is always the same no matter the context
      // These should be used with "WithCustomTheme" wrapping the target component
      // ===================================
      // NOTE: Full theme (component will use its own primary, box shadow...)
      pages: {
        auth: {
          ...otherTheme,
          // We reconstruct input & dropdown as they exist within auth
          // and these components can exist in multi context
          input: {
            background: alpha(otherTheme.text, 0.1),
            text: otherTheme.text,
            helpers: {
              background: otherTheme.text,
              text: otherTheme.background,
            },
          },
          dropdown: defaultTheme,
        },
        playlistWide: colors.playlistWide,
      },
      // NOTE: Full theme (component will use its own primary, box shadow...)
      modal: getDarkModalTheme(colors).default,
      menu: otherTheme,
      musicItem: {
        ...defaultTheme,
        // We could reconstruct dropdown as it exist within music item
        // But since those music item is on default theme, we do not change it here
      },
      musicPlayer: {
        ...getThemeForVariant(colors, 'player'),
        // We could reconstruct dropdown & tooltip as they exist within player
        // But since those elements overlap on default theme, we do not change them here
      },

      // ===================================
      // Custom config, only exists on top of default theme
      // These should be used with "WithCustomTheme" wrapping the target component
      // ===================================
      // NOTE: Full theme (component will use its own primary, box shadow...)
      filterBar: {
        ...colors.filterBar,
        // We reconstruct input as there are inputs within filterBar
        // and input can exist in multi context
        input: {
          background: alpha(colors.filterBar.text, 0.1),
          text: colors.filterBar.text,
          helpers: {
            background: colors.filterBar.text,
            text: colors.filterBar.background,
          },
        },
      },
      // NOTE: Extension of parent theme (component will use current primary, box shadow...)
      /**
       * Chip exists also on top of presearch, so the
       * theme must work well with the presearch theme
       */
      /**  Alternate chip is opposite of base chip */
      chip: {
        ...chipTheme,
        // Since we can now have a transparent background for chips, alternate is equal to
        // default chip when default background is transparent
        // If not, we would have an alternate chip with colored background and a transparent text
        backgroundAlternate: chipTheme.background ? chipTheme.text : chipTheme.background,
        textAlternate: chipTheme.background || chipTheme.text,
        outlineBackgroundAlternate: chipTheme.outlineText,
        outlineTextAlternate: chipTheme.outlineBackground,
      },
      // NOTE: Full theme (component will use its own primary, box shadow...)
      /** Header and presearch have the same theme */
      header: colors.header,
      presearch: colors.header,
      /** Search bar exists on top of header, so the theme must work well with the header theme */
      searchBar: colors.searchBar,

      // ===================================
      // Multi context configs, depends on current theme
      // Needs to be able to exist with one theme in one
      // context and with another theme in another context
      // ===================================
      // NOTE: Extension of current theme (component will use current primary, box shadow...)
      input: {
        text: defaultTheme.text,
        background: alpha(defaultTheme.text, 0.1),
        helpers: {
          background: defaultTheme.text,
          text: defaultTheme.background,
        },
      },
      dropdown: otherTheme,

      // ===================================
      // Multi context configs always opposite of current theme
      // All components changes theme depending on context
      // ===================================
      // NOTE: Full theme (component will use its own primary, box shadow...)
      notification: currentTheme.otherColors,
      tooltip: currentTheme.otherColors,
      cookieConsent: currentTheme.otherColors,

      // ===================================
      // Type colors should be Mewo's but sometimes it needs to be custom
      // eg: When default theme color is equal to a type color
      // ===================================
      type: colors.type,

      // Other values needed that depends on context
      logo: getCurrentLogo(
        colors,
        isSimpleLayout,
        darkModalsOpened,
        otherModalsOpened,
        isPreSearchOpen,
        isSearchPage,
        fullSizeCoverModule,
      ),
      showHeaderShadow:
        !isSimpleLayout && !darkModalsOpened && !otherModalsOpened && isPreSearchOpen,
      onDefaultContext: !isSimpleLayout && !darkModalsOpened && !otherModalsOpened,
    },
  };
}

/**
 * Get ui config to be used globally
 * @param {Object} config - Full modo config
 */
export function getUiConfig(config) {
  const fontFamily = _get(config, 'customisations.fontFamily', 'Mulish');

  /** This affects the presentation of admin playlists */
  /**
   * When the value is "wide": The playlist image is in
   * full-page with an overlay and the description on top
   */
  /**
   * When the value is "cover": The presentation is the
   * same as other entity pages (cover on the left, desc on the right)
   */
  const displayConf = {
    playlistImg: _get(config, 'customisations.playlists.useHeaderVariant') ? 'wide' : 'cover',
  };

  /** This affect the edges of all elements in the front-end */
  /** Api uses "rouded" or "squared", Front-end uses "round" or "square" */
  const edgeVariant = _get(config, 'customisations.global.edges', 'rounded') === 'rounded' ? 'round' : 'square';

  /** This either enables or disables the noise filter on our covers  */
  const coverConfig = {
    enableFilter: _get(config, 'customisations.covers.filter.enable', true),
  };

  return {
    // Always fallback to "sans-serif"
    fontFamily: {
      primary: `"${fontFamily}", sans-serif`,
    },
    displayConf,
    edgeVariant,
    coverConfig,
  };
}

export function getMuiConfig(baseTheme) {
  return {
    // https://material-ui.com/customization/palette/
    palette: {
      primary: {
        main: baseTheme.colors.primary,
        contrastText: baseTheme.colors.primaryText,
      },
    },
    shadows: Array(25).fill('none'), // Remove every box-shadow on every material ui components
    shape: {
      borderRadius: 5,
    },
    typography: {
      fontFamily: baseTheme.fontFamily.primary,
    },
    overrides: {
      MuiPickersCalendarHeader: {
        iconButton: {
          color: baseTheme.colors.dropdown.text,
          '&:disabled': {
            color: alpha(baseTheme.colors.dropdown.text, 0.3),
          },
          '&:hover': {
            backgroundColor: alpha(baseTheme.colors.dropdown.text, 0.1),
          },
        },
        switchHeader: {
          color: baseTheme.colors.dropdown.text,
        },

      },
      MuiPickersDay: {
        day: {
          color: baseTheme.colors.dropdown.text,
          '&:hover': {
            backgroundColor: alpha(baseTheme.colors.dropdown.text, 0.1),
          },
        },
        daySelected: {
          backgroundColor: baseTheme.colors.primary,
          color: baseTheme.colors.primaryText,
        },
        dayDisabled: {
          color: alpha(baseTheme.colors.dropdown.text, 0.3),
        },
        current: {
          color: baseTheme.colors.primary,
        },
      },
    },
    props: {
      MuiIconButton: {
        disableRipple: true,
      },
    },
  };
}
