// =============================
// Imports
// =============================

// Externals Dependencies
import Cookies from 'js-cookie';

// Config
import { i18n } from '../../config/i18n';

// Actions
import { getRealtimeChannels } from '../actions/UserActions';
import { getUserNotifications } from '../actions/PagesActions';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import { getCookieConfig } from '../../helpers/misc';

// =============================
// Middleware
// =============================

const authMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.SET_USER_SESSION_TOKEN:
      Cookies.set(
        'xAuth',
        action.payload,
        getCookieConfig(3, store.getState().core.serverContext.locationProtocol),
      );
      return next(action);

    // This action should mean that user will be logged
    case action.type === acts.SET_USER_INFO:
      store.dispatch(getUserNotifications());
      store.dispatch(getRealtimeChannels());

      // When user is logging in after app init, we might need to refresh language
      if (typeof window !== 'undefined') {
        if (Cookies.get('next-i18next') !== action.payload.language) {
          Cookies.set(
            'next-i18next',
            action.payload.language,
            getCookieConfig(3, store.getState().core.serverContext.locationProtocol),
          );

          i18n.changeLanguage(action.payload.language);
        }
      }

      return next(action);

    case action.type === acts.LOGOUT_USER_SUCCESS:
      Cookies.remove(
        'xAuth',
        getCookieConfig(null, store.getState().core.serverContext.locationProtocol),
      );
      return next(action);

    default:
      return next(action);
  }
};

export default authMiddleware;
