// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';
import { Chip, Icons, InfoTooltip, Tooltip } from '@mewo/components';

import Cover from '../../../../containers/cover';
import Button from '../../../../presentationals/button';
import Link from '../../../../other/link';
import ScrollBar from '../../../../presentationals/scrollbar';
import Tag from '../../../../other/tag';

import { headerHeight } from '../../../../presentationals/header/desktop/variables';

// =============================
// Styles
// =============================

const containerMaxWidth = '102rem';
const containerMaxWidthSxga = '128rem';
const containerPaddingX = '3rem';
const containerPaddingY = '4rem';
const columnMarginLeft = '2rem';
const columnMarginLeftSxga = '5rem';
const entityHeight = '5.5rem';

export const PageTitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 1.2rem;

  ${({ minimized }) => !minimized
    && css`
      margin-left: ${containerPaddingX};
    `};
`;

export const Column = styled.div`
  color: ${({ theme }) => theme.colors.text};
  flex-shrink: 0;
  text-align: center;

  ${({ minimized, theme }) => (minimized
    ? css`
      width: calc(
        (${containerMaxWidth} - (${containerPaddingX} * 2) - (${columnMarginLeft} * 3)) / 4
      );

      &:not(:first-child) {
        margin-left: ${columnMarginLeft};
      }

      ${theme.mediaAbove.sxga`
        width: calc((${containerMaxWidthSxga} - (${containerPaddingX} * 2) - (${columnMarginLeftSxga} * 3)) / 4);

        &:not(:first-child) {
          margin-left: ${columnMarginLeftSxga};
        }
      `};
    `
    : css`
      width: 24rem;

      &:first-child {
        margin-left: ${containerPaddingX};
      }

      &:not(:first-child) {
        margin-left: ${columnMarginLeft};
      }

      &:last-child {
        margin-right: ${containerPaddingX};
      }

      ${theme.mediaAbove.sxga`
        &:not(:first-child) {
          margin-left: ${columnMarginLeftSxga};
        }
      `};
  `)};
`;

export const ColumnTitle = styled(Link)`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 3rem;
  text-align: left;
`;

export const SeeResult = styled(Link)`
  border: 0.2rem solid transparent;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  line-height: 1.3; /** We need to put line-height > 1, or we won't see the underline hover */
  margin: 3rem auto 0;
  text-align: center;

  &:hover {
    border-bottom: 0.2rem solid;
  }
`;

export const EntityWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1.2rem;
  height: ${entityHeight};
  margin-bottom: 0.8rem;
  position: relative;
  text-align: left;
  width: 100%;

  ${({ isTrack }) => (isTrack
    ? css`
          grid-template-columns: minmax(0, ${entityHeight}) minmax(0, 1fr);
        `
    : css`
          grid-template-columns: minmax(0, ${entityHeight}) minmax(0, 1fr) minmax(0, 2rem);
        `)};

  &:before {
    background: ${({ theme }) => alpha(theme.colors.text, 0.1)};
    content: '';
    height: calc(100% + 0.8rem);
    left: -1rem;
    opacity: 0;
    position: absolute;
    top: -0.4rem;
    transition: ${({ theme }) => theme.transition.medium};
    width: calc(100% + 2rem);
    z-index: -1;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const EntityCover = styled(Cover).attrs({
  size: entityHeight,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTranslateY: true,
  playButtonSize: 'musicItem',
})`
  font-size: 0;
`;

export const EntityTitleAuthors = styled.div``;

export const EntityTitle = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 0.2rem;
  padding-right: 0.8rem;
`;

export const EntityTitleLink = styled(Link)`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.extrabold};
  margin-bottom: 0.2rem;
  padding-right: 0.8rem;
`;

export const EntityAuthors = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  max-height: 3.5rem;
  opacity: 0.7;
  padding-right: 0.8rem;
`;

export const EntityAuthor = styled(Link)`
  cursor: pointer;
  display: inline;
  flex-shrink: 0;
  line-height: 1.2;

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-right: 0.4rem;

    &:after {
      content: ',';
    }
  }
`;

export const TagCategory = styled(Chip)`
  margin: 0.4rem 0.6rem 0.6rem 0;
`;

export const IconLink = styled(Link)``;

export const ExternalLinkIcon = styled(Icons.ExternalLink).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '1.4rem',
}))``;

export const Caption = styled.div`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
`;

export const StyledTooltip = styled(Tooltip).attrs({
  enterDelay: 300,
})`
  & .tooltip {
    background-color: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.background};
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 1rem;
  }
`;

export const StyledButton = styled(Button).attrs({
  color: 'error',
  size: 'medium',
})`
  margin: 2rem auto 0;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5rem 10rem 0 ${({ theme }) => theme.colors.boxShadow};
  border-radius: ${({ theme }) => (theme.edgeVariant === 'round' ? '2rem' : 'O.2rem')};
  color: ${({ theme }) => theme.colors.text};
  /** Needed to be above Modal overlay. */
  z-index: 1;

  ${({ minimized, theme }) => (minimized
    ? css`
          border-radius: ${theme.edgeVariant === 'round' ? '0 0 2rem 2rem' : '0 0 0.2rem 0.2rem'};
        `
    : css`
          border-radius: 0;
        `)};
`;

export const PresearchContainer = styled(Container)`
  ${({ theme, minimized }) => (minimized
    ? css`
          max-width: ${containerMaxWidth};
          padding: ${containerPaddingY} ${containerPaddingX};

          ${theme.mediaAbove.sxga`
            max-width: ${containerMaxWidthSxga};
          `};
        `
    : css`
          height: calc(100vh - ${headerHeight});
          padding: ${containerPaddingY} 0;
          width: 100%;
        `)};
`;

export const CaptionContainer = styled(PresearchContainer)`
  ${({ minimized }) => !minimized
    && css`
      align-items: center;
      display: flex;
      justify-content: center;
    `};
`;

export const GuidedSearchContainer = styled(Container)`
  padding: ${containerPaddingY} ${containerPaddingX};
  width: 100%;

  ${({ theme, minimized }) => (minimized
    ? css`
          max-width: ${containerMaxWidth};

          ${theme.mediaAbove.sxga`
            max-width: ${containerMaxWidthSxga};
          `};
        `
    : css`
          height: calc(100vh - ${headerHeight});
        `)};
`;

export const StyledTag = styled(Tag)`
  margin: 0.4rem 0.6rem 0.6rem 0;
`;

export const StyledScrollBar = styled(ScrollBar).attrs({
  speed: 0.4,
  horizontal: true,
  noScrollBar: true,
  vertical: false,
  swapWheelAxes: true,
  horizontalScrollbarStyle: { display: 'none' },
  verticalScrollbarStyle: { display: 'none' },
  smoothScrolling: true,
})`
  && {
    height: 100%;
    margin-top: 4rem;
    width: 100%;

    &.scrollarea {
      /** Reset styles. */
      left: 0;

      ${({ minimized }) => minimized
        && css`
          overflow: visible;
        `};
    }

    & .scrollarea-content {
      align-items: flex-start;
      display: inline-flex;

      /** Reset styles. */
      padding-left: 0;

      ${({ minimized }) => minimized
        && css`
          overflow: visible;
        `};

      /**
        Used to display more or less results (entity) due to available height.
        Notes: we need to select from nth-of-type 1 to 5 to take
        into account the ColumnTitle.
        */

        ${({ fullSizeCoverModule }) => (fullSizeCoverModule?.isVisible ? css`
          ${Column} {
            > ${EntityWrapper} {
              display: none;
            }

            > ${EntityWrapper}:nth-of-type(1),
            > ${EntityWrapper}:nth-of-type(2) {
              display: grid;
            }

            > ${TagsList} {
              > ${StyledTag} {
                display: none;
              }

              > ${StyledTag}:nth-of-type(1),
              > ${StyledTag}:nth-of-type(2),
              > ${StyledTag}:nth-of-type(3),
              > ${StyledTag}:nth-of-type(4),
              > ${StyledTag}:nth-of-type(5),
              > ${StyledTag}:nth-of-type(6) {
                display: flex;
              }
            }
          }

          @media (min-height: ${700 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(3) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${800 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(4) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${900 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(5) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${1000 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(6) {
                  display: grid;
                }
              }
            `};
          }
        ` : css`
          ${Column} {
            > ${EntityWrapper} {
              display: none;
            }

            > ${EntityWrapper}:nth-of-type(1),
            > ${EntityWrapper}:nth-of-type(2) {
              display: grid;
            }

            > ${TagsList} {
              > ${StyledTag} {
                display: none;
              }

              > ${StyledTag}:nth-of-type(1),
              > ${StyledTag}:nth-of-type(2),
              > ${StyledTag}:nth-of-type(3),
              > ${StyledTag}:nth-of-type(4),
              > ${StyledTag}:nth-of-type(5),
              > ${StyledTag}:nth-of-type(6) {
                display: flex;
              }
            }
          }

          @media (min-height: ${550 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(3) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${600 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(4) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${650 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(5) {
                  display: grid;
                }
              }
            `};
          }

          @media (min-height: ${700 / 16}em) {
            ${css`
              ${Column} {
                > ${EntityWrapper}:nth-of-type(6) {
                  display: grid;
                }
              }
            `};
          }
        `)};
    }
  }
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 0.8rem;
`;
