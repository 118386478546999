// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import {
  getPlaylistTitle,
  getPlaylistCoverUrl,
  getPlaylistTracksCoversUrl,
} from '../../../../helpers/entity/playlist';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import { WrapIfDesktop } from '../../../other/responsive';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  StyledScrollBar,
  Title,
  TitleWrapper,
  TextWithLineBreaks,
} from '../styles';

// =============================
// Stories
// =============================

class ModalPlaylist extends PureComponent {
  static propTypes = {
    /** Function used to close modal */
    closePlaylistDescModal: PropTypes.func.isRequired,
    /** Locale */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Playlist */
    playlist: pth.playlist.isRequired,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      closePlaylistDescModal,
      i18n: { language },
      opened,
      playlist,
    } = this.props;

    if (!playlist) return null;

    const description = getValueByLocale(playlist.descriptions, language);

    return (
      <DefaultModal bottomCloseBtn name="playlist" onClose={closePlaylistDescModal} opened={opened} wideContent>
        <Wrapper>
          <Informations>
            <ModalCover
              placeholderType="playlist"
              type="playlist"
              src={
                getPlaylistCoverUrl(playlist) || getPlaylistTracksCoversUrl(playlist)
              }
              lazyload={false}
              alt={getPlaylistTitle(playlist)}
            />
          </Informations>
          <Content>
            <WrapIfDesktop Wrapper={<StyledScrollBar />}>
              <TitleWrapper>
                <Title>{getPlaylistTitle(playlist)}</Title>
              </TitleWrapper>
              <TextWithLineBreaks>{description}</TextWithLineBreaks>
            </WrapIfDesktop>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('components')(ModalPlaylist);
