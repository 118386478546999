// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { duplicatePlaylist } from '../../../../store/actions/EntitiesActions';
import { setAddToPlaylistOpen, setEntityActionsOpen } from '../../../../store/actions/ModalsActions';
import { duplicateUserPlaylist, removeUserPlaylists } from '../../../../store/actions/UserPlaylistsActions';

import { isUserOwnedPlaylist } from '../../../../helpers/entity/playlist';

import ModalEntityActions from '../../../layouts/modals/entityactions';

// =============================
// Component
// =============================

function mapStateToProps({ modals, user, userplaylists }) {
  return {
    isLogged: user.isLogged,
    userPlaylistIsOwnedByUser: isUserOwnedPlaylist(user.data, userplaylists.playlist.data),
    entity: modals.entityActions.data,
    opened: modals.entityActions.isOpen,
    type: modals.entityActions.type,
  };
}

export default connect(mapStateToProps, {
  closeModal: () => setEntityActionsOpen(false),
  duplicatePlaylist,
  duplicateUserPlaylist,
  openAddToPlaylistModal: setAddToPlaylistOpen,
  removeUserPlaylists,
})(ModalEntityActions);
