// =============================
// Imports
// =============================

import { ConditionalWrapper, Div, WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';
import _some from 'lodash/some';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import { PoseGroup } from 'react-pose';

import { withTranslation } from '../../../../config/i18n';

import CurrentTimeFetcher from '../../../other/currentTime';
import Link from '../../../other/link';
import DownloadDropdown from '../../musicitem/desktop/downloaddropdown';

import { getBaseRoute, getUrl } from '../../../../helpers/entity';
import { presentDuration } from '../../../../helpers/entity/common';
import {
  getNextSeekValue,
  getTrackAlbum,
  getTrackCoverUrl,
  getTrackDisplayArtists,
  getTrackTitle,
} from '../../../../helpers/entity/track';
import getViewProps from '../../../../helpers/front/getviewprops';
import * as pth from '../../../../helpers/proptypes';

import { encodeAndRemovePercent } from '../../../../helpers/misc';
import { Option, OptionLink } from '../../dropdown/styles';
import {
  AddToFavoriteIcon,
  AddToPlaylistIcon,
  Author,
  Authors,
  ClosePlayerIcon,
  Controls,
  CurrentTime,
  DownloadIcon,
  Interactions,
  MagicWandIcon,
  MusicPlayerWaveform,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PrevIcon,
  ShareIcon,
  StyledAddToPlaylist,
  StyledCover,
  StyledDropdownButton,
  StyledShareButton,
  StyledTooltip,
  ThreeDotIcon,
  Timeline,
  Title,
  TitleAuthors,
  TitleLink,
  TrackNameTooltip,
  TrackTime,
  WaveformContainer,
  Wrapper,
} from './styles';

// =============================
// Component
// =============================

class MusicPlayer extends PureComponent {
  static displayName = 'MusicPlayerDesktop';

  static propTypes = {
    canDownload: PropTypes.bool.isRequired,
    canDownloadStems: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    current: pth.playertrack,
    getCurrentTime: PropTypes.func,
    getFilesDatas: PropTypes.func.isRequired,
    goToNextTrack: PropTypes.func.isRequired,
    goToPrevTrack: PropTypes.func.isRequired,
    handleDownloadArchive: PropTypes.func.isRequired,
    handleDownloadAudiofile: PropTypes.func.isRequired,
    handleDownloadCopyright: PropTypes.func.isRequired,
    hasNext: PropTypes.bool.isRequired,
    isEnded: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    isPaused: PropTypes.bool.isRequired,
    isPlaying: PropTypes.bool.isRequired,
    isSimpleLayout: PropTypes.bool.isRequired,
    minimized: PropTypes.bool.isRequired,
    modalHide: PropTypes.bool.isRequired,
    notifyFileDownload: PropTypes.func.isRequired,
    onAddToFavorites: PropTypes.func.isRequired,
    onMagicWand: PropTypes.func.isRequired,
    onOpenFilesPassthrough: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    pitchToken: PropTypes.string.isRequired,
    seek: PropTypes.func.isRequired,
    setTrackLyricsOpen: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    current: null,
    getCurrentTime: null,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { current, isEnded, isPlaying, isPaused, pause, play } = this.props;

    const targetTagName = _get(e, 'target.tagName', '').toLowerCase();

    // When pressing on space bar when there is a current
    // track and target is not an input or textarea
    if (
      !!current
      && e.keyCode === 32
      && !_some(['input', 'textarea'], tag => tag === targetTagName)
    ) {
      e.preventDefault();

      if (isPlaying) {
        pause();
      }

      if (isPaused || isEnded) {
        play();
      }
    }
  };

  notifyDownload = (trackId, type) => {
    const { isLogged, pitchToken, notifyFileDownload } = this.props;

    if (isLogged || pitchToken) {
      notifyFileDownload(trackId, type);
    }
  };

  renderFilesDropdown = (music) => {
    const {
      canDownload,
      canDownloadStems,
      getFilesDatas,
      handleDownloadAudiofile,
      handleDownloadArchive,
      isLogged,
      onOpenFilesPassthrough,
      pitchToken,
      t,
    } = this.props;

    if (!isLogged && !pitchToken) {
      return (
        <StyledTooltip content={t('components:music_item.download')}>
          <Link route="/login">
            <DownloadIcon />
          </Link>
        </StyledTooltip>
      );
    }

    const options = [];
    const filesDatas = getFilesDatas(music);

    // NOTE: 1 means that there are no alternative versions
    const nbVersions = _get(music, 'versions', 1) - 1;

    if (_get(filesDatas, 'data.audiofile.original.url')) {
      options.push({
        action: () => handleDownloadAudiofile(music, 'original'),
        name: 'original',
        label: t('components:music_item.download_original_audiofile'),
      });
    }

    if (isLogged && (nbVersions || _get(filesDatas, 'data.audiofile.original.url'))) {
      options.push({
        name: 'high_quality_archive_with_versions',
        action: () => handleDownloadArchive(music),
        label: t('components:music_item.download_high_quality_archive_with_versions'),
      });
    }

    if (_get(filesDatas, 'data.audiofile.hdMp3.url')) {
      options.push({
        name: 'hdmp3',
        action: () => handleDownloadAudiofile(music),
        label: t('components:music_item.download_hd_mp3'),
      });
    }

    if (isLogged && (nbVersions || _get(filesDatas, 'data.audiofile.original.url'))) {
      options.push({
        name: 'low_quality_archive_with_versions',
        action: () => handleDownloadArchive(music, 'hd_mp3'),
        label: t('components:music_item.download_low_quality_archive_with_versions'),
      });
    }

    if (_get(filesDatas, 'data.stems.original.url')) {
      options.push({
        name: 'stems',
        link: true,
        component: (
          <OptionLink
            style={{ width: '100%' }}
            download
            target="_blank"
            href={_get(filesDatas, 'data.stems.original.url')}
            rel="noopener noreferrer"
            onClickPassthrough={() => this.notifyDownload(music.id, 'stems')}
          >
            {t('components:music_item.download_stems')}
          </OptionLink>
        ),
      });
    }

    const fetchFunction = () => {
      if (canDownload || canDownloadStems || !!pitchToken) onOpenFilesPassthrough(music);
    };

    return (
      <Div onClick={fetchFunction}>
        <DownloadDropdown
          canDownload={canDownload || canDownloadStems || !!pitchToken}
          isLoading={_get(filesDatas, 'isFetching', false)}
          options={options}
          togglerElement={(
            <StyledTooltip content={t('components:music_item.download')}>
              <DownloadIcon />
            </StyledTooltip>
          )}
        />
      </Div>
    );
  };

  dropdownButtonRenderOption = (option) => {
    const { isLogged } = this.props;

    if (option.authRequired && !isLogged) {
      return <OptionLink route="/login">{option.label}</OptionLink>;
    }

    return <Option>{option.label}</Option>;
  };

  renderDropdownButton = () => {
    const {
      current,
      isLogged,
      setTrackLyricsOpen,
      handleDownloadCopyright,
      pitchToken,
      t,
    } = this.props;

    if (pitchToken) return null;

    const options = [];

    // Show lyrics
    if (_get(current, 'lyrics')) {
      options.push({
        action: () => setTrackLyricsOpen(true, current),
        label: t('components:music_item.see_lyrics'),
        name: 'see_lyrics',
      });
    }

    // Download Copyright
    options.push({
      action: isLogged ? () => handleDownloadCopyright(current) : null,
      label: t('components:music_item.download_copyright'),
      name: 'download_copyright',
      authRequired: true,
    });

    return (
      <StyledDropdownButton
        noButton
        options={options}
        renderOption={this.dropdownButtonRenderOption}
        togglerElement={(
          <StyledTooltip content={t('components:music_item.other_actions')}>
            <ThreeDotIcon />
          </StyledTooltip>
        )}
      />
    );
  };

  handleSeek = (seekValue) => {
    const { current, seek } = this.props;

    seek(getNextSeekValue(_get(current, 'duration', 0), seekValue));
  };

  render() {
    const {
      close,
      current,
      getCurrentTime,
      goToNextTrack,
      goToPrevTrack,
      hasNext,
      isLogged,
      isPaused,
      isPlaying,
      isSimpleLayout,
      minimized,
      modalHide,
      onAddToFavorites,
      onMagicWand,
      opened,
      pause,
      play,
      pitchToken,
      t,
    } = this.props;

    const waveformSvg = _get(current, 'waveform.small.url', null);

    const trackTitle = getTrackTitle(current);

    // Status for get current time
    let status = 0;
    if (isPaused) status = 1;
    if (isPlaying) status = 2;

    // Only show magic search if duration is above 10 seconds and below 20 minutes
    const hasMagicSearch = _get(current, 'duration', 0) >= 10 && _get(current, 'duration', 0) <= 1200;

    const magicWandQuery = current ? encodeAndRemovePercent(JSON.stringify([current.id, getTrackTitle(current)])) : '';

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.musicPlayer,
        })}
      >
        <PoseGroup>
          {!isSimpleLayout && !modalHide && opened && current && (
            <Wrapper key="wrapper" minimized={minimized} {...getViewProps(this.props)}>
              <StyledCover
                LinkComponent={Link}
                linkProps={getUrl('tracks')(current) ? {
                  route: getBaseRoute('tracks'),
                  nextAs: getUrl('tracks')(current),
                } : {}}
                minimized={minimized}
                src={getTrackCoverUrl(current, 'small')}
                alt={getTrackAlbum(current).title}
              />

              <TitleAuthors minimized={minimized}>
                <Fragment>
                  {getUrl('tracks')(current) ? (
                    <TrackNameTooltip content={trackTitle}>
                      <ConditionalWrapper
                        condition={!pitchToken}
                        Wrapper={<TitleLink route={getBaseRoute('tracks')} nextAs={getUrl('tracks')(current)} />}
                      >
                        <Title>{trackTitle}</Title>
                      </ConditionalWrapper>
                    </TrackNameTooltip>
                  ) : (
                    <TrackNameTooltip noHover content={trackTitle}>
                      <Title>{trackTitle}</Title>
                    </TrackNameTooltip>
                  )}
                </Fragment>
                <Authors>
                  {getTrackDisplayArtists(current).map((da, i, a) => (
                    /* eslint-disable-next-line react/no-array-index-key */
                    <Fragment key={`da-${da.id}-${i}`}>
                      <ConditionalWrapper
                        condition={!pitchToken}
                        Wrapper={<Author route={getBaseRoute('artists')} nextAs={getUrl('artists')(da, da.name)} />}
                      >
                        {da.name}
                      </ConditionalWrapper>
                      {i < a.length - 1 && ', '}
                    </Fragment>
                  ))}
                </Authors>
              </TitleAuthors>

              <Controls minimized={minimized}>
                <PrevIcon onClick={goToPrevTrack} />
                {!isPlaying && <PlayIcon onClick={play} />}
                {isPlaying && <PauseIcon onClick={pause} />}
                <NextIcon onClick={goToNextTrack} disabled={!hasNext} />
              </Controls>

              <CurrentTimeFetcher status={status} getCurrentTime={getCurrentTime}>
                {currentTime => (
                  <Fragment>
                    <WaveformContainer minimized={minimized}>
                      {waveformSvg && (
                        <MusicPlayerWaveform
                          progress={(currentTime / _get(current, 'duration', 0)) * 100}
                          svg={waveformSvg}
                          onSeek={this.handleSeek}
                        />
                      )}
                    </WaveformContainer>

                    <Timeline minimized={minimized}>
                      <CurrentTime>{presentDuration(currentTime)}</CurrentTime>
                      <TrackTime>{presentDuration(_get(current, 'duration', 0))}</TrackTime>
                    </Timeline>
                  </Fragment>
                )}
              </CurrentTimeFetcher>

              <Interactions>
                {hasMagicSearch && !pitchToken && (
                  <StyledTooltip content={t('components:music_item.magical_search')}>
                    <Link route={`/search?track_id=${magicWandQuery}`}>
                      <MagicWandIcon />
                    </Link>
                  </StyledTooltip>
                )}

                {this.renderFilesDropdown(current)}

                {isLogged && !pitchToken && (
                  <Fragment>
                    <StyledAddToPlaylist
                      type="track"
                      itemId={current.id}
                      togglerElement={(
                        <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                          <AddToPlaylistIcon />
                        </StyledTooltip>
                      )}
                    />
                    <StyledTooltip content={t('components:music_item.add_to_favorite')} isHeartIcon>
                      <AddToFavoriteIcon
                        checked={isLogged && current.isFavorite}
                        onClick={() => {
                          onAddToFavorites(current);
                        }}
                      />
                    </StyledTooltip>
                  </Fragment>
                )}

                {!isLogged && !pitchToken && (
                  <Fragment>
                    <StyledTooltip content={t('components:music_item.add_to_playlist')}>
                      <Link route="/login">
                        <AddToPlaylistIcon />
                      </Link>
                    </StyledTooltip>
                    <StyledTooltip content={t('components:music_item.add_to_favorite')}>
                      <Link route="/login">
                        <AddToFavoriteIcon />
                      </Link>
                    </StyledTooltip>
                  </Fragment>
                )}

                {!pitchToken && (
                  <StyledShareButton
                    url={getUrl('tracks')(current)}
                    text={getTrackTitle(current)}
                    togglerElement={(
                      <StyledTooltip content={t('components:music_item.share')}>
                        <ShareIcon />
                      </StyledTooltip>
                    )}
                  />
                )}

                {this.renderDropdownButton()}

                <ClosePlayerIcon
                  onClick={() => {
                    close();

                    if (isPlaying) {
                      pause();
                    }
                  }}
                />
              </Interactions>
            </Wrapper>
          )}
        </PoseGroup>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('components')(MusicPlayer);
