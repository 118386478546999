// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Chip } from '@mewo/components';

// =============================
// Styles
// =============================

// eslint-disable-next-line import/prefer-default-export
export const StyledTag = styled(Chip)`
  ${({ disabled }) => disabled && css`
    opacity: 0.4;
  `};
`;
