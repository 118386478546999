// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';

import { headerHeight } from '../../../presentationals/header/mobile/variables';
import { musicPlayerHeight } from '../../../presentationals/musicplayer/mobile/variables';
import { marginAfterHeader, marginAfterLastItem, paddingX } from './variables';

// =============================
// Styles
// =============================

const Wrapper = styled.div`
  ${({ playerIsOpened }) => css`
    &::after {
      content: '';
      display: block;
      width: 100%;

      ${playerIsOpened ? css`
        height: calc(${musicPlayerHeight} + ${marginAfterLastItem});
      ` : css`
        height: ${marginAfterLastItem};
      `};
    }
  `};
`;

export const CustomPageWrapper = styled.div`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  width: 100%;

  ${({ noPaddingTop }) => !noPaddingTop && css`
    padding-top: calc(${headerHeight});
  `};

  ${({ playerIsOpened }) => css`
    &::after {
      content: '';
      display: block;
      width: 100%;

      ${playerIsOpened && css`
        height: ${musicPlayerHeight};
      `};
    }
  `};
`;

export const EntityWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;
`;

export const PlaylistWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  /** Playlist relative styles */
  ${({ theme }) => {
    if (theme.displayConf.playlistImg === 'wide') {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
`;
export const PitchWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;

  /** Pitch relative styles */
  ${({ theme }) => {
    if (theme.displayConf.playlistImg === 'wide') {
      return css`
        padding: 0;
      `;
    }
    return css``;
  }}
`;

export const SearchWrapper = styled(Wrapper)`
  padding-top: ${headerHeight};
  width: 100%;

  ${({ isDoingMaiaSearch }) => isDoingMaiaSearch && css`
    min-height: 100%;
    padding-top: calc(${headerHeight} + ${marginAfterHeader});
  `};
`;

export const TextWrapper = styled(Wrapper)`
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  padding-top: calc(${headerHeight} + ${marginAfterHeader});
  width: 100%;
`;

export default Wrapper;
