// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setCookiesOpen } from '../../../../store/actions/ModalsActions';

import ModalCookie from '../../../layouts/modals/cookies';

// =============================
// Component
// =============================

function mapStateToProps({ modals }) {
  return {
    opened: modals.isCookiesOpen,
  };
}

export default connect(mapStateToProps, {
  closeCookiesModal: () => setCookiesOpen(false),
})(ModalCookie);
