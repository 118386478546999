// =============================
// Imports
// =============================

// External dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { WithCustomTheme } from '@mewo/components';
import _get from 'lodash/get';

// Config
import { withTranslation } from '../config/i18n';

// Components
import Error from '../components/layouts/error';

// Helpers
import { getWebsiteTitle } from '../helpers/misc';
import * as pth from '../helpers/proptypes';

// Styles
import { InheritSize } from '../components/layouts/common.styles';

// =============================
// Page
// =============================

export class ErrorPage extends PureComponent {
  static propTypes = {
    config: pth.config,
    error: PropTypes.shape({
      message: PropTypes.string,
      context: PropTypes.string,
      type: PropTypes.string,
    }),
    isSimpleLayout: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    config: null,
    error: undefined,
  };

  static async getInitialProps() {
    return {
      namespacesRequired: ['common', 'pages', 'components', 'errors'],
    };
  }

  pageTitle() {
    const { config, error, t } = this.props;

    switch (_get(error, 'type')) {
      case 'NOT_FOUND':
        return getWebsiteTitle(config, t('pages:not_found.meta_title'));

      case 'NO_ALBUMS_SUGGESTIONS':
        return getWebsiteTitle(config, t('pages:suggested_albums.meta_title'));

      case 'NO_PLAYLISTS_SUGGESTIONS':
        return getWebsiteTitle(config, t('pages:suggested_playlists.meta_title'));

      default:
        return getWebsiteTitle(config, _get(error, 'message', t('pages:error.title')));
    }
  }

  getTitle() {
    const { error, t } = this.props;

    const errorType = _get(error, 'type');

    if (errorType === 'NOT_FOUND') return t('pages:not_found.title');
    if (errorType === 'NO_ALBUMS_SUGGESTIONS') return undefined;
    if (errorType === 'NO_PLAYLISTS_SUGGESTIONS') return undefined;

    return t('pages:error.title');
  }

  getIcon() {
    const { error } = this.props;

    if (_get(error, 'type') === 'NOT_FOUND') return 'NOT_FOUND';

    return undefined;
  }

  getErrorTheme = (theme) => {
    const { isSimpleLayout } = this.props;

    if (isSimpleLayout) return theme.colors.pages.auth;

    return theme.colors;
  };

  render() {
    const { error, isSimpleLayout, t } = this.props;

    return (
      <WithCustomTheme
        customTheme={currentTheme => ({
          colors: this.getErrorTheme(currentTheme),
        })}
      >
        <InheritSize>
          <Head>
            <title>{this.pageTitle()}</title>
          </Head>
          <Error
            message={_get(error, 'message', t('pages:error.title'))}
            title={this.getTitle()}
            icon={this.getIcon()}
            isSimpleLayout={isSimpleLayout}
          />
        </InheritSize>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('pages')(ErrorPage);
