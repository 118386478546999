// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  trackLyrics: {
    isOpen: false,
    data: null,
  },
  isUserPlaylistOpen: false,
  isAlbumOpen: false,
  isArtistOpen: false,
  isCatalogOpen: false,
  isPlaylistOpen: false,
  isPitchOpen: false,
  isAccountOpen: false,
  isMenuOpen: false,
  isCookiesOpen: false,
  isLegalOpen: false,
  isPreSearchOpen: false,
  video: {
    isOpen: false,
    data: null,
  },
  isUserMenuOpen: false,
  musicItem: {
    isOpen: false,
    data: null,
  },
  musicPlayer: {
    requestOpen: false,
    isOpen: false,
    data: null,
  },
  addToPlaylist: {
    isOpen: false,
    data: null,
    type: null,
  },
  trackArtists: {
    isOpen: false,
    data: null,
  },
  trackOwnerships: {
    isOpen: false,
    data: null,
  },
  trackTags: {
    isOpen: false,
    data: null,
  },
  trackVersions: {
    isOpen: false,
    data: null,
  },
  entityActions: {
    isOpen: false,
    data: null,
    type: null,
  },
};

// =============================
// Reducer
// =============================

export default function modals(state = initialState, action) {
  switch (action.type) {
    case acts.MODALS_SET_TRACK_LYRICS_OPEN:
      return {
        ...state,
        trackLyrics: {
          ...state.trackLyrics,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_USER_PLAYLIST_OPEN:
      return {
        ...state,
        isUserPlaylistOpen: action.payload,
      };

    case acts.MODALS_SET_ALBUM_OPEN:
      return {
        ...state,
        isAlbumOpen: action.payload,
      };

    case acts.MODALS_SET_ARTIST_OPEN:
      return {
        ...state,
        isArtistOpen: action.payload,
      };

    case acts.MODALS_SET_CATALOG_OPEN:
      return {
        ...state,
        isCatalogOpen: action.payload,
      };

    case acts.MODALS_SET_PLAYLIST_OPEN:
      return {
        ...state,
        isPlaylistOpen: action.payload,
      };

    case acts.MODALS_SET_PITCH_OPEN:
      return {
        ...state,
        isPitchOpen: action.payload,
      };

    case acts.MODALS_SET_ACCOUNT_OPEN:
      return {
        ...state,
        isAccountOpen: action.payload,
      };

    case acts.MODALS_SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload,
      };

    case acts.MODALS_SET_COOKIES_OPEN:
      return {
        ...state,
        isCookiesOpen: action.payload,
      };

    case acts.MODALS_SET_LEGAL_OPEN:
      return {
        ...state,
        isLegalOpen: action.payload,
      };

    case acts.MODALS_SET_PRE_SEARCH_OPEN:
      return {
        ...state,
        isPreSearchOpen: action.payload,
      };

    case acts.MODALS_SET_VIDEO_OPEN:
      return {
        ...state,
        video: {
          ...state.video,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_USER_MENU_OPEN:
      return {
        ...state,
        isUserMenuOpen: action.payload,
      };

    case acts.MODALS_SET_MUSIC_ITEM_OPEN:
      return {
        ...state,
        musicItem: {
          ...state.musicItem,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_REQUEST_MUSIC_PLAYER_OPEN:
      return {
        ...state,
        musicPlayer: {
          ...state.musicPlayer,
          requestOpen: action.payload,
        },
      };

    case acts.MODALS_SET_MUSIC_PLAYER_OPEN:
      return {
        ...state,
        musicPlayer: {
          ...state.musicPlayer,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.UPDATE_MODALS_FAVORITES: {
      const nextMusicItemData = state.musicItem.data
      && state.musicItem.data.id === action.payload.id
        ? { ...state.musicItem.data, isFavorite: action.payload.isFavorite }
        : state.musicItem.data;

      const nextMusicPlayerData = state.musicPlayer.data
      && state.musicPlayer.data.id === action.payload.id
        ? { ...state.musicPlayer.data, isFavorite: action.payload.isFavorite }
        : state.musicPlayer.data;

      return {
        ...state,
        musicItem: {
          ...state.musicItem,
          data: nextMusicItemData,
        },
        musicPlayer: {
          ...state.musicPlayer,
          data: nextMusicPlayerData,
        },
      };
    }

    case acts.UPDATE_MODALS_RECENTS: {
      const nextMusicItemData = state.musicItem.data
      && state.musicItem.data.id === action.payload.id
        ? { ...state.musicItem.data, recentlyPlayed: true }
        : state.musicItem.data;

      const nextMusicPlayerData = state.musicPlayer.data
      && state.musicPlayer.data.id === action.payload.id
        ? { ...state.musicItem.data, recentlyPlayed: true }
        : state.musicPlayer.data;

      return {
        ...state,
        musicItem: {
          ...state.musicItem,
          data: nextMusicItemData,
        },
        musicPlayer: {
          ...state.musicPlayer,
          data: nextMusicPlayerData,
        },
      };
    }

    case acts.MODALS_SET_ADD_TO_PLAYLIST_OPEN:
      return {
        ...state,
        addToPlaylist: {
          ...state.addToPlaylist,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
          type: action.payload.type,
        },
      };

    case acts.MODALS_SET_TRACK_ARTISTS_OPEN:
      return {
        ...state,
        trackArtists: {
          ...state.trackArtists,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_TRACK_OWNERSHIPS_OPEN:
      return {
        ...state,
        trackOwnerships: {
          ...state.trackOwnerships,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_TRACK_TAGS_OPEN:
      return {
        ...state,
        trackTags: {
          ...state.trackTags,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_TRACK_VERSIONS_OPEN:
      return {
        ...state,
        trackVersions: {
          ...state.trackVersions,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
        },
      };

    case acts.MODALS_SET_ENTITY_ACTIONS_OPEN:
      return {
        ...state,
        entityActions: {
          ...state.entityActions,
          isOpen: action.payload.isOpen,
          data: action.payload.data,
          type: action.payload.type,
        },
      };

    case acts.RESET_ALL_MODALS:
      return {
        ...state,
        ...initialState,
        // NOTE: DOES NOT APPLY TO PRESEARCH, HANDLED BY COMPONENT ITSELF
        isPreSearchOpen: state.isPreSearchOpen,
      };

    default:
      return state;
  }
}
