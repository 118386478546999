// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { WithCustomTheme, DropdownContext } from '@mewo/components';
import axios from 'axios';

import { withTranslation } from '../../../config/i18n';

import {
  addItemsToPlaylist as addItemsToPlaylistBase,
  removeTrackFromPlaylist as removeTrackFromPlaylistBase,
} from '../../../store/actions/UserPlaylistsActions';

import { getBaseRoute, getUrl } from '../../../helpers/entity';
import { getApiUrl, getXPreferredLanguage } from '../../../helpers/misc';
import * as pth from '../../../helpers/proptypes';

import {
  StyledDropdown,
  Option,
  OptionAdded,
  AddButton,
  StyledLink,
  GoToPlaylist,
  Header,
  CreatePlaylistForm,
  NewPlaylistInput,
  AddToPlaylistBtn,
  AddToPlaylistBtnPlusIcon,
  CreatePlaylistToggler,
  PlusIcon,
  NewPlaylistLabel,
  InputAndBtnWrapper,
} from './styles';

// =============================
// Component
// =============================

class AddToPlaylist extends Component {
  static propTypes = {
    placement: pth.dropdownPlacement,
    itemId: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['track', 'album', 'playlist']).isRequired,
    togglerElement: PropTypes.node.isRequired,
    userToken: PropTypes.string,
    xHost: PropTypes.string.isRequired,
    isLogged: PropTypes.bool.isRequired,
    isHandlingTracks: PropTypes.arrayOf(PropTypes.string).isRequired,
    userPlaylists: PropTypes.arrayOf(pth.listuserplaylist).isRequired,
    addItemsToPlaylist: PropTypes.func.isRequired,
    removeTrackFromPlaylist: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    placement: 'bottom',
    userToken: null,
  };

  constructor(props) {
    super(props);

    const { userPlaylists, t } = props;

    this.state = {
      creatingNewPlaylist: false,
      newPlaylistName: `${t('components:add_to_playlist_dropdown.new_playlist')} ${userPlaylists.length + 1}`,
      tracksPlaylists: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { userPlaylists: prevUserPlaylists, t } = prevProps;
    const { userPlaylists } = this.props;

    if (prevUserPlaylists.length !== userPlaylists.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        newPlaylistName: `${t('components:add_to_playlist_dropdown.new_playlist')} ${userPlaylists.length + 1}`,
      });
    }
  }

  closeNewPlaylistCreation = () => this.setState({ creatingNewPlaylist: false });

  openNewPlaylistCreation = () => this.setState({ creatingNewPlaylist: true });

  fetchTracksPlaylists = async () => {
    const { itemId, type, isLogged, userToken, xHost } = this.props;

    if (userToken && isLogged) {
      try {
        const options = {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-host': xHost,
            'x-auth': userToken,
            'x-preferred-language': getXPreferredLanguage(),
          },
        };

        if (type === 'track') {
          options.method = 'post';
          options.url = getApiUrl('public/tracks/user-playlists/data');
          options.data = { ids: [itemId] };
        } else {
          options.method = 'get';
          options.url = getApiUrl(`public/${type}s/${itemId}/user-playlists`);
        }

        const response = await axios(options);

        this.setState({ tracksPlaylists: [...response.data] });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  };

  handleAddToNewPlaylist = async () => {
    const { addItemsToPlaylist, itemId, type } = this.props;
    const { newPlaylistName } = this.state;

    this.closeNewPlaylistCreation();

    const { payload: { id } } = await addItemsToPlaylist(type, itemId, null, newPlaylistName);

    this.setState(state => ({
      tracksPlaylists: [...state.tracksPlaylists, id],
    }));
  };

  handleAddToPlaylist = async (playlistId) => {
    const { addItemsToPlaylist, itemId, type } = this.props;

    await addItemsToPlaylist(type, itemId, playlistId);

    this.setState(state => ({
      tracksPlaylists: [...state.tracksPlaylists, playlistId],
    }));
  };

  handleRemoveFromPlaylist = async (playlistId) => {
    const { removeTrackFromPlaylist, itemId } = this.props;

    await removeTrackFromPlaylist(playlistId, itemId);

    this.setState(state => ({
      tracksPlaylists: [...state.tracksPlaylists.filter(id => id !== playlistId)],
    }));
  };

  handleNewPlaylistNameChange = e => this.setState({ newPlaylistName: e.target.value });

  handleNewPlaylistKeyDown = (e) => {
    const { key } = e;

    if (key === 'Enter') {
      this.handleAddToNewPlaylist();
    }
  };

  renderAddToNewPlaylist = () => {
    const { isHandlingTracks, t } = this.props;
    const { creatingNewPlaylist, newPlaylistName } = this.state;

    return (
      <WithCustomTheme
        customTheme={theme => ({
          colors: theme.colors.dropdown,
        })}
      >
        <Header>
          {creatingNewPlaylist ? (
            <CreatePlaylistForm>
              <NewPlaylistLabel>{t('components:add_to_playlist_dropdown.new_playlist_name')}</NewPlaylistLabel>
              <InputAndBtnWrapper>

                <NewPlaylistInput
                  name="new_playlist"
                  onChange={this.handleNewPlaylistNameChange}
                  onKeyDown={this.handleNewPlaylistKeyDown}
                  value={newPlaylistName}
                />
                <AddToPlaylistBtn onClick={this.handleAddToNewPlaylist}>
                  <AddToPlaylistBtnPlusIcon />
                </AddToPlaylistBtn>
              </InputAndBtnWrapper>
            </CreatePlaylistForm>
          ) : (
            <CreatePlaylistToggler
              onClick={this.openNewPlaylistCreation}
              disabled={isHandlingTracks.length}
            >
              <PlusIcon />
              {t('components:add_to_playlist_dropdown.add_to_new_playlist')}
            </CreatePlaylistToggler>
          )}
        </Header>
      </WithCustomTheme>
    );
  };

  renderOption = (option) => {
    const { type, isHandlingTracks } = this.props;
    const { tracksPlaylists } = this.state;

    const added = tracksPlaylists.includes(option.name);
    const disabled = isHandlingTracks.includes(option.name) || (added && type !== 'track');

    return (
      <Option key={option.name}>
        <AddButton
          disabled={disabled}
          onClick={async () => {
            if (added) return this.handleRemoveFromPlaylist(option.name);
            return this.handleAddToPlaylist(option.name);
          }}
        >
          {added && <OptionAdded />}
          {option.label}
        </AddButton>
        <DropdownContext.Consumer>
          {({ closeDropdown }) => (
            <StyledLink
              onClick={closeDropdown}
              route={getBaseRoute('userplaylists')}
              nextAs={getUrl('userplaylists')({ id: option.name }, option.label)}
            >
              <GoToPlaylist />
            </StyledLink>
          )}
        </DropdownContext.Consumer>
      </Option>
    );
  };

  render() {
    const { isLogged, placement, t, togglerElement, userPlaylists, ...rest } = this.props;

    if (!isLogged) return null;

    return (
      <StyledDropdown
        {...rest}
        placement={placement}
        togglerElement={togglerElement}
        handleClick={this.fetchTracksPlaylists}
        lang={{ search: t('components:add_to_playlist_dropdown.search') }}
        header={this.renderAddToNewPlaylist()}
        options={userPlaylists.map(userPlaylist => ({
          name: userPlaylist.id,
          label: userPlaylist.name,
        }))}
        renderOption={this.renderOption}
      />
    );
  }
}

function mapStateToProps({ core, user, userplaylists }) {
  return {
    xHost: core.serverContext.xHost,
    userToken: user.token,
    isLogged: user.isLogged,
    userPlaylists: userplaylists.list.data,
    isHandlingTracks: userplaylists.playlistTracks.isHandlingTracks,
  };
}

export default compose(
  withTranslation('components'),
  connect(mapStateToProps, {
    addItemsToPlaylist: addItemsToPlaylistBase,
    removeTrackFromPlaylist: removeTrackFromPlaylistBase,
  }),
)(AddToPlaylist);
