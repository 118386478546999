// =============================
// Imports
// =============================

// External Dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Notification } from '@mewo/components';

// =============================
// Component
// =============================

class FinalNotification extends PureComponent {
  static propTypes = {
    // This component can clear notification on its own
    clearNotification: PropTypes.func.isRequired,
    // The notification can stop showing after a while
    // Duration should be expressed in milliseconds
    duration: PropTypes.number,
    // Message
    message: PropTypes.string,
    // Key of notification
    notificationKey: PropTypes.string.isRequired,
    // Request ID
    reqId: PropTypes.string,
    t: PropTypes.func.isRequired,
    // Type can be 'error', 'success' or 'warning'
    type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
  };

  static defaultProps = {
    duration: 6000,
    message: '',
    reqId: '',
  };

  componentDidMount() {
    const { clearNotification, duration, notificationKey } = this.props;

    // Clear the notification after duration
    if (duration === Infinity) return;

    this.timeout = setTimeout(() => {
      clearNotification(notificationKey);
    }, duration);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  hide = () => {
    const { notificationKey, clearNotification } = this.props;
    clearTimeout(this.timeout);
    clearNotification(notificationKey);
  };

  render() {
    const { message, notificationKey, reqId, t, type } = this.props;

    return (
      <Notification
        type={type}
        onClick={() => {
          this.hide(notificationKey);
        }}
        lang={{ reqId: t('components:notification.req_id') }}
        reqId={reqId}
      >
        {message}
      </Notification>
    );
  }
}

export default FinalNotification;
