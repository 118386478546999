// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

// =============================
// Component
// =============================

export const Wrapper = styled.div`
  align-self: flex-end;
  background: ${({ theme }) => alpha(theme.colors.modal.background, 0.95)};
  border-radius: 2rem 2rem 0 0;
  bottom: 0;
  color: ${({ theme }) => theme.colors.modal.text};
  height: calc(100% - 2rem);
  overflow-y: scroll;
  position: absolute;
  width: 100%;
`;

const headerHeight = '5rem';

export const Header = styled.div`
  align-items: center;
  border-bottom: 0.2rem solid ${({ theme }) => alpha(theme.colors.modal.text, 0.2)};
  display: flex;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  height: ${headerHeight};
  justify-content: flex-start;
  padding: 0 0.8rem;
`;

export const CloseModalBtn = styled.div`
  flex-shrink: 0;
  width: 20%;
`;

export const Title = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  flex-shrink: 0;
  text-align: center;
  width: 60%;
`;

export const EmptySpacePlaceholder = styled.div`
  flex-shrink: 0;
  width: 20%;
`;

export const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${headerHeight});
  justify-content: flex-start;
  overflow-y: auto;
  padding: 2rem 0.8rem;

  /** Used to give margin to last item. */
  &::after {
    content: ' ';
    display: block;
    flex-shrink: 0;
    height: 0.8rem;
    width: 0.1rem;
  }
`;

export const ContentTitle = styled.div`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 2.3rem;
  margin-bottom: 1.6rem;
  width: 100%;

  ${({ centered }) => centered && css`
    text-align: center;
  `};
`;
