/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import styled from 'styled-components';
import { Loader } from '@mewo/components';

// =============================
// Styled Components
// =============================

export const ThemedLoader = styled(Loader).attrs(({ theme }) => ({ color: theme.colors.currentTheme.primary }))``;
