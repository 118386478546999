// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import LanguageSwitcher from '../../presentationals/languageSwitcher';

import { modifyUserLanguage } from '../../../store/actions/UserActions';

// =============================
// Component
// =============================

function mapStateToProps({ user, core }) {
  return {
    isLogged: user.isLogged,
    locationProtocol: core.serverContext.locationProtocol,
  };
}

export default connect(mapStateToProps, {
  modifyUserLanguage,
})(LanguageSwitcher);
