// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Div } from '@mewo/components';
import { alpha } from '@material-ui/core';

import { isHoverPrimary } from '../../../../helpers/front/getHoverType';

import Button from '../../button';
import { DropdownList } from '../../dropdown';
import { OptionLink } from '../../dropdown/styles';
import Logo from '../../../containers/logo';
import LanguageSwitcher from '../../../containers/languageSwitcher';

import { headerHeight, headerPaddingX } from './variables';
import { filterBarWidth } from '../../filterbar/desktop/variables';

// =============================
// Component
// =============================

export const Wrapper = styled.div`
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: ${({ theme }) => alpha(theme.colors.background, 0.9)};
  display: flex;
  height: ${headerHeight};
  flex-shrink: 0;
  right: 0;
  padding: ${headerPaddingX};
  position: fixed;
  top: 0;
  transition: background-color ${({ theme }) => theme.transition.medium}, width ${({ theme }) => theme.transition.medium};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.scHeader};

  ${({ filterBarOpened }) => filterBarOpened && css`
    width: calc(100% - ${filterBarWidth});
  `};

  ${({ fullSizeCoverModuleIsVisible }) => fullSizeCoverModuleIsVisible && css`
    background: transparent;
  `};

  ${({ showBoxShadow, theme }) => showBoxShadow && css`
    box-shadow: 0 0.2rem 1.5rem ${theme.colors.boxShadow};
  `};
`;

export const HeaderPart = styled(Div)`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
`;

export const Right = styled(HeaderPart)`
  font-size: 1.2rem;
  justify-content: flex-end;
  margin-left: auto;
  max-width: 45%;
`;

export const RightLinks = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
  margin-right: 4.8rem;
  text-align: right;
`;

/** Used in Menu. */
export const StyledLogo = styled(Logo)`
  cursor: pointer;
  max-height: 6.5rem;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.scHeaderLogo};
`;

export const AvatarWrapper = styled.div`
  margin-left: 2rem;
`;

export const StyledButton = styled(Button).attrs({
  color: 'primary',
  size: 'small',
})``;

export const StyledDropdownList = styled(DropdownList).attrs(({ theme }) => ({
  dropdownStyles: css`
    background-color: ${theme.colors.dropdown.background};
    border-radius: ${(theme.edgeVariant === 'round' ? '0.5rem' : '0.2rem')};
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.dropdown.boxShadow};
    color: ${theme.colors.dropdown.text};
    min-width: 11rem;
  `,
  placement: 'bottom-start',
}))``;

export const StyledLanguageSwitcher = styled(LanguageSwitcher)`
  display: inline-block;
  margin-left: 1.6rem;
  text-align: left;

  ${({ theme }) => theme.mediaAbove.uxga`
    margin-left: 4rem;
  `};
`;

export const StyledOption = styled(OptionLink)`
  color: ${({ theme }) => theme.colors.dropdown.text};

  ${({ noHover, theme }) => !noHover && (isHoverPrimary(theme.colors)
    ? css`
      &:hover {
        color: ${theme.colors.dropdown.primary};
      }
    `
    : css`
      &:hover {
        opacity: 0.7;
      }
    `)};
`;
