// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icons, WithCustomTheme } from '@mewo/components';

import { withTranslation } from '../../../../../config/i18n';

import * as pth from '../../../../../helpers/proptypes';
import { getValueByLocale } from '../../../../../helpers/i18n';

import ModalCloser from '../../../../other/modalCloser';

import { StyledLogo } from '../../../header/desktop/styles';
import {
  Wrapper,
  MenuButton as MenuButtonBase,
  MenuButtonLogo,
  MenuButtonLogoBackground,
  LeftLinks,
  StyledLink,
} from './styles';

// =============================
// Component
// =============================

class MenuButton extends PureComponent {
  static propTypes = {
    /** Colors. */
    colors: pth.colors.isRequired,
    /** Asserts if filter bar is opened or not */
    filterBarOpened: PropTypes.bool.isRequired,
    /** Current FullSizeCover module */
    fullSizeCoverModule: PropTypes.shape({
      id: PropTypes.string,
      colors: pth.colorsModule,
      isVisible: PropTypes.bool,
    }),
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Account is opened. */
    isAccountOpened: PropTypes.bool.isRequired,
    /** If True, Presearch is opened. */
    isPreSearchOpened: PropTypes.bool.isRequired,
    /** Header links */
    leftHeaderLinks: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        names: pth.descriptions,
      }),
    ).isRequired,
    /** If true, Menu is opened */
    opened: PropTypes.bool.isRequired,
    /** Function to close all modals */
    resetModals: PropTypes.func.isRequired,
    /** Home route */
    rootRoute: PropTypes.string.isRequired,
    /** Callback function use to toggle Menu */
    toggleMenu: PropTypes.func.isRequired,
    /** Currently used logo */
    usedLogo: PropTypes.string.isRequired,
  };

  static defaultProps = {
    fullSizeCoverModule: null,
  };

  handleToggleMenu = () => {
    const { opened, toggleMenu } = this.props;
    toggleMenu(!opened);
  };

  render() {
    const {
      colors,
      filterBarOpened,
      fullSizeCoverModule,
      hasAccess,
      i18n: { language },
      isAccountOpened,
      isPreSearchOpened,
      leftHeaderLinks,
      opened,
      resetModals,
      rootRoute,
      usedLogo,
    } = this.props;

    return (
      <WithCustomTheme customTheme={() => ({ colors })}>
        <Wrapper fullSizeCoverIsVisible={fullSizeCoverModule?.isVisible && !isAccountOpened}>
          <MenuButtonLogo>
            <MenuButtonLogoBackground
              filterBarOpened={filterBarOpened}
              isPreSearchOpened={isPreSearchOpened}
            />
            {hasAccess && (
              <MenuButtonBase colors={colors} opened={opened} onClick={this.handleToggleMenu}>
                {opened ? (
                  <Icons.CrossOutline fill="inherit" />
                ) : (
                  <Icons.MenuOutline fill="inherit" />
                )}
              </MenuButtonBase>
            )}
            <ModalCloser onClose={resetModals} path={rootRoute}>
              {onClose => (
                <StyledLogo
                  colors={colors}
                  onClickPassthrough={onClose}
                  route={hasAccess ? rootRoute : '/login'}
                  usedLogo={usedLogo}
                />
              )}
            </ModalCloser>
          </MenuButtonLogo>

          {fullSizeCoverModule?.isVisible && !isAccountOpened && !!leftHeaderLinks.length && (
            <LeftLinks opened={opened}>
              {leftHeaderLinks.map((leftLink, i) => (
                <StyledLink
                  href={leftLink.page ? `/page/${leftLink.slug}` : leftLink.slug}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${i}-${leftLink.slug}`}
                >
                  {getValueByLocale(leftLink.names, language)}
                </StyledLink>
              ))}
            </LeftLinks>
          )}
        </Wrapper>
      </WithCustomTheme>
    );
  }
}

export default withTranslation('components')(MenuButton);
