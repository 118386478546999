// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import ModalUserMenu from '../../../layouts/modals/usermenu';

import { logout } from '../../../../store/actions/AuthActions';
import { setAccountOpen, setUserMenuOpen } from '../../../../store/actions/ModalsActions';

// =============================
// Component
// =============================

function mapStateToProps({ modals, config }) {
  return {
    config: config.data,
    opened: modals.isUserMenuOpen,
  };
}

export default connect(mapStateToProps, {
  openAccountModal: () => setAccountOpen(true),
  closeUserMenu: () => setUserMenuOpen(false),
  logout,
})(ModalUserMenu);
