// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setLegalOpen } from '../../../../store/actions/ModalsActions';

import ModalLegal from '../../../layouts/modals/legal';

// =============================
// Component
// =============================

function mapStateToProps({ modals, config }) {
  return {
    config: config.data,
    legal: config.legal.data,
    opened: modals.isLegalOpen,
  };
}

export default connect(mapStateToProps, {
  closeLegalModal: () => setLegalOpen(false),
})(ModalLegal);
