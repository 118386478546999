// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import { WrapIfDesktop } from '../../../other/responsive';

import { getCatalogCoverUrl, getCatalogName } from '../../../../helpers/entity/catalog';
import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import {
  Wrapper,
  Content,
  ModalCover,
  Informations,
  DefaultModal,
  StyledScrollBar,
  Title,
  TitleWrapper,
  TextWithLineBreaks,
} from '../styles';

// =============================
// Stories
// =============================

class ModalCatalog extends PureComponent {
  static propTypes = {
    /** Catalog */
    catalog: pth.catalog.isRequired,
    /** Function used to close modal */
    closeCatalogDescModal: PropTypes.func.isRequired,
    /** Locale */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const {
      catalog,
      closeCatalogDescModal,
      i18n: { language },
      opened,
    } = this.props;

    if (!catalog) return null;

    const description = getValueByLocale(catalog.descriptions, language);

    return (
      <DefaultModal bottomCloseBtn name="catalog" onClose={closeCatalogDescModal} opened={opened} wideContent>
        <Wrapper>
          <Informations>
            <ModalCover
              placeholderType="catalog"
              type="catalog"
              src={getCatalogCoverUrl(catalog)}
              lazyload={false}
              alt={getCatalogName(catalog)}
            />
          </Informations>
          <Content>
            <WrapIfDesktop Wrapper={<StyledScrollBar />}>
              <TitleWrapper>
                <Title>{getCatalogName(catalog)}</Title>
              </TitleWrapper>
              <TextWithLineBreaks>{description}</TextWithLineBreaks>
            </WrapIfDesktop>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation()(ModalCatalog);
