// =============================
// Imports
// =============================

import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import Header from '../../containers/header';
import Menu from '../../containers/menu';
import MenuButton from '../../containers/menuButton';
import ModalAccount from '../../containers/modals/account';
import ModalAddToPlaylist from '../../containers/modals/addtoplaylist';
import ModalAlbum from '../../containers/modals/album';
import ModalArtist from '../../containers/modals/artist';
import ModalCatalog from '../../containers/modals/catalog';
import ModalCookie from '../../containers/modals/cookie';
import ModalEntityActions from '../../containers/modals/entityactions';
import ModalLegal from '../../containers/modals/legal';
import ModalMusicItem from '../../containers/modals/musicitem';
import ModalMusicPlayer from '../../containers/modals/musicplayer';
import ModalPlaylist from '../../containers/modals/playlist';
import ModalPitch from '../../containers/modals/pitch';
import ModalTrackArtists from '../../containers/modals/trackartists';
import ModalTrackLyrics from '../../containers/modals/trackLyrics';
import ModalTrackOwnerships from '../../containers/modals/trackownerships';
import ModalTrackTags from '../../containers/modals/tracktags';
import ModalTrackVersions from '../../containers/modals/trackversions';
import ModalUserMenu from '../../containers/modals/usermenu';
import ModalUserPlaylist from '../../containers/modals/userplaylist';
import ModalVideo from '../../containers/modals/video';
import Background, { BackgroundDefs } from './background';
import SearchBarModal from '../../other/searchBarModal';

import { Content, RouteContainer } from './styles';
import { Desktop, Mobile } from '../../other/responsive';

// =============================
// Component
// =============================

class AppShell extends PureComponent {
  static propTypes = {
    /** Page Content */
    children: PropTypes.node,
    /** Function to close all modals */
    closeAllModals: PropTypes.func.isRequired,
    /** If true, we are on search page  */
    isSearchPage: PropTypes.bool.isRequired,
    /** Is simple layout (auth pages) */
    isSimpleLayout: PropTypes.bool.isRequired,
    /** When key change, toggle enter/exit animation. Correspond to router location. */
    locationKey: PropTypes.string.isRequired,
    /** Current route. */
    routeKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: null,
  };

  componentDidUpdate(prevProps) {
    const { closeAllModals, locationKey } = this.props;

    if (prevProps.locationKey !== locationKey) {
      if (window && window.scrollTo) window.scrollTo(0, 0);

      if (document) {
        if (document.getElementById('global-content')) {
          document.getElementById('global-content').scrollTo(0, 0);
        }

        if (document.getElementById('fullscreen-wrapper')) {
          document.getElementById('fullscreen-wrapper').scrollTo(0, 0);
        }
      }

      closeAllModals();
    }
  }

  render() {
    const { children, isSearchPage, isSimpleLayout, routeKey } = this.props;

    return (
      <Fragment>
        <BackgroundDefs />
        {!isSimpleLayout && <Background />}

        <Header isSimpleLayout={isSimpleLayout} filterBarOpened={isSearchPage} />

        <Desktop>
          <SearchBarModal isSimpleLayout={isSimpleLayout} />

          {!isSimpleLayout ? (
            <Fragment>
              <ModalAccount />
              <ModalTrackLyrics />
              <ModalUserPlaylist />
              <ModalAlbum />
              <ModalArtist />
              <ModalCatalog />
              <ModalPlaylist />
              <ModalPitch />
              <ModalVideo />
            </Fragment>
          ) : (
            <Fragment>
              <ModalLegal />
              <ModalCookie />
            </Fragment>
          )}

          <Menu />
          <MenuButton isSimpleLayout={isSimpleLayout} filterBarOpened={isSearchPage} />
        </Desktop>

        <Mobile>
          <Menu />
        </Mobile>

        <PoseGroup style={{ height: '100%', width: '100%' }}>
          <RouteContainer key={routeKey} noOpacityChange={isSimpleLayout}>
            <Content id="global-content" key={routeKey}>
              {children}
            </Content>
          </RouteContainer>
        </PoseGroup>

        <Mobile>
          {!isSimpleLayout ? (
            <Fragment>
              <ModalAccount />
              <ModalAlbum />
              <ModalArtist />
              <ModalCatalog />
              <ModalEntityActions />
              <ModalMusicItem />
              <ModalPlaylist />
              <ModalPitch />
              <ModalUserMenu />
              <ModalUserPlaylist />
              <ModalVideo />
              <SearchBarModal isSimpleLayout={isSimpleLayout} />
              {/** Player must be above search bar */}
              <ModalMusicPlayer />

              {/**
                Those modals are 'secondary' modals.
                In the DOM, they need to be below 'primary' modals.
                This way they will be above 'primary' modals.
              */}
              <ModalAddToPlaylist />
              <ModalTrackArtists />
              <ModalTrackLyrics />
              <ModalTrackOwnerships />
              <ModalTrackTags />
              <ModalTrackVersions />
            </Fragment>
          ) : (
            <Fragment>
              <ModalCookie />
              <ModalLegal />
            </Fragment>
          )}
        </Mobile>
      </Fragment>
    );
  }
}

export default AppShell;
