// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../../config/i18n';

import Cookies from '../../cookies';

import { AuthModal } from '../styles';

// =============================
// Layout
// =============================

class ModalCookies extends PureComponent {
  static propTypes = {
    /** Function used to close modal */
    closeCookiesModal: PropTypes.func.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Translations */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    opened: false,
  };

  render() {
    const { opened, closeCookiesModal, ...rest } = this.props;

    return (
      <AuthModal bottomCloseBtn name="cookies" noFade onClose={closeCookiesModal} opened={opened}>
        <Cookies modalInjection {...rest} />
      </AuthModal>
    );
  }
}

export default withTranslation('pages')(ModalCookies);
