// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Helpers
import { getDisplayArtistName } from './artist';
import { getGenericDocCoverUrl } from './common';

// =============================
// Helpers
// =============================

/**
 * Get cover url from an album
 * @param {object} album - Album object
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getAlbumCoverUrl = getGenericDocCoverUrl;

/**
 * Get display artists from an album
 * @param {object} album - Album object
 * @param {boolean} asString - If true, will return a string, else return an array
 * @return {array|string} - Array or String of Artists
 */
export const getAlbumDisplayArtists = (album, asString = false) => {
  let displayArtists = _get(album, 'displayArtists', []) || [];

  displayArtists = displayArtists.map(da => ({
    id: da.artist.id,
    name: getDisplayArtistName(da),
  }));

  if (asString) {
    return displayArtists.map(artist => artist.name).join(', ');
  }

  return displayArtists;
};

/**
 * Get album title
 * @param {object} album - Album object
 * @return {string} - Album title
 */
export const getAlbumTitle = album => _get(album, 'title');
