// =============================
// Imports
// =============================

import styled from 'styled-components';
import ReactPlayer from 'react-player';

import Cover from '../../../containers/cover';
import Link from '../../../other/link';

import { Wrapper } from '../styles';

import { paddingX } from '../../../other/pagewrapper/mobile/variables';

// =============================
// Styles
// =============================

export const StyledWrapper = styled(Wrapper)`
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${({ theme }) => theme.mediaBelow.minWidth`
    align-self: center;
    padding: ${paddingX};
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    padding: 0 10rem;
  `};
`;

export const Informations = styled.div`
  align-items: flex-start;
  background: #ffffff;
  color: #474748;
  display: flex;
  padding: 1.2rem;
  width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;
  `};
`;

export const VideoInfo = styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    min-width: 0;

    &:not(:last-child) {
      margin-right: auto;
    }
  `};
`;

export const VideoTitle = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  ${({ theme }) => theme.mediaBelow.minWidth`
    font-size: 1.8rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 2rem;
  `};
`;

export const VideoDescription = styled.div`
  color: #797979;
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1.8rem;
  overflow: hidden;
`;

export const TrackInfo = styled.div`
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: row-reverse;
    max-width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-left: 2rem;
    max-width: 40%;
  `};
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ theme }) => theme.mediaBelow.minWidth`
    align-items: flex-start;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    align-items: flex-end;
  `};
`;

export const TrackTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  max-width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    ${theme.helpers.textEllipsis};
    font-size: 1.4rem;
    text-align: left;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    font-size: 1.6rem;
    text-align: right;
  `};
`;

export const TrackAuthors = styled.div`
  ${({ theme }) => theme.helpers.textEllipsis};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  max-width: 100%;

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-top: 0.4rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-top: 0.8rem;
  `};
`;

export const GoToAlbumLink = styled(Link).attrs(() => ({
  inline: true,
  noHover: false,
}))`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-top: 1.2rem;
  text-align: right;
`;

export const AlbumCover = styled(Cover).attrs({
  classNames: {
    title: 'titleClassName',
    type: 'typeClassName',
  },
  lazyload: false,
  LinkComponent: Link,
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  placeholderType: 'album',
  playButtonSize: 'musicItem',
  type: 'album',
})`

  ${({ theme }) => theme.mediaBelow.minWidth`
    margin-right: 0.8rem;
    width: 6.5rem;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin-left: 2rem;
    width: 10rem;
  `};
`;

export const VideoContainer = styled.div`
  /** 16:9 is the standard video format on YouTube. */
  padding-top: 56.25%;
  position: relative;
  width: 100%;
`;

export const VideoPlayer = styled(ReactPlayer).attrs(() => ({
  playing: true,
  /*
    Set to true or false to display native player controls.
    For Vimeo videos, hiding controls must be enabled by the video owner.
  */
  controls: true,
  height: '100%',
  width: '100%',
  config: {},
}))`
  left: 0;
  position: absolute;
  top: 0;
`;

export const KeepGoodSize = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  max-width: 60rem;
  width: 100%;

  ${({ theme }) => theme.mediaAbove.sxga`
    max-width: 70rem;
  `};

  ${({ theme }) => theme.mediaAbove.wxgaplus`
    max-width: 90rem;
  `};
`;

export const Container = styled.div`
  width: 100%;
`;

export const StyledLink = styled(Link)`
  display: inline;
  /** Used for text-ellipsis. */
  max-width: 100%;

  &:hover {
    text-decoration: underline;
  }
`;
