// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import {
  getApiUrl,
  getXPreferredLanguage,
  snakeCaseKeysDeep,
  camelCaseKeysDeep,
} from '../../helpers/misc';
import determineError from '../../helpers/errors';

// =============================
// Actions
// =============================

export function setUserPitchToken(token = '') {
  return {
    type: acts.SET_USER_PITCH_TOKEN,
    payload: token,
  };
}

export function subscribeToNewsletter(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.SUBSCRIBE_TO_NEWSLETTER_LOADING,
    });

    try {
      await axios({
        method: 'post',
        url: getApiUrl('public/newsletter'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: snakeCaseKeysDeep(data),
      });

      return dispatch({
        type: acts.SUBSCRIBE_TO_NEWSLETTER_SUCCESS,
        payload: {
          message: i18n.t('pages:custom_page.modules.newsletter.subscribe_success'),
        },
      });
    } catch (err) {
      let errorMsg;

      switch (true) {
        case err.response && err.response.status === 406:
          errorMsg = i18n.t('errors:newsletter.cannot_subscribe_user');
          break;

        default:
          errorMsg = determineError(err);
          break;
      }

      return dispatch({
        type: acts.SUBSCRIBE_TO_NEWSLETTER_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function modifyUserBase(data, options) {
  return async (dispatch, getState) => {
    dispatch({
      type: options.loadingType,
    });

    try {
      const response = await axios({
        method: 'put',
        url: getApiUrl('public/users'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': getState().user.token,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: snakeCaseKeysDeep(data),
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      return dispatch({
        type: options.successType,
        payload: {
          message: i18n.t('components:modal_account.account_modified'),
        },
      });
    } catch (err) {
      let errorMsg;

      switch (true) {
        case err.response && err.response.data.key === 'email_already_exists':
          errorMsg = i18n.t('errors:auth.email_already_taken');
          break;

        case err.response && err.response.data.key === 'wrong_credentials':
          errorMsg = i18n.t('errors:auth.bad_password');
          break;

        default:
          errorMsg = determineError(err);
          break;
      }

      return dispatch({
        type: options.failureType,
        payload: {
          message: errorMsg,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function modifyUserInfo(data) {
  const nextData = _pick(data, [
    'firstName',
    'lastName',
    'email',
    'companyName',
    'phoneNumber',
    'country',
    'industry',
    'withNewsletter',
  ]);

  return modifyUserBase(nextData, {
    loadingType: acts.MODIFY_USER_LOADING,
    successType: acts.MODIFY_USER_SUCCESS,
    failureType: acts.MODIFY_USER_FAILURE,
  });
}

export function modifyUserLanguage(nextLanguage) {
  return modifyUserBase({ language: nextLanguage }, {
    loadingType: acts.MODIFY_USER_LOADING,
    successType: acts.MODIFY_USER_SUCCESS,
    failureType: acts.MODIFY_USER_FAILURE,
  });
}

export function modifyUserPassword(data) {
  const nextData = _pick(data, ['password', 'currentPassword']);

  return modifyUserBase(nextData, {
    loadingType: acts.MODIFY_USER_PASSWORD_LOADING,
    successType: acts.MODIFY_USER_PASSWORD_SUCCESS,
    failureType: acts.MODIFY_USER_PASSWORD_FAILURE,
  });
}

export function getRealtimeChannels() {
  return (dispatch, getState) => {
    dispatch({
      type: acts.GET_REALTIME_CHANNELS_LOADING,
    });

    return axios({
      method: 'get',
      url: getApiUrl('public/realtime/channels'),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Auth': getState().user.token,
        'x-host': getState().core.serverContext.xHost,
        'x-preferred-language': getXPreferredLanguage(),
      },
    })
      .then((response) => {
        dispatch({
          type: acts.SET_REALTIME_AUTH_KEY,
          payload: response.headers['x-realtime-auth-key'],
        });

        dispatch({
          type: acts.SET_REALTIME_CHANNELS,
          payload: response.data,
        });

        return dispatch({
          type: acts.GET_REALTIME_CHANNELS_SUCCESS,
        });
      })
      .catch(err => dispatch({
        type: acts.GET_REALTIME_CHANNELS_FAILURE,
        payload: {
          message: determineError(err),
          reqId: _get(err, 'response.data.reqId'),
        },
      }),
      );
  };
}
