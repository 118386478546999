// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { setMenuOpen, resetModals } from '../../../store/actions/ModalsActions';

import Menu from '../../presentationals/menu';

import { hasAccessToPrivate } from '../../../helpers/user';

// =============================
// Component
// =============================

class MenuContainer extends PureComponent {
  static propTypes = {
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
  };

  render() {
    const { hasAccess, ...props } = this.props;

    if (!hasAccess) return null;

    return <Menu {...props} />;
  }
}

function mapStateToProps({ config, modals, user, userplaylists }) {
  return {
    availableLanguages: _get(config, 'data.languages', []),
    blogUrl: _get(config, 'data.blogUrl', null),
    config: config.data,
    data: config.menus.data,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    isLogged: user.isLogged,
    nbPlaylists: _get(userplaylists, 'list.data', []).length,
    opened: modals.isMenuOpen,
    user: user.data,
  };
}

export default connect(mapStateToProps, {
  closeMenu: () => resetModals(),
  toggleMenu: open => setMenuOpen(open),
})(MenuContainer);
