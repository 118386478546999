// =============================
// Imports
// =============================

import PropTypes from 'prop-types';

import { Wrapper, Title, Description, Error404Icon } from './styles';

// =============================
// Page
// =============================

const Error = ({ message, title, icon, isSimpleLayout }) => (
  <Wrapper>
    {title && <Title isSimpleLayout={isSimpleLayout}>{title}</Title>}
    <Description isSimpleLayout={isSimpleLayout}>{message}</Description>
    {icon === 'NOT_FOUND' && <Error404Icon isSimpleLayout={isSimpleLayout} />}
  </Wrapper>
);

Error.propTypes = {
  icon: PropTypes.string,
  isSimpleLayout: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Error.defaultProps = {
  icon: undefined,
  title: undefined,
};

export default Error;
