// =============================
// Imports
// =============================

import styled from 'styled-components';

import { Dropdown, Option } from '../../presentationals/dropdown';

// =============================
// Styles
// =============================

export const StyledDropdown = styled(Dropdown).attrs(({ placement }) => ({
  placement: placement || 'bottom',
  withoutStyles: true,
}))``;

export const BaseOption = styled(Option)``;

export const OptionAsLink = styled(Option).attrs({
  as: 'a',
})`
  display: block;
`;

export const NoResult = styled(Option).attrs({
  noHover: true,
})``;
