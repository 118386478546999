// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import UserLanguages from '../../presentationals/userLanguages';

import { modifyUserLanguage } from '../../../store/actions/UserActions';

// =============================
// Component
// =============================

function mapStateToProps({ user, core, config }) {
  return {
    isLogged: user.isLogged,
    config: config.data,
    locationProtocol: core.serverContext.locationProtocol,
  };
}

export default connect(mapStateToProps, {
  modifyUserLanguage,
})(UserLanguages);
