// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import _snakeCase from 'lodash/snakeCase';
import _find from 'lodash/find';

// Config
import * as miscConfig from '../config/misc';

// Helpers
import { camelCaseKeysDeep } from './misc';

// =============================
// Helpers
// =============================

// A few terms here:
// Redux Search Object - search representation in Redux state
// API Search Object - search object sent to API as data
// Filter - search object consumed by FilterBar component
// Query String - search object in query string format for url bar
// Query Object - search object in query parsed as object(provided by next)
//
// Redux Search Object is the main, most comprehensive representation, the rest
// are projections for different consumers

export const arrayCompactGet = (obj, key, split) => _compact(_get(obj, key, '').split(split));

export const arrayWithNamesGet = (obj, key) => (obj[key] ? JSON.parse(obj[key]) : undefined);

export const searchIdGet = searchId => searchId && JSON.parse(searchId);

export const trackIdGet = trackId => trackId && JSON.parse(trackId);

export const searchLyricsGet = searchLyrics => searchLyrics && JSON.parse(searchLyrics);

// Transforms Query Object to Redux Search Object
export const transformQueryToSearch = _query => ({
  query: _get(_query, 'query', ''),
  options: _omitBy(
    {
      filters: _omitBy(
        {
          catalogs: arrayWithNamesGet(_query, 'catalogs'),
          playlists: arrayWithNamesGet(_query, 'playlists'),
          artists: arrayWithNamesGet(_query, 'artists'),
          albums: arrayWithNamesGet(_query, 'albums'),
          ref_id: arrayWithNamesGet(_query, 'ref_id'),
          labels: arrayWithNamesGet(_query, 'labels'),
          publishers: arrayWithNamesGet(_query, 'publishers'),
          // NOTE: For these values, min and max should be separated by colon
          year: arrayCompactGet(_query, 'year', ':'),
          bpm: arrayCompactGet(_query, 'bpm', ':'),
          duration: arrayCompactGet(_query, 'duration', ':'),
          // NOTE: Since we are using query params, the boolean will be as a string
          stems: _get(_query, 'stems', 'false') === 'true',
          // NOTE: Tags that are included one by one
          tags: arrayCompactGet(_query, 'tags', ','),
          // NOTE: Tag sub categories that are included
          tags_or: arrayCompactGet(_query, 'tags_or', ','),
          // NOTE: Tags that are excluded one by one or by sub category
          tags_not: arrayCompactGet(_query, 'tags_not', ','),
          // NOTE: Versions that are included one by one
          versions: arrayCompactGet(_query, 'versions', ','),
          // NOTE: Versions that are excluded one by one
          versions_not: arrayCompactGet(_query, 'versions_not', ','),
        },
        value => (Array.isArray(value) ? _isEmpty(value) : !value),
      ),
      search_lyrics: searchLyricsGet(_get(_query, 'search_lyrics', '')),
      searchId: searchIdGet(_get(_query, 'search_id', '')),
      trackId: trackIdGet(_get(_query, 'track_id', '')),
      size: miscConfig.MUSIC_ITEMS_PER_PAGE,
    },
    e => !e,
  ),
});

export const setQueryFilterArray = (queryParams, filter, key, split = ',') => {
  if (!filter[key] || !filter[key].length) return;

  queryParams.append(_snakeCase(key), filter[key].join(split));
};

export const setQueryFilterArrayWithNames = (queryParams, search, key) => {
  const filter = _get(search, `options.filters[${key}]`);
  if (!filter) return;
  queryParams.append(_snakeCase(key), JSON.stringify(filter));
};

// Joins Redux Search Object & Filter and transforms to Query String

export const composeQuery = (_search, _filter) => {
  const queryParams = new URLSearchParams();

  // NOTE: Although FilterBar sends value in camelCase, it's not true elsewhere
  // This is used to ensure everything goes well
  const search = camelCaseKeysDeep(_search);
  const filter = camelCaseKeysDeep(_filter);

  if (search.query) queryParams.append('query', search.query);

  const searchId = _get(search, 'options.searchId');

  if (searchId) {
    queryParams.append('search_id', JSON.stringify(searchId));
  }

  const trackId = _get(search, 'options.trackId');

  if (trackId) {
    queryParams.append('track_id', JSON.stringify(trackId));
  }

  setQueryFilterArrayWithNames(queryParams, search, 'catalogs');
  setQueryFilterArrayWithNames(queryParams, search, 'playlists');
  setQueryFilterArrayWithNames(queryParams, search, 'artists');
  setQueryFilterArrayWithNames(queryParams, search, 'albums');
  setQueryFilterArrayWithNames(queryParams, search, 'ref_id');
  setQueryFilterArrayWithNames(queryParams, search, 'labels');

  if (filter.stems) queryParams.append('stems', true);
  if (filter.searchLyrics) queryParams.append('search_lyrics', JSON.stringify(filter.searchLyrics));

  setQueryFilterArray(queryParams, filter, 'duration', ':');
  setQueryFilterArray(queryParams, filter, 'year', ':');
  setQueryFilterArray(queryParams, filter, 'bpm', ':');
  setQueryFilterArray(queryParams, filter, 'tagsOr');
  setQueryFilterArray(queryParams, filter, 'tags');
  setQueryFilterArray(queryParams, filter, 'tagsNot');
  setQueryFilterArray(queryParams, filter, 'versions');
  setQueryFilterArray(queryParams, filter, 'versionsNot');

  return queryParams.toString();
};

const defaultFilter = {
  tags: [],
  tagsOr: [],
  tagsNot: [],

  versions: [],
  versionsNot: [],

  duration: undefined,
  year: undefined,
  bpm: undefined,

  stems: false,

  searchLyrics: undefined,
};

// Transforms Redux Search Object to Filter

export const transformSearchToFilter = (search) => {
  if (_isEmpty(search)) return defaultFilter;

  return Object.keys(defaultFilter).reduce((acc, k) => {
    const filters = _get(search, 'options.filters', {});
    const value = filters[_snakeCase(k)];
    if (!value) return acc;
    return { ...acc, [k]: value };
  }, defaultFilter);
};

const transformTagsOr = (search, { tags }) => {
  if (!search.tags_or) return undefined;
  const tagsSubCategories = tags.reduce((acc, e) => [...acc, ...e.subCategories], []);

  // tags_or can only be sub categories
  return tagsSubCategories.reduce((acc, e) => {
    if (!search.tags_or.includes(e.id)) return acc;

    return [...acc, e.tags.map(t => t.id)];
  }, []);
};

const transformTagsNot = (search, { tags }) => {
  if (!search.tags_not) return undefined;

  const tagsNot = [];

  const tagsSubCategories = tags.reduce((acc, e) => [...acc, ...e.subCategories], []);

  // Tags not can be tags or sub categories
  const tagsNotSubCategories = [];

  search.tags_not.forEach((t) => {
    if (_find(tagsSubCategories, sc => sc.id === t)) {
      tagsNotSubCategories.push(t);
    } else {
      tagsNot.push(t);
    }
  });

  if (tagsNotSubCategories.length) {
    const tagsNotFromSubCategories = tagsSubCategories.reduce((acc, e) => {
      if (!tagsNotSubCategories.includes(e.id)) return acc;

      return [...acc, ...e.tags.map(t => t.id)];
    }, []);

    return [...tagsNot, ...tagsNotFromSubCategories];
  }

  return tagsNot;
};

const transformArrayWithNames = (search, key) => {
  if (!search[key]) return undefined;
  return search[key].map(e => e[0]);
};

// Transforms Redux Search Object to API Search Object

export const transformSearchToAPISearch = (search, { tags }) => {
  const filters = _get(search, 'options.filters', {});

  return {
    ...filters,
    catalogs: transformArrayWithNames(filters, 'catalogs'),
    playlists: transformArrayWithNames(filters, 'playlists'),
    artists: transformArrayWithNames(filters, 'artists'),
    albums: transformArrayWithNames(filters, 'albums'),
    ref_id: transformArrayWithNames(filters, 'ref_id'),
    labels: transformArrayWithNames(filters, 'labels'),
    publishers: transformArrayWithNames(filters, 'publishers'),
    tags_or: transformTagsOr(filters, { tags }),
    tags_not: transformTagsNot(filters, { tags }),
  };
};

export const transformTotalValueToString = (total) => {
  if (!total.value) return 0;
  if (total.relation === 'eq') return total.value;
  return `${total.value} +`;
};
