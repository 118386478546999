// =============================
// Imports
// =============================

import { PureComponent } from 'react';

import Responsive from '../../other/responsive';

import SearchBarDesktop from './desktop';
import SearchBarMobile from './mobile';

// =============================
// Component
// =============================

class SearchBar extends PureComponent {
  static displayName = 'SearchBarResponsive';

  render() {
    return (
      <Responsive
        DesktopComponent={SearchBarDesktop}
        MobileComponent={SearchBarMobile}
        props={this.props}
      />
    );
  }
}

export default SearchBar;
