// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { pushLatestNotification } from '../store/actions/PagesActions';

import getClient from '../helpers/realtime';
import download from '../helpers/download';
import * as pth from '../helpers/proptypes';

// =============================
// Page
// =============================

class Realtime extends Component {
  listener = {
    message: (msg) => {
      const {
        originalRealtimeChannels,
        pushLatestNotification: storePushLatestNotification,
      } = this.props;

      // =============================
      // Notifications
      // =============================

      if (msg.channel === originalRealtimeChannels.notifications) {
        switch (_get(msg, 'message.type')) {
          case 'PUBLIC_ARCHIVE': {
            if (_get(msg, 'message.status') === 'COMPLETED' && _get(msg, 'message.data.file')) {
              download(_get(msg, 'message.data.file'));
            }

            storePushLatestNotification(_get(msg, 'message', {}));
            break;
          }

          default:
            storePushLatestNotification(_get(msg, 'message', {}));
            break;
        }
      }
    },
  };

  static propTypes = {
    /** Location protocol, like https. */
    locationProtocol: PropTypes.string.isRequired,
    userData: pth.user,
    originalRealtimeChannels: PropTypes.objectOf(PropTypes.string),
    realtimeChannels: PropTypes.arrayOf(PropTypes.string),
    realtimeAuthKey: PropTypes.string,
    pushLatestNotification: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    userData: {},
    originalRealtimeChannels: {},
    realtimeChannels: [],
    realtimeAuthKey: null,
  };

  componentDidMount() {
    const { realtimeAuthKey, realtimeChannels } = this.props;

    if (realtimeAuthKey && realtimeChannels.length) {
      this.subscribe();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      realtimeAuthKey: oldRealtimeAuthKey,
      realtimeChannels: oldRealtimeChannels,
    } = prevProps;

    const { realtimeAuthKey, realtimeChannels } = this.props;

    const wasValid = !!(oldRealtimeAuthKey && oldRealtimeChannels.length);
    const isValid = !!(realtimeAuthKey && realtimeChannels.length);

    if (!wasValid && isValid) {
      this.subscribe();
    }

    if (wasValid && !isValid) {
      this.unsubscribe();
    }
  }

  componentWillUnmount() {
    const { realtimeAuthKey, realtimeChannels } = this.props;

    if (realtimeAuthKey && realtimeChannels.length) {
      this.unsubscribe();
    }
  }

  subscribe = () => {
    const { locationProtocol, userData, realtimeAuthKey, realtimeChannels } = this.props;

    const client = getClient(locationProtocol, userData.id, realtimeAuthKey);

    client.addListener(this.listener);

    client.subscribe({
      channels: realtimeChannels,
    });
  };

  unsubscribe = () => {
    const { locationProtocol, realtimeChannels } = this.props;

    const client = getClient(locationProtocol);

    client.removeListener(this.listener);

    client.unsubscribe({
      channels: realtimeChannels,
    });
  };

  render() {
    const { children } = this.props;

    return children;
  }
}

function mapStateToProps({ core, user }) {
  return {
    /** Location protocol, like https. */
    locationProtocol: core.serverContext.locationProtocol,
    userData: user.data,
    realtimeChannels: Object.values(user.realtimeChannels),
    originalRealtimeChannels: user.realtimeChannels,
    realtimeAuthKey: user.realtimeAuthKey,
  };
}

export default connect(mapStateToProps, {
  pushLatestNotification,
})(Realtime);
