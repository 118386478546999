// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _get from 'lodash/get';

import ModalMusicItem from '../../../layouts/modals/musicitem';

import { addToFavorites, removeFromFavorites } from '../../../../store/actions/FavoritesActions';
import {
  setAddToPlaylistOpen,
  setMusicItemOpen,
  setTrackArtistsOpen,
  setTrackLyricsOpen,
  setTrackOwnershipsOpen,
  setTrackTagsOpen,
  setTrackVersionsOpen,
} from '../../../../store/actions/ModalsActions';
import { removeTrackFromPlaylist } from '../../../../store/actions/UserPlaylistsActions';

import { isUserOwnedPlaylist } from '../../../../helpers/entity/playlist';

// =============================
// Component
// =============================

function mapStateToProps({ modals, user, userplaylists }) {
  return {
    isLogged: _get(user, 'isLogged', false),
    playlistIsOwnedByUser: isUserOwnedPlaylist(user.data, userplaylists.playlist.data),
    opened: modals.musicItem.isOpen,
    track: modals.musicItem.data,
  };
}

export default connect(mapStateToProps, {
  addToFavorites,
  closeModal: () => setMusicItemOpen(false),
  openAddToPlaylistModal: setAddToPlaylistOpen,
  openTrackArtistsModal: setTrackArtistsOpen,
  openTrackLyricsModal: setTrackLyricsOpen,
  openTrackTagsModal: setTrackTagsOpen,
  openTrackOwnershipsModal: setTrackOwnershipsOpen,
  openTrackVersionsModal: setTrackVersionsOpen,
  removeFromFavorites,
  removeTrackFromPlaylist,
})(ModalMusicItem);
