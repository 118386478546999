// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import { withTranslation } from '../../../../config/i18n';

import getViewProps from '../../../../helpers/front/getviewprops';

import { Wrapper, Content, CloseModalBtn } from './styles';

// =============================
// Component
// =============================

class Modal extends Component {
  static displayName = 'ModalMobile';

  static propTypes = {
    bottomCloseBtn: PropTypes.oneOfType([
      PropTypes.shape({
        text: '',
      }),
      PropTypes.bool,
    ]),
    /** Modal content. */
    children: PropTypes.node,
    /** Is search modal */
    isSearch: PropTypes.bool,
    /** Name of the modal, should be uniq, will be used to lock body. */
    name: PropTypes.string.isRequired,
    /** If True, will render mobil modal without background. */
    noBackground: PropTypes.bool,
    /** If True, no fade effect will be applied on modal bottom. */
    noBottomFade: PropTypes.bool,
    /** Disable background fade. */
    noFade: PropTypes.bool,
    /** If True, will render mobile modal without top masking zone. */
    noTopMask: PropTypes.bool,
    /** Callback function use to close Modal. */
    onClose: PropTypes.func,
    /** If True, Modal is opened. */
    opened: PropTypes.bool,
    /** If True, musicPlayer is opened. Used for presearch modal only. */
    playerIsOpened: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bottomCloseBtn: false,
    children: null,
    isSearch: false,
    noBackground: false,
    noBottomFade: false,
    noFade: false,
    noTopMask: false,
    onClose: null,
    opened: false,
    playerIsOpened: false,
  };

  componentDidMount() {
    const { name, opened } = this.props;

    // This happens when modals are mounted only when they have data (album description modal)
    if (opened) {
      document.body.classList.toggle(`is-locked-${name}`, true);
    }
  }

  componentDidUpdate(prevProps) {
    const { name, opened } = this.props;

    // This happens when modals are always mounted and are waiting to be opened
    if (prevProps.opened === false && opened === true) {
      document.body.classList.toggle(`is-locked-${name}`, true);
    } else if (prevProps.opened === true && opened === false) {
      document.body.classList.toggle(`is-locked-${name}`, false);
    }
  }

  componentWillUnmount() {
    const { name } = this.props;

    document.body.classList.toggle(`is-locked-${name}`, false);
  }

  render() {
    const {
      children,
      bottomCloseBtn,
      isSearch,
      name,
      noBottomFade,
      noFade,
      noBackground,
      noTopMask,
      onClose,
      opened,
      playerIsOpened,
      t,
      ...rest
    } = this.props;

    return (
      <PoseGroup flipMove={false}>
        {opened && (
          <Wrapper
            {...getViewProps(rest)}
            bottomCloseBtn={bottomCloseBtn}
            isSearch={isSearch}
            key="0"
            name={name}
            noBackground={noBackground}
            noFade={noFade}
            noTopMask={noTopMask}
            onClick={noBackground ? onClose : null}
            opened={opened}
            playerIsOpened={playerIsOpened}
          >
            <Content noBottomFade={noBottomFade}>{children}</Content>
            {bottomCloseBtn && (
              <CloseModalBtn onClick={onClose}>{bottomCloseBtn?.text || t('components:modal.close')}</CloseModalBtn>
            )}
          </Wrapper>
        )}
      </PoseGroup>
    );
  }
}

export default withTranslation('components')(Modal);
