// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import ModalTrackOwnerships from '../../../layouts/modals/trackownerships';

import {
  setMusicItemOpen,
  setMusicPlayerOpen,
  setTrackOwnershipsOpen,
} from '../../../../store/actions/ModalsActions';

// =============================
// Component
// =============================

function mapStateToProps({ modals }) {
  return {
    opened: modals.trackOwnerships.isOpen,
    track: modals.trackOwnerships.data,
  };
}

export default connect(mapStateToProps, {
  closeMusicItemModal: () => setMusicItemOpen(false),
  closeMusicPlayerModal: () => setMusicPlayerOpen(false),
  closeTrackOwnershipsModal: () => setTrackOwnershipsOpen(false),
})(ModalTrackOwnerships);
