// =============================
// Imports
// =============================

// Constants
import * as acts from '../constants/ActionTypes';

// Actions
import { updateVersionsRecents } from '../actions/TrackActions';
import {
  updateAlbumRecents,
  updatePlaylistRecents,
  updateArtistRecents,
  updateCatalogRecents,
} from '../actions/EntitiesActions';
import { updateUserPlaylistRecents } from '../actions/UserPlaylistsActions';
import { updateTracksSearchRecents } from '../actions/SearchActions';
import { updatePlayerRecents } from '../actions/PlayerActions';
import { updateModalsRecents } from '../actions/ModalsActions';

// =============================
// Middleware
// =============================

// NOTE: No need to update recent plays tracks
function updateTracksRecentStatus(store, id) {
  store.dispatch(updateModalsRecents(id));
  store.dispatch(updateVersionsRecents(id));
  store.dispatch(updateAlbumRecents(id));
  store.dispatch(updatePlaylistRecents(id));
  store.dispatch(updateArtistRecents(id));
  store.dispatch(updateCatalogRecents(id));
  store.dispatch(updateUserPlaylistRecents(id));
  store.dispatch(updateTracksSearchRecents(id));
  store.dispatch(updatePlayerRecents(id));
}

/**
 * This middleware is used to update tracks everywhere in the application
 * without doing any api requests
 */
const recentsMiddleware = store => next => (action) => {
  switch (true) {
    case action.type === acts.ADD_TO_RECENT_PLAYS_SUCCESS:
      updateTracksRecentStatus(store, action.payload);
      return next(action);

    default:
      return next(action);
  }
};

export default recentsMiddleware;
