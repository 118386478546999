// =============================
// Imports
// =============================

// External dependencies
import Router from 'next/router';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import { encodeAndRemovePercent } from '../../helpers/misc';

// =============================
// Middleware
// =============================

const maiaSearchMiddleware = () => next => (action) => {
  switch (true) {
    case action.type === acts.REQUEST_MAIA_TEXT_SUCCESS:
    case action.type === acts.REQUEST_MAIA_AUDIO_SUCCESS: {
      const query = encodeAndRemovePercent(
        JSON.stringify([action.payload.value, action.payload.displayValue]),
      );

      Router.push(`/search?search_id=${query}`);
      return next(action);
    }

    default:
      return next(action);
  }
};

export default maiaSearchMiddleware;
