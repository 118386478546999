// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';

// Config
import * as miscConfig from '../../config/misc';
import { i18n } from '../../config/i18n';

// Helpers
import { getValueByLocale } from '../i18n';

// =============================
// Helpers
// =============================

/**
 * Get formatted duration
 * @param {number} _duration - Duration in seconds
 * @return {string} - Formatted duration
 */
export function presentDuration(_duration) {
  const duration = _isNumber(+_duration) ? _duration : 0;

  let sec = Math.floor(duration % 60);
  let min = Math.floor((duration / 60) % 60);
  let hour = Math.floor(duration / 60 / 60);

  if (sec < 10) sec = `0${sec}`;
  if (min < 10) min = `0${min}`;
  if (hour > 0 && hour < 10) hour = `0${hour}`;

  if (hour) return `${hour}:${min}:${sec}`;
  return `${min}:${sec}`;
}

/**
 * Get textual formatted duration
 * @param {number} _duration - Duration in seconds
 * @return {string} - Formatted duration
 */
export function presentDurationEntity(_duration) {
  const duration = _isNumber(_duration) ? _duration : 0;

  const sec = Math.floor(duration % 60);
  const min = Math.floor((duration / 60) % 60);
  const hour = Math.floor((duration / 60 / 60) % 24);
  const day = Math.floor(duration / 60 / 60 / 24);

  if (day) {
    return `${day} ${i18n.t('common:time.day', { count: day })} ${hour} ${i18n.t(
      'common:time.hour',
      { count: hour },
    )} ${min} ${i18n.t('common:time.minute', { count: min })} ${sec} ${i18n.t(
      'common:time.second',
      { count: sec },
    )}`;
  }
  if (hour) {
    return `${hour} ${i18n.t('common:time.hour', {
      count: hour,
    })} ${min} ${i18n.t('common:time.minute', { count: min })} ${sec} ${i18n.t(
      'common:time.second',
      { count: sec },
    )}`;
  }
  if (min) {
    return `${min} ${i18n.t('common:time.minute', {
      count: min,
    })} ${sec} ${i18n.t('common:time.second', { count: sec })}`;
  }
  return `${sec} ${i18n.t('common:time.second', { count: sec })}`;
}

/**
 * Get a possibly shortened description
 * @param {object[]} descriptions - List of descriptions
 * @param {string} locale - Locale of the description
 * @param {string} charLimit - Character limit
 * @return {object} retVal
 * @return {string} retVal.value - Possibly shortened description
 * @return {boolean} retVal.isShortened - True if description was shortened
 */
function shortenDescriptionHelper(descriptions, locale, charLimit = miscConfig.DESC_CHAR_LIMIT) {
  const description = getValueByLocale(descriptions, locale);

  if (!description) {
    return {
      value: '',
      isShortened: false,
    };
  }

  if (description.length < charLimit) {
    return {
      value: description,
      isShortened: false,
    };
  }

  const words = description.slice(0, charLimit).split(' ');
  // remove last word since it's likely to be cut
  const shortened = words.slice(0, words.length - 1).join(' ');

  return {
    value: shortened,
    isShortened: true,
  };
}

/**
 * Shortens a description
 * @param {object[]} descriptions - List of descriptions
 * @param {string} locale - Locale of the description
 * @param {number} charLimit - Character limit
 * @return {string} - Shortened description
 */
export function presentShortDescription(
  descriptions,
  locale,
  charLimit = miscConfig.DESC_CHAR_LIMIT,
) {
  const { value, isShortened } = shortenDescriptionHelper(descriptions, locale, charLimit);
  if (isShortened) return `${value}...`;
  return value;
}

/**
 * Checks if description is too long
 * @param {object[]} descriptions - List of descriptions
 * @param {string} locale - Locale of the description
 * @param {string} charLimit - Character limit
 * @return {boolean}
 */
export function isDescriptionTooLong(descriptions, locale, charLimit = miscConfig.DESC_CHAR_LIMIT) {
  const { isShortened } = shortenDescriptionHelper(descriptions, locale, charLimit);
  return isShortened;
}

/**
 * Get tenant from an entity
 * @param {object} entity - Entity object
 * @return {string} - Cover url
 */
export function getTenant(entity, type = 'tenant') {
  return (
    _get(entity, `${type}.companyName`) || `${_get(entity, `${type}.firstName`)} ${_get(entity, `${type}.lastName`)}`
  );
}

/**
 * Get image url for a document
 * @param {object} doc - Whatever doc
 * @param {string} size - Image size, can be "large", "small" or "xsmall"
 * @return {string} - Cover url
 */
export const getGenericDocCoverUrl = (doc, size = 'large') => {
  switch (size) {
    case 'xsmall':
      return (
        _get(doc, `image[${size}].url`)
        || _get(doc, 'image.small.url')
        || _get(doc, 'image.large.url')
        || _get(doc, 'image.original.url')
      );

    case 'small':
      return (
        _get(doc, `image[${size}].url`)
        || _get(doc, 'image.large.url')
        || _get(doc, 'image.original.url')
      );

    case 'large':
      return _get(doc, `image[${size}].url`) || _get(doc, 'image.original.url');

    default:
      return _get(doc, 'image.original.url');
  }
};

export const tagCategoriesOrder = [
  // genres
  '5b532dd7450f084ddcd8354c',
  // area
  '5b532dd7450f084ddcd83546',
  // periods
  '5b532dd7450f084ddcd83549',
  // structures
  '5b532dd7450f084ddcd83555',
  // mood
  '5b532dd7450f084ddcd83552',
  // style
  '5b532dd7450f084ddcd83558',
  // usage
  '5b532dd7450f084ddcd8355b',
  // instruments
  '5b532dd7450f084ddcd8354f',
  // sound
  '5f22b65c388a470017da62a3',
  // gestures
  '5d35f5dcb42d7b56380dfa63',
  // technics
  '5d356e044ee859003222b01e',
  // nuance
  '5d35908c9fd3b20032f574bf',
  // pal
  '5b59c72cb8986ea19dc40781',
];

/**
 * Get tags by order
 * @param {Object[]} tagsWithCategory
 * @param {string} language - Language alpha sorting
 * @return {Object[]} tagsWithCategory sorted
 */
export function presentTags(tagsWithCategory, language) {
  return _sortBy(
    _compact(tagsWithCategory),
    tag => `${tagCategoriesOrder.indexOf(tag.categoryId)}-${getValueByLocale(tag.names, language)}`,
  );
}
