// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setPreSearchOpen } from '../../../../store/actions/ModalsActions';

import PresearchCloseButton from '../../../layouts/modals/presearch/desktop/modal/closeButton';

// =============================
// Component
// =============================

function mapStateToProps({ config, modals }) {
  return {
    config: config.data,
    opened: modals.isPreSearchOpen,
  };
}

export default connect(mapStateToProps, {
  onClose: () => setPreSearchOpen(false),
})(PresearchCloseButton);
