// =============================
// Imports
// =============================

import { Icons, Waveform } from '@mewo/components';
import styled, { css } from 'styled-components';

// =============================
// Styles
// =============================

export const PlayerBox = styled.div`
  margin-bottom: 4.8rem;
  padding: 0 2rem;
  width: 100%;
`;

export const StyledWaveform = styled(Waveform).attrs(({ theme }) => ({
  color: theme.colors.primary,
  width: '100%',
}))`
  margin-bottom: 0.4rem;
`;

export const CurrentTime = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  justify-content: space-between;
  margin-bottom: 1.2rem;
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 15rem;
`;

export const PrevIcon = styled(Icons.Previous).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '1.2rem',
}))`
  ${({ disabled }) => disabled
    && css`
      pointer-events: none;

      svg path {
        opacity: 0.7;
      }
    `};
`;

export const NextIcon = styled(Icons.Next).attrs(({ theme }) => ({
  fill: theme.colors.text,
  as: Icons.Next,
  width: '1.2rem',
}))`
  ${({ disabled }) => disabled
    && css`
      pointer-events: none;

      svg path {
        opacity: 0.7;
      }
    `};
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '5rem',
}))``;

export const PlayIcon = styled(Icons.PlayOutline).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '5rem',
}))``;
