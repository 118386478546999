// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { setVideoOpen } from '../../../../store/actions/ModalsActions';

import ModalVideo from '../../../layouts/modals/video';

// =============================
// Component
// =============================

function mapStateToProps({ modals, config }) {
  return {
    config: config.data,
    opened: modals.video.isOpen,
    data: modals.video.data,
  };
}

export default connect(mapStateToProps, {
  closeVideoModal: () => setVideoOpen(false),
})(ModalVideo);
