// =============================
// Imports
// =============================

import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import isEmail from 'validator/lib/isEmail';
import { i18n } from '../config/i18n';

// =============================
// Validators
// =============================

export const required = value => (_isNumber(value) || (value && !_isEmpty(value)) ? undefined : i18n.t('validation.required'));

export const minLength = min => value => (!value || value.length >= min ? undefined : i18n.t('validation.min_length', { min }));

export const maxLength = max => value => (!value || value.length <= max ? undefined : i18n.t('validation.max_length', { max }));

export const mustBeNumber = value => (value && typeof +value !== 'number' ? i18n.t('validation.must_be_number') : undefined);

export const minNumber = min => value => (value && (typeof +value !== 'number' || value < min) ? i18n.t('validation.must_be_min_number', { min }) : undefined);

export const maxNumber = max => value => (value && (typeof +value !== 'number' || value > max) ? i18n.t('validation.must_be_max_number', { max }) : undefined);

export const mustBeEmail = value => (!value || (value && isEmail(value)) ? undefined : i18n.t('validation.must_be_email'));

export const mustBeEqualTo = (compareVal, fieldName) => value => (value === compareVal ? undefined : i18n.t('validation.must_be_equal_to', { fieldName }));

export const mustBeComplexPassword = value => (!value || (value && !!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/))
  ? undefined
  : i18n.t('validation.must_be_complex_password'));

export const composeValidators = (...validators) => value => validators
  .reduce((error, validator) => error || validator(value), undefined);
