// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import { toggleDesc, toggleTags } from '../../../store/actions/TrackActions';

import MusicItemOptionsToggler from '../../presentationals/musicitemoptionstoggler';

// =============================
// Component
// =============================

function mapStateToProps({ track }) {
  return {
    descriptionStatus: track.descShown,
    tagStatus: track.tagsShown,
  };
}

export default connect(mapStateToProps, {
  toggleDescription: toggleDesc,
  toggleTag: toggleTags,
})(MusicItemOptionsToggler);
