// =============================
// Entities
// =============================

export const GET_CONFIG_PAGE = 'GET_CONFIG_PAGE';

export const GET_ALBUM = 'GET_ALBUM';
export const GET_ALBUM_TAGS = 'GET_ALBUM_TAGS';
export const GET_ALBUM_TRACKS = 'GET_ALBUM_TRACKS';

export const GET_PLAYLIST = 'GET_PLAYLIST';
export const GET_PLAYLIST_TAGS = 'GET_PLAYLIST_TAGS';
export const GET_PLAYLIST_TRACKS = 'GET_PLAYLIST_TRACKS';

export const GET_PITCH = 'GET_PITCH';
export const GET_PITCH_TAGS = 'GET_PITCH_TAGS';
export const GET_PITCH_TRACKS = 'GET_PITCH_TRACKS';

export const GET_ARTIST = 'GET_ARTIST';
export const GET_ARTIST_TRACKS = 'GET_ARTIST_TRACKS';
export const GET_ARTIST_ALBUMS = 'GET_ARTIST_ALBUMS';
export const GET_ARTIST_ALBUMS_APPEARS_ON = 'GET_ARTIST_ALBUMS_APPEARS_ON';
export const GET_ARTIST_PLAYLISTS_APPEARS_ON = 'GET_ARTIST_PLAYLISTS_APPEARS_ON';

export const GET_CATALOG = 'GET_CATALOG';
export const GET_CATALOG_TRACKS = 'GET_CATALOG_TRACKS';
export const GET_CATALOG_ALBUMS = 'GET_CATALOG_ALBUMS';

// =============================
// Favorites
// =============================

export const GET_FAVORITES = 'GET_FAVORITES';

// =============================
// Recent plays
// =============================

export const GET_RECENT_PLAYS = 'GET_RECENT_PLAYS';

// =============================
// User playlist
// =============================

// List of user playlists
export const GET_USER_PLAYLISTS = 'GET_USER_PLAYLISTS';

// Single user playlist
export const GET_USER_PLAYLIST = 'GET_USER_PLAYLIST';

// User playlist tracks
export const GET_USER_PLAYLIST_TRACKS = 'GET_USER_PLAYLIST_TRACKS';

// =============================
// Search
// =============================

export const GET_TRACKS_SEARCH_DATA = 'GET_TRACKS_SEARCH_DATA';

export const GET_PRE_SEARCH_ENTITIES = 'GET_PRE_SEARCH_ENTITIES';
export const GET_PRE_SEARCH_TRACKS = 'GET_PRE_SEARCH_TRACKS';
export const GET_PRE_SEARCH_TAGS = 'GET_PRE_SEARCH_TAGS';
export const GET_PRE_SEARCH_TAG_CATEGORIES = 'GET_PRE_SEARCH_TAG_CATEGORIES';

export const GET_ENTITY_SEARCH_DATA = 'GET_ENTITY_SEARCH_DATA';

export const REQUEST_MAIA_TEXT = 'REQUEST_MAIA_TEXT';
export const REQUEST_MAIA_AUDIO = 'REQUEST_MAIA_AUDIO';

// =============================
// Player
// =============================

export const GET_PLAYER_MEDIA = 'GET_PLAYER_MEDIA';
export const GET_PLAYER_PLAYLIST = 'GET_PLAYER_PLAYLIST';
