// =============================
// Imports
// =============================

import * as acts from '../constants/ActionTypes';

// =============================
// Initial state
// =============================

export const initialState = {
  descShown: true,
  tagsShown: true,
  isDownloadingAudiofile: false,
  isNotifyingDownload: false,
  versions: {},
  files: {},
};

// =============================
// Reducer
// =============================

export default function track(state = initialState, action) {
  switch (action.type) {
    case acts.SHOW_TRACK_DESC:
      return {
        ...state,
        descShown: action.payload,
      };

    case acts.SHOW_TRACK_TAGS:
      return {
        ...state,
        tagsShown: action.payload,
      };

    case acts.SET_VERSIONS:
      return {
        ...state,
        versions: {
          ...state.versions,
          [action.payload.id]: {
            ...state.versions[action.payload.id],
            data: action.payload.data,
          },
        },
      };

    case acts.GET_VERSIONS_LOADING:
      return {
        ...state,
        versions: {
          ...state.versions,
          [action.payload.id]: {
            ...state.versions[action.payload.id],
            isFetching: true,
          },
        },
      };

    case acts.GET_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: {
          ...state.versions,
          [action.payload.id]: {
            ...state.versions[action.payload.id],
            isFetchedOnce: true,
            isFetching: false,
          },
        },
      };

    case acts.GET_VERSIONS_FAILURE:
      return {
        ...state,
        versions: {
          ...state.versions,
          [action.payload.id]: {
            ...state.versions[action.payload.id],
            isFetchedOnce: false,
            isFetching: false,
          },
        },
      };

    case acts.UPDATE_VERSION_FAVORITES:
      return {
        ...state,
        versions: Object.entries(state.versions).reduce(
          (acc, [k, wrapper]) => ({
            ...acc,
            [k]: {
              ...wrapper,
              data: wrapper.data.map((version) => {
                if (version.id !== action.payload.id) return version;
                return { ...version, isFavorite: action.payload.isFavorite };
              }),
            },
          }),
          {},
        ),
      };

    case acts.UPDATE_VERSION_RECENTS:
      return {
        ...state,
        versions: Object.entries(state.versions).reduce(
          (acc, [k, wrapper]) => ({
            ...acc,
            [k]: {
              ...wrapper,
              data: wrapper.data.map((version) => {
                if (version.id !== action.payload.id) return version;
                return { ...version, recentlyPlayed: true };
              }),
            },
          }),
          {},
        ),
      };

    case acts.SET_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: {
            ...state.files[action.payload.id],
            data: action.payload.data,
          },
        },
      };

    case acts.GET_FILES_LOADING:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: {
            ...state.files[action.payload.id],
            isFetching: true,
          },
        },
      };

    case acts.GET_FILES_SUCCESS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: {
            ...state.files[action.payload.id],
            isFetchedOnce: true,
            isFetching: false,
          },
        },
      };

    case acts.GET_FILES_FAILURE:
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.id]: {
            ...state.files[action.payload.id],
            isFetchedOnce: false,
            isFetching: false,
          },
        },
      };

    case acts.DOWNLOAD_AUDIOFILE_LOADING:
      return {
        ...state,
        isDownloadingAudiofile: true,
      };

    case acts.DOWNLOAD_AUDIOFILE_SUCCESS:
    case acts.DOWNLOAD_AUDIOFILE_FAILURE:
      return {
        ...state,
        isDownloadingAudiofile: false,
      };

    case acts.NOTIFY_FILE_DOWNLOAD_LOADING:
      return {
        ...state,
        isNotifyingDownload: true,
      };

    case acts.NOTIFY_FILE_DOWNLOAD_SUCCESS:
    case acts.NOTIFY_FILE_DOWNLOAD_FAILURE:
      return {
        ...state,
        isNotifyingDownload: false,
      };

    default:
      return state;
  }
}
