// =============================
// Imports
// =============================

// External Dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Config
import { withTranslation } from '../../../config/i18n';

// Components
import Notification from '../notification';

// Styles
import { Wrapper, ClearNotificationsButton } from './styles';

// =============================
// Component
// =============================

class NotificationsList extends PureComponent {
  static propTypes = {
    clearAllNotifications: PropTypes.func.isRequired,
    clearNotification: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        actions: PropTypes.func,
        duration: PropTypes.number,
        message: PropTypes.string,
        notificationKey: PropTypes.string,
        reqId: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { clearAllNotifications, clearNotification, notifications, t } = this.props;

    const activeNotifications = notifications.map(n => (
      <Notification
        clearNotification={clearNotification}
        duration={n.duration}
        key={n.notificationKey}
        message={n.message}
        notificationKey={n.notificationKey}
        reqId={n.reqId}
        t={t}
        type={n.type}
      />
    ));

    return (
      <Wrapper>
        {activeNotifications}
        {activeNotifications.length > 1 && (
          <ClearNotificationsButton onClick={clearAllNotifications}>
            {t('components:notification_list.clear_all')}
          </ClearNotificationsButton>
        )}
      </Wrapper>
    );
  }
}

export default withTranslation('components')(NotificationsList);
