// =============================
// Imports
// =============================

import styled from 'styled-components';

import Tag from '../../../other/tag';

// =============================
// Component
// =============================

export const TagsList = styled.div``;

export const StyledTag = styled(Tag)`
  margin: 0.4rem 0.6rem 0.6rem 0;
`;
