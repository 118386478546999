// =============================
// Imports
// =============================

import { connect } from 'react-redux';
import _get from 'lodash/get';

import ModalMusicPlayer from '../../../layouts/modals/musicplayer';

import { addToFavorites, removeFromFavorites } from '../../../../store/actions/FavoritesActions';
import {
  setAddToPlaylistOpen,
  setMusicPlayerOpen,
  setTrackArtistsOpen,
  setTrackLyricsOpen,
  setTrackOwnershipsOpen,
  setTrackTagsOpen,
  setTrackVersionsOpen,
} from '../../../../store/actions/ModalsActions';
import {
  play,
  pause,
  seek,
  goToNextTrack,
  goToPrevTrack,
} from '../../../../store/actions/PlayerActions';

import * as states from '../../../../store/constants/PlayerStates';

// =============================
// Component
// =============================

function mapStateToProps({ modals, player, user }) {
  return {
    isLogged: _get(user, 'isLogged', false),
    getCurrentTime: player.getCurrentTime,
    hasNext: !!player.next,
    isPlaying: player.playerState === states.PLAYING,
    isPaused: player.playerState === states.PAUSED,
    opened: modals.musicPlayer.isOpen,
    track: player.current,
  };
}

export default connect(mapStateToProps, {
  addToFavorites,
  closeModal: () => setMusicPlayerOpen(false),
  goToNextTrack,
  goToPrevTrack,
  openAddToPlaylistModal: setAddToPlaylistOpen,
  openTrackArtistsModal: setTrackArtistsOpen,
  openTrackLyricsModal: setTrackLyricsOpen,
  openTrackTagsModal: setTrackTagsOpen,
  openTrackOwnershipsModal: setTrackOwnershipsOpen,
  openTrackVersionsModal: setTrackVersionsOpen,
  removeFromFavorites,
  pause,
  play,
  seek,
})(ModalMusicPlayer);
