// =============================
// Imports
// =============================

import { useEffect, useState } from 'react';
import { Context } from 'react-responsive';
import PropTypes from 'prop-types';

// =============================
// Imports
// =============================

const ResponsiveContext = ({ children, initIsMobile }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setInitialized(true);
    }
  }, []);

  const predefinedWidth = initIsMobile ? 1023 : 1024;

  // NOTE: This system allows us to profit from SSR and only render once.
  return (
    <Context.Provider value={initialized ? {} : { width: predefinedWidth }}>
      {children}
    </Context.Provider>
  );
};

ResponsiveContext.propTypes = {
  children: PropTypes.node.isRequired,
  initIsMobile: PropTypes.bool.isRequired,
};

export default ResponsiveContext;
