// =============================
// Imports
// =============================

import { Icons } from '@mewo/components';
import styled, { css } from 'styled-components';
import { svgHoverCirclePath } from '../../../../helpers/front/svg';

import Cover from '../../../containers/cover';

import { musicPlayerHeight } from './variables';

// =============================
// Styles
// =============================

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0 8px 30px 0 ${({ theme }) => theme.colors.boxShadow};
  bottom: 0;
  height: ${musicPlayerHeight};
  left: 0;
  position: fixed;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.scMusicPlayer};

  ${({ hide }) => hide && css`
    display: none;
    pointer-events: none;
  `};
`;

export const ProgressBar = styled.div.attrs(({ progress }) => ({
  styles: {
    width: `${progress}%`,
  },
}))`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 0.2rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
`;

export const Content = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1.2rem;
  grid-template-columns: 4.5rem minmax(7rem, 1fr) 3rem 3.5rem;
  height: 4.5rem;
  padding: 1.2rem;
`;

export const StyledCover = styled(Cover).attrs(() => ({
  noBoxShadow: true,
  noRadius: true,
  noScale: true,
  noTilt: true,
  noTranslateY: true,
  onPause: null,
  onPlay: null,
  placeholderType: 'track',
  size: '4.5rem',
  type: 'track',
}))`
  z-index: 0;
`;

export const TitleAuthors = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const baseTitle = css`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 0.4rem;
`;

export const Title = styled.div`
  ${baseTitle}
`;

export const Authors = styled.span`
  ${({ theme }) => theme.helpers.textEllipsis};
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  opacity: 0.7;
`;

export const Author = styled.div`
  display: inline;
  flex-shrink: 0;
  line-height: 1.2;
`;

export const ThreeDotIcon = styled(Icons.ThreeDot).attrs(({ theme }) => ({
  fill: theme.colors.text,
  width: '2rem',
}))``;

export const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  /** Used to be above OverlayAction. */
  z-index: 2;
`;

export const PauseIcon = styled(Icons.PauseOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const PlayIcon = styled(Icons.PlayOutline).attrs({
  width: '3.5rem',
})`
  ${({ theme }) => svgHoverCirclePath(theme.colors.text, theme.colors.background)};
`;

export const OverlayAction = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;
