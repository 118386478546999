// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import _get from 'lodash/get';

import { withTranslation } from '../../../config/i18n';

import { composeQuery, transformSearchToFilter } from '../../../helpers/search';
import * as pth from '../../../helpers/proptypes';
import { snakeCaseKeysDeep } from '../../../helpers/misc';

import FilterBarBase from '../../presentationals/filterbar';

// =============================
// Component
// =============================

class FilterBar extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Initial search filters (even those non available anymore due to filtering). */
    initialFacets: pth.facets.isRequired,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: PropTypes.bool.isRequired,
    /** If True, MusicPlayer is displayed in minimized styles. */
    isMusicPlayerMinimized: PropTypes.bool.isRequired,
    /** If True, MusicPlayer is opened. */
    isMusicPlayerOpened: PropTypes.bool.isRequired,
    /** If True, we are on the Search Page. */
    isSearchPage: PropTypes.bool,
    search: pth.searchquery.isRequired,
    t: PropTypes.func.isRequired,
    /** All Tags Tree (even those not used in any tracks) */
    tags: PropTypes.arrayOf(pth.tagCategory).isRequired,
    /** All Versions. */
    versions: PropTypes.arrayOf(pth.trackVersion).isRequired,
  };

  static defaultProps = {
    isSearchPage: false,
  };

  handleChange = (value) => {
    const { search } = this.props;

    const query = composeQuery(search, value);
    Router.push(`/search?${query}`);
  };

  handleLyricsChange = (value) => {
    const { search } = this.props;

    const filters = _get(search, 'options.filters', {});
    const query = composeQuery(search, { ...filters, searchLyrics: value });

    Router.push(`/search?${query}`);
  };

  render() {
    const {
      config,
      facets,
      i18n: { language },
      initialFacets,
      isDoingMaiaSearch,
      isMusicPlayerMinimized,
      isMusicPlayerOpened,
      isSearchPage,
      search,
      t,
      tags,
      versions,
      ...rest
    } = this.props;

    const lang = {
      bpm: t('components:filter_bar.bpm'),
      duration: t('components:filter_bar.duration'),
      input: {
        search: t('components:filter_bar.search'),
        pressEnter: t('components:filter_bar.press_enter'),
        Label: {
          optional: t('components:mewo_inputs.Label.optional'),
        },
      },
      lyrics: t('components:filter_bar.lyrics'),
      maiaLoading: t('components:filter_bar.maia_loading'),
      noTags: t('components:filter_bar.no_tags'),
      onlyStems: t('components:filter_bar.only_stems'),
      showUnavailableFilters: t('components:filter_bar.show_unavailable_filters'),
      stems: t('components:filter_bar.stems'),
      title: t('components:filter_bar.title'),
      versions: t('components:filter_bar.versions'),
      year: t('components:filter_bar.year'),
    };

    return (
      <FilterBarBase
        displaySearchBar={!isDoingMaiaSearch}
        facets={facets}
        initialFacets={initialFacets}
        isMusicPlayerMinimized={isMusicPlayerMinimized}
        isMusicPlayerOpened={isMusicPlayerOpened}
        lang={lang}
        locale={language}
        maiaLoading={isDoingMaiaSearch}
        onChange={this.handleChange}
        onLyricsChange={this.handleLyricsChange}
        opened={isSearchPage}
        showUnavailableFilters={_get(config, 'customisations.filterBar.showUnavailableTags', false)}
        value={transformSearchToFilter(search)}
        tags={snakeCaseKeysDeep(tags)}
        versions={snakeCaseKeysDeep(versions)}
        {...rest}
      />
    );
  }
}

function mapStateToProps({ config, options, player, search }) {
  return {
    config: config.data,
    facets: search.tracksSearch.data.facets,
    initialFacets: search.tracksSearch.data.initialFacets,
    /** If True, a Maia search is ongoing. */
    isDoingMaiaSearch: search.tracksSearch.isDoingMaiaSearch,
    /** If True, MusicPlayer is displayed in minimized styles. */
    isMusicPlayerMinimized: _get(config.data, 'customisations.player.size') === 'small',
    /** If True, MusicPlayer is opened. */
    isMusicPlayerOpened: player.isOpened,
    search: search.tracksSearch.query,
    tags: options.data.tags,
    versions: options.data.trackversions,
  };
}

export default compose(
  withTranslation('components'),
  connect(mapStateToProps),
)(FilterBar);
