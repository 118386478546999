// =============================
// Imports
// =============================

import { FilterBar } from './styles';

// =============================
// Exports
// =============================

FilterBar.displayName = 'FilterBarMobile';

export default FilterBar;
