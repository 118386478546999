// =============================
// Imports
// =============================

// External dependencies
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

// Actions
import { reorderPlaylistTrack as reorderPlaylistTrackBase } from '../store/actions/UserPlaylistsActions';
import { resetModals } from '../store/actions/ModalsActions';

// Components
import AppShell from '../components/presentationals/appshell';
import MusicPlayer from '../components/containers/musicplayer';

// =============================
// Page
// =============================

class Drag extends PureComponent {
  static propTypes = {
    isSimpleLayout: PropTypes.bool.isRequired,
    locationKey: PropTypes.string.isRequired,
    routeKey: PropTypes.string.isRequired,
    /** If True, we are on the Search Page. */
    isSearchPage: PropTypes.bool.isRequired,
    isPreSearchOpen: PropTypes.bool.isRequired,
    reorderPlaylistTrack: PropTypes.func.isRequired,
    closeAllModals: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  };

  handleOnDragEnd = (event) => {
    const { reorderPlaylistTrack } = this.props;

    if (event.type === 'userPlaylistReorder') {
      if (event.destination) {
        reorderPlaylistTrack(
          event.destination.droppableId,
          event.draggableId,
          event.source.index,
          event.destination.index,
        );
      }
    }
  };

  render() {
    const {
      children,
      isPreSearchOpen,
      isSimpleLayout,
      locationKey,
      routeKey,
      isSearchPage,
      closeAllModals,
    } = this.props;

    return (
      <DragDropContext onDragEnd={this.handleOnDragEnd}>
        <AppShell
          locationKey={locationKey}
          routeKey={routeKey}
          isSimpleLayout={isSimpleLayout}
          isSearchPage={isSearchPage}
          isPreSearchOpen={isPreSearchOpen}
          closeAllModals={closeAllModals}
        >
          {children}
        </AppShell>
        <MusicPlayer isSimpleLayout={isSimpleLayout} />
      </DragDropContext>
    );
  }
}

function mapStateToProps({ modals }) {
  return {
    isPreSearchOpen: modals.isPreSearchOpen,
  };
}

export default connect(mapStateToProps, {
  reorderPlaylistTrack: reorderPlaylistTrackBase,
  closeAllModals: resetModals,
})(Drag);
