// =============================
// Imports
// =============================

import styled from 'styled-components';

// =============================
// Styles
// =============================

export const Wrapper = styled.div``;

export const ActualLang = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;
