// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// Config
import { i18n } from '../config/i18n';

// =============================
// Helpers
// =============================

/**
 * Get error message depending on error
 * @param {object} err - Error object
 * @return {string} - Error message
 */
export default function determineError(err) {
  if (!err.response) {
    console.error(err);

    return i18n.t('errors:global.network_error');
  }

  const { data } = err.response;
  switch (true) {
    case data.key === 'database_error':
      return i18n.t('errors:global.database_error');

    case data.key === 'job_worker_error':
      return i18n.t('errors:global.job_worker_error');

    case data.key === 'api_not_found':
      return i18n.t('errors:global.api_not_found');

    case data.key === 'invalid_config':
      return i18n.t('errors:global.invalid_config');

    case data.key === 'config_not_found':
      return i18n.t('errors:global.config_not_found');

    case data.key?.toLowerCase() === 'invalid_access':
      return i18n.t('errors:auth.forbidden');

    case data.key === 'invalid_token':
      return i18n.t('errors:auth.session_expired');

    default:
      return i18n.t('errors:global.default_error');
  }
}

export class MewoError extends Error {
  constructor({ message: initialMessage, statusCode, error, type } = {}) {
    // if error is MewoError just return it
    if (error && error.name === 'MewoError') return error;

    const message = initialMessage || (error ? determineError(error) : '');
    super(message);

    this.name = this.constructor.name;
    this.type = type;
    this.originalError = error;
    this.statusCode = statusCode || _get(error, 'response.status', 400);
    this.reqId = _get(error, 'response.data.reqId');

    // capture js stack
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
