// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { withTranslation } from '../../../../../config/i18n';

import { getTrackTitle } from '../../../../../helpers/entity/track';
import * as pth from '../../../../../helpers/proptypes';

import {
  CloseModalBtn,
  Content,
  ContentTitle,
  EmptySpacePlaceholder,
  Header,
  Title,
  Wrapper,
} from '../../mobile.styles';
import {
  DefaultModal,
  TextWithLineBreaks,
} from '../../styles';

// =============================
// Stories
// =============================

class ModalTrackLyrics extends PureComponent {
  static displayName = 'ModalTrackLyricsMobile';

  static propTypes = {
    /** Function used to close modal */
    closeTrackLyricsModal: PropTypes.func.isRequired,
    /** If True, Modal is open */
    opened: PropTypes.bool,
    /** Translation strings. */
    t: PropTypes.func.isRequired,
    /** Track */
    track: pth.track,
  };

  static defaultProps = {
    opened: false,
    track: null,
  };

  render() {
    const { closeTrackLyricsModal, opened, t, track } = this.props;

    if (!track) return null;

    const lyrics = _get(track, 'lyrics');

    return (
      <DefaultModal
        name="trackLyrics"
        noBackground
        noBottomFade
        noTopMask
        onClose={closeTrackLyricsModal}
        opened={opened}
      >
        <Wrapper>
          <Header>
            <CloseModalBtn onClick={closeTrackLyricsModal}>
              {t('components:modal.close')}
            </CloseModalBtn>
            <Title>{t('components:modal_track_lyrics.lyrics')}</Title>
            <EmptySpacePlaceholder />
          </Header>
          <Content>
            <ContentTitle centered>{getTrackTitle(track)}</ContentTitle>
            <TextWithLineBreaks>{lyrics}</TextWithLineBreaks>
          </Content>
        </Wrapper>
      </DefaultModal>
    );
  }
}

export default withTranslation('pages')(ModalTrackLyrics);
