// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { alpha } from '@material-ui/core';

import Button from '../button';

// =============================
// Styled Components
// =============================

export const VeilWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  background-color: ${({ theme }) => alpha(theme.colors.currentTheme.background, 0.9)};
  transition: opacity ${({ theme }) => theme.transition.medium}
    ${({ theme }) => theme.transitionTiming.easeInOut};
  z-index: ${({ theme }) => theme.zIndex.notifications + 1};

  ${({ show }) => show
    && css`
      opacity: 1;
      pointer-events: auto;
    `};
`;

export const Container = styled.div`
  border-radius: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding: 1.6rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;

  ${({ theme }) => theme.mediaAbove.minWidth`
    width: 45rem;
  `};

  ${({ theme }) => theme
    && css`
      color: ${theme.colors.text};
      background-color: ${theme.colors.background};
    `}
`;

export const TextButton = styled.div`
  cursor: pointer;
  display: inline;
  text-decoration: underline;
`;

export const PolicyTitle = styled.div`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 2.4rem;
`;

export const Policy = styled.div`
  display: inline;
`;

export const CookieCategoryWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const CookieChoiceWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 5rem 1fr;
`;

export const CookieTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CookieDesc = styled.div`
  margin-top: 1rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  `};
`;

export const StyledButton = styled(Button).attrs(() => ({
  size: 'small',
}))`
  margin: 0 auto;
`;

export const RefuseAllButton = styled.div`
  text-align: right;
  margin-bottom: 2rem;
  text-decoration: underline;
  cursor: pointer;
`;
