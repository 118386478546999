// =============================
// Helpers
// =============================

/**
 * Find value in locale, falling back to english or first value in values list
 * @param {array} array - Array of values
 * @param {string} locale - Locale of user
 * @return {string} - Value
 */
export function findByLocale(array, locale) {
  return array.find(e => e.locale === locale) || array.find(e => e.locale === 'en') || array[0];
}

/**
 * Get value in the desired locale
 * @param {array} array - Array of values
 * @param {string} locale - Locale of user
 * @return {string} - Value
 */
export function getValueByLocale(array = [], locale = '') {
  return (findByLocale(array, locale) || {}).value;
}
