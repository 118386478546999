// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'next/router';
import _get from 'lodash/get';

import {
  downloadArchives as downloadArchivesBase,
  downloadCopyright as downloadCopyrightBase,
} from '../../../store/actions/EntitiesActions';
import {
  addToFavorites as addToFavoritesBase,
  removeFromFavorites as removeFromFavoritesBase,
} from '../../../store/actions/FavoritesActions';

import {
  getVersions as getVersionsBase,
  getFiles as getFilesBase,
  notifyFileDownload as notifyFileDownloadBase,
  downloadAudiofile as downloadAudiofileBase,
} from '../../../store/actions/TrackActions';
import {
  getMedia as getMediaBase,
  play as playBase,
  pause as pauseBase,
  seek as seekBase,
} from '../../../store/actions/PlayerActions';
import {
  setTrackLyricsOpen as setTrackLyricsOpenBase,
  setMusicItemOpen as setMusicItemOpenBase,
} from '../../../store/actions/ModalsActions';

import * as playerStates from '../../../store/constants/PlayerStates';

import MusicItemBase from '../../presentationals/musicitem';

import { getTrackTitle } from '../../../helpers/entity/track';
import { hasAccessToPrivate } from '../../../helpers/user';
import { encodeAndRemovePercent } from '../../../helpers/misc';

import * as pth from '../../../helpers/proptypes';

// =============================
// Component
// =============================

class MusicItem extends PureComponent {
  static propTypes = {
    addToFavorites: PropTypes.func.isRequired,
    canDownload: PropTypes.bool.isRequired,
    canDownloadStems: PropTypes.bool.isRequired,
    downloadArchives: PropTypes.func.isRequired,
    downloadCopyright: PropTypes.func.isRequired,
    downloadAudiofile: PropTypes.func.isRequired,
    filesStore: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    getFiles: PropTypes.func.isRequired,
    getMedia: PropTypes.func.isRequired,
    getVersions: PropTypes.func.isRequired,
    isFetchedVersions: PropTypes.bool.isRequired,
    isFetchingVersions: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    music: pth.track.isRequired,
    onDelete: PropTypes.func,
    pause: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    playerStore: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    removeFromFavorites: PropTypes.func.isRequired,
    seek: PropTypes.func.isRequired,
    showExtraOptionsDescription: PropTypes.bool,
    showExtraOptionsTags: PropTypes.bool,
    versions: PropTypes.arrayOf(pth.track).isRequired,
  };

  static defaultProps = {
    onDelete: null,
    showExtraOptionsDescription: false,
    showExtraOptionsTags: false,
  };

  remove = () => {
    const { music, onDelete } = this.props;

    if (onDelete) onDelete(music);
  };

  addToFavorites = (music) => {
    const { addToFavorites, removeFromFavorites } = this.props;

    if (music.isFavorite) removeFromFavorites(music.id);
    else addToFavorites(music.id);
  };

  openDetailsPassthrough = () => {
    const { music, getVersions, isFetchedVersions, isFetchingVersions } = this.props;

    if (isFetchedVersions || isFetchingVersions || music.versions <= 1) return;
    getVersions(music.id);
  };

  openFilesPassthrough = (music) => {
    const { getFiles, filesStore } = this.props;

    const isFetchingFiles = _get(filesStore, `[${music.id}].isFetching`, false);
    const isFetchedFiles = _get(filesStore, `[${music.id}].isFetchedOnce`, false);

    // We fetch each time the version in case there was changes
    if (isFetchedFiles || isFetchingFiles) return;
    getFiles(music.id);
  };

  getFilesDatas = (music) => {
    const { filesStore } = this.props;

    return _get(filesStore, music.id, {});
  };

  isCurrent = (trackId, contextName, contextId = null) => {
    const { playerStore } = this.props;

    return (
      _get(playerStore, 'current.id', null) === trackId
      && _get(playerStore, 'contextName', null) === contextName
      && _get(playerStore, 'contextId', null) === contextId
    );
  };

  isPlaying = (trackId, contextName, contextId = null) => {
    const { playerStore } = this.props;

    return !!(
      this.isCurrent(trackId, contextName, contextId)
      && _get(playerStore, 'playerState') === playerStates.PLAYING
    );
  };

  handleMagicWand = (music) => {
    const query = encodeAndRemovePercent(JSON.stringify([music.id, getTrackTitle(music)]));
    Router.push(`/search?track_id=${query}`);
  };

  handleDownloadArchive = (music, quality = 'original') => {
    const { downloadArchives } = this.props;

    downloadArchives('track', [music.id], quality, true);
  };

  handleDownloadCopyright = (music) => {
    const { downloadCopyright } = this.props;

    downloadCopyright('track', music.id, getTrackTitle(music));
  };

  handleDownloadAudiofile = (music, quality = 'hd_mp3') => {
    const { downloadAudiofile } = this.props;

    downloadAudiofile(music.id, quality);
  };

  render() {
    const {
      music,
      isFetchingVersions,
      versions,
      onDelete,
      canDownload,
      canDownloadStems,
      isLogged,
      getMedia,
      play,
      seek,
      pause,
      ...rest
    } = this.props;

    const onDeleteVal = onDelete ? this.remove : null;

    return (
      <MusicItemBase
        canDownload={canDownload}
        canDownloadStems={canDownloadStems}
        getFilesDatas={this.getFilesDatas}
        getMedia={getMedia}
        handleDownloadArchive={this.handleDownloadArchive}
        handleDownloadCopyright={this.handleDownloadCopyright}
        handleDownloadAudiofile={this.handleDownloadAudiofile}
        isCurrent={this.isCurrent}
        isFetchingVersions={isFetchingVersions}
        isLogged={isLogged}
        isPlaying={this.isPlaying}
        music={music}
        onAddToFavorites={this.addToFavorites}
        onDelete={onDeleteVal}
        onMagicWand={this.handleMagicWand}
        onOpenFilesPassthrough={this.openFilesPassthrough}
        onOpenDetailsPassthrough={this.openDetailsPassthrough}
        onPause={pause}
        play={play}
        seek={seek}
        versions={versions}
        {...rest}
      />
    );
  }
}

// Cache default value, so it reference won't differ
const defaultVersions = [];

function mapStateToProps({ track, user, player, search, config, options }, { music }) {
  return {
    canDownload: _get(user, 'data.canDownload', false),
    canDownloadStems: _get(user, 'data.canDownloadStems', false),
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    filesStore: _get(track, 'files', {}),
    getCurrentTime: player.getCurrentTime,
    isFetchingVersions: _get(track, `versions[${music.id}].isFetching`, false),
    isFetchedVersions: _get(track, `versions[${music.id}].isFetchedOnce`, false),
    isLogged: _get(user, 'isLogged', false),
    pitchToken: user.pitchToken,
    playerStore: player,
    tags: options.data.tags_only,
    search: search.tracksSearch.query,
    showExtraOptionsDescription: track.descShown,
    showExtraOptionsTags: track.tagsShown,
    versions: _get(track, `versions[${music.id}].data`, defaultVersions),
  };
}

export default connect(mapStateToProps, {
  addToFavorites: addToFavoritesBase,
  downloadArchives: downloadArchivesBase,
  downloadCopyright: downloadCopyrightBase,
  downloadAudiofile: downloadAudiofileBase,
  getFiles: getFilesBase,
  getMedia: getMediaBase,
  getVersions: getVersionsBase,
  notifyFileDownload: notifyFileDownloadBase,
  pause: pauseBase,
  play: playBase,
  removeFromFavorites: removeFromFavoritesBase,
  seek: seekBase,
  setTrackLyricsOpen: setTrackLyricsOpenBase,
  toggleMusicItemModal: setMusicItemOpenBase,
})(MusicItem);
