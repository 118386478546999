// =============================
// Imports
// =============================

import { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _some from 'lodash/some';
import _sortBy from 'lodash/sortBy';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';

import { composeQuery } from '../../../../../../helpers/search';
import { getValueByLocale } from '../../../../../../helpers/i18n';
import * as pth from '../../../../../../helpers/proptypes';

import {
  Wrapper,
  Title,
  Tag,
  SeeResultButton,
  TagsList,
  Tabs,
  Tab,
  TabCounter,
  StyledFilters,
  TabsBorder,
  SeeResultLink,
} from './styles';

// =============================
// Stories
// =============================

class GuidedSearch extends Component {
  static propTypes = {
    closePreSearchModal: PropTypes.func.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    /** Initial search filters (even those non available anymore due to filtering). */
    initialFacets: pth.facets.isRequired,
    locale: PropTypes.string.isRequired,
    searchQuery: pth.searchquery.isRequired,
    searchTracksTotal: PropTypes.shape({
      value: PropTypes.number,
      relation: PropTypes.oneOf(['gte', 'eq']),
    }).isRequired,
    t: PropTypes.func.isRequired,
    /** All existing tags subCategory. (Guided search only show subCategory tags). */
    tagsSc: PropTypes.arrayOf(pth.tagSubCategory).isRequired,
  };

  constructor(props) {
    super(props);

    const usedRootCats = this.getUsedRootCats();
    const activeRootCatKey = Object.keys(usedRootCats)[0];

    this.state = {
      activeTagCategory: activeRootCatKey,
    };
  }

  changeActiveTagsCategory = key => this.setState({
    activeTagCategory: key,
  });

  handleSubCatClick = (subCatId) => {
    const { searchQuery } = this.props;

    const filters = _get(searchQuery, 'options.filters', {});
    let tagsOrFilters = _get(filters, 'tags_or', []);
    const otherFilters = _omit(filters, ['tags_or']);

    if (tagsOrFilters.indexOf(subCatId) === -1) {
      tagsOrFilters.push(subCatId);
    } else {
      tagsOrFilters = tagsOrFilters.filter(subCat => subCat !== subCatId);
    }

    const query = composeQuery(searchQuery, { tags_or: tagsOrFilters, ...otherFilters });

    Router.replace(`/search?${query}`);
  };

  getUsedTagsSc = () => {
    const { initialFacets, tagsSc } = this.props;

    // Excluded categories
    const excludedTagsCat = [
      'periods',
      'sound-design',
      'structures',
      'style',
      'technics',
    ];

    // Group tags by category.
    const groupedTagsSc = _omit(_groupBy(tagsSc, 'category_key'), excludedTagsCat);

    // Get a list of tag id used in catalog.
    const initialFacetsTags = Object.keys(initialFacets.tags);

    // Get tags sub categories actually used in catalog
    const usedTagsSc = {};

    Object.keys(groupedTagsSc).forEach((categoryKey) => {
      usedTagsSc[categoryKey] = [];

      groupedTagsSc[categoryKey].forEach((subCat) => {
        // We need to check if sub cat has any tags used in catalog
        if (subCat.tags) {
          const subCatTags = _filter(subCat.tags, t => _includes(initialFacetsTags, t.id));

          if (subCatTags.length) {
            usedTagsSc[categoryKey].push(subCat);
          }
        }
      });
    });

    return usedTagsSc;
  };

  getUsedRootCats = () => {
    const { initialFacets, searchQuery, tagsSc, locale } = this.props;

    // Excluded categories
    const excludedTagsCat = [
      'periods',
      'sound-design',
      'structures',
      'style',
      'technics',
    ];

    // Group tags subcategories by category.
    const groupedTagsSc = _omit(_groupBy(tagsSc, 'category_key'), excludedTagsCat);

    // Get a list of tag ids used in catalog
    const initialFacetsTags = Object.keys(initialFacets.tags);

    // Get tag category name used in catalog
    const usedCategories = {};

    const usedTagsSc = this.getUsedTagsSc();
    const activeSubCats = _get(searchQuery, 'options.filters.tags_or', []);

    Object.keys(groupedTagsSc).forEach((categoryKey) => {
      groupedTagsSc[categoryKey].forEach((subCat) => {
        // We retrieve name of category tag actually used in a catalog.
        if (_some(subCat.tags, tag => _includes(initialFacetsTags, tag.id))) {
          usedCategories[categoryKey] = {
            name: getValueByLocale(_get(subCat, 'category_names', []), locale),
            // We get the number of active tag present in this category.
            count: _filter(usedTagsSc[categoryKey], tag => _includes(activeSubCats, tag.id)).length,
          };
        }
      });
    });

    return usedCategories;
  };

  renderCategorySwitcher = () => {
    const { activeTagCategory } = this.state;

    const usedRootCats = this.getUsedRootCats();

    return Object.keys(usedRootCats).map(key => (
      <Tab
        active={activeTagCategory === key}
        key={key}
        onClick={() => this.changeActiveTagsCategory(key)}
      >
        {usedRootCats[key].name}
        {!!usedRootCats[key].count && <TabCounter>{usedRootCats[key].count}</TabCounter>}
      </Tab>
    ));
  };

  renderTagsScList = () => {
    const { facets, locale, searchQuery } = this.props;
    const { activeTagCategory } = this.state;

    const usedTagsSc = this.getUsedTagsSc();
    const activeSubCats = _get(searchQuery, 'options.filters.tags_or', []);
    const facetsTags = Object.keys(facets.tags);

    // Sort tags by name.
    const sortedTags = _sortBy(_get(usedTagsSc, activeTagCategory, []), tag => getValueByLocale(_get(tag, 'names', []), locale));

    return sortedTags.map((tagSc) => {
      const isAvailable = !!_filter(tagSc.tags, t => _includes(facetsTags, t.id)).length;

      return (
        <Tag
          key={tagSc.id}
          name={getValueByLocale(_get(tagSc, 'names', []), locale)}
          disabled={!isAvailable}
          indicationColor={tagSc.color}
          color={activeSubCats.indexOf(tagSc.id) !== -1 ? 'primary' : 'default'}
          onClick={isAvailable ? () => this.handleSubCatClick(tagSc.id) : null}
        />
      );
    });
  };

  renderResultButton = () => {
    const { closePreSearchModal, searchTracksTotal, t } = this.props;

    const totalTracks = _get(searchTracksTotal, 'value');

    if (totalTracks > 0) {
      return (
        <SeeResultButton onClick={closePreSearchModal}>
          {t('pages:search.pre_search_see_tracks', { amount: totalTracks })}
        </SeeResultButton>
      );
    }

    return (
      <SeeResultLink route="/search">
        <SeeResultButton>{t('pages:search.pre_search_see_all_tracks')}</SeeResultButton>
      </SeeResultLink>
    );
  };

  render() {
    const { t } = this.props;

    const tagsScList = this.renderTagsScList();

    return (
      <Wrapper>
        <Title>{t('pages:search.pre_search_guided_search')}</Title>
        <StyledFilters />

        <Tabs>
          <TabsBorder>{this.renderCategorySwitcher()}</TabsBorder>
        </Tabs>

        <TagsList>{tagsScList}</TagsList>

        {this.renderResultButton()}
      </Wrapper>
    );
  }
}

export default GuidedSearch;
