// =============================
// Imports
// =============================

import { connect } from 'react-redux';

import {
  clearNotification,
  clearAllNotifications,
} from '../../../store/actions/NotificationActions';

import NotificationsList from '../../presentationals/notificationsList';

// =============================
// Component
// =============================

function mapStateToProps({ notifications }) {
  return {
    notifications,
  };
}

export default connect(mapStateToProps, { clearNotification, clearAllNotifications })(
  NotificationsList,
);
