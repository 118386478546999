// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import posed from 'react-pose';
import { Flex, WithCustomTheme } from '@mewo/components';

import Button from '../../presentationals/button';
import Input from '../../presentationals/inputs/input';
import Link from '../../other/link';
import SelectInput from '../../presentationals/inputs/selectinput';
import CheckboxInput from '../../presentationals/inputs/checkboxinput';
import UserLanguages from '../../containers/userLanguages';

import { headerHeight as headerHeightMobile } from '../../presentationals/header/mobile/variables';

// =============================
// Styles
// =============================

export const WrapperBase = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;

  ${({ isModal }) => !isModal
    && css`
      ${({ theme }) => theme.mediaBelow.minWidth`
        min-height: 100%;
        width: 100%;
      `};

      ${({ theme }) => theme.mediaAbove.minWidth`
        height: 100%;
        width: 100%;
      `};
    `};
`;

export const Wrapper = props => (
  <WithCustomTheme
    customTheme={theme => ({
      colors: theme.colors.pages.auth,
    })}
  >
    <WrapperBase {...props} />
  </WithCustomTheme>
);

export const Container = posed(styled.div`
  ${({ theme }) => theme.mediaBelow.minWidth`
    display: flex;
    flex-direction: column;
    padding: calc(${headerHeightMobile} + 4rem) 1.2rem 1.2rem 1.2rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    margin: auto;
    width: 60rem;
  `};
`)({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

export const AuthTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 3rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: 5rem;

  ${({ withSubInfo }) => withSubInfo
    && css`
      margin-bottom: 2rem;
    `};
`;

export const StyledForm = styled.form`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const InputTitle = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.2rem;
`;

export const InputWrapper = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.2rem;

  > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
`;

export const DoubleInputWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.mediaBelow.minWidth`
    flex-direction: column;

    > * {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    > *:first-child {
      margin-right: 0.4rem;
      width: calc(50% - 0.4rem);
    }

    > *:last-child {
      margin-left: 0.4rem;
      width: calc(50% - 0.4rem);
    }

    > *:first-child:last-child {
      margin: 0;
      width: 100%;
    }
  `};
`;

export const ButtonWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaBelow.minWidth`
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  `};

  ${({ theme }) => theme.mediaAbove.minWidth`
    justify-content: space-between;
    font-size: 1.4rem;
    margin-top: 3rem;

    ${({ centeredChildren }) => (centeredChildren
    ? css`
            justify-content: center;
          `
    : css`
            /** If we only show 1 button, align it on the right */
            > *:first-child:last-child {
              margin-left: auto;
            }
          `)};
  `};
`;

export const ButtonLink = styled(Link)`
  ${({ theme }) => (theme.isMobile()
    ? css`
          width: 100%;
        `
    : null)};
`;

export const LinksWrapper = styled.div`
  font-size: 1.3rem;
  margin-bottom: 1.6rem;
`;

export const AgreeToTOS = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-top: 3rem;

  > * {
    color: ${({ theme }) => theme.colors.text};
    display: inline;
  }
`;

/** Use this when link point to a route */
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.pages.auth.primary};
  margin-top: ${({ mt }) => mt || 0};
  transition: opacity ${({ theme }) => theme.transition.fast};

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledInput = styled(Input).attrs({
  containerClassName: 'containerClassName',
  variant: 'background',
})`
  & .containerClassName {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
    min-height: 4rem;

    input {
      color: ${({ theme }) => theme.colors.input.text};
    }
  }
`;

export const StyledButton = styled(Button).attrs(({ theme }) => ({
  fullWidth: theme.isMobile(),
  size: 'small',
}))`
  ${({ firstOnMobile, theme }) => firstOnMobile && theme.mediaBelow.minWidth`
    order: -1;
  `};
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  color: ${({ theme }) => theme.colors.input.text};
`;

export const StyledSelectInput = styled(SelectInput).attrs({
  containerClassName: 'containerClassName',
  dropdownClassName: 'dropdownClassName',
  variant: 'background',
})`
  & .containerClassName {
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 0;
    color: ${({ theme }) => theme.colors.input.text};
    min-height: 4rem;

    input {
      color: ${({ theme }) => theme.colors.input.text};
    }
  }

  & .dropdownClassName {
    background-color: ${({ theme }) => theme.colors.dropdown.background};
    color: ${({ theme }) => theme.colors.dropdown.text};
  }
`;

export const Notification = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  width: 100%;

  ${({ theme, type }) => (type === 'error'
    ? css`
          color: ${theme.colors.type.error};
        `
    : css`
          color: ${theme.colors.type.success};
        `)};
`;

export const StyledUserLanguages = styled(UserLanguages)`
  ${({ theme }) => theme.mediaBelow.minWidth`
    bottom: 0;
    left: 0;
    margin-top: auto;
    position: relative;
  `};
`;
