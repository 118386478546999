// =============================
// Helpers
// =============================

/**
 * Get type of hover needed based on color
 * @param {Object} colors - Colors object
 * @returns {Object} - Returns true if we should use opacity for hover.
 */
export const isHoverOpacity = colors => colors.text === colors.primary;

/**
 * Get type of hover needed based on color
 * @param {Object} colors - Colors object
 * @returns {Object} - Returns true if we should use primary color for hover.
 */
export const isHoverPrimary = colors => colors.text !== colors.primary;
