// =============================
// Imports
// =============================

import styled, { css } from 'styled-components';
import { Dropdown as DropdownBase, DropdownList as DropdownListBase } from '@mewo/components';

import { isHoverPrimary } from '../../../helpers/front/getHoverType';

import Link from '../../other/link';

// =============================
// Styles
// =============================

export const Dropdown = styled(DropdownBase).attrs(({ theme }) => ({
  contentStyles: css`
    max-height: 20rem;
  `,
  dropdownStyles: css`
    background: ${theme.colors.dropdown.background};
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.boxShadow};
    color: ${theme.colors.dropdown.text};
    font-size: 1.2rem;
    font-weight: ${theme.fontWeight.semibold};
    padding: 0.8rem 0;
  `,
  withoutStyles: true,
}))``;

export const DropdownList = styled(DropdownListBase).attrs(({ renderOption, theme }) => ({
  contentStyles: css`
    max-height: none;
  `,
  dropdownStyles: css`
    background: ${theme.colors.dropdown.background};
    box-shadow: 0 0.4rem 1.5rem 0 ${theme.colors.boxShadow};
    color: ${theme.colors.dropdown.text};
    font-size: 1.2rem;
    font-weight: ${theme.fontWeight.semibold};
    padding: 0.8rem 0;
  `,
  renderOption:
    renderOption || (option => <Option onClick={option.action || null}>{option.label}</Option>),
  optionsContainerStyles: css`
    max-height: 20rem;
  `,
  withoutStyles: true,
}))`
`;

export const Option = styled.div`
  color: ${({ theme }) => theme.colors.dropdown.text};
  text-decoration: none;
  transition: color ${({ theme }) => theme.transition.medium},
    opacity ${({ theme }) => theme.transition.medium};
  padding: 0.8rem 1.2rem;
  white-space: nowrap;
  width: 100%;

  ${({ noHover, theme }) => !noHover && (isHoverPrimary(theme.colors.dropdown)
    ? css`
      cursor: pointer;

      &:hover {
        color: ${theme.colors.dropdown.primary};
      }
    `
    : css`
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    `)};
`;

export const OptionLink = styled(Option).attrs({
  as: Link,
})`
  * > {
    text-decoration: none;
  }
`;
