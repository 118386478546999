// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import removeAccents from 'remove-accents';
import _get from 'lodash/get';
import _omit from 'lodash/omit';

import { withTranslation } from '../../../config/i18n';

import { hasAccessToPrivate } from '../../../helpers/user';
import { composeQuery } from '../../../helpers/search';
import { getValueByLocale } from '../../../helpers/i18n';
import * as pth from '../../../helpers/proptypes';

import { StyledTag } from './styles';

// =============================
// Component
// =============================

class Tag extends PureComponent {
  static propTypes = {
    /* Tag data */
    data: pth.tagWithCat.isRequired,
    /** Set to true to check if tag is already used */
    checkIfUsable: PropTypes.bool,
    /** Props used by styled component to override styles */
    className: PropTypes.string,
    /** Tag Color. */
    color: PropTypes.oneOf(['default', 'primary', PropTypes.string]),
    /** Site config. */
    config: pth.config.isRequired,
    /** Search filters (after being filtered by availability). */
    facets: pth.facets.isRequired,
    /** If True, has access to website when private. */
    hasAccess: PropTypes.bool.isRequired,
    /** Language */
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    /** Action to do with at the same time as onClick */
    onClickPassthrough: PropTypes.func,
    /** Search query */
    search: pth.searchquery.isRequired,
    /** Current string search value, used to try and display matching synonyms */
    stringSearchValue: PropTypes.string,
    /** Tags with colors and cat names */
    tagsWithColorsAndCatNames: PropTypes.arrayOf(pth.tagWithColorAndCatNames).isRequired,
    /** Show synonyms */
    withSynonyms: PropTypes.bool,
  };

  static defaultProps = {
    checkIfUsable: false,
    className: '',
    color: 'default',
    onClickPassthrough: null,
    stringSearchValue: null,
    withSynonyms: false,
  };

  handleTagClick = (tagId) => {
    const { onClickPassthrough, search } = this.props;

    const filters = _get(search, 'options.filters', {});
    let tagFilters = _get(filters, 'tags', []);
    const otherFilters = _omit(filters, ['tags']);

    if (tagFilters.indexOf(tagId) === -1) {
      tagFilters.push(tagId);
    } else {
      tagFilters = tagFilters.filter(tag => tag !== tagId);
    }

    const query = composeQuery(search, { tags: tagFilters, ...otherFilters });

    Router.push(`/search?${query}`);

    if (onClickPassthrough) {
      onClickPassthrough();
    }
  };

  render() {
    const {
      className,
      checkIfUsable,
      data,
      facets,
      hasAccess,
      i18n: { language },
      search,
      stringSearchValue,
      tagsWithColorsAndCatNames,
      withSynonyms,
    } = this.props;

    const foundTag = tagsWithColorsAndCatNames.find(tag => tag.id === data.id);

    const tagUsed = _get(search, 'options.filters.tags', []).indexOf(data.id) !== -1;
    const tagAvailable = Object.keys(_get(facets, 'tags', {})).indexOf(data.id) !== -1;

    let name = getValueByLocale(_get(data, 'names', []), language);
    const searchName = getValueByLocale(_get(data, 'searchNames', []), language);

    if (
      withSynonyms
      && stringSearchValue
      && data.synonyms.length
      && data.searchSynonyms.length
    ) {
      const synonym = data.synonyms.find(e => e.locale === language);
      const searchSynonym = data.searchSynonyms.find(e => e.locale === language);

      if (synonym && searchSynonym) {
        // NOTE: This condition mimics elastic search mecanism
        // if we change it, we need to change it here to
        const searchValues = removeAccents(stringSearchValue).toLowerCase().split(/\s+/);

        const matchedSynonym = searchSynonym.values.find((value) => {
          const synonymValues = value.split(/\s+/);

          return searchValues.every(searchValue => synonymValues
            .find(synonymValue => synonymValue.indexOf(searchValue) === 0));
        });

        if (matchedSynonym) {
          const nameValues = searchName.split(/\s+/);

          const matchedName = searchValues.every(searchValue => nameValues
            .find(nameValue => nameValue.indexOf(searchValue) === 0));

          if (!matchedName && matchedSynonym) {
            name = `${name} (${matchedSynonym})`;
          }
        }
      }
    }

    const chipProps = {
      name,
      ...(checkIfUsable && tagUsed ? { color: foundTag.color } : {}),
      ...(!checkIfUsable || (checkIfUsable && !tagUsed)
        ? {
          indicationColor: foundTag.color,
          indicationTooltipMessage: getValueByLocale(_get(foundTag, 'categoryNames', []), language),
        }
        : {}),
      ...(hasAccess && (!checkIfUsable || (checkIfUsable && tagAvailable))
        ? { onClick: () => this.handleTagClick(data.id) }
        : {}),
    };

    return (
      <StyledTag disabled={checkIfUsable && !tagAvailable} {...chipProps} className={className} />
    );
  }
}

function mapStateToProps({ user, config, search, options }) {
  return {
    config: config.data,
    facets: search.tracksSearch.data.facets,
    /** If True, has access to website when private. */
    hasAccess: hasAccessToPrivate(user, config),
    search: search.tracksSearch.query,
    tagsWithColorsAndCatNames: options.data.tags_colors_cat_names,
  };
}

export default compose(withTranslation(), connect(mapStateToProps))(Tag);
